import { TrashIcon } from '@heroicons/react/solid'
import { Checkbox, Form, FormError, FormLabel, Input } from '@plusplusminus/plusplusdash'
import ActionTypeSelect from 'components/ActionTypeSelect'
import Button from 'components/Button'
import MessageChannelSelect from 'components/MessageChannelSelect'
import { CreateMessageModal } from 'components/Modals/CreateMessageModal'
import Select from 'components/Select'
import { ActionEventType } from 'generated'
import { EmailTemplatesCollection } from 'hooks/useEmailTemplates'
import { TUsePaginatedQuery } from 'hooks/usePaginatedQuery'
import { MessagesResult } from 'pages/Message/MessagesTable'
import SearchMessage from 'pages/Message/SearchMessage'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ExtendedUpdateActionInput } from './Actions'

type UseFormType = ReturnType<typeof useForm>
type FormData = ExtendedUpdateActionInput

export type ActionFormProps = {
  isLoading: boolean
  reloadMessages: () => void
  paginatedMessages: TUsePaginatedQuery<MessagesResult>
  emailTemplateCollection: EmailTemplatesCollection
  onAddAction: (action: ExtendedUpdateActionInput) => void
  defaultValues: ExtendedUpdateActionInput
}

interface ConditionalFieldsProps {
  defaultValues: ExtendedUpdateActionInput
  control: UseFormType['control']
  errors: UseFormType['errors']
  watch: UseFormType['watch']
  register: UseFormType['register']
  unregister: UseFormType['unregister']
  setValue: (
    name: string,
    value: unknown,
    config?:
      | Partial<{
          shouldValidate: boolean
          shouldDirty: boolean
        }>
      | undefined
  ) => void
  clearErrors: (name?: string | string[] | undefined) => void
  paginatedMessages: TUsePaginatedQuery<MessagesResult>
  emailTemplateCollection: EmailTemplatesCollection
  reloadMessages: () => void
}

const ConditionalFields = ({
  control,
  defaultValues,
  watch,
  register,
  errors,
  paginatedMessages,
  emailTemplateCollection,
  reloadMessages,
  setValue,
  clearErrors
}: ConditionalFieldsProps) => {
  const fieldName = defaultValues.messageId
  const messageId = watch('messageId')
  const messageName = watch('messageName')
  const type = watch('type')
  let component = null
  const onCompleted = (id: string, fieldName: string) => {
    reloadMessages()
    clearErrors(fieldName)
    setValue(fieldName, id)
  }
  useEffect(() => {
    if (!!type) {
      register('messageId', { required: 'Message is required' })
      setValue('messageId', defaultValues.messageId)
      register('messageName')
    }
  }, [type, register, fieldName, setValue])
  const delayFields = (
    <FormLabel>
      <div className="flex gap-2">
        <strong>Delay</strong>
        <TrashIcon className="w-4 h-4 text-red-500 cursor-pointer" onClick={() => setValue(`delay.value`, 0)} />
      </div>
      <div className="flex gap-2">
        <div className="flex-1">
          <Input
            type="number"
            ref={register({ valueAsNumber: true })}
            width="full"
            variant="standard"
            name={`delay.value`}
            defaultValue={defaultValues.delay?.value ?? 0}
            placeholder="0"
            min={0}
          />
          {errors['delay.value']?.message ? <FormError>{errors['delay.value'].message}</FormError> : null}
        </div>
        <Controller
          key="delay-unit"
          control={control}
          name={`delay.unit`}
          render={({ onChange, value }) => (
            <div className="flex-1">
              <Select
                value={value}
                options={[
                  { label: 'Minutes', value: 'minutes', key: 'minutes' },
                  { label: 'Hours', value: 'hours', key: 'hours' },
                  { label: 'Days', value: 'days', key: 'days' }
                ]}
                onChange={onChange}
              />
            </div>
          )}
          defaultValue={defaultValues.delay?.unit ?? 'minutes'}
        />
      </div>
    </FormLabel>
  )

  switch (type) {
    case ActionEventType.SendMessage: {
      component = (
        <>
          {defaultValues.id ? <input type="hidden" name={`id`} value={defaultValues.id} ref={register} /> : null}
          <div className="relative col-start-1">
            <FormLabel>
              <strong>Message</strong>
              <SearchMessage
                initialMessage={
                  messageId
                    ? { messageId, messageName }
                    : { messageId: defaultValues.messageId, messageName: defaultValues.messageName }
                }
                onSelectedMessage={(message) => {
                  if (message?.messageId) {
                    clearErrors('messageId')
                    setValue('messageId', message.messageId)
                  }
                  setValue('messageName', message?.messageName ?? '')
                }}
                paginatedMessages={paginatedMessages}
                emailTemplateCollection={emailTemplateCollection}
                showSelectionsTags={false}
              />
              {errors['messageId']?.message ? <FormError>{errors['messageId'].message}</FormError> : null}
            </FormLabel>
            <CreateMessageModal
              className="absolute top-1 left-16"
              onCompleted={(id: string, name: string) => {
                onCompleted(name, `messageName`)
                onCompleted(id, `messageId`)
              }}
            />
          </div>
          {delayFields}
          <FormLabel className="col-span-2">
            <strong>Channels</strong>
            <Controller
              control={control}
              name="channels"
              rules={{
                required: 'Channels must be specified',
                validate: (value) => (value && value.length > 0) || 'Channels must be specified'
              }}
              render={({ onChange, value }) => <MessageChannelSelect value={value} onChange={onChange} />}
              defaultValue={defaultValues?.channels}
            />
            {errors['channels']?.message ? <FormError>{errors['channels'].message}</FormError> : null}
          </FormLabel>
        </>
      )
      break
    }
    case ActionEventType.IssueVoucher: {
      component = (
        <>
          {!!defaultValues.id && <input type="hidden" name={`id`} value={defaultValues.id} ref={register} />}
          <FormLabel className="col-start-1">
            <strong>Voucher Value (R)</strong>
            <Input
              width="full"
              type="number"
              variant="standard"
              ref={register({
                valueAsNumber: true,
                validate: (value) => {
                  const cast = Number(value)
                  return (Number.isInteger(cast) && cast > 0) || 'Must be a positive integer'
                },
                required: 'Value is required'
              })}
              name={`voucherValue`}
              defaultValue={defaultValues.voucherValue ?? 0}
            />
            {errors['voucherValue']?.message ? <FormError>{errors['voucherValue'].message}</FormError> : null}
          </FormLabel>
          <FormLabel>
            <strong>Expiry Date</strong>
            <div className="flex gap-2">
              <div className="flex-1">
                <Input
                  type="number"
                  ref={register({ valueAsNumber: true })}
                  width="full"
                  variant="standard"
                  name={`expiryDate.value`}
                  defaultValue={defaultValues.expiryDate?.value}
                  placeholder="0"
                  min={0}
                />
                {errors.expiryDate?.value?.message ? <FormError>{errors.expiryDate.value.message}</FormError> : null}
              </div>
              <Controller
                key="expiry-date-unit"
                control={control}
                name={`expiryDate.unit`}
                render={({ onChange, value }) => (
                  <div className="flex-1">
                    <Select
                      value={value}
                      options={[
                        { label: 'Days', value: 'day', key: 'day' },
                        { label: 'Weeks', value: 'week', key: 'week' },
                        { label: 'Months', value: 'month', key: 'month' }
                      ]}
                      onChange={onChange}
                    />
                  </div>
                )}
                defaultValue={defaultValues.expiryDate?.unit ?? 'day'}
              />
            </div>
            {errors.expiryDate?.unit?.message ? <FormError>{errors.expiryDate?.unit.message}</FormError> : null}
          </FormLabel>
          <div className="relative col-start-1">
            <FormLabel>
              <strong>Message</strong>
              <SearchMessage
                initialMessage={
                  messageId
                    ? { messageId, messageName }
                    : { messageId: defaultValues.messageId, messageName: defaultValues.messageName }
                }
                onSelectedMessage={(message) => {
                  if (message?.messageId) {
                    clearErrors('messageId')
                    setValue('messageId', message.messageId)
                  }
                  setValue('messageName', message?.messageName ?? '')
                }}
                paginatedMessages={paginatedMessages}
                emailTemplateCollection={emailTemplateCollection}
                showSelectionsTags={false}
              />
              {errors['messageId']?.message ? <FormError>{errors['messageId'].message}</FormError> : null}
            </FormLabel>
            <CreateMessageModal
              className="absolute top-1 left-16"
              onCompleted={(id: string, name: string) => {
                onCompleted(name, `messageName`)
                onCompleted(id, `messageId`)
              }}
            />
          </div>
          {delayFields}
        </>
      )
    }
  }

  return component
}

export default function ActionForm(props: ActionFormProps): JSX.Element {
  const { isLoading, reloadMessages, paginatedMessages, emailTemplateCollection, defaultValues, onAddAction } = props

  const { register, unregister, handleSubmit, errors, control, setValue, watch, clearErrors } = useForm<FormData>({
    defaultValues
  })

  const onSubmit = (data: ExtendedUpdateActionInput) => {
    onAddAction(data)
  }
  return (
    <Form
      onSubmit={(e) => {
        e.stopPropagation()
        handleSubmit(onSubmit)(e)
      }}
    >
      <div className="py-4 border-b border-gray-100 justify-between flex gap-x-2 items-start">
        <div className="w-full flex flex-col space-y-5 gap:0 sm:grid sm:grid-cols-2 sm:gap-5 sm:space-y-0">
          <FormLabel className="col-span-2">
            <strong>Enabled</strong>
            <Controller
              name="enabled"
              control={control}
              render={({ value, onChange }) => (
                <Checkbox id="enabled" className="mr-0 ml-2 mb-1" checked={value} onChange={() => onChange(!value)} />
              )}
              defaultValue={defaultValues.enabled ?? true}
            />
            {errors.name && <FormError>{errors.name.message}</FormError>}
          </FormLabel>
          <FormLabel>
            <strong>Name*</strong>
            <Input
              width="full"
              variant="standard"
              name="name"
              ref={register({ required: 'Name is required' })}
              defaultValue={defaultValues.name ?? ''}
            />
            {errors.name?.message && <FormError>{errors.name.message}</FormError>}
          </FormLabel>
          <FormLabel>
            <strong>Type</strong>
            <Controller
              control={control}
              name="type"
              rules={{ required: 'Action type is required' }}
              render={({ onChange, value }) => <ActionTypeSelect value={value} onChange={onChange} />}
              defaultValue={defaultValues.type}
            />
            {errors.type?.message && <FormError>{errors.type.message}</FormError>}
          </FormLabel>
          <ConditionalFields
            {...{
              defaultValues,
              control,
              watch,
              register,
              unregister,
              errors,
              paginatedMessages,
              emailTemplateCollection,
              reloadMessages,
              setValue,
              clearErrors
            }}
          />
        </div>
      </div>
      <Button
        colorScheme="green"
        type="submit"
        variant="primary"
        className="mt-5"
        isDisabled={isLoading}
        isLoading={isLoading}
      >
        Save
      </Button>
    </Form>
  )
}
