import { Permission } from './types'

export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

export const ROLES: Record<Permission, string> = {
  [Permission.Admin]: 'Admin',
  [Permission.FinancialAdmin]: 'Fincancial Admin',
  [Permission.OrderAdmin]: 'Order Admin',
  [Permission.BrandAdmin]: 'Brand Admin',
  [Permission.SuperAdmin]: 'Super Admin',
  [Permission.Authenticated]: 'Authenticated',
  [Permission.Owner]: 'Owner',
  [Permission.Public]: 'Public',
  [Permission.ProductAdmin]: 'Product Admin'
}
// use this to create an array of objects with the bank name and i universal branch code
export const banks = [
  { name: 'Standard Bank', branchCode: '051001' },
  { name: 'Mercantile', branchCode: '460005' },
  { name: 'RMB', branchCode: '261251' },
  { name: 'Sasfin', branchCode: '683000' },
  { name: 'Standard', branchCode: '051001' },
  { name: 'FNB', branchCode: '250655' },
  { name: 'Investec', branchCode: '580105' },
  { name: 'ABSA', branchCode: '632005' },
  { name: 'Capitec', branchCode: '470010' },
  { name: 'Nedbank', branchCode: '198765' },
  { name: 'African Bank', branchCode: '430000' },
  { name: 'Tyme Bank', branchCode: '678910' },
  { name: 'Discovery Bank', branchCode: '679000' },
  { name: 'Bidvest', branchCode: '462005' }
]
