import React from 'react'
import cn from 'classnames'

type LoaderSize = 'sm' | 'md' | 'lg'

interface LoaderProps {
  className?: string
  isActive?: boolean
  size?: LoaderSize
}

const sizeMap: Record<LoaderSize, string> = {
  sm: 'h-4 w-4',
  md: 'h-6 w-6',
  lg: 'h-10 w-10'
}

const baseStyle = 'rounded-full'

export const Loader: React.FC<LoaderProps> = (props) => {
  const { className, isActive = true, size = 'lg' } = props
  const style = cn(baseStyle, sizeMap[size], className)
  return (
    <div
      className={cn(style, 'relative border-4 border-gray-200', { 'animate-spin': isActive })}
      style={{ borderLeftColor: 'white' }}
    >
      <div className={style} />
    </div>
  )
}
