import { gql } from '@apollo/client'
import { Form, FormLabel, Input, Modal } from '@plusplusminus/plusplusdash'
import { BrandQuery, useUpdateShopifyMutation } from 'generated'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import Button from 'components/Button'

interface EditShopifyProps {
  isOpen: boolean
  onClose: () => void
  brand: NonNullable<BrandQuery['brand']>
}

type FormValues = {
  domain: string
  secret: string
  key: string
  token: string
}

const EditShopify = (props: EditShopifyProps): JSX.Element => {
  const { isOpen, onClose, brand } = props
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: { domain: brand.shopify.domain }
  })

  const [updateShopify, { loading }] = useUpdateShopifyMutation({
    onCompleted: () => {
      onClose()
      toast.success('Successfully updated payout info.')
    },
    onError: (error) => {
      console.log(error)
      toast.error('Server error. Could not update payout info.')
    }
  })

  const onSubmit: SubmitHandler<FormValues> = (_update) => {
    const update = Object.entries(_update).reduce((acc, [key, value]) => {
      if (Boolean(value)) acc[key as keyof FormValues] = value
      return acc
    }, {} as FormValues)
    updateShopify({ variables: { id: brand.shopify.id, update } })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <Modal.Title>Shopify Info</Modal.Title>
      <Modal.Description className="my-4">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-8 space-y-4">
            <div>
              <FormLabel htmlFor="domain">Domain</FormLabel>
              <Input name="domain" variant="standard" ref={register} />
            </div>
            <div>
              <FormLabel htmlFor="token">Token</FormLabel>
              <Input name="token" variant="standard" ref={register} />
            </div>
            <div>
              <FormLabel htmlFor="secret">Secret</FormLabel>
              <Input name="secret" variant="standard" ref={register} />
            </div>
            <div>
              <FormLabel htmlFor="key">Key</FormLabel>
              <Input name="key" variant="standard" ref={register} />
            </div>
          </div>
          <div className="flex justify-end gap-2">
            <Button variant="plain" onClick={onClose} type="button">
              Close
            </Button>
            <Button variant="primary" colorScheme="green" type="submit" isDisabled={loading} isLoading={loading}>
              Save
            </Button>
          </div>
        </Form>
      </Modal.Description>
    </Modal>
  )
}

export default EditShopify

EditShopify.mutation = gql`
  mutation UpdateShopify($id: String!, $update: ShopifyUpdate!) {
    updateShopify(id: $id, update: $update) {
      id
      domain
    }
  }
`
