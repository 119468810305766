import { Form, FormField, FormLabel, Input, Modal } from '@plusplusminus/plusplusdash'
import { CreateUserInput, useCreateUser } from 'hooks/users'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import Button from 'components/Button'

interface CreateUserProps {
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}

const CreateUser: React.FC<CreateUserProps> = (props) => {
  const { isOpen, onClose, refetch } = props
  const { register, handleSubmit } = useForm()
  const { createUser, loading } = useCreateUser({
    onCompleted: () => {
      toast.success('Successfully created user.')
      refetch()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Server error. could not create user.')
    }
  })

  const onSubmit = (data: CreateUserInput) => {
    createUser(data)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} size="md" onClose={onClose}>
      <h3 className="text-lg leading-4 mb-8">Create User</h3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-x-4 gap-y-6">
          <FormField>
            <FormLabel>First Name</FormLabel>
            <Input id="firstName" name="firstName" variant="outline" width="full" ref={register({ required: true })} />
          </FormField>
          <FormField>
            <FormLabel>Last Name</FormLabel>
            <Input id="lastName" name="lastName" variant="outline" width="full" ref={register({ required: true })} />
          </FormField>
          <FormField>
            <FormLabel>Email Address</FormLabel>
            <Input id="email" name="email" variant="outline" width="full" ref={register({ required: true })} />
          </FormField>
          <FormField>
            <FormLabel>Password</FormLabel>
            <Input
              id="password"
              name="password"
              type="password"
              width="full"
              variant="outline"
              ref={register({ required: true })}
            />
          </FormField>
        </div>
        <div className="mt-8 flex justify-end gap-2">
          <Button variant="plain" type="button" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" colorScheme="green" type="submit" isDisabled={loading} isLoading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export { CreateUser }
