import { useLocation } from '@reach/router'
import { useEffect, useRef, useState } from 'react'
import queryString from 'query-string'

type Paging = { first?: number; after?: string } | { last?: number; before?: string }
type TPagination = {
  page: number
  limit: number
  paging: Paging | undefined
  setCursor: (cursors: { startCursor: string; endCursor: string }) => void
  nextPage: () => void
  prevPage: () => void
  resetPaging: () => void
}
type UsePaginationArgs = {
  limit?: number
  persist?: boolean
}

const usePagination = (args: UsePaginationArgs): TPagination => {
  const { limit = 10, persist = false } = args

  const location = useLocation()
  const [page, setPage] = useState(1)
  const endCursorRef = useRef<string>()
  const startCursorRef = useRef<string>()
  const [paging, setPaging] = useState<Paging>({
    first: limit
  })

  const { search } = location || {}
  useEffect(() => {
    if (search) {
      const params = queryString.parse(search, { parseNumbers: true })

      let update: Paging | undefined
      if (params.first && typeof params.first === 'number') {
        update = { first: params.first }
        if (params.after && typeof params.after === 'string') {
          update = { ...update, after: params.after }
        }
      } else if (params.last && typeof params.last === 'number') {
        update = { last: params.last }
        if (params.before && typeof params.before === 'string') {
          update = { ...update, before: params.before }
        }
      }
      if (update) {
        setPaging(update)
      }
      if (params.page && typeof params.page === 'number') {
        setPage(params.page)
      }
    }
  }, [])

  const nextPage = () => {
    setPage(page + 1)
    setPaging({ first: limit, after: endCursorRef.current })
    if (persist) {
      const url = new URL(window.location.toString())
      url.searchParams.set('first', limit.toString())
      url.searchParams.set('page', (page + 1).toString())
      endCursorRef.current && url.searchParams.set('after', endCursorRef.current)
      url.searchParams.delete('last')
      url.searchParams.delete('before')
      history.pushState({}, '', url.toString())
    }
  }
  const prevPage = () => {
    setPage(page - 1)
    setPaging({ last: limit, before: startCursorRef.current })
    if (persist) {
      const url = new URL(window.location.toString())
      if (startCursorRef.current) {
        url.searchParams.set('last', limit.toString())
        url.searchParams.set('before', startCursorRef.current)
      }
      url.searchParams.set('page', (page - 1).toString())
      url.searchParams.delete('after')
      url.searchParams.delete('first')
      history.pushState({}, '', url.toString())
    }
  }
  const setCursorCb = ({ startCursor, endCursor }: { startCursor: string; endCursor: string }) => {
    endCursorRef.current = endCursor
    startCursorRef.current = startCursor
  }
  const resetPaging = () => {
    setPaging({ first: limit })
    setPage(1)
    endCursorRef.current = undefined
    startCursorRef.current = undefined

    const url = new URL(window.location.toString())
    if (persist) {
      url.searchParams.delete('last')
      url.searchParams.delete('first')
      url.searchParams.delete('before')
      url.searchParams.delete('after')
      url.searchParams.delete('page')
      history.pushState({}, '', url.toString())
    }
  }

  return { page, limit, nextPage, prevPage, paging, setCursor: setCursorCb, resetPaging }
}

export { usePagination }
export type { TPagination }
