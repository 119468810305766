import { Link, Redirect, RouteComponentProps } from '@reach/router'
import { Input, Form, FormLabel, FormError, FormField, Loader } from '@plusplusminus/plusplusdash'
import { useLogin } from '../../hooks/useLogin'
import { useForm } from 'react-hook-form'
import { FC } from 'react'
import { emailRegex } from '../../common/constants'
import { LoginData } from '../../common/types'
import { getFormError } from '../../common/utils'
import { Field } from '../../common/enums'
import logo from '../../images/tle-logo-stacked-black.png'
import { useUser } from '../../hooks/useUser'
import Button from 'components/Button'

export const Login: FC<RouteComponentProps> = () => {
  const { isAuth, loadingUser } = useUser()
  const { login, customError, loading } = useLogin()
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = (data: LoginData) => login({ ...data, email: data.email.toLowerCase().trim() })
  return loadingUser ? (
    <Loader isActive />
  ) : !isAuth ? (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="TLE Logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <FormField direction="column">
              <FormLabel htmlFor="email">Email address</FormLabel>
              <Input
                as="input"
                variant="outline"
                width="full"
                ref={register({ required: true, pattern: emailRegex })}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
              />
              {errors.email ? <FormError>{getFormError(errors.email.type, Field.EMAIL)}</FormError> : null}
            </FormField>
            <FormField direction="column">
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                as="input"
                variant="outline"
                width="full"
                ref={register({ required: true })}
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
              />
              {errors.password ? <FormError>{getFormError(errors.password.type, Field.PASSWORD)}</FormError> : null}
            </FormField>
            <div className="flex items-center justify-end">
              <div className="text-sm">
                <Link to="/forgotPassword" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </Link>
              </div>
            </div>

            {customError && <div className="mb-4 text-sm text-red-300">{customError.message}</div>}
            <Button
              variant="primary"
              type="submit"
              colorScheme="indigo"
              className="w-full justify-center"
              isDisabled={loading}
              isLoading={loading}
            >
              Sign in
            </Button>
          </Form>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/" noThrow />
  )
}
