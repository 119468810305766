import { DocumentNode, gql } from '@apollo/client'
import { Card } from 'components/Card'
import { InfoLabel } from 'components/Info/InfoLabel'
import { InfoValue } from 'components/Info/InfoValue'
import { useGetAppVersionQuery } from 'generated'
import React from 'react'
import AppConfigEdit from './AppConfigEdit'

interface AppConfigViewProps {
  isOpen: boolean
  onClose: () => void
}
const AppConfigView: React.FC<AppConfigViewProps> & { query: DocumentNode } = ({ isOpen, onClose }): JSX.Element => {
  const { data, refetch } = useGetAppVersionQuery()
  const onReload = () => refetch()

  return (
    <div className="p-5">
      <Card>
        <Card.Content>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <InfoLabel>App Minimum Version</InfoLabel>
              <InfoValue>{data?.configValue?.value} </InfoValue>
            </div>
          </div>
        </Card.Content>
      </Card>
      {!!data?.configValue.value && !!data?.configValue.id && isOpen && (
        <AppConfigEdit
          currentAppVersionId={data.configValue.id}
          currentAppVersionValue={data.configValue.value}
          onClose={onClose}
          onReload={onReload}
          isOpen={isOpen}
        />
      )}
    </div>
  )
}

AppConfigView.query = gql`
  query GetAppVersion {
    configValue(name: "minimum_app_version") {
      id
      value
    }
  }
`

export default AppConfigView
