import React, { FC, MouseEventHandler } from 'react'
import cn from 'classnames'
import { styles } from './styles'

interface ToggleProps {
  on: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
  colorScheme?: string
  label?: string
  'aria-label'?: string
}

export const Toggle: FC<ToggleProps> = (props) => {
  const { on, onClick, colorScheme = 'blue', label, 'aria-label': ariaLabel } = props

  const containerClass = cn(styles.base, { [`bg-${colorScheme}-200`]: !on }, { [`bg-${colorScheme}-500`]: on })
  const toggleClass = cn(styles.toggle, { 'translate-x-5': on }, { 'translate-x-0': !on })

  return (
    <div className="flex items-center justify-between">
      {label && (
        <span className="text-sm" id={ariaLabel}>
          {label}
        </span>
      )}
      <button
        type="button"
        className={containerClass}
        aria-pressed="false"
        aria-labelledby={ariaLabel}
        onClick={onClick}
      >
        <span className="sr-only">Use setting</span>
        <span aria-hidden="true" className={toggleClass}></span>
      </button>
    </div>
  )
}
