import { gql } from '@apollo/client'
import { Button } from '@plusplusminus/plusplusdash'
import { formatPrice } from 'common/utils'
import { Card } from 'components/Card'
import { FullPageLoader } from 'components/FullPageLoader'
import { PageHeader } from 'components/PageHeader'
import dayjs from 'dayjs'
import { useStatisticsQuery } from 'generated'
import { useMemo, useRef } from 'react'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const Row: React.FC<{ label: string | JSX.Element; value: string | number | JSX.Element }> = (props) => {
  return (
    <div className="flex items-center justify-between border-b border-gray-200 py-3">
      <span className="text-sm font-medium">{props.label}</span>
      <span className="text-sm">{props.value}</span>
    </div>
  )
}

const LIMIT = 20

const Dashboard = (): JSX.Element => {
  const offset = useRef(0)
  const { data, loading, fetchMore } = useStatisticsQuery({ variables: { limit: LIMIT, offset: 0 } })

  const chartData = useMemo(() => {
    if (data) {
      return data.statistics.sales.rollingSum.map(({ day, total }) => ({
        name: dayjs(day).format('DD-MM-YYYY'),
        total
      }))
    } else {
      return []
    }
  }, [data?.statistics.sales.rollingSum])

  if (loading || !data) return <FullPageLoader isActive />

  const { sales, users, brandSales } = data.statistics
  const tooltipFormatter = (value: string) => formatPrice(value)

  return (
    <div>
      <PageHeader>Dashboard</PageHeader>
      <div className="p-5">
        <Card className="mb-5">
          <Card.Header>Revenue Last 30 Days</Card.Header>
          <Card.Content>
            <ResponsiveContainer width="100%" height={350}>
              <LineChart data={chartData} margin={{ top: 15, right: 30, left: 30, bottom: 30 }}>
                <XAxis dataKey="name" label={{ value: 'Date', position: 'bottom', offset: 15 }} />
                <YAxis label={{ value: 'ZAR', angle: -90, position: 'left', offset: 20 }} />
                <Tooltip formatter={tooltipFormatter} />
                <Line type="monotone" dataKey="total" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Card.Content>
        </Card>
        <div className="grid grid-cols-2 gap-5">
          <Card>
            <Card.Header>Sales</Card.Header>
            <Card.Content>
              <Row label="Total Revenue" value={formatPrice(sales.completedOrders.value)} />
              <Row label="Per Day" value={formatPrice(sales.salesPerDay)} />
              <Row label="Per User" value={formatPrice(sales.salesPerUser)} />
            </Card.Content>
          </Card>
          <Card>
            <Card.Header>Completed Orders</Card.Header>
            <Card.Content>
              <Row label="Total Value" value={formatPrice(sales.revenue)} />
              <Row label="Number of Orders" value={sales.completedOrders.numOrders} />
              <Row label="Average Order Value" value={formatPrice(sales.completedOrders.averageBasketSize)} />
            </Card.Content>
          </Card>
          <Card>
            <Card.Header>Active Orders (Pending Cart)</Card.Header>
            <Card.Content>
              <Row label="Total Value" value={formatPrice(sales.activeCarts.value)} />
              <Row label="Number of Carts" value={sales.activeCarts.numOrders} />
              <Row label="Average Cart Value" value={formatPrice(sales.activeCarts.averageBasketSize)} />
            </Card.Content>
          </Card>
          <Card>
            <Card.Header>Users</Card.Header>
            <Card.Content>
              <Row label="Total Signups" value={users.signups} />
              <Row label="Signups Per Day" value={users.signupsPerDay} />
              <Row label="Wishlists" value={`${users.wishlists.total} (${users.wishlists.numberOfUsers} users)`} />
            </Card.Content>
          </Card>
          <Card>
            <Card.Header>
              <div>
                <div>Brands Sales</div>
                <div className="text-xs text-gray-500">Number of orders and items sold per brand</div>
              </div>
            </Card.Header>
            <Card.Content>
              <Row
                label="Brand"
                value={
                  <div className="grid grid-cols-2 gap-2 w-72">
                    <p className="text-sm text-right">Number of Orders</p>
                    <p className="text-sm text-right">Number of Products Sold</p>
                  </div>
                }
              />
              {brandSales.map(({ name, numOrders, numProductsSold }) => (
                <Row
                  label={name}
                  value={
                    <div className="grid grid-cols-2 gap-2 w-72">
                      <p className="text-sm text-right">{numOrders}</p>
                      <p className="text-sm text-right">{numProductsSold}</p>
                    </div>
                  }
                />
              ))}
              <div className="mt-4 flex justify-end">
                <Button
                  variant="plain"
                  onClick={() => {
                    offset.current = offset.current + LIMIT
                    fetchMore({ variables: { offset: offset.current } })
                  }}
                >
                  Load More
                </Button>
              </div>
            </Card.Content>
          </Card>
          <Card>
            <Card.Header>
              <div>
                <div>Top Products</div>
                <div className="text-xs text-gray-500">Most products sold</div>
              </div>
            </Card.Header>
            <Card.Content>
              <Row label="Product" value="Number of order items" />
              {sales.topProducts.map(({ numOrders, product }) => (
                <Row
                  label={
                    <>
                      <div>{product.productTitle}</div>
                      <div className="text-gray-500 font-normal text-xs">By {product.brand.brandName}</div>
                    </>
                  }
                  value={numOrders}
                />
              ))}
            </Card.Content>
          </Card>
        </div>
      </div>
    </div>
  )
}

Dashboard.query = gql`
  query Statistics($limit: Int!, $offset: Int!) {
    statistics {
      sales {
        revenue
        salesPerDay
        salesPerUser
        activeCarts {
          numOrders
          value
          averageBasketSize
        }
        completedOrders {
          numOrders
          value
          averageBasketSize
        }
        topBrands {
          numOrders
          brand {
            id
            brandName
          }
        }
        topProducts {
          numOrders
          product {
            id
            productTitle
            brand {
              brandName
            }
          }
        }
        rollingSum {
          day
          total
        }
      }
      users {
        signups
        wishlists {
          total
          numberOfUsers
        }
        signupsPerDay
      }
      brandSales(limit: $limit, offset: $offset) {
        name
        numOrders
        numProductsSold
      }
    }
  }
`

export default Dashboard
