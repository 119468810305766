import { gql } from '@apollo/client'
import { ClockIcon, RefreshIcon } from '@heroicons/react/solid'
import { Badge, Button, Loader } from '@plusplusminus/plusplusdash'
import { Link, RouteComponentProps } from '@reach/router'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import dayjs from 'dayjs'
import { CampaignType, useMessageCampaignQuery } from 'generated'

interface ViewMessagePropsCampaign extends RouteComponentProps {
  id: string
}

const TYPE_ICONS = {
  [CampaignType.Once]: ClockIcon,
  [CampaignType.Recurring]: RefreshIcon
}

export default function ViewMessageCampaign(props: ViewMessagePropsCampaign): JSX.Element {
  const { data, loading } = useMessageCampaignQuery({
    variables: { id: props.id },
    fetchPolicy: 'cache-and-network'
  })

  const toggleEdit = () => {
    props.navigate?.(`/message-campaigns/${props.id}/edit`)
  }
  const Icon = data && data.messageCampaign ? TYPE_ICONS[data?.messageCampaign.type] : null

  return (
    <div>
      <PageHeader>
        <h1 className="text-lg">View Message Campaign</h1>
        <Button variant="primary" colorScheme="blue" onClick={toggleEdit}>
          Edit
        </Button>
      </PageHeader>
      <div className="p-5">
        <Card>
          {loading ? (
            <Loader isActive />
          ) : (
            <>
              <Card.Header>
                <p>{data?.messageCampaign?.name}</p>
                <Badge color="blue">
                  {data?.messageCampaign?.type}
                  {Icon && <Icon className="h-4 w-4 ml-2" />}
                </Badge>
              </Card.Header>
              <Card.Content>
                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                  <div>
                    <strong>Message</strong>
                    <Link
                      className="block underline text-blue-400"
                      to={`/messages/${data?.messageCampaign?.message?.id}/view`}
                    >
                      {data?.messageCampaign?.message?.name}
                    </Link>
                  </div>
                  <div>
                    <strong>Audience Segments ({data?.messageCampaign?.accountGroupOperator})</strong>
                    <div>
                      {data?.messageCampaign?.accountGroups.map((accountGroup) => (
                        <Link
                          className="block underline text-blue-400"
                          to={`/audience-segments/${accountGroup.id}/view`}
                        >
                          {accountGroup.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div>
                    <strong>Channels</strong>
                    <p>{data?.messageCampaign?.channels.join(', ')}</p>
                  </div>
                  {data?.messageCampaign?.type == CampaignType.Once ? (
                    <div className="col-start-1">
                      <strong>Scheduled At</strong>
                      <p>{dayjs(data?.messageCampaign?.scheduledAt).format('DD/MM/YYYY HH:mm')}</p>
                    </div>
                  ) : (
                    <>
                      <div className="col-start-1">
                        <strong>Next Run At</strong>
                        <p>{dayjs(data?.messageCampaign?.nextRunAt).format('DD/MM/YYYY HH:mm')}</p>
                      </div>
                      <div>
                        <strong>Interval</strong>
                        <p>{data?.messageCampaign?.interval}</p>
                      </div>
                      <div>
                        <strong>Time to Run</strong>
                        <p>
                          {data?.messageCampaign?.timeToRun
                            ? dayjs(data?.messageCampaign?.timeToRun).format('HH:mm')
                            : null}
                        </p>
                      </div>
                    </>
                  )}
                  {data?.messageCampaign?.sentAt ? (
                    <div>
                      <strong>Sent At</strong>
                      <p>{dayjs(data?.messageCampaign?.sentAt).format('DD/MM/YYYY HH:mm')}</p>
                    </div>
                  ) : null}
                  <div className="col-start-1">
                    <strong>Comment</strong>
                    <p>{data?.messageCampaign?.comment}</p>
                  </div>
                </div>
              </Card.Content>
            </>
          )}
        </Card>
      </div>
    </div>
  )
}

ViewMessageCampaign.fields = gql`
  fragment MessageCampaignFields on MessageCampaignDTO {
    id
    name
    scheduledAt
    comment
    sentAt
    message {
      id
      name
      title
    }
    accountGroups {
      id
      name
    }
    channels
    accountGroupOperator
    type
    interval
    timeToRun
    nextRunAt
  }
`

ViewMessageCampaign.query = gql`
  query MessageCampaign($id: ID!) {
    messageCampaign(id: $id) {
      ...MessageCampaignFields
    }
  }
  ${ViewMessageCampaign.fields}
`
