import { QueryResult, useQuery } from '@apollo/client'
import { EMAIL_QUERY } from 'graphql/contentful'

export type EmailTemplatesCollection = { items: { sys: { id: string }; name: string }[] }

export function useEmailTemplates(): QueryResult<{
  emailTemplatesCollection: EmailTemplatesCollection
}> {
  return useQuery(EMAIL_QUERY, { context: { clientName: 'contentful' } })
}
