import React, { FC, FormEventHandler } from 'react'
import cn from 'classnames'
import { getColor } from '../../common/utils'

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string
  id: string
  onChange?: FormEventHandler<HTMLInputElement>
  colorScheme?: string
}

export const Radio: FC<RadioProps> = (props) => {
  const { className, id, onChange, colorScheme = 'blue', ...additionalProps } = props
  const color = getColor(colorScheme as string, 500)
  const radioClass = cn(`mr-2 w-4 h-4 cursor-pointer`, { [`text-${color}`]: Boolean(colorScheme) }, className)

  return <input id={id} className={radioClass} type="radio" onChange={onChange} {...additionalProps} />
}
