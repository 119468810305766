import { Button, Checkbox, Input } from '@plusplusminus/plusplusdash'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { formatPrice } from 'common/utils'
import React, { useState } from 'react'
import cn from 'classnames'
import { SelectedProduct } from './BulkTag'

type ModalSize = 'xs' | 'sm' | 'md' | 'lg'

const styles = {
  container: (size: ModalSize) =>
    cn('absolute mx-auto h-full w-full bg-gray-50 px-4 py-10 sm:h-auto sm:rounded sm:p-6 lg:p-8', {
      'max-w-4xl': size === 'lg',
      'max-w-2xl': size === 'md',
      'max-w-xl': size === 'sm',
      'max-w-lg': size === 'xs'
    })
}

interface BulkTagProductSelectionProps {
  selectedProducts: SelectedProduct[]
  onAdd: (selectedProduct: SelectedProduct) => void
}
const BulkTagProductSelection = ({ selectedProducts, onAdd }: BulkTagProductSelectionProps): JSX.Element => {
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => {
    setIsOpen(false)
    setQuery('')
  }
  return (
    <>
      <Button variant="primary" colorScheme="blue" isDisabled={selectedProducts.length === 0} onClick={onOpen}>
        View Selected
      </Button>
      <Dialog open={isOpen} onClose={onClose} className="fixed inset-0 z-50 h-screen w-screen sm:h-auto sm:w-auto">
        <div className="flex min-h-screen items-center justify-center">
          <div className="fixed inset-0 bg-black opacity-30" />
          <div className={styles.container('lg')}>
            <Dialog.Panel>
              <Dialog.Title>Products Selected</Dialog.Title>
              <div className="flex space-x-2 my-5 items-center">
                <Input
                  variant="standard"
                  type="text"
                  placeholder="Search produc/brand"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                {query.length > 0 && <XIcon className="h-6 w-6" onClick={() => setQuery('')} />}
              </div>
              <div
                className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 py-5 pr-5 overflow-y-auto"
                style={{ maxHeight: '70vh' }}
              >
                {selectedProducts
                  .filter(({ title, brand }) => {
                    const regexpQuery = new RegExp(query, 'gi')
                    return !query || query.length < 3 || title.match(regexpQuery) || brand.match(regexpQuery)
                  })
                  .map(({ id, title, image, price, brand }) => {
                    return (
                      <div key={id} className="group-item relative">
                        <div
                          className={cn('p-5 border border-gray-200 rounded-md overflow-hidden h-full', {
                            'shadow-xl': true
                          })}
                        >
                          <div className="product-image-wrapper">
                            <img
                              src={`${image}&width=400`}
                              alt=""
                              className="absolute top-0 left-0 h-full w-full object-cover"
                            />
                          </div>
                          <p>{`By ${brand}`}</p>
                          <p className="font-semibold">{(title as string).substring(0, 30)}</p>
                          <p className="font-semibold">{price ? formatPrice(price as number) : ''}</p>
                          <div className="absolute bottom-2 right-2">
                            <Checkbox
                              id="productSelection"
                              aria-label="enabled"
                              checked={true}
                              onClick={() => onAdd({ id, title, image, price, brand } as SelectedProduct)}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
              <XIcon className="h-7 w-7 fixed right-3 top-3 cursor-pointer sm:absolute" onClick={onClose} />
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default BulkTagProductSelection
