import { gql } from '@apollo/client'
import { Button, Loader } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import { Trigger } from 'components/Trigger'
import { useTriggerQuery } from 'generated'

interface ViewTriggerProps extends RouteComponentProps {
  id: string
}

export default function ViewTrigger(props: ViewTriggerProps): JSX.Element {
  const { data, loading } = useTriggerQuery({ variables: { id: props.id }, fetchPolicy: 'cache-and-network' })

  const toggleEdit = () => {
    props.navigate?.(`/triggers/${props.id}/edit`, { state: { comingFromView: true } })
  }

  return (
    <div>
      <PageHeader>
        <h1 className="text-lg">View Trigger</h1>
        <Button variant="primary" colorScheme="blue" onClick={toggleEdit}>
          Edit
        </Button>
      </PageHeader>
      <div className="p-5">
        <Card>{loading || !data ? <Loader isActive /> : data.trigger ? <Trigger trigger={data.trigger} /> : null}</Card>
      </div>
    </div>
  )
}

export const TRIGGER_FIELDS = gql`
  fragment TriggerFields on Trigger {
    id
    name
    eventType
    enabled
    actions {
      id
      name
      type
      enabled
      message {
        id
        name
      }
      messageId
      channels
      createdAt
      updatedAt
      voucherValue
      voucherMessage
      expiryDate
      delay {
        unit
        value
      }
    }
    accountGroups {
      id
      name
    }
    criteria
    createdAt
    updatedAt
  }
`
ViewTrigger.query = gql`
  query Trigger($id: ID!) {
    trigger(id: $id) {
      ...TriggerFields
    }
  }
  ${TRIGGER_FIELDS}
`
