import { RouteComponentProps, Router } from '@reach/router'
import { ForgotPassword } from '../../pages/ForgotPassword'
import { Login } from '../../pages/Login'
import { ResetPassword } from '../../pages/ResetPassword'
import { BrandVerify } from '../../pages/BrandVerify'
import ResetSuccess from 'pages/ResetPassword/ResetSuccess'

export const UnauthenticatedApp: React.FC<RouteComponentProps> = () => {
  return (
    <Router className="h-full">
      <ForgotPassword path="forgotPassword" />
      <ResetPassword path="resetPassword" />
      <ResetSuccess path="success" />
      <BrandVerify path="brandVerify" />
      <Login path="*" />
    </Router>
  )
}
