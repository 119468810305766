import { DocumentNode, gql } from '@apollo/client'
import { Link, RouteComponentProps } from '@reach/router'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import { useAccountingCodesQuery, useBrandOrdersQuery, useBrandQuery } from 'generated'
import React from 'react'
import LineItem from 'components/LineItem'
import { ORDER_LINE_FIELDS } from 'hooks/orders'
import { Button, Loader } from '@plusplusminus/plusplusdash'
import { Badge } from 'components/Badge'

const LIMIT = 10

const BrandOrders: React.FC<RouteComponentProps<{ brandId: string }>> & { query: DocumentNode } = (props) => {
  const { brandId = '' } = props

  const { data: brandData } = useBrandQuery({ variables: { id: brandId } })

  const { data: dataAccountingCodes, loading: loadingAccountingCodes } = useAccountingCodesQuery()

  const { data, loading, refetch } = useBrandOrdersQuery({
    variables: {
      filter: { brandId: { eq: brandId } },
      paging: { first: LIMIT }
    }
  })
  const onRefetch = () => refetch()

  if (loading || loadingAccountingCodes) {
    return <Loader isActive />
  }

  if (!data || !data.orderLines || !dataAccountingCodes?.accountingCodes) {
    return null
  }

  return (
    <div>
      <PageHeader>
        <div className="flex justify-between items-center w-full">{brandData?.brand?.brandName} Orders</div>
      </PageHeader>
      <div className="p-5">
        {data?.orderLines.edges.map(({ node }) => {
          return (
            <Card className="mb-5">
              <Card.Header>
                <div className="flex flex-row w-full justify-between">
                  <div>
                    <Link to={`/orders/${node.order.id}`} className="underline">
                      Order #{node.order.orderNumber}
                    </Link>
                    {node.orderStatus?.orderReference && (
                      <p className="text-xs text-gray-600">Shopify Reference: {node.orderStatus?.orderReference}</p>
                    )}
                    <div className="text-xs text-gray-600">
                      Customer: {node.order.account?.firstName ?? ''} {node.order.account?.lastName ?? ''}
                    </div>
                  </div>
                  <div>
                    <Badge color="blue">{node.order.status}</Badge>
                  </div>
                </div>
              </Card.Header>
              <Card.Content>
                <LineItem
                  orderId={node.order.id}
                  accountingCodes={dataAccountingCodes.accountingCodes}
                  lineItem={node}
                  isMinimal
                  onRefetch={onRefetch}
                />
              </Card.Content>
            </Card>
          )
        })}
        {data?.orderLines.pageInfo.hasPreviousPage ? (
          <Button
            variant="plain"
            onClick={() => refetch({ paging: { before: data.orderLines.pageInfo.startCursor, last: LIMIT } })}
          >
            Prev Page
          </Button>
        ) : null}
        {data?.orderLines.pageInfo.hasNextPage ? (
          <Button
            variant="plain"
            onClick={() => refetch({ paging: { after: data.orderLines.pageInfo.endCursor, first: LIMIT } })}
          >
            Next Page
          </Button>
        ) : null}
      </div>
    </div>
  )
}

BrandOrders.query = gql`
  query BrandOrders($filter: OrderLineFilter, $sorting: [OrderLineSort!], $paging: CursorPaging) {
    orderLines(filter: $filter, sorting: $sorting, paging: $paging) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...OrderLineFields
          order {
            id
            orderNumber
            isActive
            status
            account {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
  ${ORDER_LINE_FIELDS}
`

export default BrandOrders
