import { Notes, noteTypes } from './common'

interface NotesProps {
  notes?: Notes
}

export const ViewNotes: React.FC<NotesProps> = (props) => {
  const { notes } = props
  return (
    <>
      {noteTypes.map((note) => {
        const value = notes?.[note.key] ?? undefined
        return (
          <div className="grid grid-cols-2 gap-y-4 border-b border-gray-100 py-4">
            <div>
              <p className="text-black text-sm mb-1">{note.title}</p>
              <p className="text-gray-500 text-xs">{note.description}</p>
            </div>
            <div>
              <p className="text-sm">{value}</p>
            </div>
          </div>
        )
      })}
    </>
  )
}
