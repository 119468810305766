import { gql } from '@apollo/client'
import { Checkbox, FormLabel, Popover } from '@plusplusminus/plusplusdash'
import { UserExportMutationVariables, useUserExportMutation } from 'generated'
import { Moment } from 'moment'
import { ChangeEvent, useState } from 'react'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import toast from 'react-hot-toast'
import Button from 'components/Button'

type Dates = { startDate: Moment | null; endDate: Moment | null }
type FocusState = FocusedInputShape | null

const isOutsideRange = () => false

export default function UserExport(): JSX.Element {
  const [focus, setFocus] = useState<FocusState>(null)
  const [{ startDate, endDate }, setDates] = useState<Dates>({ startDate: null, endDate: null })
  const [isSubscribed, setIsSubscribed] = useState(true)
  const [exportUsers, { loading }] = useUserExportMutation({
    onCompleted: () => toast.success('Sent user csv to admin email'),
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not set user csv')
    }
  })

  const onDatesChange = (dates: Dates) => {
    setDates(dates)
  }
  const onFocusChange = (focus: FocusState) => {
    setFocus(focus)
  }
  const onChangeSubscribed = (event: ChangeEvent<HTMLInputElement>) => {
    setIsSubscribed(event.target.checked)
  }
  const submitDownload = (close: () => void) => async () => {
    const variables = { isSubscribed } as UserExportMutationVariables
    if (startDate && endDate) {
      variables.dateRange = [startDate.toDate(), endDate.toDate()]
    }
    await exportUsers({ variables })
    close()
  }

  return (
    <Popover>
      <Popover.Button>Export</Popover.Button>
      <Popover.Panel className="w-96">
        {({ close }: { close: any }) => (
          <div className="p-8 grid grid-cols-1 gap-y-8">
            <FormLabel>
              Date Range
              <DateRangePicker
                {...{ startDate, endDate, onDatesChange, onFocusChange, isOutsideRange }}
                focusedInput={focus}
                startDateId="start-date"
                endDateId="end-date"
              />
            </FormLabel>
            <FormLabel>
              Is Subscribed?
              <Checkbox id="isSubscribed" className="ml-2" checked={isSubscribed} onChange={onChangeSubscribed} />
            </FormLabel>
            <div className="flex justify-end">
              <Button
                colorScheme="blue"
                variant="primary"
                onClick={submitDownload(close)}
                isDisabled={loading}
                isLoading={loading}
              >
                Send CSV
              </Button>
            </div>
          </div>
        )}
      </Popover.Panel>
    </Popover>
  )
}

UserExport.mutation = gql`
  mutation UserExport($isSubscribed: Boolean!, $dateRange: [DateTime!], $emails: [String!]) {
    sendCustomerCsv(isSubscribed: $isSubscribed, dateRange: $dateRange, emails: $emails)
  }
`
