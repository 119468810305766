import React from 'react'

type BatchTuple = [string[], (id: string) => void, (id: string) => boolean, () => void, (ids: string[]) => void]

function useBatch(): BatchTuple {
  const [selected, setSelected] = React.useState<string[]>([])

  const onClick = React.useCallback(
    (id: string) => {
      const index = selected.indexOf(id)
      if (index > -1) {
        setSelected([...selected.slice(0, index), ...selected.slice(index + 1)])
        return
      }
      setSelected([...selected, id])
    },
    [selected, setSelected]
  )
  const contains = (id: string) => selected.includes(id)
  const reset = () => setSelected([])
  const setAll = (ids: string[]) => setSelected(ids)

  return [selected, onClick, contains, reset, setAll]
}

export { useBatch }
