import { createContext, useState, FC, useCallback, useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { Account } from '../common/types'
import { ACCOUNT_FIELDS } from 'graphql/fragments'

export const ME_QUERY = gql`
  ${ACCOUNT_FIELDS}
  query MeQuery {
    me {
      ...AccountFields
    }
  }
`

interface Login {
  user: Account | null
  isAuth: boolean
}

export interface ILoginContext {
  user: Account | null
  isAuth: boolean
  setUser: ((user: Account | null) => void) | null
  loadingUser: boolean
}

const LoginContext = createContext<ILoginContext>({
  user: null,
  isAuth: false,
  setUser: null,
  loadingUser: false
})

const LoginProvider: FC = ({ children }) => {
  const [loginData, setLoginData] = useState<Login>({
    user: null,
    isAuth: false
  })
  const { data, loading, error } = useQuery(ME_QUERY, { fetchPolicy: 'no-cache' })

  const setUser = useCallback(
    (user: Account | null) => {
      if (user) {
        setLoginData({ user, isAuth: true })
      } else {
        setLoginData({ user: null, isAuth: false })
      }
    },
    [setLoginData]
  )

  useEffect(() => {
    if (data && data.me) {
      setUser(data.me)
    }
  }, [data, error])

  const isAuth = loginData.isAuth || Boolean(data?.me)
  const user = loginData.user || data?.me

  return (
    <LoginContext.Provider value={{ user, isAuth, setUser, loadingUser: loading }}>{children}</LoginContext.Provider>
  )
}

export { LoginContext, LoginProvider }
