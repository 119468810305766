import { ChevronLeftIcon, ChevronRightIcon, PencilAltIcon, TagIcon } from '@heroicons/react/solid'
import { Link } from '@reach/router'
import cn from 'classnames'
import { Badge } from 'components/Badge'
import { Card } from 'components/Card'
import { InfoLabel } from 'components/Info/InfoLabel'
import { InfoValue } from 'components/Info/InfoValue'
import { LinksModal } from 'components/LinksModal/LinksModal'
import { EditNotes } from 'components/Notes/EditNotes'
import { ViewNotes } from 'components/Notes/ViewNotes'
import { InventoryPolicy, Notes, ProductQueryQuery, useUpdateProductMutation } from 'generated'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { formatDate, getImageUrl } from 'utils'
import { buildTagBadges } from 'utils/tags'

interface ProductViewProps {
  productId?: string
  product: NonNullable<ProductQueryQuery['product']>
}
export const ProductView: React.FC<ProductViewProps> = (props) => {
  const { product } = props
  const [activeImage, setActiveImage] = useState(0)
  const [isEditingLinks, setIsEditingLinks] = useState(false)
  const [isEditingNotes, setIsEditingNotes] = useState(false)

  const [updateProduct, { loading }] = useUpdateProductMutation({
    onCompleted: () => {
      toast.success('Successfully set updated product')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update product')
    }
  })

  let imageCarousel: Array<NonNullable<typeof product['image']>> = []
  if (product.image) {
    imageCarousel = [product.image, ...product.images.filter((img) => img.id !== product.image?.id)].filter((image) =>
      Boolean(image)
    )
  } else {
    imageCarousel = product.images
  }
  const activeImageIsFeatured = imageCarousel.length > 0 ? imageCarousel[activeImage].url === product.image?.url : false

  const toggleLinks = () => setIsEditingLinks(!isEditingLinks)
  const toggleNotes = () => setIsEditingNotes(!isEditingNotes)
  const updateNotes = useCallback((notes: Omit<Notes, '__typename'>) => {
    updateProduct({ variables: { id: product.id, update: { notes } } })
  }, [])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...notes } = product.notes ?? {}

  return (
    <div className="p-5">
      <LinksModal
        isOpen={isEditingLinks}
        onClose={toggleLinks}
        meta={product.meta}
        id={product.id}
        mutationFunc={updateProduct}
        loading={loading}
      />
      <EditNotes
        isOpen={isEditingNotes}
        notes={notes}
        onClose={toggleNotes}
        updateFunc={updateNotes}
        loading={loading}
      />

      <div className="bg-white shadow overflow-hidden sm:rounded-lg p-5 mb-5">
        <div className="flex flex-row">
          <div className="flex-initial w-72">
            {imageCarousel?.length ? (
              <>
                <div className="relative border rounded border-gray-100 w-full p-1 mb-1">
                  <img src={getImageUrl(imageCarousel[activeImage], 400) as string} className="w-full object-contain" />
                  {activeImageIsFeatured ? (
                    <div className="text-sm rounded-xl px-2 py-1 top-2 right-2 absolute bg-gray-100">Featured</div>
                  ) : null}
                </div>
                <div className="flex gap-1 justify-center items-center w-full relative flex-wrap">
                  {activeImage > 0 ? (
                    <ChevronLeftIcon
                      className="h-6 w-6 cursor-pointer absolute -left-3"
                      onClick={() => setActiveImage(activeImage - 1)}
                    />
                  ) : null}
                  {imageCarousel.map((image, index) => (
                    <div key={image.id}>
                      <img
                        className="h-12 w-12 mr-1 object-contain cursor-pointer border border-gray-100"
                        src={getImageUrl(image, 100) as string}
                        onClick={() => setActiveImage(index)}
                      />
                    </div>
                  ))}
                  {activeImage < imageCarousel.length - 1 ? (
                    <ChevronRightIcon
                      className="h-6 w-6 cursor-pointer absolute -right-3"
                      onClick={() => setActiveImage(activeImage + 1)}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <div className="w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center h-72 flex items-center justify-center">
                <span className="mt-2 block text-sm font-medium text-gray-900">No images</span>
              </div>
            )}
          </div>
          <div className="flex-1 px-5">
            <div className="text-lg text-black mb-4">{product.productTitle}</div>
            <p className="text-gray-500 text-sm">Brand</p>
            <Link to={`/brands/${product.brand?.id}`}>{product.brand?.name ?? ''}</Link>
            <p className="text-gray-500 text-sm mt-6">Description</p>
            {product.productDescription ? (
              <p className="text-sm text-black" dangerouslySetInnerHTML={{ __html: product.productDescription }} />
            ) : null}

            <div className="mt-6">
              <p className="text-gray-500 text-sm">Tracks Inventory</p>
              <p className="text-sm text-black">{product.tracksInventory ? 'Yes' : 'No'}</p>
            </div>

            <div className="mt-6">
              <p className="text-sm font-medium text-gray-500 border-b border-gray-300 py-2">Variants</p>
              {product.variants?.map((variant, index) => {
                const isBuyable =
                  !product.tracksInventory ||
                  variant.inventory > 0 ||
                  variant.inventoryPolicy === InventoryPolicy.Continue

                return (
                  <div
                    key={variant.id}
                    className={cn('p-2 border-b border-gray-300', {
                      'bg-gray-50': index % 2 === 0
                    })}
                  >
                    <div className="flex">
                      {variant.images && variant.images.length > 0 ? (
                        <img src={getImageUrl(variant.images[0], 300) as string} className="h-32 w-32 object-contain" />
                      ) : null}
                      <div className="flex-1">
                        <div className="text-md mb-2">{variant.title}</div>
                        <div className="grid grid-cols-3 gap-y-3 gap-x-4 w-full">
                          {variant.selectedOptions.map((opt, index) => (
                            <div key={`${opt.name}-${index}`} className="flex gap-1 col-start-1">
                              <span className="text-sm text-gray-500">{opt.name}: </span>
                              <span className="text-sm text-black">{opt.value}</span>
                            </div>
                          ))}
                          <div className="flex gap-1 col-start-2 row-start-1">
                            <span className="text-sm text-gray-500">Back Orders Allowed:</span>
                            <span className="text-sm text-black">
                              {variant.inventoryPolicy === InventoryPolicy.Continue ? 'Yes' : 'No'}
                            </span>
                          </div>
                          <div className="flex gap-1 col-start-2 row-start-2">
                            <span className="text-sm text-gray-500">SKU: </span>
                            <span className="text-sm text-black">{variant.sku}</span>
                          </div>
                          <div className="flex gap-1 col-start-2 row-start-3">
                            <span className="text-sm text-gray-500">Inventory: </span>
                            <span className="text-sm text-black">{variant.inventory}</span>
                          </div>
                          <div className="flex gap-1 col-start-2 row-start-4">
                            <span className="text-sm text-gray-500">Buyable?: </span>
                            <span className="text-sm text-black">{isBuyable ? 'Yes' : 'No'}</span>
                          </div>
                          <span className="text-sm text-black col-start-3 row-start-1">
                            <span className="text-sm text-gray-500">Price: </span>
                            <span className="text-sm text-black">R{variant.price}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex-initial w-64 flex flex-col items-end gap-6">
            <div className="flex flex-col">
              <Badge color={product.enabled ? 'green' : 'red'} className="mb-1">
                {product.enabled ? 'Enabled' : 'Disabled'}
              </Badge>
              {product.isAutoPublish ? <Badge color="gray">Autopublish</Badge> : null}
            </div>
            <div className="w-52">
              <div className="text-sm font-medium text-gray-500 p-2 bg-gray-50 w-full">Created Date</div>
              <div className="text-sm text-black p-2">{formatDate(product.createdAt)}</div>
            </div>
            <div className="w-52">
              <div className="text-sm font-medium text-gray-500 p-2 bg-gray-50 w-full">Last Updated</div>
              <div className="text-sm text-black p-2">{formatDate(product.updatedAt)}</div>
            </div>

            <div className="w-52 border rounded border-gray-400">
              <div className="text-sm font-medium text-gray-500 p-2 bg-gray-50 w-full rounded rounded-b-none">
                Tags <TagIcon className="h-4 w-4 inline" />
              </div>
              <div className="p-2">{buildTagBadges(product.tags)}</div>
            </div>
          </div>
        </div>
      </div>
      <Card className="mb-5">
        <Card.Header>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Links</h3>
          <PencilAltIcon className="h-6 w-6 cursor-pointer" onClick={toggleLinks} />
        </Card.Header>
        <Card.Content>
          <div className="grid grid-cols-4">
            {product.meta?.fields?.map((f, index) => (
              <div key={`${f.title}-${index}`}>
                <InfoLabel>{f.title}</InfoLabel>
                <InfoValue>{f.link}</InfoValue>
              </div>
            )) ?? null}
          </div>
        </Card.Content>
      </Card>
      <Card className="col-span-2">
        <Card.Header>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Notes</h3>
          <PencilAltIcon className="h-6 w-6 cursor-pointer" onClick={toggleNotes} />
        </Card.Header>
        <Card.Content>
          <ViewNotes notes={notes} />
        </Card.Content>
      </Card>
    </div>
  )
}
