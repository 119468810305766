import React from 'react'
import { Button as ButtonPpmDash, ButtonProps as ButtonPpmDashProps, Loader } from '@plusplusminus/plusplusdash'

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
}

const Button: React.ForwardRefExoticComponent<
  ButtonProps & ButtonPpmDashProps & React.RefAttributes<HTMLButtonElement>
> = React.forwardRef(({ isLoading = false, children, ...props }, ref) => {
  return (
    <ButtonPpmDash ref={ref} {...props}>
      <div className="flex items-center">
        {isLoading && <Loader size="sm" isActive className="mr-1" />}
        {children}
      </div>
    </ButtonPpmDash>
  )
})

export default Button
