import { Box, Form, FormField, FormLabel, Grid, Input, MultiSelect, Select } from '@plusplusminus/plusplusdash'
import { TagForm, TagInput, useSetImageOnTagMutation, useTagTypesQuery, useUpdateTagMutation } from 'hooks/tags'
import { Controller, useForm } from 'react-hook-form'
import { Upload } from 'components/Upload'
import { useEffect, useState } from 'react'
import { useUploadImage } from 'hooks/images'
import { getDirtyFieldValues, getImageUrl } from 'utils'
import { Tag, useTagCategoriesQuery } from 'generated'
import toast from 'react-hot-toast'
import Button from 'components/Button'

interface TagEditProps {
  tagId: string
  tag: Tag
  cancel: () => void
  updateCallback: () => void
}

export const TagEdit: React.FC<TagEditProps> = (props) => {
  const { tag, tagId, cancel, updateCallback } = props
  const {
    register,
    handleSubmit,
    formState: { dirtyFields },
    control
  } = useForm<TagForm>()

  const { tagTypes, loading: loadingTagTypes } = useTagTypesQuery()
  const tagTypeOptions = tagTypes ? tagTypes.map(({ name }) => ({ value: name, label: name, key: name })) : []
  const { data, loading: loadingTagCategories } = useTagCategoriesQuery()
  const tagCategoriesOptions =
    data?.tagCategories.map(({ name, title }) => ({ value: name, label: title, key: name })) ?? []

  const { updateTag, loading: loadingUpdateTag } = useUpdateTagMutation({
    onCompleted: () => {
      toast.success('Successfully saved tag')
      updateCallback()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not save tag')
    }
  })
  const { setImageOnTag, loading: loadingSetImage } = useSetImageOnTagMutation({
    onCompleted: () => {
      toast.success('Successfully set image on tag')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not set image on tag')
    }
  })

  const [image, setImage] = useState<Tag['image']>(tag.image)
  const { uploadOneImage, uploadedImage, uploading } = useUploadImage({})

  const onUpload = (event: any) => {
    uploadOneImage(event?.target?.files[0])
  }
  const onSubmit = (data: TagForm) => {
    const { types, ...rest } = data

    if (image?.url !== tag.image?.url && image) {
      setImageOnTag(tag.id, image.id)
    }

    const update: Partial<TagInput> = { ...getDirtyFieldValues(rest, dirtyFields) }

    if (types && types.length > 0) {
      update.types = types.map((t) => ({ name: t }))
    }

    if (update.category === '') {
      update.category = null
    }

    update && updateTag(tagId, update)
  }

  useEffect(() => {
    if (uploadedImage) {
      setImage(uploadedImage)
    }
  }, [uploadedImage])

  const loading = loadingUpdateTag || loadingSetImage

  return (
    <Box className="p-5">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid className="w-2/3 mb-8" rowGap={8} columnGap={4}>
          <FormField>
            <FormLabel htmlFor="name">Name</FormLabel>
            <Input
              id="name"
              name="name"
              as="input"
              variant="standard"
              width="full"
              defaultValue={tag.name}
              ref={register}
            />
          </FormField>
          <FormField>
            <FormLabel htmlFor="description">Description</FormLabel>
            <Input
              id="description"
              name="description"
              as="textarea"
              variant="standard"
              width="full"
              defaultValue={tag.description ?? undefined}
              ref={register}
            />
          </FormField>
          <FormField>
            <FormLabel>Group</FormLabel>
            {!loadingTagCategories && (
              <Controller
                name="category"
                control={control}
                defaultValue={tag.category}
                render={({ onChange, value }) => (
                  <Select
                    items={[{ key: '1', value: '', label: 'No group' }, ...tagCategoriesOptions]}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </FormField>
          <FormField>
            <FormLabel>Type</FormLabel>
            {!loadingTagTypes && (
              <Controller
                name="types"
                control={control}
                defaultValue={tag.types?.map(({ name }) => name) || []}
                render={({ onChange, value }) => (
                  <MultiSelect items={tagTypeOptions} value={value} onChange={onChange} />
                )}
              />
            )}
          </FormField>
          <FormField>
            <FormLabel htmlFor="name">Tag Image</FormLabel>
            <div className="row flex">
              {image ? (
                <div className="flex relative">
                  <img
                    src={getImageUrl(image, 200)}
                    className="w-24 object-contain p-1 cursor-pointer border-2 border-transparent"
                  />
                </div>
              ) : null}
              <Upload uploading={uploading} onUpload={onUpload} multiple={false} />
            </div>
          </FormField>
        </Grid>
        <Box className="flex w-2/3 gap-2">
          <Button variant="plain" type="button" onClick={() => cancel()}>
            Cancel
          </Button>
          <Button variant="primary" colorScheme="green" type="submit" isDisabled={loading} isLoading={loading}>
            Save
          </Button>
        </Box>
      </Form>
    </Box>
  )
}
