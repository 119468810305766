import { Badge, Box, Grid } from '@plusplusminus/plusplusdash'
import { Link } from '@reach/router'
import { Card } from 'components/Card'
import { InfoLabel } from 'components/Info/InfoLabel'
import { InfoValue } from 'components/Info/InfoValue'
import { getImageUrl } from 'utils'
import { tagCategory } from 'utils/tags'
import { Tag } from '../../common/types'

interface TagViewProps {
  tagId?: string
  tag: Tag
}

const links = {
  Brand: { to: '/brands' },
  Product: { to: '/products' }
}

export const TagView: React.FC<TagViewProps> = (props) => {
  const { tag } = props

  return (
    <Box className="p-5">
      <Card>
        <Card.Header>
          <div className="flex justify-between items-center w-full">
            <span>{tagCategory(tag.name, tag.categoryLabel)}</span>
            {tag.meta?.contentfulFeedId && <Badge color="green">Feed Tag</Badge>}
          </div>
        </Card.Header>
        <Card.Content>
          <Grid className="w-2/3 mb-8" columns={2} rowGap={8}>
            <div>
              <InfoLabel>Tag Name</InfoLabel>
              <InfoValue>{tag.name}</InfoValue>
            </div>
            <div>
              <InfoLabel>Description</InfoLabel>
              <InfoValue>{tag.description}</InfoValue>
            </div>
            <div>
              <InfoLabel>Group</InfoLabel>
              <InfoValue>{tag.categoryLabel ?? ''}</InfoValue>
            </div>
            <div>
              <InfoLabel>Types</InfoLabel>
              <InfoValue>
                {tag.types?.map((type) => {
                  const { to } = links[type.name as keyof typeof links] || {}
                  const query = new URLSearchParams()
                  query.set(`tags[]`, tag.id)
                  return to ? (
                    <Link className="text-sm underline block mb-1" to={`${to}?${query.toString()}`}>
                      {type.name}
                    </Link>
                  ) : null
                })}
              </InfoValue>
            </div>
            {tag.image ? (
              <div className="w-48">
                <InfoLabel className="mb-2">Image</InfoLabel>
                <img src={getImageUrl(tag.image, 400)} className="w-full object-contain" />
              </div>
            ) : null}
          </Grid>
        </Card.Content>
      </Card>
    </Box>
  )
}
