import { Input, Modal } from '@plusplusminus/plusplusdash'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Notes, noteTypes } from './common'
import Button from 'components/Button'

type EditNotesProps = {
  loading: boolean
  isOpen: boolean
  onClose: () => void
  notes?: Notes
  updateFunc: (notes: Notes) => void
}

const EditNotes: React.FC<EditNotesProps> = (props) => {
  const { loading, isOpen, onClose, updateFunc } = props
  const [notes, setNotes] = useState<Notes>(props.notes ?? ({} as Notes))

  useEffect(() => {
    !loading && setNotes(props.notes || ({} as Notes))
  }, [props.notes, loading])

  const onSubmit = useCallback(async () => {
    await updateFunc(notes)
  }, [notes])
  const onUpdate = useCallback(
    (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setNotes((notes) => ({ ...notes, [key]: event.target.value }))
    },
    [setNotes]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <Modal.Title>Notes</Modal.Title>
      <Modal.Description className="my-4">
        <div className="grid grid-cols-2 gap-y-4">
          {noteTypes.map((note) => {
            const value = notes?.[note.key] ?? undefined
            return (
              <>
                <div>
                  <p className="text-black mb-1">{note.title}</p>
                  <p className="text-xs text-gray-500">{note.description}</p>
                </div>
                <div>
                  <Input variant="standard" as="textarea" value={value} width="full" onChange={onUpdate(note.key)} />
                </div>
              </>
            )
          })}
        </div>
        <div className="flex justify-end gap-2 mt-4">
          <Button variant="plain" onClick={onClose} type="button">
            Close
          </Button>
          <Button variant="primary" colorScheme="green" onClick={onSubmit} isDisabled={loading} isLoading={loading}>
            Save
          </Button>
        </div>
      </Modal.Description>
    </Modal>
  )
}

export { EditNotes }
