import { useState } from 'react'
import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/solid'
import { Alert, Box, Input, Loader, Table } from '@plusplusminus/plusplusdash'
import { OptionName, Page } from 'common/types'
import { Pagination } from 'components/Pagination'
import { useOptionNamesQuery, useUpdateOptionNameMutation } from 'hooks/variants'
import cn from 'classnames'
import DebouncedSearchInput from 'components/DebouncedSearchInput'
import toast from 'react-hot-toast'

const TABLE_SHAPE = [
  { label: 'Name', key: 'name', isSortable: true },
  { label: '', key: '', isSortable: false }
]

const Options: Page<any> = () => {
  const { query, pagination, sort, filtering } = useOptionNamesQuery()
  const { filter, setFilter, removeFilter } = filtering
  const { refetch, loading, error, data, previousData } = query
  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback] = sort

  const tableData = data || previousData

  return (
    <Box className="flex flex-col">
      <Box className="flex p-5 justify-between items-center bg-white border-b border-gray-200">
        <h1 className="text-lg">Product Options</h1>
      </Box>
      {error ? (
        <Alert type="error">
          <Alert.Heading>There was an error fetching option names</Alert.Heading>
        </Alert>
      ) : !previousData && loading ? (
        <div className="flex flex-auto justify-center items-center">
          <Loader isActive={true} />
        </div>
      ) : tableData ? (
        <Box>
          <div className="bg-white w-full flex justify-between py-2 px-5">
            <DebouncedSearchInput
              onRemoveFilter={() => removeFilter('name')}
              as="input"
              variant="outline"
              width="md"
              id="name"
              name="name"
              onChange={(e) => setFilter('name', e.target.value)}
              value={filter.name ?? ''}
              placeholder="Type to search for options"
            />
          </div>
          <Table
            shape={TABLE_SHAPE}
            sortCallback={sortCallback}
            activeSort={sortField}
            sortDirection={direction?.toLowerCase()}
          >
            {tableData.optionNames.edges.map(({ node: optionName }) => {
              return <OptionNameRow key={optionName.id} option={optionName} refetch={refetch} />
            })}
          </Table>
          <Pagination
            hasPreviousPage={tableData.optionNames.pageInfo.hasPreviousPage}
            hasNextPage={tableData.optionNames.pageInfo.hasNextPage}
            onNext={nextPage}
            onPrev={prevPage}
            page={page}
          />
        </Box>
      ) : null}
    </Box>
  )
}

const iconStyle = 'h-4 w-4 cursor-pointer'

const OptionNameRow = (props: { option: OptionName; refetch: () => void }) => {
  const { option, refetch } = props

  const [isEditing, setIsEditing] = useState(false)
  const [name, setName] = useState(option.name)

  const { updateOptionName, loading } = useUpdateOptionNameMutation({
    onCompleted: () => {
      toast.success('Successfully updated option name')
      setIsEditing(false)
      refetch()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update option name')
    }
  })

  const save = () => {
    updateOptionName(option.id, { name })
  }

  return (
    <Table.Row>
      <Table.Cell className="font-medium text-gray-900 flex items-center gap-4 w-96">
        {isEditing ? (
          <Input
            id="name"
            name="name"
            defaultValue={name}
            variant="outline"
            width="full"
            onChange={(e) => setName(e.target.value)}
          />
        ) : (
          <span>{option.name}</span>
        )}
      </Table.Cell>
      <Table.Cell>
        {isEditing ? (
          <div>
            <CheckIcon
              className={cn(iconStyle, 'text-green-600', { 'opacity-30': loading })}
              onClick={() => loading && save()}
            />
            <XIcon className={cn(iconStyle, 'text-red-600')} onClick={() => setIsEditing(false)} />
          </div>
        ) : (
          <PencilIcon className={iconStyle} onClick={() => setIsEditing(true)} />
        )}
      </Table.Cell>
    </Table.Row>
  )
}

export { Options }
