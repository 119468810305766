import { Combobox } from '@headlessui/react'
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid'
import { Modal } from '@plusplusminus/plusplusdash'
import { TagTypes } from 'common/enums'
import { useAddTagsToProductsMutation, useRemoveTagsFromProductsMutation } from 'generated'
import { useAllTags } from 'hooks/tags'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { buildTagSelectOptions, buildTagsMap } from 'utils/tags'
import { SelectedProduct } from './BulkTag'
import Button from 'components/Button'

interface BulkTagModalProps {
  onRefresh: () => void
  isOpen: boolean
  onClose: () => void
  selectedProducts: SelectedProduct[]
}

export const BulkTagModal = ({ isOpen, selectedProducts, onClose, onRefresh }: BulkTagModalProps): JSX.Element => {
  const [query, setQuery] = useState('')
  const [selectedTags, setSelectedTags] = useState<string[]>([])

  const [tags] = useAllTags(TagTypes.Product)
  const [addTagsToProducts, { loading: loadingAdding }] = useAddTagsToProductsMutation({
    onCompleted: () => {
      onCompleted('Tags added to selected products')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not add tags.')
    }
  })
  const [removeTagsFromProducts, { loading: loadingRemoving }] = useRemoveTagsFromProductsMutation({
    onCompleted: () => {
      onCompleted('Tags removed from selected products')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not remove tags.')
    }
  })

  const onCompleted = (message: string) => {
    setQuery('')
    setSelectedTags([])
    onRefresh()
    toast.success(message)
    onClose()
  }

  const loading = !tags || loadingAdding || loadingRemoving
  const tagSelectOptions = buildTagSelectOptions(tags)
  const tagsMap = buildTagsMap(tags)
  const filteredTags =
    query === ''
      ? tagSelectOptions
      : tagSelectOptions
      ? tagSelectOptions.filter((tag) =>
          tag.label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        )
      : []
  const isTags = selectedTags && selectedTags.length > 0
  const onAddTags = () => {
    if (selectedTags.length > 0) {
      addTagsToProducts({
        variables: {
          input: { productIds: selectedProducts.map((selectedProduct) => selectedProduct.id), tagIds: selectedTags }
        }
      })
    }
  }
  const onRemoveTags = () => {
    if (selectedTags.length > 0) {
      removeTagsFromProducts({
        variables: {
          input: { productIds: selectedProducts.map((selectedProduct) => selectedProduct.id), tagIds: selectedTags }
        }
      })
    }
  }
  const onResetSelection = () => {
    setSelectedTags([])
  }
  const onRemoveFromSelection = (index: number) => {
    setSelectedTags((selectedTags) => [...selectedTags.slice(0, index), ...selectedTags.slice(index + 1)])
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <Modal.Title>Bulk Tag - Add/Remove Tags</Modal.Title>
      <Modal.Description className="my-4">
        <div className="text-base mb-5 font-semibold">{`Products selected: ${selectedProducts.length}`}</div>

        {tags ? (
          <div className="grid grid-cols-2 gap-10">
            <div>
              <div className="text-base font-semibold mb-2">Select Tags</div>
              <Combobox value={selectedTags} onChange={(value) => setSelectedTags(value)} multiple>
                <div className="relative mt-1">
                  <div className="relative w-full cursor-default text-left">
                    <Combobox.Input
                      autoFocus
                      className="w-full py-2 pl-3 pr-10 text-sm leading-5 border-2 text-gray-900 focus:border-indigo-500 border-gray-200 rounded outline-none focus:shadow-none"
                      value={query}
                      onChange={(event) => setQuery(event.target.value)}
                    />
                    {query ? (
                      <div
                        className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer"
                        onClick={() => setQuery('')}
                      >
                        <XIcon className="h-5 w-5" />
                      </div>
                    ) : (
                      <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </Combobox.Button>
                    )}
                  </div>
                  {/* <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setQuery('')}
                > */}
                  <Combobox.Options
                    static
                    className="mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {filteredTags?.length === 0 && query !== '' ? (
                      <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
                    ) : (
                      filteredTags.map((tag) => (
                        <Combobox.Option
                          key={tag.value}
                          className={({ active }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active ? 'bg-teal-600 text-black' : 'text-gray-900'
                            }`
                          }
                          value={tag.value}
                        >
                          {({ selected, active }) => {
                            return (
                              <>
                                <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                                  {tag.label}
                                </span>
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                      active ? 'text-teal-600' : 'text-teal-600'
                                    }`}
                                  >
                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )
                          }}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                  {/* </Transition> */}
                </div>
              </Combobox>
            </div>
            <div>
              <div className="flex space-x-2 items-center mb-2">
                <div className="text-base font-semibold ">Selected Tags</div>
              </div>
              {isTags && (
                <>
                  <div className="mt-1">
                    {selectedTags.map((selectedTag, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center mr-1 bg-gray-200 text-xs px-1 py-1 mb-1 rounded-sm cursor-pointer"
                        onClick={() => onRemoveFromSelection(index)}
                      >
                        {tagsMap[selectedTag]}&nbsp; <XIcon className="h-4 w-4" />
                      </span>
                    ))}
                  </div>
                  <Button
                    isDisabled={loading}
                    isLoading={loading}
                    variant="plain"
                    colorScheme="green"
                    type="button"
                    onClick={onResetSelection}
                    className="mt-2"
                  >
                    Clear
                  </Button>
                </>
              )}
            </div>
          </div>
        ) : (
          'Loading tags...'
        )}
        <div className="flex justify-end gap-2 mt-5">
          <Button variant="primary" colorScheme="red" onClick={onClose} type="button" isDisabled={loading}>
            Cancel
          </Button>
          <Button
            isDisabled={loading || !isTags}
            isLoading={loading}
            variant="primary"
            colorScheme="green"
            type="button"
            onClick={onAddTags}
          >
            Apply Tags
          </Button>
          <Button
            isDisabled={loading || !isTags}
            isLoading={loading}
            variant="primary"
            colorScheme="green"
            type="button"
            onClick={onRemoveTags}
          >
            Remove Tags
          </Button>
        </div>
      </Modal.Description>
    </Modal>
  )
}
