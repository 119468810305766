import { gql } from '@apollo/client'
import { Form, FormLabel, Input, Loader } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import AccountGroupQueryBuilder from 'components/AccountGroupQueryBuilder'
import Button from 'components/Button'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import {
  AccountGroupInput,
  GetAccountGroupQuery,
  useEditAccountGroupMutation,
  useGetAccountGroupQuery
} from 'generated'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import DeleteAccountGroupModal from './DeleteAccountGroupModal'
import ViewAccountGroup from './ViewAccountGroup'

interface EditAccountGroupProps extends RouteComponentProps {
  id: string
}

function getFormValues(accountGroup: GetAccountGroupQuery['accountGroup']) {
  return {
    name: accountGroup?.name,
    description: accountGroup?.description,
    definition: accountGroup?.definition
  }
}

export default function EditAccountGroup(props: EditAccountGroupProps): JSX.Element {
  const [{ showDeleteModal, id: idToDelete, name: nameToDelete }, toggleDeleteModal] = useState<{
    id?: string
    name?: string
    showDeleteModal: boolean
  }>({ showDeleteModal: false })

  const { data, loading: loadingAccountGroup } = useGetAccountGroupQuery({ variables: { id: props.id } })
  const [updateAccountGroup, { loading }] = useEditAccountGroupMutation({
    onCompleted: () => {
      toast.success('Audience Segment updated successfully')
      props.navigate?.(`/audience-segments/${props.id}/view`)
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not delete audience segment')
    }
  })

  const { control, register, handleSubmit, reset } = useForm({
    defaultValues: getFormValues(data?.accountGroup)
  })

  useEffect(() => {
    if (data) {
      reset(getFormValues(data.accountGroup))
    }
  }, [data])

  const onSubmit = (data: AccountGroupInput) => {
    updateAccountGroup({ variables: { id: props.id, update: data } })
  }

  if (loadingAccountGroup) return <Loader isActive />

  return (
    <div>
      <DeleteAccountGroupModal
        id={idToDelete}
        name={nameToDelete}
        isOpen={showDeleteModal}
        onDelete={() => {
          props.navigate?.(`/audience-segments`)
        }}
        onClose={() => toggleDeleteModal({ showDeleteModal: false })}
      />
      <PageHeader>
        <h1 className="text-lg">Edit Audience Segment</h1>
        <Button
          variant="primary"
          colorScheme="red"
          onClick={() => toggleDeleteModal({ showDeleteModal: true, id: props.id, name: data?.accountGroup?.name })}
        >
          Delete
        </Button>
      </PageHeader>
      <div className="p-5">
        <Card>
          <Card.Content>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-4 items-start">
                <FormLabel>
                  <strong>Name</strong>
                  <Input variant="standard" name="name" ref={register({ required: true })} />
                </FormLabel>
                <FormLabel>
                  <strong>Description</strong>
                  <Input variant="standard" width="lg" name="description" ref={register} />
                </FormLabel>
                <FormLabel>
                  <strong>Query Definition</strong>
                </FormLabel>
                <div className="self-stretch">
                  <Controller
                    control={control}
                    name="definition"
                    render={({ onChange }) => (
                      <AccountGroupQueryBuilder
                        tree={data?.accountGroup?.definition}
                        onChange={(json) => onChange(json)}
                      />
                    )}
                  />
                </div>
                <Button colorScheme="green" type="submit" variant="primary" isDisabled={loading} isLoading={loading}>
                  Save
                </Button>
              </div>
            </Form>
          </Card.Content>
        </Card>
      </div>
    </div>
  )
}

EditAccountGroup.mutation = gql`
  mutation EditAccountGroup($id: ID!, $update: AccountGroupInput!) {
    updateOneAccountGroup(input: { id: $id, update: $update }) {
      ...AccountGroupFields
    }
  }
  ${ViewAccountGroup.fields}
`
