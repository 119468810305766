import { useLocation } from '@reach/router'
import { useCallback, useEffect, useState } from 'react'
import { Direction as DirectionEnum } from '../common/enums'
import queryString from 'query-string'

type SortTuple = [
  string | undefined,
  SortDirection | undefined,
  (name: string, overrideDirection?: DirectionEnum) => void,
  (name: string, direction: DirectionEnum) => void,
  () => void
]
type UseSortOptions = {
  persist?: boolean
  onSortChange?: () => void
}

class SortDirection {
  constructor(public readonly value: DirectionEnum) {}

  toString() {
    return this.value.toString() as 'ASC' | 'DESC'
  }

  isAsc() {
    return this.value === DirectionEnum.ASC
  }

  toLowerCase() {
    return this.value.toString().toLowerCase() as 'asc' | 'desc'
  }
}

const useSort = (options: UseSortOptions): SortTuple => {
  const { persist = false, onSortChange } = options
  const location = useLocation()
  const [sortField, setSortField] = useState<string>()
  const [direction, setDirection] = useState<SortDirection>()

  const { search } = location || {}

  useEffect(() => {
    if (search) {
      const params = queryString.parse(search)
      if (params.sortField && typeof params.sortField === 'string') {
        setSortField(params.sortField)
        if (typeof params.direction === 'string') {
          setDirection(new SortDirection(params.direction as DirectionEnum))
        } else {
          setDirection(new SortDirection(DirectionEnum.ASC))
        }
      }
    }
  }, [])

  const setSort = useCallback((name: string, direction: DirectionEnum) => {
    setSortField(name)
    setDirection(new SortDirection(direction))
    if (persist) {
      const url = new URL(window.location.toString())
      if (name) {
        url.searchParams.set('sortField', name)
        url.searchParams.set('direction', direction)
      } else {
        url.searchParams.delete(name)
      }
      history.pushState({}, '', url.toString())
    }
    onSortChange && onSortChange()
  }, [])

  const sortCallback = useCallback(
    (name: string, overrideDirection?: DirectionEnum) => {
      let newSortField
      let newDirection
      if (!sortField) {
        newSortField = name
        newDirection = new SortDirection(DirectionEnum.ASC)
      } else if (sortField === name && direction) {
        if (direction.isAsc()) {
          newDirection = new SortDirection(DirectionEnum.DESC)
          newSortField = name
        } else {
          newSortField = undefined
          newDirection = undefined
        }
      } else {
        newSortField = name
        newDirection = new SortDirection(DirectionEnum.ASC)
      }
      setSortField(newSortField)
      const directionToSet = overrideDirection ? new SortDirection(overrideDirection) : newDirection
      setDirection(directionToSet)
      onSortChange && onSortChange()
      if (persist) {
        const url = new URL(window.location.toString())
        if (newSortField) {
          url.searchParams.set('sortField', newSortField)
          url.searchParams.set('direction', directionToSet?.toString() || DirectionEnum.ASC)
        } else {
          url.searchParams.delete('sortField')
          url.searchParams.delete('direction')
        }
        history.pushState({}, '', url.toString())
      }
    },
    [sortField, direction, setSortField, setDirection, search]
  )

  const removeSort = () => {
    setSortField(undefined)
    setDirection(undefined)
    if (persist) {
      const url = new URL(window.location.toString())
      url.searchParams.delete('sortField')
      url.searchParams.delete('direction')
      history.pushState({}, '', url.toString())
    }
    onSortChange && onSortChange()
  }

  return [sortField, direction, sortCallback, setSort, removeSort]
}

export { useSort }
export type { SortTuple }
