import { OperationVariables, QueryResult } from '@apollo/client'
import { Alert, Loader, Table } from '@plusplusminus/plusplusdash'
import { navigate } from '@reach/router'
import { Badge } from 'components/Badge'
import { Pagination } from 'components/Pagination'
import { OrdersResult } from 'hooks/orders'
import { TPagination } from 'hooks/usePagination'
import { SortTuple } from 'hooks/useSort'
import React from 'react'
import { formatDate } from 'utils'

const TABLE_SHAPE = [
  { label: 'Order Number', key: 'orderNumber', isSortable: true },
  { label: 'Platform', key: 'platform', isSortable: false },
  { label: 'Order Date', key: 'orderDate', isSortable: true },
  { label: 'Customer', key: 'customer', isSortable: false },
  { label: 'Subtotal', key: 'subTotal', isSortable: true },
  { label: 'Number of items', key: 'productCount', isSortable: false },
  { label: 'Brands', key: 'brands', isSortable: false }
]

interface OrdersPaginatedProps {
  query: QueryResult<OrdersResult, OperationVariables>
  sort: SortTuple
  pagination: TPagination
}

const OrdersPaginated: React.FC<OrdersPaginatedProps> = ({ query, sort, pagination }) => {
  const { data, previousData, loading, error } = query
  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback] = sort
  const tableData = data || previousData

  function onClickRow(orderId: string) {
    return () => {
      navigate(`/orders/${orderId}`)
    }
  }

  return (
    <div>
      {error ? (
        <Alert type="error">
          <Alert.Heading>There was an error fetching orders.</Alert.Heading>
        </Alert>
      ) : !previousData && loading ? (
        <Loader isActive />
      ) : tableData ? (
        <>
          <Table
            shape={TABLE_SHAPE}
            sortCallback={sortCallback}
            activeSort={sortField}
            sortDirection={direction?.toLowerCase()}
          >
            {tableData?.orders.edges.map(({ node: order }) => {
              return (
                <Table.Row
                  key={order.id}
                  className="cursor-pointer hover:bg-blue-50 border-b border-gray-100"
                  onClick={onClickRow(order.id)}
                >
                  <Table.Cell>{order.orderNumber}</Table.Cell>
                  <Table.Cell>
                    {!!order?.metadata?.checkout?.platform && (
                      <Badge color="green" className="mr-1">
                        {order?.metadata?.checkout?.platform}
                      </Badge>
                    )}
                    {!order?.metadata?.checkout?.platform && (
                      <Badge color="yellow" className="mr-1">
                        Website
                      </Badge>
                    )}
                    {!order?.customer?.accountId && <Badge color="blue">Guest</Badge>}
                  </Table.Cell>
                  <Table.Cell>{formatDate(order.orderDate)}</Table.Cell>
                  <Table.Cell>
                    {order.customer?.firstName ?? ''} {order.customer?.lastName ?? ''}
                  </Table.Cell>
                  <Table.Cell>R {order.subTotal}</Table.Cell>
                  <Table.Cell>{order.productCount}</Table.Cell>
                  <Table.Cell>
                    {order.lines.slice(0, 3).map((l) => (
                      <Badge className="mr-1" color="blue">
                        {l.brand.brandName}
                      </Badge>
                    ))}
                    {order.lines.length > 3 ? (
                      <p className="text-xs text-gray-400 ml-2 inline">{order.lines.length - 3} others</p>
                    ) : null}
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table>
          <Pagination
            hasPreviousPage={tableData.orders.pageInfo.hasPreviousPage}
            hasNextPage={tableData.orders.pageInfo.hasNextPage}
            onNext={nextPage}
            onPrev={prevPage}
            page={page}
          />
        </>
      ) : null}
    </div>
  )
}

export { OrdersPaginated }
