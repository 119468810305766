import { DocumentNode, QueryHookOptions, QueryResult, useQuery } from '@apollo/client'
import { PaginatedEntity } from 'common/types'
import { SortDirection } from 'generated'
import { set } from 'lodash'
import { useMemo } from 'react'
import { TUseFilter, useFilter } from './useFilter'
import { TPagination, usePagination } from './usePagination'
import { SortTuple, useSort } from './useSort'

type TUsePaginatedQuery<T> = {
  query: QueryResult<T>
  pagination: TPagination
  sort: SortTuple
  filtering: TUseFilter
  queryFields: any
}

type DefaultSort = { sortField: string; direction: SortDirection }

type TQueryResult<T, V> = { [key in keyof T]: PaginatedEntity<V> }
type PaginatedQueryArgs<T> = {
  query: DocumentNode
  accessor: keyof T
  options?: QueryHookOptions
  buildFilter?: (filter: any) => any
  persist?: boolean
  limit?: number
  defaultSort?: DefaultSort
}

export function defaultBuildFilter(filter: TUseFilter['filter']) {
  let f: Record<string, any> = {}
  if (filter) {
    f = Object.entries(filter).reduce((acc, [key, value]) => {
      if (typeof value === 'string') {
        set(acc, key, { iLike: `%${value}%` })
      } else if (typeof value === 'boolean') {
        set(acc, key, { is: value })
      }
      return acc
    }, {})
  }
  return f
}

function usePaginatedQuery<T extends TQueryResult<T, V>, V>({
  query,
  accessor,
  options,
  buildFilter = defaultBuildFilter,
  persist = false,
  limit,
  defaultSort = {} as DefaultSort
}: PaginatedQueryArgs<T>): TUsePaginatedQuery<T> {
  const pagination = usePagination({ persist, limit })
  const sort = useSort({ persist, onSortChange: pagination.resetPaging })
  const filtering = useFilter({ persist, onFilterChange: pagination.resetPaging })
  const { filter } = filtering

  const builtFilter = useMemo(() => {
    if (buildFilter) {
      return buildFilter(filter)
    } else {
      return {}
    }
  }, [filter, buildFilter])

  const { paging, setCursor } = pagination
  const [sortField = defaultSort.sortField, direction = defaultSort.direction] = sort

  const result = useQuery<T>(query, {
    variables: {
      paging,
      ...(sortField ? { sorting: { field: sortField, direction: direction?.toString() } } : {}),
      filter: builtFilter
    },
    onCompleted: (data) => {
      const pageInfo = data[accessor].pageInfo
      if (pageInfo) {
        const { startCursor, endCursor } = pageInfo
        setCursor({ startCursor, endCursor })
      }
    },
    fetchPolicy: 'cache-and-network',
    ...options
  })

  return {
    query: result,
    pagination,
    sort,
    filtering,
    queryFields: {
      paging,
      ...(sortField ? { sorting: { field: sortField, direction: direction?.toString() } } : {}),
      filter: builtFilter
    }
  }
}

export { usePaginatedQuery }
export type { TUsePaginatedQuery }
