interface UploadProps {
  uploading: boolean
  multiple?: boolean
  onUpload: (event: any) => void
}

export const Upload: React.FC<UploadProps> = ({ uploading, onUpload, multiple = true }) => (
  <div className="relative overflow-hidden flex items-center justify-center w-24">
    <div className="bg-blue-400 text-white rounded-md shadow w-16 h-10 items-center justify-center flex">
      <span className="sr-only">Upload</span>
      {uploading ? (
        <div className="animate-spin">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
        </div>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
          />
        </svg>
      )}
    </div>
    {uploading ? null : (
      <input className="opacity-0 cursor-pointer absolute block" onChange={onUpload} type="file" multiple={multiple} />
    )}
  </div>
)
