import { PencilIcon } from '@heroicons/react/solid'
import { Modal } from '@plusplusminus/plusplusdash'
import { AccountCode, OrderLedger, OrderLineLedgerInput, useUpdateLedgerItemMutation } from 'generated'
import React, { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import toast from 'react-hot-toast'
import AccountLineItemForm from './AccountingLineForm'

interface EditAccountingLineProps {
  accountingCodes: AccountCode[]
  accountingLine: OrderLedger
  onRefetch: () => void
}
const EditAccountingLine: React.FC<EditAccountingLineProps> = ({ accountingCodes, onRefetch, accountingLine }) => {
  const { id, amount, isCredit, code, description = '' } = accountingLine
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const [updateLedgerItem, { loading }] = useUpdateLedgerItemMutation({
    onCompleted: () => {
      onClose()
      onRefetch()
      toast.success('Successfully updated accounting line item')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update accounting line item')
    }
  })

  const onSubmit: SubmitHandler<OrderLineLedgerInput> = (input) => {
    updateLedgerItem({ variables: { id: id ?? '', input } })
  }
  return (
    <>
      <button type="button" onClick={onOpen}>
        <PencilIcon className="h-5 w-5" />
      </button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <Modal.Title>Edit accounting line</Modal.Title>
        <Modal.Description className="my-4">
          <AccountLineItemForm
            defaultValues={{
              codeId: code ? parseFloat(code) : null,
              description,
              amount,
              isCredit: isCredit ?? false
            }}
            onClose={onClose}
            isLoading={loading}
            accountingCodes={accountingCodes}
            onSubmit={onSubmit}
          />
        </Modal.Description>
      </Modal>
    </>
  )
}

export default EditAccountingLine
