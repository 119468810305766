import { gql } from '@apollo/client'
import Select from 'components/Select'
import { useActionTypeOptionsQuery } from 'generated'

type ActionTypeSelectProps = {
  onChange: (value: string) => void
  value: string
}

export default function ActionTypeSelect(props: ActionTypeSelectProps): JSX.Element | null {
  const { data } = useActionTypeOptionsQuery()

  return data?.actionTypeOptions && data.actionTypeOptions.length > 0 ? (
    <Select options={data.actionTypeOptions} {...props} />
  ) : null
}

ActionTypeSelect.query = gql`
  query ActionTypeOptions {
    actionTypeOptions
  }
`
