import { Maybe } from 'generated'

interface PaginationProps {
  hasNextPage?: Maybe<boolean>
  hasPreviousPage?: Maybe<boolean>
  page: number
  onNext: () => void
  onPrev: () => void
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const { hasNextPage, hasPreviousPage, onNext, onPrev, page } = props

  return (
    <div className="flex justify-end py-4 px-8">
      <div className="w-16">
        {hasPreviousPage && (
          <a className="underline text-md cursor-pointer" onClick={onPrev}>
            Prev
          </a>
        )}
      </div>
      <span className="w-16 text-md mx-4">Page {page}</span>
      <div className="w-16">
        {hasNextPage && (
          <a className="underline text-md cursor-pointer" onClick={onNext}>
            Next
          </a>
        )}
      </div>
    </div>
  )
}

export { Pagination }
