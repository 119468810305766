import { gql } from '@apollo/client'
import { MultiSelect } from '@plusplusminus/plusplusdash'
import { useMessageChannelsQuery } from 'generated'
import { useMemo } from 'react'

type MessageChannelSelectProps = {
  onChange: (value: string[]) => void
  value: string[]
}

export default function MessageChannelSelect(props: MessageChannelSelectProps): JSX.Element | null {
  const { data } = useMessageChannelsQuery()

  const selectItems = useMemo(() => {
    if (data?.messageChannels) {
      return Object.entries(data.messageChannels).map(([key, value]) => ({ label: value as string, value: key, key }))
    }
  }, [data])

  return selectItems && selectItems.length > 0 ? <MultiSelect items={selectItems} {...props} /> : null
}

MessageChannelSelect.query = gql`
  query MessageChannels {
    messageChannels
  }
`
