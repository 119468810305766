import { DocumentNode, gql } from '@apollo/client'
import { DocumentTextIcon } from '@heroicons/react/solid'
import { Alert, Badge, Loader } from '@plusplusminus/plusplusdash'
import { Link, useParams } from '@reach/router'
import { Page } from 'common/types'
import { Card } from 'components/Card'
import { InfoLabel } from 'components/Info/InfoLabel'
import { InfoValue } from 'components/Info/InfoValue'
import OrderDetails from 'components/Orders/OrderDetails'
import { PageHeader } from 'components/PageHeader'
import { OrderFieldsFragment, useAccountingCodesQuery, useRefundQuery } from 'generated'
import React from 'react'
import { formatDate } from 'utils'
import LineItem from '../../components/LineItem'
import RefundPayoutDate from './RefundPayoutDate'

const statusColors: Record<string, React.ComponentProps<typeof Badge>['color']> = {
  Completed: 'green',
  Pending: 'yellow'
}

const paymentBadgeColor: Record<string, React.ComponentProps<typeof Badge>['color']> = {
  Created: 'gray',
  Authorized: 'yellow',
  Settled: 'green',
  Declined: 'red',
  Error: 'red',
  Cancelled: 'red'
}

const Refund: Page<any> & { query: DocumentNode } = () => {
  const { refundId } = useParams()

  const { data, loading, error, refetch } = useRefundQuery({ variables: { id: refundId } })
  const { data: dataAccountingCodes, loading: loadingAccountingCodes } = useAccountingCodesQuery()
  const onRefetch = () => refetch()
  if (error?.graphQLErrors) {
    return (
      <Alert type="error">
        <Alert.Heading>Error fetching refund</Alert.Heading>
        <Alert.Description>An unexpected error occurred when fetching the specified refund.</Alert.Description>
      </Alert>
    )
  }

  if (loading || loadingAccountingCodes) {
    return <Loader isActive />
  }

  if (!data || !data.refund || !dataAccountingCodes?.accountingCodes) {
    return null
  }

  const { refund } = data
  const isSettled = refund.state === 'Settled'
  const color = statusColors[refund.state]
  const isPayout = !!refund.orderLine?.ledgerItems?.find((ledgerItem) => ledgerItem.code === '9990/000')
  return (
    <div>
      <PageHeader>
        <div className="flex justify-between items-center w-full">
          <div>Refund Detail</div>
          {isSettled && <Badge color="green">Settled</Badge>}
        </div>
      </PageHeader>
      <div className="p-5">
        <Card className="mb-5">
          <Card.Header>
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center">
                <span className="text-md pr-2 border-r border-gray-400">Refund Request</span>

                <div className="pl-2 text-gray-500 text-sm">{formatDate(refund.createdAt)}</div>
              </div>

              <div className="flex flex-col gap-1">
                <Badge color={color}>{refund.state}</Badge>
              </div>
            </div>
          </Card.Header>
          <Card.Content>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <InfoLabel className="flex items-center gap-1">
                  <DocumentTextIcon className="h-4" />
                  Refund Summary
                </InfoLabel>
                <InfoValue>
                  <div className="text-sm">Note: {refund.note}</div>
                </InfoValue>
              </div>
              <div className="flex justify-end items-end h-full">
                <div className="grid grid-cols-2 gap-x-4 text-md justify-items-end">
                  <div className="text-right">Refund Amount</div>
                  <div>R {refund.basketAmount}</div>
                  <div className="text-right">Adjusted Amount</div>
                  <div>R {refund.adjustmentAmount}</div>
                </div>
              </div>
            </div>
          </Card.Content>
        </Card>
        {refund.orderLine?.order && <OrderDetails order={refund.orderLine.order as OrderFieldsFragment} link />}
        <Card className="mb-5">
          <Card.Header>
            <div className="w-full flex justify-between">
              <div className="flex items-center space-x-2">
                <span className="border-r border-gray-400 pr-2">Line Item</span>
                <RefundPayoutDate
                  orderLineId={refund.orderLine?.id ?? ''}
                  currentPaymentDate={refund.orderLine?.paymentDate}
                  isPayout={isPayout}
                  onRefetch={onRefetch}
                />
              </div>
            </div>
          </Card.Header>
          {refund.orderLine?.order.id && (
            <Card.Content>
              <LineItem
                orderId={refund.orderLine.order.id}
                isCompleted={true}
                accountingCodes={dataAccountingCodes.accountingCodes}
                onRefetch={onRefetch}
                lineItem={refund.orderLine}
              />
            </Card.Content>
          )}
        </Card>
        <Card>
          <Card.Header>
            <div className="flex justify-between">
              <span>Payments</span>
            </div>
          </Card.Header>
          <Card.Content>
            {refund.orderLine?.order.payments.map((payment) => {
              return (
                <div className="flex space-x-5 items-center py-2 border-b border-gray-100">
                  <span>{formatDate(payment.createdAt)}</span>
                  <span>R {payment.amount}</span>
                  <span className="text-sm text-gray-600">{payment.method}</span>
                  <Badge color={paymentBadgeColor[payment.state]}>{payment.state}</Badge>
                </div>
              )
            })}
          </Card.Content>
        </Card>
      </div>
    </div>
  )
}

Refund.query = gql`
  query Refund($id: ID!) {
    refund(id: $id) {
      ...RefundFields
    }
  }
`

export { Refund }
