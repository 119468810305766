import React from 'react'
import cn from 'classnames'

interface ErrorProps {
  children: React.ReactNode
  className?: string
}

export const FormError: React.FC<ErrorProps> = (props) => {
  const { children, className: overrideClassname } = props
  const className = cn('text-red-500 text-xs', overrideClassname)
  return <span className={className}>{children}</span>
}
