import { Form, FormError, FormField, FormLabel } from '@plusplusminus/plusplusdash'
import { DatePicker } from 'antd'
import Button from 'components/Button'
import { OrderLinePaymentDateInput } from 'generated'
import moment from 'moment'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'

export type RefundPayoutDateFormDefaultValues = {
  id: string
  paymentDate: Date
}

interface RefundPayoutDateFormProps {
  defaultValues: RefundPayoutDateFormDefaultValues
  onSubmit: (input: OrderLinePaymentDateInput) => void
  onClose: () => void
  isLoading: boolean
}
const RefundPayoutDateForm: FC<RefundPayoutDateFormProps> = ({ defaultValues, onSubmit, onClose, isLoading }) => {
  const {
    errors,
    register,
    handleSubmit,
    formState: { isDirty },
    control
  } = useForm<RefundPayoutDateFormDefaultValues>({ defaultValues })
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <FormField>
        <FormLabel className="col-start-1">
          <strong>Payment Date</strong>
          <Controller
            control={control}
            name="paymentDate"
            render={({ onChange, value }) => (
              <div className="block">
                <DatePicker
                  className="rounded border-2 border-gray-200 p-2 w-full"
                  value={value ? moment(value) : undefined}
                  onChange={(pickedDate) => onChange(pickedDate?.toDate())}
                />
              </div>
            )}
          />
          {errors.paymentDate && <FormError>{errors.paymentDate.message}</FormError>}
        </FormLabel>
      </FormField>
      <input hidden readOnly type="text" name="id" ref={register} />

      <div className="flex justify-end gap-2">
        <Button variant="plain" onClick={onClose} type="button">
          Close
        </Button>
        <Button
          variant="primary"
          colorScheme="green"
          type="submit"
          isDisabled={isLoading || !isDirty}
          isLoading={isLoading}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

export default RefundPayoutDateForm
