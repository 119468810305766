import { PlusIcon } from '@heroicons/react/solid'
import { Button, Modal } from '@plusplusminus/plusplusdash'
import { AccountCode, OrderLineLedgerInput, useCreateLedgerItemMutation } from 'generated'
import React, { useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import toast from 'react-hot-toast'
import AccountLineItemForm from './AccountingLineForm'

interface AddAccountingLineProps {
  accountingCodes: AccountCode[]
  orderLineId: string
  onRefetch: () => void
}
const AddAccountingLine: React.FC<AddAccountingLineProps> = ({ accountingCodes, onRefetch, orderLineId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const [createLedgerItem, { loading }] = useCreateLedgerItemMutation({
    onCompleted: () => {
      onClose()
      onRefetch()
      toast.success('Successfully added accounting line item')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not add accounting line item')
    }
  })

  const onSubmit: SubmitHandler<OrderLineLedgerInput> = (input) => {
    createLedgerItem({ variables: { orderLineId, input } })
  }
  return (
    <>
      <Button variant="primary" colorScheme="green" type="button" onClick={onOpen}>
        <PlusIcon className="h-4 w-4 " />
        <div className="ml-2">Add accounting line</div>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <Modal.Title>Add accounting line</Modal.Title>
        <Modal.Description className="my-4">
          <AccountLineItemForm
            defaultValues={{ codeId: null, amount: 0, isCredit: false, description: '' }}
            onClose={onClose}
            isLoading={loading}
            accountingCodes={accountingCodes}
            onSubmit={onSubmit}
          />
        </Modal.Description>
      </Modal>
    </>
  )
}

export default AddAccountingLine
