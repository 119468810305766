import { gql } from '@apollo/client'
import { Form, FormError, FormLabel, Input, Radio } from '@plusplusminus/plusplusdash'
import { DatePicker, TimePicker } from 'antd'
import AccountGroupComboBox from 'components/AccountGroupComboBox'
import MessageChannelSelect from 'components/MessageChannelSelect'
import { CreateMessageModal } from 'components/Modals/CreateMessageModal'
import {
  CampaignInterval,
  CampaignType,
  CreateMessageCampaignInput,
  CreateMessageCampaignMutation,
  Maybe,
  Message,
  MessageChannels,
  useCreateMessageCampaignMutation
} from 'generated'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import moment, { Moment } from 'moment'
import MessagesTable, { MessagesResult } from 'pages/Message/MessagesTable'
import SearchMessage from 'pages/Message/SearchMessage'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import Select from 'components/Select'
import Button from 'components/Button'
import { EmailTemplatesCollection } from 'hooks/useEmailTemplates'

const limit = 5

const buildFilter = (filter: any) => {
  const f: any = {}
  if (filter.search) {
    f.or = [{ name: { iLike: `%${filter.search}%` } }, { title: { iLike: `%${filter.search}%` } }]
  }
  if ('enabled' in filter) {
    f.enabled = { is: filter.enabled }
  }
  return f
}

type DefaultValues = Omit<CreateMessageCampaignInput, 'channels'> & {
  channels?: Maybe<MessageChannels[]> | undefined
  messageName?: string
}

type CreateMessageCampaignFormData = Omit<CreateMessageCampaignInput, 'scheduledAt' | 'nextRunAt' | 'timeToRun'> & {
  scheduledAt: Moment
  accountGroupIds: string
  nextRunAt?: Moment
  timeToRun?: Moment
  messageName?: string
}

interface CreateMessageCampaignFormProps {
  messageId?: string
  messageName?: string
  onCompleted?: (data?: CreateMessageCampaignMutation) => void
  emailTemplateCollection: EmailTemplatesCollection
}

export default function CreateMessageCampaignForm(props: CreateMessageCampaignFormProps): JSX.Element {
  const [type, setType] = useState(CampaignType.Once)
  const { register, handleSubmit, errors, control, watch, setValue, clearErrors } = useForm<DefaultValues>({
    defaultValues: { messageId: props.messageId, messageName: props.messageName }
  })

  const [createMessageCampaign, { loading }] = useCreateMessageCampaignMutation({
    onCompleted: (data) => {
      toast.success('Successfully created message campaign')
      props.onCompleted?.(data)
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not create message campaign.')
    }
  })

  const messageId = watch('messageId')
  const messageName = watch('messageName')

  const paginatedMessages = usePaginatedQuery<MessagesResult, Partial<Message>>({
    query: MessagesTable.query,
    accessor: 'messages',
    options: {},
    persist: false,
    limit,
    buildFilter
  })
  const reloadMessages = () => paginatedMessages.query.refetch()
  const onCompleted = (id: string, fieldName: string) => {
    reloadMessages()
    clearErrors(fieldName)
    setValue(fieldName, id)
  }

  const onSubmit = (data: CreateMessageCampaignFormData) => {
    const inputData = { ...data }
    delete inputData.messageName
    let input = {} as CreateMessageCampaignInput
    if (type === CampaignType.Once) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { timeToRun, ...rest } = inputData
      input = {
        ...rest,
        scheduledAt: inputData.scheduledAt.toDate(),
        type
      }
    } else if (type === CampaignType.Recurring) {
      input = {
        ...inputData,
        type,
        timeToRun: inputData.timeToRun?.toDate(),
        nextRunAt: inputData.nextRunAt?.toDate()
      }
    }
    createMessageCampaign({ variables: { input } })
  }
  useEffect(() => {
    register('messageId', { required: 'Message is required' })
    register('messageName')
  }, [])
  return (
    <div>
      <strong>Campaign Type</strong>
      <div className="flex gap-4 mb-4">
        <FormLabel htmlFor="type">
          <Radio
            id="type"
            name="type"
            onClick={() => setType(CampaignType.Once)}
            checked={type === CampaignType.Once}
          />
          Once Off
        </FormLabel>
        <FormLabel htmlFor="type">
          <Radio
            id="type"
            name="type"
            onClick={() => setType(CampaignType.Recurring)}
            checked={type === CampaignType.Recurring}
          />
          Recurring
        </FormLabel>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-4 items-start">
          <FormLabel>
            <strong>Name*</strong>
            <Input width="full" variant="standard" name="name" ref={register({ required: 'Name is required' })} />
            {errors.name && <FormError>{errors.name.message}</FormError>}
          </FormLabel>
          {type === CampaignType.Once ? (
            <FormLabel className="col-start-1">
              <strong>Scheduled At*</strong>
              <Controller
                control={control}
                name="scheduledAt"
                render={({ onChange, value }) => (
                  <div className="block">
                    <DatePicker
                      className="rounded border-2 border-gray-200 p-2 w-full"
                      value={value}
                      onChange={onChange}
                      showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                    />
                  </div>
                )}
              />
              {errors.scheduledAt && <FormError>{errors.scheduledAt.message}</FormError>}
            </FormLabel>
          ) : (
            <>
              <FormLabel className="col-start-1">
                <strong>First Run Date</strong>
                <Controller
                  control={control}
                  name="nextRunAt"
                  render={({ onChange, value }) => (
                    <div className="block">
                      <DatePicker
                        className="rounded border-2 border-gray-200 p-2 w-full"
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  )}
                />
                {errors.nextRunAt && <FormError>{errors.nextRunAt.message}</FormError>}
              </FormLabel>
              <FormLabel>
                <strong>Frequency</strong>
                <Controller
                  control={control}
                  name="interval"
                  render={({ onChange, value }) => (
                    <div className="block">
                      <Select
                        options={Object.entries(CampaignInterval).map(([key, value]) => ({
                          label: key,
                          key,
                          value
                        }))}
                        value={value}
                        onChange={onChange}
                      />
                    </div>
                  )}
                />
                {errors.interval && <FormError>{errors.interval.message}</FormError>}
              </FormLabel>
              <FormLabel>
                <strong>Time To Run</strong>
                <Controller
                  control={control}
                  name="timeToRun"
                  render={({ onChange, value }) => (
                    <div className="block">
                      <TimePicker value={value} onChange={(value) => onChange(value)} format="HH:mm" />
                    </div>
                  )}
                />
                {errors.interval && <FormError>{errors.interval.message}</FormError>}
              </FormLabel>
            </>
          )}
          <FormLabel className="col-start-1">
            <strong>Comment</strong>
            <Input as="textarea" width="full" variant="standard" name="comment" ref={register} />
          </FormLabel>
          <div className="relative col-start-1">
            <FormLabel>
              <strong>Message</strong>
              <SearchMessage
                initialMessage={
                  messageId && messageName
                    ? { messageId, messageName }
                    : { messageId: undefined, messageName: undefined }
                }
                onSelectedMessage={(message) => {
                  if (message?.messageId) {
                    clearErrors('messageId')
                    setValue('messageId', message.messageId)
                  }
                  setValue('messageName', message?.messageName ?? '')
                }}
                paginatedMessages={paginatedMessages}
                showSelectionsTags={false}
                emailTemplateCollection={props.emailTemplateCollection}
              />
              {errors['messageId']?.message ? <FormError>{errors['messageId'].message}</FormError> : null}
            </FormLabel>
            <CreateMessageModal
              className="absolute top-1 left-16"
              onCompleted={(id: string, name: string) => {
                onCompleted(name, `messageName`)
                onCompleted(id, `messageId`)
              }}
            />
          </div>
          <div>
            <strong>Audience Segments</strong>
            <Controller
              control={control}
              name="accountGroupIds"
              render={({ onChange: onChangeAccounts, value: accountGroupIds }) => (
                <Controller
                  control={control}
                  name="accountGroupOperator"
                  render={({ onChange: onChangeOperator, value: operator }) => (
                    <AccountGroupComboBox
                      accountGroupIds={accountGroupIds}
                      operator={operator}
                      onChangeAccountGroups={onChangeAccounts}
                      onChangeOperator={onChangeOperator}
                    />
                  )}
                />
              )}
            />
          </div>
          <FormLabel>
            <strong>Channels</strong>
            <Controller
              control={control}
              rules={{
                required: 'Channels must be specified',
                validate: (value) => (value && value.length > 0) || 'Channels must be specified'
              }}
              name="channels"
              render={({ onChange, value }) => <MessageChannelSelect value={value} onChange={onChange} />}
              defaultValue={[]}
            />
            {errors['channels']?.message ? <FormError>{errors['channels'].message}</FormError> : null}
          </FormLabel>
        </div>
        <Button colorScheme="green" type="submit" variant="primary" isDisabled={loading} isLoading={loading}>
          Save
        </Button>
      </Form>
    </div>
  )
}

CreateMessageCampaignForm.mutation = gql`
  mutation CreateMessageCampaign($input: CreateMessageCampaignInput!) {
    createMessageCampaign(input: $input) {
      id
      name
      scheduledAt
    }
  }
`
