import { DocumentNode, gql } from '@apollo/client'
import { PencilIcon } from '@heroicons/react/solid'
import { Box, Button } from '@plusplusminus/plusplusdash'
import AppConfigView from 'components/AppConfig/AppConfigView'
import { useState } from 'react'
import { Page } from '../../common/types'

export const AppConfig: Page & { query: DocumentNode } = () => {
  const [isEditing, setIsEditing] = useState(false)
  const onClose = () => setIsEditing(false)
  return (
    <Box>
      <Box className="flex justify-between p-5 border-b border-gray-300 items-center bg-white">
        <Box>
          <h3 className="text-lg leading-6 font-medium text-gray-900">App Config</h3>
        </Box>
        <Button key="edit-product-button" variant="primary" colorScheme="blue" onClick={() => setIsEditing(true)}>
          <span className="flex justify-between">
            <span className="mr-2">Edit</span>
            <PencilIcon className="w-4 h-4" />
          </span>
        </Button>
      </Box>
      <AppConfigView isOpen={isEditing} onClose={onClose} />
    </Box>
  )
}

AppConfig.query = gql`
  query GetAppVersion {
    configValue(name: "minimum_app_version") {
      id
      value
    }
  }
`
