import cn from 'classnames'
import { Image } from 'generated'
import { createContext, useContext } from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import { getImageUrl } from 'utils'

const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const ImageContext = createContext<{
  setFeaturedImage: (image: Image) => void
  onRemoveImage: (url: string) => () => void
  featuredPortrait?: Image | null
  featuredLandscape?: Image | null
  featuredEditMode: 'portrait' | 'landscape'
}>({ setFeaturedImage: () => null, onRemoveImage: () => () => null, featuredEditMode: 'portrait' })

export const Images: React.FC<{ images: Array<Image>; setImages: (images: Array<Image>) => void }> = (props) => {
  const { images, setImages } = props
  const onDragEnd = (result: DropResult) => {
    if (result.combine) {
      const imgs = [...images]
      imgs.splice(result.source.index, 1)
      setImages(imgs)
      return
    }

    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const i = reorder(images, result.source.index, result.destination.index)

    setImages(i)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="IMAGES" direction="horizontal">
        {(dropProvided) => (
          <div
            className={cn('flex flex-col p-3 select-none my-3')}
            style={{ transition: 'background-color 0.1s ease' }}
            {...dropProvided.droppableProps}
          >
            <div className="overflow-auto">
              <div ref={dropProvided.innerRef} className="flex items-start">
                {images.map((img, index) => (
                  <Draggable key={img.id} draggableId={img.id} index={index}>
                    {(dragProvided, dragSnapshot) => (
                      <DraggableImage image={img} provided={dragProvided} snapshot={dragSnapshot} />
                    )}
                  </Draggable>
                ))}
                {dropProvided.placeholder}
              </div>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const DraggableImage = (props: { image: Image; provided: DraggableProvided; snapshot: DraggableStateSnapshot }) => {
  const { image, provided, snapshot } = props
  const { featuredPortrait, featuredLandscape, featuredEditMode, setFeaturedImage, onRemoveImage } = useContext(
    ImageContext
  )

  const isFeaturedPortrait = image.url === featuredPortrait?.url
  const isFeaturedLandscape = image.url === featuredLandscape?.url

  const isFeatured = featuredEditMode === 'portrait' ? isFeaturedPortrait : isFeaturedLandscape

  return (
    <div className={cn('relative border-2', { 'border-blue-600': isFeatured, 'border-transparent': !isFeatured })}>
      <img
        ref={(ref) => provided.innerRef(ref)}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        src={getImageUrl(image, 300)}
        onClick={() => setFeaturedImage(image)}
        className={cn('w-24 object-contain p-1 cursor-pointer', {
          'opacity-70': snapshot.isDragging
        })}
      />
      <button
        type="button"
        className="absolute top-0 right-0 bg-red-400 text-white rounded-full shadow"
        onClick={onRemoveImage(image.url)}
      >
        <span className="sr-only">Dismiss</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            strokeWidth="2"
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </button>
    </div>
  )
}
