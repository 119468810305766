import { gql } from '@apollo/client'
import { CheckIcon, PlusIcon, XIcon } from '@heroicons/react/solid'
import { Button, FormField, FormLabel, Loader, Popover, Radio, Table } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import { PageInfo } from 'common/types'
import DebouncedSearchInput from 'components/DebouncedSearchInput'
import FilterWrapper from 'components/FilterWrapper'
import { PageHeader } from 'components/PageHeader'
import { Pagination } from 'components/Pagination'
import dayjs from 'dayjs'
import { Message, MessagesQuery, SortDirection } from 'generated'
import { EmailTemplatesCollection, useEmailTemplates } from 'hooks/useEmailTemplates'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import MessageActions from './MessageActions'

// TODO: would be nice to extract this to a generic type
export type MessagesResult = {
  messages: Omit<MessagesQuery['messages'], 'pageInfo'> & { pageInfo: PageInfo }
}

const TABLE_SHAPE = [
  { label: 'Name', key: 'name', isSortable: true },
  { label: 'Title', key: 'title', isSortable: false },
  { label: 'Enabled', key: 'enabled', isSortable: false },
  { label: 'Created At', key: 'createdAt', isSortable: true },
  { label: 'Updated At', key: 'updatedAt', isSortable: true },
  { label: 'Actions', key: '', isSortable: false, render: () => <div className="flex w-full justify-end">Actions</div> }
]

const buildFilter = (filter: any) => {
  const f: any = {}
  if (filter.search) {
    f.or = [{ name: { iLike: `%${filter.search}%` } }, { title: { iLike: `%${filter.search}%` } }]
  }
  if ('enabled' in filter) {
    f.enabled = { is: filter.enabled }
  }
  return f
}

export default function MessagesTable(props: RouteComponentProps): JSX.Element {
  const { query, pagination, sort, filtering } = usePaginatedQuery<MessagesResult, Partial<Message>>({
    query: MessagesTable.query,
    accessor: 'messages',
    options: {},
    persist: true,
    limit: 20,
    buildFilter,
    defaultSort: { sortField: 'createdAt', direction: SortDirection.Desc }
  })
  const { refetch, data } = query
  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback] = sort
  const { filter, setFilter, removeFilter } = filtering

  const { data: emailTemplatesData, loading: loadingEmailTemplates } = useEmailTemplates()

  const goTo = (path: string) => () => {
    props.navigate?.(path)
  }

  const isStatusFilter = typeof filter.enabled !== 'undefined'
  return (
    <div>
      <PageHeader>
        <h1 className="text-lg">Messages</h1>
        <Button variant="plain" onClick={goTo('create')}>
          Create <PlusIcon className="w-4 h-4" />
        </Button>
      </PageHeader>
      <div className="bg-white w-full flex py-2 px-5 justify-between">
        <DebouncedSearchInput
          onRemoveFilter={() => removeFilter('search')}
          as="input"
          variant="outline"
          width="md"
          id="search"
          name="search"
          onChange={(e) => setFilter('search', e.target.value)}
          value={filter.search ?? ''}
          placeholder="Search by name / title"
        />
        <FilterWrapper canRemoveFilter={isStatusFilter} removeFilterAction={() => removeFilter('enabled')}>
          <Popover>
            <Popover.Button>Status</Popover.Button>
            <Popover.Panel>
              <div className="pt-2 px-4">
                <FormField direction="row" className="mr-2">
                  <Radio
                    id="enabled"
                    name="enabledFilter"
                    aria-label="Enabled"
                    onClick={() => setFilter('enabled', true)}
                    checked={filter?.enabled === true}
                  />
                  <FormLabel htmlFor="enabled">Enabled</FormLabel>
                </FormField>
                <FormField direction="row">
                  <Radio
                    id="disabled"
                    name="enabledFilter"
                    aria-label="Enabled"
                    onClick={() => setFilter('enabled', false)}
                    checked={filter?.enabled === false}
                  />
                  <FormLabel htmlFor="disabled">Disabled</FormLabel>
                </FormField>
              </div>
            </Popover.Panel>
          </Popover>
        </FilterWrapper>
      </div>
      <Table
        shape={TABLE_SHAPE}
        sortCallback={sortCallback}
        activeSort={sortField}
        sortDirection={direction?.toLowerCase() as 'asc' | 'desc'}
        className="overflow-visible"
      >
        {loadingEmailTemplates ? (
          <Loader isActive />
        ) : (
          data?.messages.edges.map(({ node: message }) => {
            return (
              <Table.Row key={message.id}>
                <Table.Cell>{message.name}</Table.Cell>
                <Table.Cell>{message.title}</Table.Cell>
                <Table.Cell className="w-6">
                  <div className="flex justify-center">
                    {message.enabled ? (
                      <CheckIcon className="h-4 w-4 text-green-600" />
                    ) : (
                      <XIcon className="h-4 w-4 text-red-600" />
                    )}
                  </div>
                </Table.Cell>
                <Table.Cell>{dayjs(message.createdAt).format('DD-MM-YYYY')}</Table.Cell>
                <Table.Cell>{dayjs(message.updatedAt).format('DD-MM-YYYY')}</Table.Cell>
                <Table.Cell className="flex gap-2 justify-end items-center">
                  <MessageActions
                    messageId={message.id}
                    messageName={message.name}
                    emailTemplateCollection={emailTemplatesData?.emailTemplatesCollection as EmailTemplatesCollection}
                    onDeleteMessage={() => refetch()}
                  />
                </Table.Cell>
              </Table.Row>
            )
          })
        )}
      </Table>
      <Pagination
        hasPreviousPage={data?.messages.pageInfo.hasPreviousPage}
        hasNextPage={data?.messages.pageInfo.hasNextPage}
        onNext={nextPage}
        onPrev={prevPage}
        page={page}
      />
    </div>
  )
}

MessagesTable.query = gql`
  query Messages($paging: CursorPaging, $filter: MessageFilter, $sorting: [MessageSort!]) {
    messages(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          id
          name
          createdAt
          updatedAt
          title
          enabled
          contentId
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`
