import { Tab } from '@headlessui/react'
import {
  BadgeCheckIcon,
  ExclamationIcon,
  MailOpenIcon,
  PencilAltIcon,
  RefreshIcon,
  TagIcon,
  XCircleIcon
} from '@heroicons/react/solid'
import Button from 'components/Button'
import cn from 'classnames'
import { Badge } from 'components/Badge'
import { Card } from 'components/Card'
import { InfoLabel } from 'components/Info/InfoLabel'
import { InfoValue } from 'components/Info/InfoValue'
import { LinksModal } from 'components/LinksModal/LinksModal'
import { EditNotes } from 'components/Notes/EditNotes'
import { ViewNotes } from 'components/Notes/ViewNotes'
import dayjs from 'dayjs'
import {
  BrandContactFields,
  BrandQuery,
  BrandStatus,
  Notes,
  useBrandPoliciesQuery,
  useUpdateBrandMutation
} from 'generated'
import { useSyncBrand } from 'hooks/brands'
import BrandOrdersTable from 'pages/BrandOrdersTable'
import React, { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { getImageUrl } from 'utils'
import { buildTagBadges } from 'utils/tags'
import EditEmails from './EditEmails'
import { EditPayout } from './EditPayout'
import EditShopify from './EditShopify'
import EditMarketingFields from './EditMarketingFields'

interface BrandViewProps {
  brandId?: string
  brand: NonNullable<BrandQuery['brand']>
  refetch: () => void
}

function getLinkForPolicy(url?: string | null) {
  return url ? (
    <a href={url} target="_blank" className="underline text-blue-500">
      View
    </a>
  ) : (
    'Not set'
  )
}

const handles: Record<string, string> = {
  'privacy-policy': 'Privacy Policy',
  'refund-policy': 'Return Policy',
  'shipping-policy': 'Shipping Policy',
  'terms-of-service': 'Terms of Service'
}

const HiddenLabel = () => <span className="text-xs text-gray-400 ml-1">(Hidden)</span>

const styles = {
  tab:
    'w-full border-b-2 text-blue-600 bg-white py-2.5 text-sm leading-5 font-medium focus:outline-none hover:border-blue-500',
  tabList: 'flex rounded-xl w-full justify-center',
  tabUnselected: 'border-transparent',
  tabSelected: 'border-blue-500'
}

export const BrandView: React.FC<BrandViewProps> = (props) => {
  const { brand } = props

  return (
    <div>
      <Tab.Group>
        <Tab.List className={styles.tabList}>
          <Tab as={React.Fragment}>
            {({ selected }) => (
              <button className={cn(styles.tab, { [styles.tabSelected]: selected, [styles.tabUnselected]: !selected })}>
                Info
              </button>
            )}
          </Tab>
          <Tab as={React.Fragment}>
            {({ selected }) => (
              <button className={cn(styles.tab, { [styles.tabSelected]: selected, [styles.tabUnselected]: !selected })}>
                Completed orders
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <BrandInfo {...props} />
          </Tab.Panel>
          <Tab.Panel>
            <BrandOrdersTable brand={brand} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

const BrandInfo = (props: BrandViewProps) => {
  const { brand, refetch } = props

  const { data: policiesData } = useBrandPoliciesQuery({ variables: { id: brand.id } })

  const [isEditingLinks, setIsEditingLinks] = useState(false)
  const [isEditingPayouts, setIsEditingPayouts] = useState(false)
  const [isEditingEmails, setIsEditingEmails] = useState(false)
  const [isEditingNotes, setIsEditingNotes] = useState(false)
  const [isEditingShopify, setIsEditingShopify] = useState(false)
  const [isEditingMarketingFields, setIsEditingMarketingFields] = useState(false)
  const [, setShowInstallLink] = useState(false)
  const [, setShowUpdateLink] = useState(false)

  const toggleLinks = () => setIsEditingLinks(!isEditingLinks)
  const togglePayouts = () => setIsEditingPayouts(!isEditingPayouts)
  const toggleEmails = () => setIsEditingEmails(!isEditingEmails)
  const toggleNotes = () => setIsEditingNotes(!isEditingNotes)
  const toggleShopify = () => setIsEditingShopify(!isEditingShopify)
  const toggleMarketingFields = () => setIsEditingMarketingFields(!isEditingMarketingFields)

  const [updateBrand, { loading }] = useUpdateBrandMutation({
    onCompleted: () => {
      setIsEditingNotes(false)
      toast.success('Successfully saved notes')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not save notes')
    }
  })
  const { syncBrand, syncing } = useSyncBrand({
    onCompleted: () => {
      toast.success('Successfully synced brand')
      refetch()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not sync brand')
    }
  })

  const onOpen = (set: any) => () => {
    set(true)
  }

  const onSync = () => {
    syncBrand(brand.id)
  }
  const updateNotes = useCallback((notes: Omit<Notes, '__typename'>) => {
    updateBrand({ variables: { id: brand.id, update: { notes } } })
  }, [])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...notes } = brand.notes ?? {}

  return (
    <div className="p-5">
      <LinksModal
        loading={loading}
        isOpen={isEditingLinks}
        onClose={toggleLinks}
        meta={brand.meta}
        id={brand.id}
        mutationFunc={updateBrand}
      />
      <EditEmails isOpen={isEditingEmails} onClose={toggleEmails} brand={brand} />
      <EditPayout isOpen={isEditingPayouts} brand={brand} onClose={togglePayouts} />
      <EditNotes
        isOpen={isEditingNotes}
        notes={notes}
        onClose={toggleNotes}
        updateFunc={updateNotes}
        loading={loading}
      />
      <EditShopify isOpen={isEditingShopify} brand={brand} onClose={toggleShopify} />
      <EditMarketingFields isOpen={isEditingMarketingFields} brand={brand} onClose={toggleMarketingFields} />

      <Card className="mb-5">
        <Card.Header>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">{brand.brandName}</h3>
            <span className="text-xs text-gray-500">App ID: {brand.shopify.appId}</span>
          </div>
          <div className="flex flex-col gap-1">
            <Badge color={brand.enabled ? 'green' : 'red'}>{brand.enabled ? 'Enabled' : 'Disabled'}</Badge>
            <Badge className="mb-1" color={brand.verified === BrandStatus.Verified ? 'green' : 'yellow'}>
              {brand.verified === BrandStatus.Verified ? (
                <>
                  <BadgeCheckIcon className="h-4 w-4 mr-1" /> <span>Verified</span>
                </>
              ) : null}
              {brand.verified === BrandStatus.Unverified ? (
                <>
                  <XCircleIcon className="h-4 w-4 mr-1" /> <span>Unverified</span>
                </>
              ) : null}
              {brand.verified === BrandStatus.Expired ? (
                <>
                  <ExclamationIcon className="h-4 w-4 mr-1" /> <span>Expired</span>
                </>
              ) : null}
              {brand.verified === BrandStatus.Pending ? (
                <>
                  <MailOpenIcon className="h-4 w-4 mr-1" /> <span>Pending</span>
                </>
              ) : null}
            </Badge>
            {brand.verified === BrandStatus.Unverified || brand.verified === BrandStatus.Pending ? (
              <Button key="edit-brand-button" variant="primary" colorScheme="blue" onClick={onOpen(setShowInstallLink)}>
                <span className="flex justify-between">
                  <span className="mr-2">
                    {brand.verified === BrandStatus.Pending ? 'Resend' : 'Send'} Install Link
                  </span>
                </span>
              </Button>
            ) : null}
            {brand.verified === BrandStatus.Expired || brand.verified === BrandStatus.Pending ? (
              <Button key="edit-brand-button" variant="primary" colorScheme="blue" onClick={onOpen(setShowUpdateLink)}>
                <span className="flex justify-between">
                  <span className="mr-2">Update Install Link</span>
                </span>
              </Button>
            ) : null}
            {brand.verified === BrandStatus.Verified ? (
              <>
                <Button
                  isDisabled={syncing}
                  key="edit-brand-button"
                  variant="primary"
                  colorScheme="blue"
                  onClick={onSync}
                  isLoading={syncing}
                >
                  <span className="w-36 flex items-center justify-center">
                    <span className="mr-2">
                      {syncing ? <RefreshIcon className="animate-spin h-4 w-4" /> : 'Sync with Shopify'}
                    </span>
                  </span>
                </Button>
                <span className="text-sm text-gray-400">
                  last sync: {brand.lastSync ? dayjs().to(dayjs(brand.lastSync)) : 'never'}
                </span>
              </>
            ) : null}
          </div>
        </Card.Header>
        <Card.Content>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-2">
              <InfoLabel>Images</InfoLabel>
              <dd className="flex gap-2 mt-1 flex-wrap">
                {brand.images &&
                  brand.images.map((img) => {
                    const isFeaturedPortrait = img.url === brand.featuredPortrait?.url
                    const isFeaturedLandscape = img.url === brand.featuredLandscape?.url
                    return (
                      <div className="relative">
                        <img src={getImageUrl(img, 250)} className="w-32 object-contain" />
                        <div className="absolute top-2 right-2 opacity-80">
                          {isFeaturedPortrait ? <Badge color="gray">Portrait</Badge> : null}
                          {isFeaturedLandscape ? <Badge color="gray">Landscape</Badge> : null}
                        </div>
                      </div>
                    )
                  })}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <InfoLabel className="flex gap-2 items-center">
                <TagIcon className="h-4 w-4" /> Tags
              </InfoLabel>
              <InfoValue>{buildTagBadges(brand.tags)}</InfoValue>
            </div>
            <div className="col-span-2">
              <InfoLabel>Description</InfoLabel>
              <InfoValue>{brand.brandDescription}</InfoValue>
            </div>
            <div>
              <InfoLabel>Owner</InfoLabel>
              <InfoValue>{brand.ownerName}</InfoValue>
            </div>
            <div>
              <InfoLabel>
                Address{brand.hiddenFields.includes(BrandContactFields.Address) ? <HiddenLabel /> : ''}
              </InfoLabel>
              <InfoValue>{brand.address}</InfoValue>
            </div>
            <div>
              <InfoLabel>Phone{brand.hiddenFields.includes(BrandContactFields.Phone) ? <HiddenLabel /> : ''}</InfoLabel>
              <InfoValue>{brand.brandCellphone}</InfoValue>
            </div>
            <div>
              <InfoLabel>
                Email Address{brand.hiddenFields.includes(BrandContactFields.Email) ? <HiddenLabel /> : ''}
              </InfoLabel>
              <InfoValue>{brand.brandEmail}</InfoValue>
            </div>
            <div>
              <InfoLabel>Currency</InfoLabel>
              <InfoValue>{brand.currency}</InfoValue>
            </div>
            <div>
              <InfoLabel>Locale</InfoLabel>
              <InfoValue>{brand.locale}</InfoValue>
            </div>
            <div>
              <InfoLabel>
                Website{brand.hiddenFields.includes(BrandContactFields.Website) ? <HiddenLabel /> : ''}
              </InfoLabel>
              <InfoValue>{brand.brandWebsite}</InfoValue>
            </div>
            {brand.facebook && (
              <div>
                <InfoLabel>
                  Facebook{brand.hiddenFields.includes(BrandContactFields.Facebook) ? <HiddenLabel /> : ''}
                </InfoLabel>
                <InfoValue>
                  <a href={brand.facebook} target="_blank">
                    {brand.facebook}
                  </a>
                </InfoValue>
              </div>
            )}
            {brand.instagram && (
              <div>
                <InfoLabel>
                  Instagram{brand.hiddenFields.includes(BrandContactFields.Instagram) ? <HiddenLabel /> : ''}
                </InfoLabel>
                <InfoValue>
                  <a href={brand.instagram} target="_blank">
                    {brand.instagram}
                  </a>
                </InfoValue>
              </div>
            )}
            {brand.vatNumber && (
              <div>
                <InfoLabel>VAT Number</InfoLabel>
                <InfoValue>{brand.vatNumber}</InfoValue>
              </div>
            )}
            {brand.invoiceAddress && (
              <div>
                <InfoLabel>Invoice Address</InfoLabel>
                <InfoValue>
                  {[
                    brand.invoiceAddress.address1,
                    brand.invoiceAddress.address2,
                    brand.invoiceAddress.city,
                    brand.invoiceAddress.province,
                    brand.invoiceAddress.zip
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </InfoValue>
              </div>
            )}
            <div>
              <InfoLabel>Include Recently Sold Products</InfoLabel>
              <InfoValue>{brand.meta?.includeRecentlySold ? 'Yes' : 'No'}</InfoValue>
            </div>
            <div>
              <InfoLabel>Location</InfoLabel>
              <InfoValue>{brand.meta?.location}</InfoValue>
            </div>
          </dl>
        </Card.Content>
      </Card>
      <div className="flex gap-4 mb-5 w-full">
        <Card className="flex-1">
          <Card.Header>
            <div className="w-2/3 leading-none">
              <h3 className="text-lg font-medium text-gray-900">Order Email List</h3>
              <span className="text-xs text-gray-600">
                List of email addresses to which order confirmations and payment advice will be sent.
              </span>
            </div>
            <PencilAltIcon className="h-6 w-6 cursor-pointer" onClick={toggleEmails} />
          </Card.Header>
          <Card.Content>
            {brand.adminEmails.map((email) => (
              <div className="text-sm text-gray-600">{email}</div>
            ))}
          </Card.Content>
        </Card>
        <Card className="flex-1">
          <Card.Header>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Policies</h3>
          </Card.Header>
          <Card.Content>
            <div className="grid grid-cols-2 gap-4">
              {policiesData?.policies.map((p) => {
                return (
                  <div>
                    <InfoLabel>{handles[p.handle]}</InfoLabel>
                    <InfoValue>{getLinkForPolicy(p.url)}</InfoValue>
                  </div>
                )
              })}
            </div>
          </Card.Content>
        </Card>
      </div>
      <div className="flex gap-4 mb-5 w-full">
        <Card className="flex-1">
          <Card.Header>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Links</h3>
            <PencilAltIcon className="h-6 w-6 cursor-pointer" onClick={toggleLinks} />
          </Card.Header>
          <Card.Content>
            <div className="grid grid-cols-4">
              {brand.meta?.fields?.map((f) => (
                <a href={f.link} target="_blank" rel="noreferrer nofollow">
                  <InfoLabel>{f.title}</InfoLabel>
                </a>
              )) ?? null}
            </div>
          </Card.Content>
        </Card>
        <Card className="flex-1">
          <Card.Header>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Payout Information</h3>
            <PencilAltIcon className="h-6 w-6 cursor-pointer" onClick={togglePayouts} />
          </Card.Header>
          <Card.Content>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <InfoLabel>Commission</InfoLabel>
                <InfoValue>{brand.commission} %</InfoValue>
              </div>
              {brand.paymentTerms ? (
                <div>
                  <InfoLabel>Payment Terms</InfoLabel>
                  <InfoValue>
                    {brand.paymentTerms?.unit} {brand.paymentTerms.amount}
                  </InfoValue>
                </div>
              ) : null}
              {brand.bankDetails ? (
                <>
                  <div className="col-start-1">
                    <InfoLabel>Account Number</InfoLabel>
                    <InfoValue>{brand.bankDetails.accountNumber}</InfoValue>
                  </div>
                  <div>
                    <InfoLabel>Bank Name</InfoLabel>
                    <InfoValue>{brand.bankDetails.bankName}</InfoValue>
                  </div>
                  <div>
                    <InfoLabel>Branch Code</InfoLabel>
                    <InfoValue>{brand.bankDetails.branchCode}</InfoValue>
                  </div>
                </>
              ) : null}
            </div>
          </Card.Content>
        </Card>
      </div>
      <div className="flex gap-4 mb-5 w-full">
        <Card className="flex-1">
          <Card.Header>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Products</h3>
          </Card.Header>
          <Card.Content>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <InfoLabel>Include Recently Sold Products</InfoLabel>
                <InfoValue>{brand.meta?.includeRecentlySold ? 'Yes' : 'No'}</InfoValue>
              </div>
              <div>
                <InfoLabel>Product Sort Order</InfoLabel>
                <InfoValue>{brand.productSortFriendly}</InfoValue>
              </div>
              <div>
                <InfoLabel>Recommended Product Tags</InfoLabel>
                <InfoValue>{brand.recommendedProductTags.join(', ')}</InfoValue>
              </div>
            </div>
          </Card.Content>
        </Card>
        <Card className="flex-1">
          <Card.Header>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Shopify</h3>
            <PencilAltIcon className="h-6 w-6 cursor-pointer" onClick={toggleShopify} />
          </Card.Header>
          <Card.Content>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <InfoLabel>Domain</InfoLabel>
                <InfoValue>{brand.shopify.domain}</InfoValue>
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
      <div className="flex gap-4 mb-5 w-full">
        <Card className="flex-1">
          <Card.Header>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Marketing Fields</h3>
            <PencilAltIcon className="h-6 w-6 cursor-pointer" onClick={toggleMarketingFields} />
          </Card.Header>
          <Card.Content>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <InfoLabel>Marketing Name</InfoLabel>
                <InfoValue>{brand.marketingName}</InfoValue>
              </div>
              <div>
                <InfoLabel>Marketing Description</InfoLabel>
                <InfoValue>{brand.marketingDescription}</InfoValue>
              </div>
              <div>
                <InfoLabel>Marketing Color</InfoLabel>
                <InfoValue>{brand.marketingColor}</InfoValue>
              </div>
            </div>
          </Card.Content>
        </Card>
        <div className="flex-1" />
      </div>
      <Card className="col-span-2">
        <Card.Header>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Notes</h3>
          <PencilAltIcon className="h-6 w-6 cursor-pointer" onClick={toggleNotes} />
        </Card.Header>
        <Card.Content>
          <ViewNotes notes={notes} />
        </Card.Content>
      </Card>
    </div>
  )
}
