import React, { useState } from 'react'

import { navigate, RouteComponentProps } from '@reach/router'
import { Alert, Box, Loader } from '@plusplusminus/plusplusdash'
import { TagEdit } from '../../components/Tag/TagEdit'
import { DeleteModal } from '../../components/Modals/Delete'
import { useUser } from '../../hooks/useUser'
import { TagView } from '../../components/Tag/TagView'
import { useDeleteTagMutation, usePublishTagMutation, useTagQuery } from 'hooks/tags'
import { PencilIcon } from '@heroicons/react/solid'
import toast from 'react-hot-toast'
import Button from 'components/Button'
import { Permission } from 'common/types'

interface TagRouteProps {
  tagId: string
}

export const Tag: React.FC<RouteComponentProps<TagRouteProps>> = (props) => {
  const { tagId } = props

  const { user: loggedInUser } = useUser()
  const { data, loading: loadingTags, refetch, error } = useTagQuery(tagId as string)

  const [isEditing, setIsEditing] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { deleteTag, loading: loadingDeleteTag } = useDeleteTagMutation({
    onCompleted: () => {
      toast.success('Successfully deleted tag')
      navigate('/tags', { state: { refetch: true } })
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not delete tag')
    }
  })
  const { publishTag, loading: loadingPublishTag } = usePublishTagMutation({
    onCompleted: () => {
      toast.success('Successfully published tag feed')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not publish tag feed')
    }
  })

  if (error?.graphQLErrors) {
    return (
      <Alert type="error">
        <Alert.Heading>Error fetching tag</Alert.Heading>
        <Alert.Description>An unexpected error occured when fetching the specified tag</Alert.Description>
      </Alert>
    )
  }

  if (!data || loadingTags) {
    return <Loader isActive />
  }

  const { tag } = data

  return (
    <Box>
      <DeleteModal
        isLoading={loadingDeleteTag}
        headline="Delete Tag"
        description="Are you sure you want to delete this tag?"
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDelete={() => {
          deleteTag(tag.id)
        }}
      />
      <Box className="flex justify-between p-5 border-b bg-white border-gray-300 items-center">
        <Box>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Tag Information</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Tag details for viewing and editing</p>
        </Box>
        {isEditing ? (
          <Button
            key="delete-tag-button"
            variant="primary"
            type="button"
            size="sm"
            colorScheme="red"
            onClick={() => {
              setIsModalOpen(true)
            }}
          >
            Delete tag
          </Button>
        ) : (
          <div className="flex items-center gap-2">
            <Button
              variant="secondary"
              colorScheme="green"
              onClick={() => {
                publishTag(tag.id)
              }}
              isDisabled={loadingPublishTag}
              isLoading={loadingPublishTag}
            >
              Publish Feed
            </Button>
            <Button key="edit-tag-button" variant="primary" colorScheme="blue" onClick={() => setIsEditing(true)}>
              <span className="flex justify-between">
                <span className="mr-2">Edit</span>
                <PencilIcon className="w-4 h-4" />
              </span>
            </Button>
          </div>
        )}
      </Box>
      {isEditing ? (
        <TagEdit
          tagId={tagId as string}
          tag={tag}
          cancel={() => navigate(-1)}
          updateCallback={() => {
            refetch()
            navigate(-1)
          }}
        />
      ) : (
        <TagView tagId={tagId} tag={tag} />
      )}
    </Box>
  )
}
