import { Modal } from '@plusplusminus/plusplusdash'
import { useState } from 'react'
import ActionForm from './ActionForm'
import { ActionFormProps } from './ActionForm'

type ActionModalProps = ActionFormProps & {
  isLoading: boolean
  modalTitle: string
  renderIcon: () => React.ReactNode
}
const ActionModal = ({ isLoading, modalTitle, renderIcon, onAddAction, ...props }: ActionModalProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => {
    setIsOpen(true)
  }
  const onClose = () => setIsOpen(false)
  return (
    <>
      <button type="button" onClick={onOpen} className="block focus:outline-none">
        {renderIcon()}
      </button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <Modal.Title>{modalTitle}</Modal.Title>
        <Modal.Description className="my-4">
          <ActionForm
            isLoading={isLoading}
            onAddAction={(action) => {
              onClose()
              onAddAction(action)
            }}
            {...props}
          />
        </Modal.Description>
      </Modal>
    </>
  )
}

export default ActionModal
