import { gql } from '@apollo/client'
import { Form, FormError, FormLabel, Input } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import { DatePicker, TimePicker } from 'antd'
import AccountGroupComboBox from 'components/AccountGroupComboBox'
import { Card } from 'components/Card'
import { FullPageLoader } from 'components/FullPageLoader'
import MessageChannelSelect from 'components/MessageChannelSelect'
import { CreateMessageModal } from 'components/Modals/CreateMessageModal'
import { PageHeader } from 'components/PageHeader'
import Select from 'components/Select'
import {
  CampaignInterval,
  CampaignType,
  Message,
  MessageCampaignQuery,
  UpdateMessageCampaignInput,
  useMessageCampaignQuery,
  useUpdateMessageCampaignMutation
} from 'generated'
import { useDeleteModal } from 'hooks/useDeleteModal'
import { EmailTemplatesCollection, useEmailTemplates } from 'hooks/useEmailTemplates'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import moment, { Moment } from 'moment'
import MessagesTable, { MessagesResult } from 'pages/Message/MessagesTable'
import SearchMessage from 'pages/Message/SearchMessage'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import DeleteMessageCampaignModal from './DeleteMessageCampaignModal'
import Button from 'components/Button'

const limit = 5

const buildFilter = (filter: any) => {
  const f: any = {}
  if (filter.search) {
    f.or = [{ name: { iLike: `%${filter.search}%` } }, { title: { iLike: `%${filter.search}%` } }]
  }
  if ('enabled' in filter) {
    f.enabled = { is: filter.enabled }
  }
  return f
}

interface UpdateMessageCampaignProps extends RouteComponentProps {
  id: string
}
type UpdateMessageCampaignForm = Omit<UpdateMessageCampaignInput, 'timeToRun' | 'messageId'> & {
  timeToRun?: Moment
  messageId?: string
  messageName?: string
}

function getFormValues(data: MessageCampaignQuery['messageCampaign']): UpdateMessageCampaignForm {
  return {
    name: data?.name,
    accountGroupIds: data?.accountGroups.map((acc) => acc.id),
    messageId: data?.message?.id,
    messageName: data?.message?.name,
    comment: data?.comment,
    channels: data?.channels,
    accountGroupOperator: data?.accountGroupOperator,
    ...(data?.type === CampaignType.Once
      ? { scheduledAt: moment(data?.scheduledAt) }
      : {
          nextRunAt: moment(data?.nextRunAt),
          timeToRun: moment(data?.timeToRun),
          interval: data?.interval
        })
  }
}

export default function EditMessageCampaign(props: UpdateMessageCampaignProps): JSX.Element {
  const deleteModalProps = useDeleteModal()

  const { data: emailTemplatesData, loading: loadingEmailTemplates } = useEmailTemplates()
  const { data: messageCampaign, loading: loadingMessageCampaign } = useMessageCampaignQuery({
    variables: { id: props.id }
  })

  const defaultValues = useMemo(() => getFormValues(messageCampaign?.messageCampaign), [
    messageCampaign?.messageCampaign
  ])

  const {
    register,
    handleSubmit,
    errors,
    control,
    reset,
    setValue,
    watch,
    clearErrors
  } = useForm<UpdateMessageCampaignForm>({
    defaultValues
  })

  const messageId = watch('messageId')
  const messageName = watch('messageName')

  const paginatedMessages = usePaginatedQuery<MessagesResult, Partial<Message>>({
    query: MessagesTable.query,
    accessor: 'messages',
    options: {},
    persist: false,
    limit,
    buildFilter
  })
  const reloadMessages = () => paginatedMessages.query.refetch()
  const onCompleted = (id: string, fieldName: string) => {
    reloadMessages()
    clearErrors(fieldName)
    setValue(fieldName, id)
  }

  useEffect(() => {
    reset(getFormValues(messageCampaign?.messageCampaign))
  }, [messageCampaign])

  const [updateMessageCampaign, { loading }] = useUpdateMessageCampaignMutation({
    onCompleted: () => {
      toast.success('Successfully updated message campaign')
      props.navigate?.(`/message-campaigns/${props.id}/view`)
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update message campaign')
    }
  })

  const onSubmit = (data: UpdateMessageCampaignForm & { messageName?: string }) => {
    const inputData = { ...data }
    delete inputData.messageName
    let input = {} as UpdateMessageCampaignInput
    const type = messageCampaign?.messageCampaign?.type
    if (type === CampaignType.Once) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { timeToRun, ...rest } = inputData
      input = {
        ...rest,
        scheduledAt: inputData.scheduledAt.toDate()
      }
    } else if (type === CampaignType.Recurring) {
      input = {
        ...inputData,
        timeToRun: inputData.timeToRun?.toDate(),
        nextRunAt: inputData.nextRunAt?.toDate()
      }
    }
    updateMessageCampaign({ variables: { id: props.id, input } })
  }

  const openDelete = () => deleteModalProps.openModal(props.id, messageCampaign?.messageCampaign?.name)

  useEffect(() => {
    if (defaultValues.messageId) {
      register('messageId', { required: 'Message is required' })
      setValue('messageId', defaultValues.messageId)
      register('messageName')
    }
  }, [defaultValues.messageId])
  return (
    <div>
      <DeleteMessageCampaignModal
        {...deleteModalProps}
        onDelete={() => {
          props.navigate?.(`/message-campaigns?sent=false`)
        }}
      />
      <PageHeader>
        <h1 className="text-lg">Edit Message Campaign</h1>
        <Button variant="primary" colorScheme="red" onClick={openDelete}>
          Delete
        </Button>
      </PageHeader>
      {loadingMessageCampaign || loadingEmailTemplates ? (
        <FullPageLoader isActive />
      ) : (
        <div className="p-5">
          <Card>
            <Card.Content>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-4 items-start">
                  <FormLabel>
                    <strong>Name*</strong>
                    <Input
                      width="full"
                      variant="standard"
                      name="name"
                      ref={register({ required: 'Name is required' })}
                    />
                    {errors.name && <FormError>{errors.name.message}</FormError>}
                  </FormLabel>
                  {messageCampaign?.messageCampaign?.type === CampaignType.Once ? (
                    <FormLabel className="col-start-1">
                      <strong>Scheduled At*</strong>
                      <Controller
                        control={control}
                        name="scheduledAt"
                        render={({ onChange, value }) => (
                          <div className="block">
                            <DatePicker
                              className="rounded border-2 border-gray-200 p-2 w-full"
                              value={value}
                              onChange={onChange}
                              showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                            />
                          </div>
                        )}
                      />
                      {errors.scheduledAt && <FormError>{errors.scheduledAt.message}</FormError>}
                    </FormLabel>
                  ) : (
                    <>
                      <FormLabel className="col-start-1">
                        <strong>First Run Date</strong>
                        <Controller
                          control={control}
                          name="nextRunAt"
                          render={({ onChange, value }) => (
                            <div className="block">
                              <DatePicker
                                className="rounded border-2 border-gray-200 p-2 w-full"
                                value={value}
                                onChange={onChange}
                              />
                            </div>
                          )}
                        />
                        {errors.nextRunAt && <FormError>{errors.nextRunAt.message}</FormError>}
                      </FormLabel>
                      <FormLabel>
                        <strong>Frequency</strong>
                        <Controller
                          control={control}
                          name="interval"
                          render={({ onChange, value }) => (
                            <div className="block">
                              <Select
                                key={value}
                                options={Object.entries(CampaignInterval).map(([key, value]) => ({
                                  label: key,
                                  key,
                                  value
                                }))}
                                value={value}
                                onChange={onChange}
                              />
                            </div>
                          )}
                        />
                        {errors.interval && <FormError>{errors.interval.message}</FormError>}
                      </FormLabel>
                      <FormLabel>
                        <strong>Time To Run</strong>
                        <Controller
                          control={control}
                          name="timeToRun"
                          render={({ onChange, value }) => (
                            <div className="block">
                              <TimePicker value={value} onChange={(value) => onChange(value)} format="HH:mm" />
                            </div>
                          )}
                        />
                        {errors.interval && <FormError>{errors.interval.message}</FormError>}
                      </FormLabel>
                    </>
                  )}
                  <FormLabel>
                    <strong>Comment</strong>
                    <Input as="textarea" width="full" variant="standard" name="comment" ref={register} />
                  </FormLabel>
                  <div className="relative col-start-1">
                    <FormLabel>
                      <strong>Message</strong>
                      <SearchMessage
                        initialMessage={
                          messageId && messageName
                            ? { messageId, messageName }
                            : { messageId: defaultValues.messageId, messageName: defaultValues.messageName }
                        }
                        onSelectedMessage={(message) => {
                          if (message?.messageId) {
                            clearErrors('messageId')
                            setValue('messageId', message.messageId)
                          }
                          setValue('messageName', message?.messageName ?? '')
                        }}
                        paginatedMessages={paginatedMessages}
                        showSelectionsTags={false}
                        emailTemplateCollection={
                          emailTemplatesData?.emailTemplatesCollection as EmailTemplatesCollection
                        }
                      />
                      {errors['messageId']?.message ? <FormError>{errors['messageId'].message}</FormError> : null}
                    </FormLabel>
                    <CreateMessageModal
                      className="absolute top-1 left-16"
                      onCompleted={(id: string, name: string) => {
                        onCompleted(name, `messageName`)
                        onCompleted(id, `messageId`)
                      }}
                    />
                  </div>
                  <div>
                    <strong>Audience Segments</strong>
                    <Controller
                      control={control}
                      name="accountGroupIds"
                      render={({ onChange: onChangeAccounts, value: accountGroupIds }) => (
                        <Controller
                          control={control}
                          name="accountGroupOperator"
                          render={({ onChange: onChangeOperator, value: operator }) => (
                            <AccountGroupComboBox
                              accountGroupIds={accountGroupIds}
                              operator={operator}
                              onChangeAccountGroups={onChangeAccounts}
                              onChangeOperator={onChangeOperator}
                            />
                          )}
                        />
                      )}
                    />
                  </div>
                  <FormLabel>
                    <strong>Channels</strong>
                    <Controller
                      control={control}
                      name="channels"
                      rules={{
                        required: 'Channels must be specified',
                        validate: (value) => (value && value.length > 0) || 'Channels must be specified'
                      }}
                      render={({ onChange, value }) => <MessageChannelSelect value={value} onChange={onChange} />}
                      defaultValue={defaultValues?.channels}
                    />
                    {errors['channels']?.message ? <FormError>{errors['channels'].message}</FormError> : null}
                  </FormLabel>
                </div>
                <Button colorScheme="green" type="submit" variant="primary" isDisabled={loading} isLoading={loading}>
                  Save
                </Button>
              </Form>
            </Card.Content>
          </Card>
        </div>
      )}
    </div>
  )
}

EditMessageCampaign.mutation = gql`
  mutation UpdateMessageCampaign($id: String!, $input: UpdateMessageCampaignInput!) {
    updateMessageCampaign(id: $id, input: $input) {
      id
      name
      scheduledAt
    }
  }
`
