import { MutationFunction } from '@apollo/client'
import { Checkbox, Input, Modal } from '@plusplusminus/plusplusdash'
import Repeater from 'components/Repeater/Repeater'
import {
  Field,
  Maybe,
  BrandMeta,
  UpdateBrandMutation,
  UpdateBrandMutationVariables,
  UpdateProductMutation,
  UpdateProductMutationVariables,
  Meta
} from 'generated'
import Button from 'components/Button'
import { isEqual } from 'lodash'
import { ChangeEvent, useState } from 'react'

type LinksModalProps = {
  loading: boolean
  isOpen: boolean
  onClose: () => void
  id: string
  meta?: Maybe<Meta | BrandMeta>
  mutationFunc:
    | MutationFunction<UpdateBrandMutation, UpdateBrandMutationVariables>
    | MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>
}
type FieldWOutTypename = Omit<Field, '__typename'>

const LinksModal: React.FC<LinksModalProps> = (props) => {
  const { loading, isOpen, onClose, id, meta, mutationFunc } = props

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [links, setLinks] = useState((meta?.fields ?? []).map(({ __typename, ...rest }) => ({ ...rest })) || [])

  const addLink = (item: FieldWOutTypename) => {
    setLinks([...links, item])
  }
  const removeLink = (index: number) => {
    setLinks([...links.slice(0, index), ...links.slice(index + 1)])
  }

  const onChangeItem = (index: number, field: keyof FieldWOutTypename, isCheckbox = false) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const item = links[index]
    setLinks([
      ...links.slice(0, index),
      { ...item, [field]: isCheckbox ? e.target.checked : e.target.value },
      ...links.slice(index + 1)
    ])
  }

  const onSubmit = async () => {
    if (!isEqual(meta?.fields ?? [], links)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { __typename, ...rest } = meta || {}
      await mutationFunc({ variables: { id, update: { meta: { ...rest, fields: links } } } })
      onClose()
    }
  }

  const renderLink = (item: FieldWOutTypename, index: number) => {
    return (
      <>
        <div className="flex-1">
          <span className="text-xs font-medium text-gray-600">Title</span>
          <Input width="full" variant="standard" value={item.title} onChange={onChangeItem(index, 'title')} />
        </div>
        <div className="flex-1">
          <span className="text-xs font-medium text-gray-600">Link URL</span>
          <Input width="full" variant="standard" value={item.link} onChange={onChangeItem(index, 'link')} />
        </div>
        <div className="flex flex-col flex-shrink">
          <span className="text-xs font-medium text-gray-600">Open External</span>
          <Checkbox
            id="isExternal"
            checked={item.isExternal ?? false}
            onChange={onChangeItem(index, 'isExternal', true)}
          />
        </div>
      </>
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <Modal.Title>Links</Modal.Title>
      <Modal.Description className="my-4">
        <div className="mb-8">
          <Repeater data={links} renderItem={renderLink} onAdd={addLink} onRemove={removeLink} />
        </div>
        <div className="flex justify-end gap-2">
          <Button variant="plain" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" colorScheme="green" onClick={onSubmit} isDisabled={loading} isLoading={loading}>
            Save
          </Button>
        </div>
      </Modal.Description>
    </Modal>
  )
}

export { LinksModal }
