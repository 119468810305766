import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { CookiesProvider } from 'react-cookie'
import ReactDOM from 'react-dom'
import { useAppApolloClient } from './apollo'
import { App } from './components/App'
import { LoginProvider } from './context/LoginContext'
import './index.css'
import './utils/config'

function Entry() {
  const client = useAppApolloClient()

  return (
    <ApolloProvider client={client}>
      <LoginProvider>
        <App />
      </LoginProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Entry />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
