import { gql } from '@apollo/client'
import { Button, Modal } from '@plusplusminus/plusplusdash'
import { useDeleteTriggerMutation } from 'generated'
import toast from 'react-hot-toast'

type DeleteTriggerProps = {
  id?: string
  name?: string
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}
export default function DeleteTriggerModal(props: DeleteTriggerProps): JSX.Element {
  const [deleteTriggerMutation] = useDeleteTriggerMutation({
    onCompleted: () => {
      toast.success('Deleted trigger')
      props.onDelete()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not delete trigger.')
    }
  })
  const deleteTrigger = () => {
    props.id && deleteTriggerMutation({ variables: { id: props.id } })
  }

  return (
    <Modal isOpen={props.isOpen} size="sm" onClose={props.onClose}>
      <Modal.Title>Are you sure you want to delete the "{props.name}" trigger?</Modal.Title>
      <Modal.Description>
        <div className="flex justify-end gap-2 mt-8">
          <Button variant="plain" colorScheme="red" onClick={props.onClose}>
            Cancel
          </Button>
          <Button variant="primary" colorScheme="red" onClick={deleteTrigger}>
            Yes, Delete
          </Button>
        </div>
      </Modal.Description>
    </Modal>
  )
}

DeleteTriggerModal.mutation = gql`
  mutation DeleteTrigger($id: ID!) {
    deleteTrigger(id: $id)
  }
`
