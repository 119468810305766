import { BadgeCheckIcon, CheckIcon, ExclamationIcon, MailOpenIcon, XCircleIcon } from '@heroicons/react/solid'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dropdown,
  FormField,
  FormLabel,
  MultiSelectWithSearch,
  Popover,
  Radio,
  Table
} from '@plusplusminus/plusplusdash'
import { Link, navigate, RouteComponentProps, WindowLocation } from '@reach/router'
import cn from 'classnames'
import { BrandStatus, Direction, TagTypes } from 'common/enums'
import DebouncedSearchInput from 'components/DebouncedSearchInput'
import FilterWrapper from 'components/FilterWrapper'
import { FullPageLoader } from 'components/FullPageLoader'
import { useBrandsQuery, useDeleteBrandsMutation, useUpdateBrandsMutation } from 'hooks/brands'
import { useAllTags } from 'hooks/tags'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { convertToYesNo, getImageUrl } from 'utils'
import { buildTagBadges, buildTagSelectOptions } from 'utils/tags'
import { DeleteModal } from '../../components/Modals/Delete'
import { Pagination } from '../../components/Pagination'
import { useBatch } from '../../hooks/useBatch'

const TABLE_SHAPE = [
  { label: '', key: '', isSortable: false },
  { label: '', key: '', isSortable: false },
  { label: 'Name', key: 'name', isSortable: true },
  { label: 'Enabled', key: 'enabled', isSortable: true },
  { label: 'Verified', key: 'verified', isSortable: false },
  { label: 'Tags', key: 'tags', isSortable: false },
  { label: '', key: '', isSortable: false }
]

const dropdownItemStyle = 'block w-full text-left px-5 py-2 hover:bg-gray-100 text-sm rounded-sm cursor-pointer'
const sortItems = [
  { key: 'created_asc', label: 'Created Date: Ascending', value: 'createdAt', direction: Direction.ASC },
  { key: 'created_desc', label: 'Created Date: Descending', value: 'createdAt', direction: Direction.DESC },
  { key: 'updated_asc', label: 'Updated Date: Ascending', value: 'updatedAt', direction: Direction.ASC },
  { key: 'updated_desc', label: 'Updated Date: Descending', value: 'updatedAt', direction: Direction.DESC }
]

export const Brands: React.FC<RouteComponentProps<{ location: WindowLocation<any> }>> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const reset = () => {
    refetch()
    resetSelected()
    setIsModalOpen(false)
  }

  const [selectedIds, onClickItem, contains, resetSelected] = useBatch()
  const { query, pagination, sort, filtering } = useBrandsQuery({ persist: true })
  const { refetch, loading: loadingBrands, error, data } = query

  const { deleteBrands, loading: loadingDeleteBrands } = useDeleteBrandsMutation({
    onCompleted: () => {
      toast.success('Successfully deleted brands.')
      reset()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Server error. could not delete brands.')
    }
  })
  const { enableBrands } = useUpdateBrandsMutation({
    onCompleted: () => {
      toast.success('Successfully enabled brands.')
      reset()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Server error. could not enable brands.')
    }
  })
  const [tags] = useAllTags(TagTypes.Brand)

  const tagSelectOptions = buildTagSelectOptions(tags)

  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback, setSort, removeSort] = sort
  const { filter, setFilter, removeFilter } = filtering

  useEffect(() => {
    if (props?.location?.state?.refetch) {
      refetch()
    }
    if (props?.location?.state?.tags) {
      setFilter('tags', props?.location?.state?.tags)
    }
  }, [props.location])

  const onFilterTags = (value: string[]) => {
    if (value.length === 0) {
      removeFilter('tags', undefined, { isArray: true })
    } else {
      setFilter('tags', value)
    }
  }

  const customSort = (name: string, direction: Direction) => () => {
    setSort(name, direction)
  }

  const removeMultipleFilter = () => {
    removeFilter('tags', undefined, { isArray: true })
    removeFilter('enabled')
  }

  const isMultipleFilter = filter.tags || typeof filter.enabled !== 'undefined'

  return (
    <Box className="flex flex-col">
      <DeleteModal
        isLoading={loadingDeleteBrands}
        headline="Delete Brands"
        description="Are you sure you want to delete the selected brands?"
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDelete={() => {
          deleteBrands(selectedIds)
        }}
      />
      <Box className="flex p-5 justify-between bg-white border-b border-gray-200">
        <h1 className="text-lg">Brands</h1>
        <Link to="/brands/create">
          <Button type="button" variant="primary" size="sm" colorScheme="blue">
            Create Shopify Brand
          </Button>
        </Link>
      </Box>
      {error ? (
        <Alert type="error">
          <Alert.Heading>There was an error fetching brands</Alert.Heading>
        </Alert>
      ) : (
        <Box>
          <div className="bg-white w-full flex py-2 px-5 justify-between">
            <DebouncedSearchInput
              onRemoveFilter={() => removeFilter('name')}
              as="input"
              variant="outline"
              width="md"
              placeholder="Search by brand name"
              value={filter.name ?? ''}
              onChange={(e) => {
                setFilter('name', e.target.value)
              }}
              className="mr-5"
            />
            <div className="flex gap-2">
              <FilterWrapper canRemoveFilter={isMultipleFilter} removeFilterAction={removeMultipleFilter}>
                <Popover>
                  <Popover.Button>Filters</Popover.Button>
                  <Popover.Panel>
                    <div className="py-2 px-4">
                      <div className="mb-2">
                        <p className="font-medium text-sm mb-1">Status</p>
                        <FormField direction="row" className="mr-2">
                          <Radio
                            id="enabled"
                            name="enabledFilter"
                            aria-label="Enabled"
                            onClick={() => setFilter('enabled', true)}
                            checked={filter?.enabled === true}
                          />
                          <FormLabel htmlFor="enabled">Enabled</FormLabel>
                        </FormField>
                        <FormField direction="row">
                          <Radio
                            id="disabled"
                            name="enabledFilter"
                            aria-label="Enabled"
                            onClick={() => setFilter('enabled', false)}
                            checked={filter?.enabled === false}
                          />
                          <FormLabel htmlFor="disabled">Disabled</FormLabel>
                        </FormField>
                      </div>
                      {tagSelectOptions.length > 0 ? (
                        <div>
                          <p className="font-medium text-sm mb-1">Tags</p>
                          <MultiSelectWithSearch
                            key={filter?.tags ? 'tags' : 'notags'}
                            items={tagSelectOptions}
                            value={filter?.tags || []}
                            onChange={onFilterTags}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Popover.Panel>
                </Popover>
              </FilterWrapper>
              <FilterWrapper canRemoveFilter={!!sortField} removeFilterAction={removeSort}>
                <Dropdown>
                  <Dropdown.Button>Sort By</Dropdown.Button>
                  <Dropdown.Items className="z-10">
                    {sortItems.map((item) => (
                      <Dropdown.Item>
                        <button
                          key={item.key}
                          className={cn(dropdownItemStyle, 'flex justify-between items-center')}
                          onClick={customSort(item.value, item.direction)}
                        >
                          {item.label}
                          {sortField === item.value && direction?.value === item.direction ? (
                            <CheckIcon className="h-4 w-4 text-green-600" />
                          ) : null}
                        </button>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Items>
                </Dropdown>
              </FilterWrapper>
            </div>
          </div>
          {selectedIds.length > 0 ? (
            <div className="bg-gray-50 w-full flex py-2 px-5 justify-between">
              <Button
                colorScheme="red"
                variant="plain"
                className="text-red-500 mr-1"
                onClick={() => setIsModalOpen(true)}
              >
                Delete
              </Button>
              <Popover>
                <Popover.Button>Status</Popover.Button>
                <Popover.Panel>
                  <button
                    className="block w-full text-left px-5 py-1 hover:bg-gray-100 text-sm rounded-sm cursor-pointer"
                    onClick={() => enableBrands(selectedIds, true)}
                  >
                    Enable
                  </button>
                  <button
                    className="block w-full text-left px-5 py-1 hover:bg-gray-100 text-sm rounded-sm cursor-pointer"
                    onClick={() => enableBrands(selectedIds, false)}
                  >
                    Disable
                  </button>
                </Popover.Panel>
              </Popover>
            </div>
          ) : null}
          {!loadingBrands && data ? (
            <>
              <Table
                shape={TABLE_SHAPE}
                sortCallback={sortCallback}
                activeSort={sortField}
                sortDirection={direction?.toLowerCase()}
              >
                {data.brands.edges.map(({ node: brand }) => {
                  const image = brand.featuredPortrait || brand.featuredLandscape || brand.images?.[0]
                  const imageUrl = getImageUrl(image, 160)
                  return (
                    <Table.Row
                      key={brand.id}
                      className={contains(brand.id) ? 'bg-blue-100' : 'hover:bg-blue-50 cursor-pointer'}
                      onClick={() => navigate(`/brands/${brand.id}`)}
                    >
                      <Table.Cell className="w-6">
                        <Checkbox id={brand.id} checked={contains(brand.id)} onClick={() => onClickItem(brand.id)} />
                      </Table.Cell>
                      <Table.Cell className="w-32">
                        {imageUrl ? <img className="w-full object-cover" src={imageUrl} /> : null}
                      </Table.Cell>
                      <Table.Cell className="font-medium text-gray-900">{brand.brandName}</Table.Cell>
                      <Table.Cell>{convertToYesNo(brand.enabled)}</Table.Cell>
                      <Table.Cell>
                        {brand.verified === BrandStatus.VERIFIED ? (
                          <BadgeCheckIcon className="text-green-800 w-6 h-6" />
                        ) : null}
                        {brand.verified === BrandStatus.UNVERIFIED ? (
                          <XCircleIcon className="text-yellow-500 w-6 h-6" />
                        ) : null}
                        {brand.verified === BrandStatus.PENDING ? (
                          <MailOpenIcon className="text-yellow-500 w-6 h-6" />
                        ) : null}
                        {brand.verified === BrandStatus.EXPIRED ? (
                          <ExclamationIcon className="text-yellow-500 w-6 h-6" />
                        ) : null}
                      </Table.Cell>
                      <Table.Cell>
                        <div className="inline">{buildTagBadges(brand.tags?.slice(0, 3))}</div>
                        {brand.tags?.length && brand.tags.length > 3 ? (
                          <p className="text-xs text-gray-400 ml-2 inline">{brand.tags.length - 3} others</p>
                        ) : null}
                      </Table.Cell>
                      <Table.Cell>
                        <Link className="underline" to={`/brands/${brand.id}`}>
                          View
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table>
              <Pagination
                hasPreviousPage={data.brands.pageInfo.hasPreviousPage}
                hasNextPage={data.brands.pageInfo.hasNextPage}
                onNext={nextPage}
                onPrev={prevPage}
                page={page}
              />
            </>
          ) : (
            <FullPageLoader isActive />
          )}
        </Box>
      )}
    </Box>
  )
}
