import { gql, MutationHookOptions, QueryHookOptions, useMutation } from '@apollo/client'
import { PaginatedEntity, OptionName } from 'common/types'
import { OptionNameFilter } from 'generated'
import { OPTION_NAME_FIELDS, PAGING_FIELDS } from 'graphql/fragments'
import { useCallback } from 'react'
import { TUsePaginatedQuery, usePaginatedQuery } from './usePaginatedQuery'

type OptionNamesResult = {
  optionNames: PaginatedEntity<OptionName>
}
type FilterFields = { name?: string }

const GET_OPTION_NAMES_QUERY = gql`
  ${OPTION_NAME_FIELDS}
  ${PAGING_FIELDS}
  query GetOptionNames($paging: CursorPaging, $filter: OptionNameFilter, $sorting: [OptionNameSort!]) {
    optionNames(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...OptionNameFields
        }
      }
      pageInfo {
        ...PagingFields
      }
    }
  }
`

const UPDATE_OPTION_NAME_MUTATION = gql`
  ${OPTION_NAME_FIELDS}
  mutation UpdateOptionName($input: UpdateOneOptionNameInput!) {
    updateOneOptionName(input: $input) {
      ...OptionNameFields
    }
  }
`

async function run<T>(promise: Promise<T>) {
  if (!promise || !promise.then) {
    throw new Error(`The argument passed to run must be a promise.`)
  }
  return promise.then(
    (data) => {
      return data
    },
    (error) => {
      console.error(error)
      return Promise.reject(error)
    }
  )
}

const buildOptionNamesFilter = (filter: FilterFields) => {
  const f = {} as OptionNameFilter

  if (filter.name) {
    f.name = { iLike: `%${filter.name}%` }
  }

  return f
}

function useOptionNamesQuery(options?: QueryHookOptions): TUsePaginatedQuery<OptionNamesResult> {
  return usePaginatedQuery<OptionNamesResult, OptionName>({
    query: GET_OPTION_NAMES_QUERY,
    accessor: 'optionNames',
    options,
    buildFilter: buildOptionNamesFilter
  })
}

function useUpdateOptionNameMutation(
  options?: MutationHookOptions
): { updateOptionName: (id: string, update: { name: string }) => void; loading: boolean } {
  const [updateOptionNameMutation, { loading }] = useMutation(UPDATE_OPTION_NAME_MUTATION, options)

  const updateOptionName = useCallback(
    async (id: string, update: { name: string }) => {
      await run(updateOptionNameMutation({ variables: { input: { id, update } } }))
    },
    [updateOptionNameMutation]
  )

  return { updateOptionName, loading }
}

export { useOptionNamesQuery, useUpdateOptionNameMutation }
