import cn from 'classnames'

const colors = {
  green: 'bg-green-100 text-green-800',
  red: 'bg-red-100 text-red-800',
  yellow: 'bg-yellow-100 text-yellow-800',
  blue: 'bg-blue-100 text-blue-800',
  gray: 'bg-gray-100 text-gray-800'
}

const baseStyle = 'inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium justify-center'

const Badge: React.FC<{ color: keyof typeof colors; className?: string }> = (props) => {
  const color = colors[props.color]

  return <div className={cn(baseStyle, color, props.className)}>{props.children}</div>
}

export { Badge }
