import { gql } from '@apollo/client'
import { Modal } from '@plusplusminus/plusplusdash'
import { useDeleteMessageCampaignMutation } from 'generated'
import toast from 'react-hot-toast'
import Button from 'components/Button'

type DeleteMessageProps = {
  id?: string
  name?: string
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
}
export default function DeleteMessageCampaignModal(props: DeleteMessageProps): JSX.Element {
  const [deleteMessageCampaignMtn, { loading }] = useDeleteMessageCampaignMutation({
    onCompleted: () => {
      toast.success('Deleted message campaign')
      props.onDelete()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not delete message campaign.')
    }
  })
  const deleteCampaign = () => {
    props.id && deleteMessageCampaignMtn({ variables: { id: props.id } })
  }

  return (
    <Modal isOpen={props.isOpen} size="sm" onClose={props.onClose}>
      <Modal.Title>Are you sure you want to delete the "{props.name}" message campaign?</Modal.Title>
      <Modal.Description>
        <div className="flex justify-end gap-2 mt-8">
          <Button variant="plain" colorScheme="red" onClick={props.onClose} isDisabled={loading}>
            Cancel
          </Button>
          <Button variant="primary" colorScheme="red" onClick={deleteCampaign} isDisabled={loading} isLoading={loading}>
            Yes, Delete
          </Button>
        </div>
      </Modal.Description>
    </Modal>
  )
}

DeleteMessageCampaignModal.mutation = gql`
  mutation DeleteMessageCampaign($id: String!) {
    removeMessageCampaign(id: $id)
  }
`
