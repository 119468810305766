import { useCookies } from 'react-cookie'

const TOKEN_NAME = 'authToken'

type AuthTokenTuple = [string, (authToken: string) => void, () => void]

export const useAuthToken = (): AuthTokenTuple => {
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME])

  const setAuthToken = (authToken: string) => setCookie(TOKEN_NAME, authToken, { maxAge: 60 * 60 * 24 * 7 })

  const removeAuthToken = () => removeCookie(TOKEN_NAME)

  return [cookies[TOKEN_NAME], setAuthToken, removeAuthToken]
}
