import { Form, FormLabel, Input, Modal } from '@plusplusminus/plusplusdash'
import Button from 'components/Button'
import { ChromePicker } from 'react-color'
import { BrandQuery, Maybe, useUpdateBrandMutation } from 'generated'
import { useEffect } from 'react'
import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import { toast } from 'react-hot-toast'

type EditMarketingFieldsProps = {
  isOpen: boolean
  onClose: () => void
  brand: NonNullable<BrandQuery['brand']>
}

type FormValues = {
  marketingName?: Maybe<string>
  marketingDescription?: Maybe<string>
  marketingColor: Maybe<string>
}

const EditMarketingFields: React.FC<EditMarketingFieldsProps> = (props) => {
  const { isOpen, onClose, brand } = props

  const { register, handleSubmit, reset, control } = useForm<FormValues>({
    defaultValues: {
      marketingName: brand.marketingName,
      marketingDescription: brand.marketingDescription,
      marketingColor: brand.marketingColor
    }
  })

  useEffect(() => {
    reset({
      marketingName: brand.marketingName,
      marketingDescription: brand.marketingDescription,
      marketingColor: brand.marketingColor
    })
  }, [brand])

  const [updateBrand, { loading }] = useUpdateBrandMutation({
    onCompleted: () => {
      onClose()
      toast.success('Successfully updated marketing fields info.')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update marketing fields info.')
    }
  })
  const onSubmit: SubmitHandler<FormValues> = (update) => {
    updateBrand({ variables: { id: brand.id, update } })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <Modal.Title>Links</Modal.Title>
      <Modal.Description className="my-4">
        <Form className="mb-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-4">
              <FormLabel htmlFor="marketingName">
                <span className="font-bold text-sm">Marketing Name</span>
                <Input
                  id="marketingName"
                  name="marketingName"
                  type="text"
                  ref={register}
                  variant="standard"
                  placeholder="Marketing Name"
                  width="full"
                />
              </FormLabel>
            </div>
            <div className="mb-4">
              <FormLabel htmlFor="marketingColor">
                <span className="font-bold text-sm">Marketing Color</span>
                {/* <Input
                  id="marketingColor"
                  name="marketingColor"
                  type="text"
                  ref={register}
                  variant="standard"
                  placeholder="Marketing Color (hex)"
                  width="full"
                /> */}
                <Controller
                  control={control}
                  name="marketingColor"
                  render={({ onChange, value }) => {
                    return (
                      <ChromePicker
                        key="marketingColor"
                        disableAlpha
                        color={value as string}
                        onChangeComplete={(props) => {
                          onChange(props.hex)
                        }}
                      />
                    )
                  }}
                />
              </FormLabel>
            </div>
            <div className="mb-4 col-span-2">
              <FormLabel htmlFor="marketingDescription">
                <span className="font-bold text-sm">Marketing Description</span>
                <Input
                  id="marketingDescription"
                  name="marketingDescription"
                  as="textarea"
                  type="text-a"
                  ref={register}
                  variant="standard"
                  placeholder="Marketing Description"
                  width="full"
                />
              </FormLabel>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <Button variant="plain" onClick={onClose} type="button">
              Close
            </Button>
            <Button variant="primary" colorScheme="green" type="submit" isDisabled={loading} isLoading={loading}>
              Save
            </Button>
          </div>
        </Form>
      </Modal.Description>
    </Modal>
  )
}

export default EditMarketingFields
