import { Checkbox, Form, FormError, FormField, FormLabel, Input, Select } from '@plusplusminus/plusplusdash'
import Button from 'components/Button'
import { AccountCode, OrderLineLedgerInput } from 'generated'
import React, { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'

export type AccountLineItemDefaultValues = {
  codeId: number | null
  amount: number | null
  description: string | null
  isCredit: boolean
}

interface AccountingLineFormProps {
  defaultValues: AccountLineItemDefaultValues
  onSubmit: (input: OrderLineLedgerInput) => void
  accountingCodes: AccountCode[]
  onClose: () => void
  isLoading: boolean
}
const AccountingLineForm: React.FC<AccountingLineFormProps> = ({
  defaultValues,
  onSubmit,
  accountingCodes,
  onClose,
  isLoading
}) => {
  const {
    errors,
    register,
    handleSubmit,
    formState: { isDirty },
    control
  } = useForm<AccountLineItemDefaultValues>({ defaultValues })

  const codeOptions = useMemo(
    () =>
      accountingCodes.map(({ id, code, description }) => ({
        key: `${id}`,
        value: `${id}`,
        label: `${code} - ${description}`
      })),
    [accountingCodes]
  )
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
      <FormField>
        <FormLabel htmlFor="codeId">Accounting code</FormLabel>
        <Controller
          control={control}
          id="codeId"
          name="codeId"
          rules={{
            required: 'Please select an accounting code'
          }}
          render={({ onChange, value }) => (
            <div className="flex-1">
              <Select value={`${value}`} items={codeOptions} onChange={(value) => onChange(+value)} />
            </div>
          )}
        />
        {errors['codeId']?.message ? <FormError>{errors['codeId'].message}</FormError> : null}
      </FormField>
      <FormLabel className="col-start-1">
        <strong>Description</strong>
        <Input as="textarea" width="full" variant="standard" name="description" ref={register} />
      </FormLabel>
      <FormField>
        <FormLabel htmlFor="amount">Amount (R)</FormLabel>
        <Input
          id="amount"
          name="amount"
          type="number"
          step=".01"
          ref={register({
            valueAsNumber: true,
            validate: (value) => !!value || 'Please enter an amount'
          })}
          variant="standard"
        />
        {errors['amount']?.message ? <FormError>{errors['amount'].message}</FormError> : null}
      </FormField>
      <FormField>
        <div className="flex space-x-2">
          <Controller
            name="isCredit"
            control={control}
            render={({ value, onChange }) => (
              <Checkbox id="isCredit" checked={value} onClick={() => onChange(!value)} />
            )}
          />
          <FormLabel htmlFor="isCredit">is Credit?</FormLabel>
        </div>
      </FormField>
      <div className="flex justify-end gap-2">
        <Button variant="plain" onClick={onClose} type="button">
          Close
        </Button>
        <Button
          variant="primary"
          colorScheme="green"
          type="submit"
          isDisabled={isLoading || !isDirty}
          isLoading={isLoading}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

export default AccountingLineForm
