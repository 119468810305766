import {
  DocumentNode,
  gql,
  MutationHookOptions,
  QueryHookOptions,
  QueryResult,
  useMutation,
  useQuery
} from '@apollo/client'
import { Brand, Image, PaginatedEntity, Tag } from 'common/types'
import { BRAND_DETAIL_FIELDS, BRAND_FIELDS, IMAGE_FIELDS, PAGING_FIELDS, TAG_FIELDS } from 'graphql/fragments'
import { isArray } from 'lodash'
import { useCallback } from 'react'
import { TUsePaginatedQuery, usePaginatedQuery } from './usePaginatedQuery'

interface BrandsResult<T = Brand> {
  brands: PaginatedEntity<T>
}
type AllBrandsResult = { allBrands: { id: string; brandName: string; tags: Tag[] }[] }
type FilterFields = { name?: string; enabled?: boolean; tags?: string[] }
type CreateBrandInput = {
  brandName: string
  domain: string
}
type BrandUpdate = {
  name?: string
  description?: string
  enabled?: boolean
  terms?: string
  privacyPolicy?: string
  returnPolicy?: string
  isPhoneNumberHidden?: boolean
  isAddressHidden?: boolean
  isEmailHidden?: boolean
  facebook?: string
  instagram?: string
}
type SendLinkInput = {
  id: string
  email: string
}
type UpdateLinkInput = {
  id: string
  installUrl: string
}

/** Queries */

const ALL_BRANDS_QUERY = gql`
  query AllBrandsQuery {
    allBrands {
      id
      brandName
      tags {
        id
        name
        category
        categoryLabel
      }
    }
  }
`

const BRANDS_QUERY = gql`
  ${BRAND_FIELDS}
  ${PAGING_FIELDS}
  query BrandsQuery($paging: CursorPaging, $filter: BrandFilter, $sorting: [BrandSort!]) {
    brands(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...BrandFields
        }
      }
      pageInfo {
        ...PagingFields
      }
    }
  }
`

/** Mutations */

export const DELETE_BRAND = gql`
  mutation DeleteBrand($id: ID!) {
    deleteOneBrand(input: { id: $id }) {
      id
    }
  }
`

const DELETE_BRANDS = gql`
  mutation DeleteBrands($ids: [ID!]!) {
    deleteManyBrands(input: { filter: { id: { in: $ids } } }) {
      deletedCount
    }
  }
`

const UPDATE_BRANDS = gql`
  mutation UpdateBrands($ids: [ID!]!, $update: BrandsInput!) {
    updateManyBrands(input: { filter: { id: { in: $ids } }, update: $update }) {
      updatedCount
    }
  }
`

const UPDATE_BRAND = gql`
  mutation UpdateBrand($id: ID!, $update: BrandsInput!) {
    updateBrand(id: $id, update: $update) {
      ...BrandFields
      ...BrandDetailFields
    }
  }
  ${BRAND_FIELDS}
  ${BRAND_DETAIL_FIELDS}
`

const SET_TAGS_ON_BRAND = gql`
  ${TAG_FIELDS}
  mutation SetTagsOnBrand($id: ID!, $tagIds: [ID!]!) {
    setTagsOnBrand(input: { id: $id, relationIds: $tagIds }) {
      id
      tags {
        ...TagFields
      }
    }
  }
`

const CREATE_BRAND = gql`
  ${BRAND_FIELDS}
  mutation CreateBrand($input: CreateOneShopifyBrandInput!) {
    createOneShopifyBrand(input: $input) {
      ...BrandFields
    }
  }
`

const SET_PORTRAIT_ON_BRAND = gql`
  ${IMAGE_FIELDS}
  mutation SetPortraitOnBrand($id: ID!, $imageId: ID!) {
    setFeaturedPortraitOnBrand(input: { id: $id, relationId: $imageId }) {
      id
      featuredPortrait {
        ...ImageFields
      }
    }
  }
`
const SET_LANDSCAPE_ON_BRAND = gql`
  ${IMAGE_FIELDS}
  mutation SetLandscapeOnBrand($id: ID!, $imageId: ID!) {
    setFeaturedLandscapeOnBrand(input: { id: $id, relationId: $imageId }) {
      id
      featuredLandscape {
        ...ImageFields
      }
    }
  }
`

const SET_IMAGES_ON_BRAND = gql`
  ${IMAGE_FIELDS}
  mutation SetImagesOnBrand($id: ID!, $imageIds: [ID!]!) {
    setImagesOnBrand(input: { id: $id, relationIds: $imageIds }) {
      id
      images {
        ...ImageFields
      }
    }
  }
`

const SEND_LINK = gql`
  mutation SendOneBrandVerify($id: ID!, $email: String!) {
    sendOneBrandVerify(input: { id: $id, email: $email })
  }
`

const UPDATE_LINK = gql`
  mutation UpdateOneBrandVerify($id: ID!, $installUrl: String!) {
    updateOneBrandVerify(input: { id: $id, installUrl: $installUrl })
  }
`

const SYNC_BRAND = gql`
  mutation SyncOneBrand($id: ID!) {
    syncOneBrand(input: { id: $id })
  }
`

gql`
  mutation IndexBrand($id: String!) {
    indexBrand(id: $id)
  }
`

gql`
  mutation SetupWebhook($id: String!) {
    registerBrandWebhooks(id: $id)
  }
`

const buildBrandsFilter = (filter?: FilterFields) => {
  const f = { or: [] } as { [x: string]: any }
  if (filter) {
    if (filter.name) {
      f.or.push({ name: { iLike: `%${filter.name}%` } }, { providerName: { iLike: `%${filter.name}%` } })
    }
    if ('enabled' in filter) {
      f.enabled = { is: filter.enabled }
    }
    if (filter.tags) {
      if (isArray(filter.tags) && filter.tags.length > 0) {
        f.tags = { or: filter.tags.map((tag) => ({ id: { eq: tag } })) }
      }
    }
  }
  return f
}

function useBrandsQuery(args: { options?: QueryHookOptions; persist: boolean }): TUsePaginatedQuery<BrandsResult> {
  const { options, persist = false } = args
  return usePaginatedQuery<BrandsResult, Brand>({
    query: BRANDS_QUERY,
    accessor: 'brands',
    options,
    persist,
    buildFilter: buildBrandsFilter
  })
}

function useAllBrandsQuery(options?: QueryHookOptions): QueryResult<AllBrandsResult> {
  return useQuery<AllBrandsResult>(ALL_BRANDS_QUERY, options)
}

function useDeleteBrandsMutation(
  options: MutationHookOptions = {}
): { deleteBrands: (ids: string[]) => void; loading: boolean } {
  const [deleteBrandsMutation, { loading }] = useMutation(DELETE_BRANDS, options)

  const deleteBrands = useCallback(
    async (ids: string[]) => {
      try {
        await deleteBrandsMutation({ variables: { ids } })
      } catch (e) {
        console.error(e)
      }
    },
    [deleteBrandsMutation]
  )

  return { deleteBrands, loading }
}

function useDeleteBrandMutation(
  options?: MutationHookOptions
): { deleteBrand: (id: string) => void; loading: boolean } {
  const [deleteBrandMutation, { loading }] = useMutation(DELETE_BRAND, options)

  const deleteBrand = useCallback(
    async (id: string) => {
      try {
        await deleteBrandMutation({ variables: { id } })
      } catch (e) {
        console.error(e)
      }
    },
    [deleteBrandMutation]
  )

  return { deleteBrand, loading }
}

function useUpdateBrandsMutation(
  options?: MutationHookOptions
): {
  enableBrands: (ids: string[], enabled: boolean) => void
  loading: boolean
} {
  const [updateBrandsMutation, { loading }] = useMutation(UPDATE_BRANDS, options)

  const enableBrands = useCallback(
    async (ids: string[], enabled: boolean) => {
      try {
        await updateBrandsMutation({ variables: { ids, update: { enabled } } })
      } catch (e) {
        console.error(e)
      }
    },
    [updateBrandsMutation]
  )

  return { enableBrands, loading }
}

function useUpdateBrandMutation(
  options?: MutationHookOptions
): { updateBrand: (id: string, update: BrandUpdate) => void; loading: boolean } {
  const [updateBrandMutation, { loading }] = useMutation(UPDATE_BRAND, options)

  const updateBrand = useCallback(
    async (id: string, update: BrandUpdate) => {
      try {
        await updateBrandMutation({ variables: { id, update } })
      } catch (e) {
        console.error(e)
      }
    },
    [updateBrandMutation]
  )

  return { updateBrand, loading }
}

function useSetTagsOnBrandMutation(
  options?: MutationHookOptions
): { setTagsOnBrand: (id: string, tagIds: string[]) => void; loading: boolean } {
  const [setTagsOnBrandMutation, { loading }] = useMutation(SET_TAGS_ON_BRAND, options)

  const setTagsOnBrand = useCallback(
    async (id: string, tagIds: string[]) => {
      try {
        await setTagsOnBrandMutation({ variables: { id, tagIds } })
      } catch (e) {
        console.error(e)
      }
    },
    [setTagsOnBrandMutation]
  )

  return { setTagsOnBrand, loading }
}

function useCreateBrandMutation(
  options?: MutationHookOptions
): { createBrand: (input: CreateBrandInput) => void; loading: boolean } {
  const [createBrandMutation, { loading }] = useMutation(CREATE_BRAND, options)

  const createBrand = useCallback(
    async (input: CreateBrandInput) => {
      try {
        await createBrandMutation({ variables: { input } })
      } catch (e) {
        console.error(e)
      }
    },
    [createBrandMutation]
  )

  return { createBrand, loading }
}

type ImageUpdateRes = { id: string; image: Image }
type ImageUpdateReq = { id: string; imageId: string }

function useSetImageOnBrandMutation(
  type: 'portrait' | 'landscape',
  options?: MutationHookOptions<ImageUpdateRes, ImageUpdateReq>
): { setImageOnBrand: (id: string, imageId: string) => void; loading: boolean } {
  const queries: Record<typeof type, DocumentNode> = {
    portrait: SET_PORTRAIT_ON_BRAND,
    landscape: SET_LANDSCAPE_ON_BRAND
  }
  const [setImageOnBrandMutation, { loading }] = useMutation<ImageUpdateRes, ImageUpdateReq>(queries[type], options)

  const setImageOnBrand = useCallback(
    async (id: string, imageId: string) => {
      try {
        await setImageOnBrandMutation({ variables: { id, imageId } })
      } catch (e) {
        console.error(e)
      }
    },
    [setImageOnBrandMutation]
  )

  return { setImageOnBrand, loading }
}

type ImagesUpdateReq = { id: string; imageIds: string[] }

function useSetImagesOnBrandMutation(
  options?: MutationHookOptions<ImageUpdateRes[], ImagesUpdateReq>
): { setImagesOnBrand: (id: string, imageIds: string[]) => void; loading: boolean } {
  const [setImagesOnBrandMutation, { loading }] = useMutation<ImageUpdateRes[], ImagesUpdateReq>(
    SET_IMAGES_ON_BRAND,
    options
  )

  const setImagesOnBrand = useCallback(
    async (id: string, imageIds: string[]) => {
      try {
        await setImagesOnBrandMutation({ variables: { id, imageIds } })
      } catch (e) {
        console.error(e)
      }
    },
    [setImagesOnBrandMutation]
  )

  return { setImagesOnBrand, loading }
}

function useSendLink(options?: MutationHookOptions): { sendLink: (input: SendLinkInput) => void; loading: boolean } {
  const [sendLinkMutation, { loading }] = useMutation(SEND_LINK, options)

  const sendLink = useCallback(
    async (input: SendLinkInput) => {
      try {
        await sendLinkMutation({ variables: input })
      } catch (e) {
        console.error(e)
      }
    },
    [sendLinkMutation]
  )

  return { sendLink, loading }
}

function useUpdateLink(
  options?: MutationHookOptions
): { updateLink: (input: UpdateLinkInput) => void; loading: boolean } {
  const [updateLinkMutation, { loading }] = useMutation(UPDATE_LINK, options)

  const updateLink = useCallback(
    async (input: UpdateLinkInput) => {
      try {
        await updateLinkMutation({ variables: input })
      } catch (e) {
        console.error(e)
      }
    },
    [updateLinkMutation]
  )

  return { updateLink, loading }
}

function useSyncBrand(options?: MutationHookOptions): any {
  const [sync, { loading: syncing }] = useMutation(SYNC_BRAND, options)

  const syncBrand = useCallback(
    async (id: string) => {
      try {
        await sync({ variables: { id } })
      } catch (e) {
        console.error(e)
      }
    },
    [sync]
  )

  return {
    syncBrand,
    syncing
  }
}

export {
  useBrandsQuery,
  useAllBrandsQuery,
  useDeleteBrandsMutation,
  useDeleteBrandMutation,
  useUpdateBrandsMutation,
  useUpdateBrandMutation,
  useSetTagsOnBrandMutation,
  useCreateBrandMutation,
  useSetImageOnBrandMutation,
  useSetImagesOnBrandMutation,
  useSendLink,
  useUpdateLink,
  useSyncBrand
}
export type { CreateBrandInput, BrandUpdate, SendLinkInput, UpdateLinkInput }
