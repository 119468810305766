import { useState } from 'react'
import { ApolloError } from '@apollo/client'
import { LoginData } from '../common/types'
import { useUser } from './useUser'
import { useAuthToken } from './useAuthToken'

export const useLogin = (): {
  login: (input: LoginData) => void
  logout: () => void
  error: string | undefined
  customError: { message: string } | undefined
  loading: boolean
} => {
  const apiUrl = process.env.REACT_APP_TLE_API || ''
  const [loading, setLoading] = useState(false)
  const [, setAuthToken, removeAuthToken] = useAuthToken()
  const { setUser } = useUser()
  const [customError, setCustomError] = useState<{ message: string }>()

  const login = async (input: LoginData) => {
    setLoading(true)

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `
        mutation login($input: LoginAccountInput!) {
          login(input: $input) {
            id
            firstName
            lastName
            email
            customer {
              availableCredit
            }
            roles
          }
        }
      `,
        variables: {
          input: {
            email: input.email,
            password: input.password
          }
        }
      })
    })

    if (!response.ok) {
      setLoading(false)
      setCustomError({ message: 'Invalid credentials' })
      return
    }

    const { data, errors } = await response.json()

    if (errors) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const { message } = errors[0]
      setLoading(false)
      setCustomError({ message: 'Invalid credentials' })
      return
    }

    const token = response.headers.get('tle-auth-token')

    if (!token) {
      setLoading(false)
      setCustomError({ message: 'Invalid credentials' })
      return
    }

    setAuthToken(token)
    setLoading(false)

    if (data.login) {
      setUser && setUser(data?.login ?? null)
    }
  }

  const logout = async () => {
    removeAuthToken()

    setLoading(true)
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `mutation { logout }`
      })
    })

    if (!response.ok) {
      throw new Error('Logout failed')
    }

    setUser && setUser(null)

    setLoading(false)
  }

  return { login, logout, error: customError?.message, customError, loading }
}
