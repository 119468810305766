import React from 'react'
import { Box } from '@plusplusminus/plusplusdash'
import cn from 'classnames'

type PageHeaderProps = {
  className?: string
}

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const { children, className } = props

  const toRender =
    typeof children === 'string' ? (
      <h1 className={cn('text-lg', className)}>{children}</h1>
    ) : typeof children === 'function' ? (
      children()
    ) : (
      children
    )

  return <Box className="flex p-5 justify-between items-center bg-white border-b border-gray-200">{toRender}</Box>
}

export { PageHeader }
