import React, { FC } from 'react'
import { useSelect } from 'downshift'
import { selectStyles as styles } from './styles'
import { ISelectItem, ISelectSection } from '../../common/types'
import { FormLabel } from '../Form'
import { isSection } from './helpers'
import { SelectItem } from './SelectItem'

interface SelectProps {
  items: ISelectItem[] | ISelectSection[]
  label?: string
  onChange?: (value: any) => void
  value?: string
  renderActions?: () => React.ReactNode
}

const itemToString = (item: ISelectItem | null) => (item ? String(item.label) : '')

export const Select: FC<SelectProps> = (props) => {
  const { items: selectItems, label, onChange, value } = props

  const onSelectedItemChange = (changes: any) => {
    onChange && onChange(changes.selectedItem.value)
  }

  const items = isSection(selectItems)
    ? selectItems.reduce((acc, { items }) => {
        acc.push(...items)
        return acc
      }, [] as ISelectItem[])
    : selectItems

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps
  } = useSelect<ISelectItem>({
    items,
    itemToString,
    onSelectedItemChange,
    initialSelectedItem: items.find((item) => item.value === value)
  })

  let sectionIndex = 0

  return (
    <div>
      {label && (
        <FormLabel className={styles.label} {...getLabelProps()}>
          {label}
        </FormLabel>
      )}
      <div className="relative">
        <button type="button" className={styles.button} style={{ minHeight: '38px' }} {...getToggleButtonProps()}>
          <span className="block truncate">{selectedItem?.label}</span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>
        {isOpen && (
          <div className={styles.menuContainer}>
            <ul tabIndex="-1" className={styles.menu} {...getMenuProps()}>
              {isSection(selectItems)
                ? selectItems.map((item) => {
                    return (
                      <div className="border-b border-gray-300">
                        <p className="text-base font-medium py-2 pl-3">{item.heading}</p>
                        {item.items.map((item) => {
                          const index = sectionIndex++
                          return (
                            <SelectItem
                              key={`${item.key}${index}`}
                              highlightedIndex={highlightedIndex}
                              selectedItems={selectedItem?.value ? [selectedItem?.value] : []}
                              index={index}
                              item={item}
                              getItemProps={getItemProps}
                            />
                          )
                        })}
                      </div>
                    )
                  })
                : selectItems.map((item, index) => {
                    return (
                      <SelectItem
                        key={`${item.key}${index}`}
                        highlightedIndex={highlightedIndex}
                        selectedItems={selectedItem?.value ? [selectedItem?.value] : []}
                        index={index}
                        item={item}
                        getItemProps={getItemProps}
                      />
                    )
                  })}
            </ul>
            {props.renderActions?.()}
          </div>
        )}
      </div>
    </div>
  )
}
