import { useMutation, gql, MutationHookOptions } from '@apollo/client'
import { useCallback } from 'react'

const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($email: String!) {
    sendOnePasswordForgot(input: { email: $email }) {
      success
      message
    }
  }
`

export const useForgotPassword = (
  options?: MutationHookOptions
): { sendForgotPassword: (email: string) => void; loading: boolean } => {
  const [forgotPasswordMutation, { loading }] = useMutation(FORGOT_PASSWORD_MUTATION, options)

  const sendForgotPassword = useCallback(
    async (email: string) => {
      try {
        await forgotPasswordMutation({ variables: { email } })
      } catch (e) {
        console.error(e)
      }
    },
    [forgotPasswordMutation]
  )

  return { sendForgotPassword, loading }
}
