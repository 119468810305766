import { Modal } from '@plusplusminus/plusplusdash'
import Button from 'components/Button'

interface ModalProps {
  headline: string
  description: string
  isModalOpen: boolean
  onClose: () => void
  onDelete: () => void
  isLoading?: boolean
}

export const DeleteModal: React.FC<ModalProps> = (props) => {
  const { headline, description, isModalOpen, onClose, onDelete, isLoading = false } = props
  return (
    <Modal isOpen={isModalOpen} onClose={onClose} size="sm">
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <svg
            className="h-6 w-6 text-red-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
            {headline}
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">{description}</p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <Button
          type="button"
          variant="primary"
          colorScheme="red"
          className="ml-3"
          onClick={() => onDelete()}
          isDisabled={isLoading}
          isLoading={isLoading}
        >
          Delete
        </Button>
        <Button type="button" variant="plain" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </Modal>
  )
}
