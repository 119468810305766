export const selectStyles = {
  label: `block text-sm font-medium text-gray-700 mb-1`,
  button: `flex flex-wrap bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`,
  menuContainer: `absolute mt-1 w-full rounded-md bg-white shadow-lg z-50`,
  menu: `max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`,
  menuItem: `text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 cursor-pointer`
}

export const multiSelectStyles = {
  selectedItem: `bg-gray-200 rounded px-2 py-1 mr-2`
}
