import DebouncedSearchInput from 'components/DebouncedSearchInput'
import { TUseFilter } from 'hooks/useFilter'
import React from 'react'

type UsersFilterProps = {
  filtering: TUseFilter
}

const UsersFilter: React.FC<UsersFilterProps> = (props) => {
  const {
    filtering: { filter, setFilter, removeFilter }
  } = props

  return (
    <div className="bg-white flex p-2 gap-4">
      <DebouncedSearchInput
        onRemoveFilter={() => removeFilter('firstName')}
        as="input"
        variant="outline"
        width="md"
        id="name"
        name="name"
        onChange={(e) => setFilter('firstName', e.target.value)}
        value={filter.firstName ?? ''}
        placeholder="Search by name"
      />
      <DebouncedSearchInput
        onRemoveFilter={() => removeFilter('email')}
        as="input"
        variant="outline"
        width="md"
        id="email"
        name="email"
        onChange={(e) => setFilter('email', e.target.value)}
        value={filter.email ?? ''}
        placeholder="Search by email"
      />
    </div>
  )
}

export { UsersFilter }
