import { DocumentTextIcon, ReceiptTaxIcon, TruckIcon, UserIcon } from '@heroicons/react/solid'
import { Badge } from '@plusplusminus/plusplusdash'
import { Link } from '@reach/router'
import { Card } from 'components/Card'
import { InfoLabel } from 'components/Info/InfoLabel'
import { InfoValue } from 'components/Info/InfoValue'
import { OrderAddress, OrderFieldsFragment } from 'generated'
import React from 'react'
import { formatDate } from 'utils'

function mapAddress(address: Partial<OrderAddress>) {
  if (!address) return null

  const fields: (keyof OrderAddress)[] = [
    'addressLine1',
    'addressLine2',
    'suburb',
    'city',
    'province',
    'postalCode',
    'country',
    'phone'
  ]
  return fields.filter((field) => address[field]).map((field) => <div>{address[field]}</div>)
}

const statusColors: Record<string, React.ComponentProps<typeof Badge>['color']> = {
  Completed: 'green',
  Pending: 'yellow'
}

interface OrderDetailsProps {
  order: OrderFieldsFragment
  link?: boolean
}
const OrderDetails = ({ order, link = false }: OrderDetailsProps) => {
  const color = statusColors[order.status]
  return (
    <Card className="mb-5">
      <Card.Header>
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center">
            {link ? (
              <Link to={`/orders/${order.id}`}>
                <span className="pr-2 border-r border-gray-400">Order #{order.orderNumber}</span>
              </Link>
            ) : (
              <span className="pr-2 border-r border-gray-400">Order #{order.orderNumber}</span>
            )}
            <div>
              <div className="pl-2 text-gray-500 text-xs">Created: {formatDate(order.createdAt)}</div>
              <div className="pl-2 text-gray-500 text-xs">Updated: {formatDate(order.updatedAt)}</div>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <Badge color={color}>{order.status}</Badge>
            {order.useCredit && !order.isActive ? <Badge color="green">Credit Used</Badge> : null}
          </div>
        </div>
      </Card.Header>
      <Card.Content>
        <div className="grid grid-cols-3 gap-4">
          {order.shippingAddress ? (
            <div>
              <InfoLabel className="flex items-center gap-1">
                <TruckIcon className="h-4" />
                Shipping Address
              </InfoLabel>
              <InfoValue>{mapAddress(order.shippingAddress)}</InfoValue>
            </div>
          ) : null}
          {order.billingAddress ? (
            <div>
              <InfoLabel className="flex items-center gap-1">
                <ReceiptTaxIcon className="h-4" />
                Billing Address
              </InfoLabel>
              <InfoValue>{mapAddress(order.billingAddress)}</InfoValue>
            </div>
          ) : null}
          <div>
            <div className="mb-4">
              <InfoLabel className="flex items-center gap-1">
                <UserIcon className="h-4" />
                Customer
              </InfoLabel>
              <InfoValue>
                {order.customer?.accountId ? (
                  <Link to={`/users/${order.customer?.accountId}`} className="text-sm underline block">
                    {order.customer?.firstName} {order.customer?.lastName}
                  </Link>
                ) : (
                  <span className="text-sm underline mb-4 block">
                    {order.customer?.firstName} {order.customer?.lastName}
                  </span>
                )}
                {!!order.customer?.mobile && <div className="text-sm">{order.customer.mobile}</div>}
                {!!order.customer?.email && <div className="text-sm">{order.customer.email}</div>}
              </InfoValue>
            </div>
            <InfoLabel className="flex items-center gap-1">
              <DocumentTextIcon className="h-4" />
              Order Summary
            </InfoLabel>
            <InfoValue>
              <div className="text-sm">{order.lines.length} Brands</div>
              <div className="text-sm">{order.productCount} Products</div>
            </InfoValue>
          </div>
          <div className="flex justify-end items-end h-full">
            <div className="justify-items-end grid grid-cols-2 gap-x-4 text-md">
              {order.creditUsed ? (
                <>
                  <div className="text-right">Credit Used</div>
                  <div>R {order.creditUsed}</div>
                </>
              ) : null}
              <div className="text-right">Subtotal</div>
              <div>R {order.subTotal}</div>
              <div className="font-bold text-right">Paid</div>
              <div className="font-bold">R {order.totalPaid}</div>
            </div>
          </div>
        </div>
      </Card.Content>
    </Card>
  )
}

export default OrderDetails
