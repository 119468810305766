import { TrashIcon } from '@heroicons/react/solid'
import { DeleteModal } from 'components/Modals/Delete'
import { useDeleteLedgerItemMutation } from 'generated'
import React, { useState } from 'react'
import toast from 'react-hot-toast'

interface DeleteAccountingLineProps {
  accountingLineId: string
  onRefetch: () => void
}
const DeleteAccountingLine: React.FC<DeleteAccountingLineProps> = ({ onRefetch, accountingLineId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const [deleteLedgerItem, { loading }] = useDeleteLedgerItemMutation({
    onCompleted: () => {
      onClose()
      onRefetch()
      toast.success('Successfully deleted accounting line item')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not delete accounting line item')
    }
  })

  const onSubmit = () => {
    deleteLedgerItem({ variables: { id: accountingLineId } })
  }
  return (
    <>
      <button type="button" onClick={onOpen}>
        <TrashIcon className="h-5 w-5 text-red-500" />
      </button>
      <DeleteModal
        headline="Delete accounting line"
        description="Are you sure you want to delete this accounting line?"
        isModalOpen={isOpen}
        onClose={onClose}
        onDelete={onSubmit}
        isLoading={loading}
      />
    </>
  )
}

export default DeleteAccountingLine
