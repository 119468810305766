import { Form, FormError, FormLabel, Input, Radio } from '@plusplusminus/plusplusdash'
import Select from 'components/Select'
import Button from 'components/Button'
import { CreateMessageInput, useCreateMessageMutation } from 'generated'
import { useEmailTemplates } from 'hooks/useEmailTemplates'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

type FormData = Omit<CreateMessageInput, 'meta'> & { deeplink: string }

interface CreateMessageComponentProps {
  onCompleted: (id: string, name: string) => void
}
const CreateMessageComponent = ({ onCompleted }: CreateMessageComponentProps): JSX.Element => {
  const [contentfulTemplates, SetContentfulTemplates] = useState(false)
  const { register, handleSubmit, errors, control } = useForm<FormData>()
  const { data: emailTemplatesData, loading: loadingEmailTemplates } = useEmailTemplates()

  const [createMessage, { loading }] = useCreateMessageMutation({
    onCompleted: (data) => {
      toast.success('Successfully created message')
      if (data.createMessage?.id) {
        onCompleted(data.createMessage.id, data.createMessage.name)
      }
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not create message')
    }
  })

  const onSubmit = ({ deeplink, ...data }: FormData) => {
    const input = { ...data } as CreateMessageInput
    if (deeplink) {
      input.meta = { deeplink }
    }
    createMessage({ variables: { input } })
  }
  return (
    <div>
      {!loadingEmailTemplates && emailTemplatesData && (
        <div className="mb-5">
          <p className="mb-1 text-sm font-bold">Choose a template</p>
          <div className="flex space-x-4 items-center mb-2">
            <FormLabel htmlFor="basic" className="flex items-center">
              <Radio
                id="basic"
                name="basic"
                checked={!contentfulTemplates}
                onClick={() => SetContentfulTemplates(false)}
              />
              Basic
            </FormLabel>
            <FormLabel htmlFor="contentful" className="flex items-center">
              <Radio
                id="contentful"
                name="contentful"
                checked={contentfulTemplates}
                onClick={() => SetContentfulTemplates(true)}
              />
              Contentful
            </FormLabel>
          </div>
        </div>
      )}
      <Form>
        <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-4 items-start">
          <div>
            <div className="mb-4">
              <FormLabel>
                <strong>Name*</strong>
                <Input width="full" variant="standard" name="name" ref={register({ required: 'Name is required' })} />
                {errors.name && <FormError>{errors.name.message}</FormError>}
              </FormLabel>
            </div>
            {contentfulTemplates && emailTemplatesData ? (
              <>
                <input hidden readOnly type="text" name="title" ref={register} />
                <input hidden readOnly type="text" name="body" ref={register} />
                <FormLabel>
                  <strong>Contentful Email Template</strong>
                  <Controller
                    control={control}
                    name="contentId"
                    render={(props) => (
                      <Select
                        options={emailTemplatesData.emailTemplatesCollection.items.map((email) => ({
                          label: email.name,
                          key: email.sys.id,
                          value: email.sys.id
                        }))}
                        {...props}
                      />
                    )}
                  />
                </FormLabel>
              </>
            ) : (
              <>
                <FormLabel className="block mb-4">
                  <strong>Subject</strong>
                  <Input width="full" variant="standard" name="title" ref={register} />
                  {errors.title && <FormError>{errors.title.message}</FormError>}
                </FormLabel>
                <FormLabel>
                  <strong>Message Body</strong>
                  <Input width="full" as="textarea" variant="standard" name="body" ref={register} />
                </FormLabel>
              </>
            )}
          </div>
          <div>
            <FormLabel className="block mb-4">
              <strong>Notification Title</strong>
              <Input width="full" variant="standard" name="notificationTitle" ref={register} />
            </FormLabel>
            <FormLabel>
              <strong>Notification Body</strong>
              <Input width="full" as="textarea" variant="standard" name="notificationBody" ref={register} />
            </FormLabel>
          </div>
          <FormLabel>
            <strong>Deeplink</strong>
            <Input width="full" variant="standard" name="deeplink" ref={register} />
          </FormLabel>
        </div>
        <Button
          colorScheme="green"
          type="button"
          variant="primary"
          isDisabled={loading}
          isLoading={loading}
          onClick={(e) => {
            e.stopPropagation()
            handleSubmit(onSubmit)(e)
          }}
        >
          Save
        </Button>
      </Form>
    </div>
  )
}

export default CreateMessageComponent
