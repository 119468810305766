import React from 'react'
import cn from 'classnames'

type TCard = React.FC<{ className?: string }> & { Header: React.FC; Content: React.FC }

const Card: TCard = ({ children, className }) => {
  return <div className={cn('bg-white shadow sm:rounded-lg', className)}>{children}</div>
}

const CardHeader: React.FC<{ className?: string }> = ({ children, className }) => (
  <div className={cn('px-4 py-5 sm:px-6 flex justify-between items-center border-b border-gray-200', className)}>
    {children}
  </div>
)

const CardContent: React.FC<{ className?: string }> = ({ children, className }) => (
  <div className={cn('px-4 py-5 sm:px-6', className)}>{children}</div>
)

Card.Header = CardHeader
Card.Content = CardContent

export { Card }
