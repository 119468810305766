import React from 'react'
import cn from 'classnames'

interface FieldProps {
  direction?: 'row' | 'column'
  className?: string
}

export const FormField: React.FC<FieldProps> = (props) => {
  const { children, className: overrideClassname, direction = 'column' } = props
  const className = cn(
    'flex mb-2',
    { 'flex-col': direction === 'column' },
    { 'flex-row items-center': direction === 'row' },
    overrideClassname
  )
  return <div className={className}>{children}</div>
}
