import React, { useCallback, useMemo, useState } from 'react'
import {
  Query,
  Builder,
  BuilderProps,
  BasicConfig,
  Config,
  ImmutableTree,
  Utils as QbUtils,
  JsonGroup,
  JsonTree,
  Settings
} from 'react-awesome-query-builder'
import AntdConfig from 'react-awesome-query-builder/lib/config/antd'
import 'antd/dist/antd.css'
import 'react-awesome-query-builder/lib/css/styles.css'
import 'react-awesome-query-builder/lib/css/compact_styles.css'

type QueryBuilderProps = {
  tree?: JsonTree
  settings?: Partial<Settings>
  config: Partial<Config>
  onChange?: (jsonLogic: any) => void
}

const queryValue: JsonGroup = { id: QbUtils.uuid(), type: 'group' }

export default function QueryBuilder(props: QueryBuilderProps): JSX.Element {
  const { config } = props

  const mergedConfig = useMemo(
    () => ({
      ...(AntdConfig as BasicConfig),
      ...config,
      settings: { ...(AntdConfig as BasicConfig).settings, ...config.settings, ...props.settings }
    }),
    [config]
  )

  const [state, setState] = useState({
    tree: QbUtils.checkTree(
      props.tree
        ? (QbUtils.loadFromJsonLogic(props.tree, mergedConfig) as ImmutableTree)
        : QbUtils.loadTree(queryValue),
      mergedConfig
    ),
    config: mergedConfig
  })

  const onChange = useCallback((tree: ImmutableTree, config: Config) => {
    setState((prevState) => ({ ...prevState, tree, config }))
    const jsonLogic = QbUtils.jsonLogicFormat(tree, config)
    props.onChange?.(jsonLogic.logic)
  }, [])

  const renderBuilder = useCallback(
    (props: BuilderProps) => (
      <div className="query-builder-container" style={{ padding: '10px' }}>
        <div className="query-builder qb-lite">
          <Builder {...props} />
        </div>
      </div>
    ),
    []
  )

  return <Query {...mergedConfig} value={state.tree} onChange={onChange} renderBuilder={renderBuilder} />
}
