import { gql } from '@apollo/client'
import { CheckIcon, EyeIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid'
import { Button, Dropdown, Table } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import cn from 'classnames'
import { Direction } from 'common/enums'
import { PageInfo } from 'common/types'
import DebouncedSearchInput from 'components/DebouncedSearchInput'
import FilterWrapper from 'components/FilterWrapper'
import { PageHeader } from 'components/PageHeader'
import { Pagination } from 'components/Pagination'
import { AccountGroup, GetAccountGroupsQuery } from 'generated'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useState } from 'react'
import DeleteAccountGroupModal from './DeleteAccountGroupModal'

type AccountGroupsResult = {
  accountGroups: Omit<GetAccountGroupsQuery['accountGroups'], 'pageInfo'> & { pageInfo: PageInfo }
}

const TABLE_SHAPE = [
  { label: 'Name', key: 'name', isSortable: true },
  { label: 'Description', key: 'description', isSortable: false },
  { label: '', key: '', isSortable: false }
]

const sortItems = [
  { key: 'created_asc', label: 'Created Date: Ascending', value: 'createdAt', direction: Direction.ASC },
  { key: 'created_desc', label: 'Created Date: Descending', value: 'createdAt', direction: Direction.DESC },
  { key: 'updated_asc', label: 'Updated Date: Ascending', value: 'updatedAt', direction: Direction.ASC },
  { key: 'updated_desc', label: 'Updated Date: Descending', value: 'updatedAt', direction: Direction.DESC }
]

export default function AccountGroupsTable(props: RouteComponentProps): JSX.Element {
  const [{ showDeleteModal, id: idToDelete, name: nameToDelete }, setShowDeleteModal] = useState<{
    id?: string
    showDeleteModal: boolean
    name?: string
  }>({ showDeleteModal: false })
  const { query, pagination, sort, filtering } = usePaginatedQuery<AccountGroupsResult, Partial<AccountGroup>>({
    query: AccountGroupsTable.query,
    accessor: 'accountGroups',
    options: {},
    persist: true,
    limit: 20
  })

  const { refetch, data } = query
  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback, setSort, removeSort] = sort
  const { filter, setFilter, removeFilter } = filtering

  const goTo = (path: string) => () => {
    props.navigate?.(path)
  }
  const customSort = (name: string, direction: Direction) => () => {
    setSort(name, direction)
  }

  return (
    <div>
      <DeleteAccountGroupModal
        id={idToDelete}
        name={nameToDelete}
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal({ showDeleteModal: false })
        }}
        onDelete={() => {
          setShowDeleteModal({ showDeleteModal: false })
          refetch()
        }}
      />
      <PageHeader>
        <h1 className="text-lg">Audience Segments</h1>
        <Button variant="plain" onClick={goTo('create')}>
          Create <PlusIcon className="w-4 h-4" />
        </Button>
      </PageHeader>
      <div className="bg-white w-full flex py-2 px-5 justify-between">
        <DebouncedSearchInput
          onRemoveFilter={() => removeFilter('name')}
          as="input"
          variant="outline"
          width="md"
          id="name"
          name="name"
          onChange={(e) => setFilter('name', e.target.value)}
          value={filter.name ?? ''}
          placeholder="Search by name"
        />
        <FilterWrapper canRemoveFilter={!!sortField} removeFilterAction={removeSort}>
          <Dropdown>
            <Dropdown.Button>Sort By</Dropdown.Button>
            <Dropdown.Items className="z-10">
              {sortItems.map((item) => (
                <Dropdown.Item>
                  <button
                    key={item.key}
                    className={cn(styles.dropdownItem, 'flex justify-between items-center')}
                    onClick={customSort(item.value, item.direction)}
                  >
                    {item.label}
                    {sortField === item.value && direction?.value === item.direction ? (
                      <CheckIcon className="h-4 w-4 text-green-600" />
                    ) : null}
                  </button>
                </Dropdown.Item>
              ))}
            </Dropdown.Items>
          </Dropdown>
        </FilterWrapper>
      </div>
      <Table
        shape={TABLE_SHAPE}
        sortCallback={sortCallback}
        activeSort={sortField}
        sortDirection={direction?.toLowerCase()}
      >
        {data?.accountGroups.edges.map(({ node: acc }) => {
          return (
            <Table.Row key={acc.id}>
              <Table.Cell>{acc.name}</Table.Cell>
              <Table.Cell>{acc.description}</Table.Cell>
              <Table.Cell className="flex gap-2">
                <EyeIcon className={styles.icon} onClick={goTo(`${acc.id}/view`)} />
                <PencilIcon className={styles.icon} onClick={goTo(`${acc.id}/edit`)} />
                <TrashIcon
                  className={cn(styles.icon, 'text-red-500')}
                  onClick={() => setShowDeleteModal({ showDeleteModal: true, id: acc.id, name: acc.name })}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table>
      <Pagination
        hasPreviousPage={data?.accountGroups.pageInfo.hasPreviousPage}
        hasNextPage={data?.accountGroups.pageInfo.hasNextPage}
        onNext={nextPage}
        onPrev={prevPage}
        page={page}
      />
    </div>
  )
}

const styles = {
  icon: 'hover:text-gray-400 w-5 h-5 cursor-pointer',
  dropdownItem: 'block w-full text-left px-5 py-2 hover:bg-gray-100 text-sm rounded-sm cursor-pointer'
}

AccountGroupsTable.query = gql`
  query GetAccountGroups($paging: CursorPaging, $filter: AccountGroupFilter, $sorting: [AccountGroupSort!]) {
    accountGroups(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          id
          name
          description
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`
