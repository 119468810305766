import { navigate, RouteComponentProps } from '@reach/router'
import { useForm } from 'react-hook-form'
import { Box, Form, FormField, FormLabel, FormError, Grid, Input } from '@plusplusminus/plusplusdash'
import { getFormError } from '../../common/utils'
import { CreateBrandInput, useCreateBrandMutation } from 'hooks/brands'
import toast from 'react-hot-toast'
import Button from 'components/Button'

export const CreateBrand: React.FC<RouteComponentProps> = () => {
  const { register, handleSubmit, errors } = useForm()
  const { createBrand, loading } = useCreateBrandMutation({
    onCompleted: () => {
      toast.success('Successfully created brand.')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Server error. could not create brand.')
    }
  })
  const onSubmit = async (data: CreateBrandInput) => {
    await createBrand(data)
    navigate('/brands', { state: { refetch: true } })
  }

  return (
    <Box>
      <Box className="flex justify-between p-5 border-b border-gray-300 items-center">
        <Box>
          <h3 className="text-lg leading-6 font-medium text-gray-900">New Shopify Brand</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter brand details to create a new brand</p>
        </Box>
      </Box>
      <Box className="p-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid className="sm:w-full lg:w-2/3 mb-4" columns={2} rowGap={4} columnGap={4}>
            <FormField>
              <FormLabel htmlFor="brandName">Brand Name &#42;</FormLabel>
              <Input
                id="brandName"
                name="brandName"
                as="input"
                variant="standard"
                width="full"
                ref={register({ required: true })}
              />
              {errors.name && <FormError>{getFormError(errors.name.type)}</FormError>}
            </FormField>
            <FormField>
              <FormLabel htmlFor="domain">Domain &#42;</FormLabel>
              <Input
                id="domain"
                name="domain"
                as="input"
                variant="standard"
                width="full"
                placeholder="example.myshopify.com"
                ref={register({ required: true })}
              />
              {errors.installUrl && <FormError>{getFormError(errors.domain.type)}</FormError>}
            </FormField>
            <FormField>
              <FormLabel htmlFor="key">Shopify API Key &#42;</FormLabel>
              <Input
                id="clientKey"
                name="clientKey"
                as="input"
                variant="standard"
                width="full"
                ref={register({ required: true })}
              />
              {errors.clientKey && <FormError>{getFormError(errors.clientKey.type)}</FormError>}
            </FormField>
            <FormField>
              <FormLabel htmlFor="key">API Secret &#42;</FormLabel>
              <Input
                id="clientSecret"
                name="clientSecret"
                as="input"
                variant="standard"
                width="full"
                ref={register({ required: true })}
              />
              {errors.clientSecret && <FormError>{getFormError(errors.clientSecret.type)}</FormError>}
            </FormField>
          </Grid>
          <Button
            variant="primary"
            colorScheme="green"
            type="submit"
            className="px-8"
            isDisabled={loading}
            isLoading={loading}
          >
            Save
          </Button>
        </Form>
      </Box>
    </Box>
  )
}
