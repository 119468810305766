enum UserStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  DISABLED = 'disabled'
}

enum BrandStatus {
  PENDING = 'pending',
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  EXPIRED = 'expired'
}

enum Field {
  EMAIL = 'email',
  PASSWORD = 'password'
}

enum ErrorType {
  REQUIRED = 'required',
  PATTERN = 'pattern'
}

enum Entity {
  ACCOUNT = 'account',
  BRAND = 'brand',
  TAG = 'tag',
  PRODUCT = 'product'
}

enum Direction {
  ASC = 'ASC',
  DESC = 'DESC'
}

enum TagTypes {
  Brand = 'Brand',
  Product = 'Product',
  Story = 'Story'
}

enum InventoryPolicy {
  Deny = 'deny',
  Continue = 'continue'
}

export { UserStatus, Field, ErrorType, Entity, Direction, BrandStatus, TagTypes, InventoryPolicy }
