import { RouteComponentProps } from '@reach/router'
import { React } from '@ungap/global-this'
import { Tag } from 'generated'
import { DocumentNode } from 'graphql'
import { BrandStatus, UserStatus } from './enums'
import { Image } from 'generated'
import { Width } from '@plusplusminus/plusplusdash/dist/components/Input/types'
/* Types */
type LoginData = { email: string; password: string }
type Role = 'ADMIN' | 'USER' | 'ORDER_ADMIN' | 'OPERATIONS' | 'PRODUCT_ADMIN'
type Page<T = any> = React.FC<RouteComponentProps<T>>

export enum Permission {
  Authenticated = 'Authenticated',
  Owner = 'Owner',
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  Public = 'Public',
  OrderAdmin = 'OrderAdmin',
  BrandAdmin = 'BrandAdmin',
  FinancialAdmin = 'FinancialAdmin',
  ProductAdmin = 'ProductAdmin'
}

interface Account {
  id: string
  firstName: string
  lastName: string
  email: string
  status: UserStatus
  isAdmin: boolean
  enabled: boolean
  roles: Permission[]
  lastSeenAt: string
  createdAt: string
  updatedAt: string
}

type TagType = {
  name: string
}

interface Brand {
  id: string
  name: string
  providerName: string
  brandName: string

  marketingName: string
  marketingDescription: string
  marketingColor: string

  description: string
  providerDescription: string
  brandDescription: string

  email: string
  providerEmail: string
  brandEmail: string

  phone: string
  providerPhone: string
  brandCellphone: string

  enabled: boolean

  address: string

  providerAddress2: string
  providerCity: string
  providerZip: string
  providerProvince: string

  currency: string
  locale: string
  ownerName: string
  terms: string
  privacyPolicy: string
  returnPolicy: string
  shippingPolicy: string
  // shopify: Shopify!
  tags?: Tag[]
  products?: Product[]
  verified: BrandStatus
  lastSync?: Date
  installUrl: string

  image?: Image
  images?: Image[]
  featuredPortrait?: Image
  featuredLandscape?: Image

  facebook: string
  instagram: string
}

interface OptionName {
  id: string
  name: string
}

interface Option {
  id: string
  value: string
  name: OptionName
}

interface Variant {
  id: string
  title: string
  price: number
  inventory: number
  options: Option[]
  sku: string
  product?: Product
}

interface Product {
  id: string

  title: string
  providerTitle: string
  productTitle: string

  description: string
  providerDescription: string
  productDescription: string

  image: Image
  images: Image[]
  enabled: boolean
  brand: Brand
  options: Option
  variants: Variant[]

  tags: Tag[]
  providerTags: string[]

  createdAt: Date
  updatedAt: Date

  providerImage: string
  providerImages: Image[]

  isAutoPublish: boolean
}

type OrderAddress = {
  addressLine1: string
  addressLine2: string
  suburb: string
  city: string
  province: string
  postalCode: string
  country: string
  note: string
}

interface LineItem {
  id: string
  subTotal: number
  items: {
    id: string
    quantity: number
    price: number
    productVariant: Variant
  }[]
  brand: Brand
}
interface Order {
  id: string
  createdAt: Date
  updatedAt: Date
  account: Account
  isActive: boolean
  subTotal: number
  shippingAddress: OrderAddress
  billingAddress: OrderAddress
  lines: LineItem[]
}

interface PageInfo {
  hasPreviousPage: boolean
  hasNextPage: boolean
  endCursor: string
  startCursor: string
}

interface PaginatedEntity<T> {
  __typename: string
  edges: { node: T }[]
  pageInfo: PageInfo
}

export type {
  LoginData,
  Role,
  Page,
  Account,
  TagType,
  Tag,
  Brand,
  Option,
  OptionName,
  Variant,
  Product,
  PaginatedEntity,
  PageInfo,
  Image,
  Order,
  OrderAddress,
  LineItem
}

export type GraphqlOperations = {
  queries?: { [key: string]: DocumentNode }
  mutations?: { [key: string]: DocumentNode }
}

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  className?: string
  as?: 'input' | 'textarea'
  variant: 'standard' | 'outline' | 'ghost'
  width?: keyof Width
}
