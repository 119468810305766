import { gql } from '@apollo/client'
import { Badge, Button, Loader } from '@plusplusminus/plusplusdash'
import { Link, RouteComponentProps } from '@reach/router'
import ActionModal from 'components/Actions/ActionModal'
import { ExtendedUpdateActionInput } from 'components/Actions/Actions'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import { Trigger } from 'components/Trigger'
import dayjs from 'dayjs'
import { ActionQuery, Message, useActionQuery, useUpdateActionMutation } from 'generated'
import { EmailTemplatesCollection, useEmailTemplates } from 'hooks/useEmailTemplates'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import MessagesTable, { MessagesResult } from 'pages/Message/MessagesTable'
import { useEffect, useRef } from 'react'
import toast from 'react-hot-toast'
import { formatPrice } from 'utils'

function getDefaultValues(data: ActionQuery['action'] | undefined) {
  return {
    id: data?.id,
    name: data?.name,
    messageId: data?.message?.id,
    messageName: data?.message?.name,
    type: data?.type,
    channels: data?.channels,
    enabled: data?.enabled,
    voucherMessage: data?.voucherMessage,
    voucherValue: data?.voucherValue,
    expiryDate: data?.expiryDate ?? { value: 0, unit: 'day' },
    delay: data?.delay ?? { value: 0, unit: 'minutes' }
  }
}

const limit = 5

const buildFilter = (filter: any) => {
  const f: any = {}
  if (filter.search) {
    f.or = [{ name: { iLike: `%${filter.search}%` } }, { title: { iLike: `%${filter.search}%` } }]
  }
  if ('enabled' in filter) {
    f.enabled = { is: filter.enabled }
  }
  return f
}

interface ViewActionProps extends RouteComponentProps {
  id: string
}

export default function ViewAction(props: ViewActionProps): JSX.Element {
  const { data, loading: loadingAction, refetch } = useActionQuery({
    variables: { id: props.id },
    fetchPolicy: 'cache-and-network'
  })

  const actionRef = useRef<ActionQuery['action'] | undefined>()

  const paginatedMessages = usePaginatedQuery<MessagesResult, Partial<Message>>({
    query: MessagesTable.query,
    accessor: 'messages',
    options: {},
    persist: false,
    limit,
    buildFilter
  })
  const reloadMessages = () => paginatedMessages.query.refetch()

  const { data: emailTemplatesData, loading: loadingEmailTemplates } = useEmailTemplates()

  const [updateAction, { loading }] = useUpdateActionMutation({
    onCompleted: () => {
      toast.success('Successfully updated action.')
      refetch()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update action')
    }
  })

  const onAddAction = (dataInput: ExtendedUpdateActionInput) => {
    const input = { ...dataInput }
    delete input.messageName
    if (!input.delay || !input.delay.value || Object.is(input.delay.value, NaN) || input.delay.value <= 0) {
      input.delay = null
    } else {
      delete (input.delay as any).__typeName
    }
    updateAction({ variables: { id: props.id, input } })
  }

  useEffect(() => {
    if (data?.action) {
      actionRef.current = data.action
    }
  }, [data?.action])

  const action = data?.action || actionRef.current
  return (
    <div>
      <PageHeader>
        <h1 className="text-lg">View Action</h1>
        {action && !loadingAction && !loadingEmailTemplates ? (
          <ActionModal
            isLoading={loading}
            defaultValues={action ? getDefaultValues(action) : {}}
            modalTitle="Update Action"
            renderIcon={() => (
              <Button variant="primary" colorScheme="blue">
                Edit
              </Button>
            )}
            onAddAction={onAddAction}
            {...{
              paginatedMessages,
              reloadMessages,
              emailTemplateCollection: emailTemplatesData?.emailTemplatesCollection as EmailTemplatesCollection
            }}
          />
        ) : (
          <Button variant="primary" colorScheme="blue">
            Edit
          </Button>
        )}
      </PageHeader>
      <div className="p-5">
        <Card className="mb-4">
          {loading && !action ? (
            <Loader isActive />
          ) : (
            <>
              <Card.Header>
                <h2>{action?.name}</h2>
                <Badge color={action?.enabled ? 'green' : 'gray'}>{action?.enabled ? 'Enabled' : 'Disabled'}</Badge>
              </Card.Header>
              <Card.Content>
                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                  <div>
                    <strong>Action Type</strong>
                    <p>{action?.type}</p>
                  </div>
                  {action?.message ? (
                    <div className="col-start-1">
                      <strong>Message</strong>
                      <Link className="block underline text-blue-400" to={`/messages/${action?.message.id}/view`}>
                        {action?.message.name}
                      </Link>
                    </div>
                  ) : null}
                  {action?.channels ? (
                    <div>
                      <strong>Channels</strong>
                      <p>{action?.channels.join(', ')}</p>
                    </div>
                  ) : null}
                  {action?.voucherValue ? (
                    <div>
                      <strong>Voucher Value</strong>
                      <p>{`R ${formatPrice(action?.voucherValue)}`}</p>
                    </div>
                  ) : null}
                  {action?.voucherMessage ? (
                    <div>
                      <strong>Voucher Message</strong>
                      <p>{action?.voucherMessage}</p>
                    </div>
                  ) : null}
                  {action?.expiryDate ? (
                    <div>
                      <strong>Voucher Expiry</strong>
                      <p>{action?.expiryDate ? dayjs(action.expiryDate).format('DD-MM-YYYY') : null}</p>
                    </div>
                  ) : null}
                  <div>
                    <strong>Delay</strong>
                    <p>
                      {action?.delay?.value} {action?.delay?.unit}
                    </p>
                  </div>
                </div>
              </Card.Content>
            </>
          )}
        </Card>
        {action?.trigger ? <Trigger trigger={action.trigger} /> : null}
      </div>
    </div>
  )
}

ViewAction.query = gql`
  query Action($id: ID!) {
    action(id: $id) {
      id
      name
      type
      enabled
      message {
        id
        name
      }
      channels
      createdAt
      updatedAt
      voucherValue
      voucherMessage
      expiryDate
      delay {
        unit
        value
      }
      trigger {
        ...TriggerFields
      }
    }
  }
  mutation UpdateAction($id: ID!, $input: UpdateActionInput!) {
    updateAction(id: $id, input: $input) {
      id
    }
  }
`
