import dayjs from 'dayjs'
import { DeepMap } from 'react-hook-form'
import { Image } from 'common/types'

function formatDate(date: Date, format = 'DD-MM-YYYY'): string | null {
  return date ? dayjs(date).format(format) : null
}

function getDirtyFieldValues<T>(data: T, dirtyFields: DeepMap<T, true>): Partial<T> {
  return Object.keys(dirtyFields).reduce((acc, key) => {
    const mappedKey = key as keyof T
    if (dirtyFields[mappedKey]) {
      Object.assign(acc, { [key]: data[mappedKey] })
    }
    return acc
  }, {})
}

function convertToYesNo(value: boolean): string {
  return value ? 'Yes' : 'No'
}

export function formatPrice(value: number | string): string {
  const val = typeof value === 'string' ? parseFloat(value) : value
  const output = new Intl.NumberFormat('en-za', {
    style: 'currency',
    currency: 'ZAR'
  }).format(val)
  return output
}

const getImageUrl = (
  image: Image | undefined | null,
  width: number | undefined | null,
  quality = 80
): string | undefined => {
  if (!image) return undefined
  if (!width) {
    width = 1200
  }
  const { sourceType, url } = image
  if (!url) return url
  if (!width) return url
  if (sourceType?.toLowerCase() === 'shopify') {
    const roundedWidth = Math.round(width)
    const imageUrl = new URL(encodeURI(url))
    imageUrl.searchParams.set('width', `${roundedWidth}`)
    return imageUrl.href
  }

  if (sourceType?.toLowerCase() === 's3') {
    const roundedWidth = Math.round(width / 10) * 10
    const imageUrl = new URL(encodeURI(url))
    imageUrl.searchParams.set('w', `${roundedWidth}`)
    return imageUrl.href
  }
  if (sourceType?.toLowerCase() === 'squarespace') {
    const roundedWidth = Math.round(width / 50) * 50
    const imageUrl = new URL(encodeURI(url))
    imageUrl.searchParams.set('format', `${roundedWidth}w`)
    return imageUrl.href
  }
  if (sourceType?.toLowerCase() === 'contentful') {
    const roundedWidth = Math.round(width)
    const imageUrl = new URL(encodeURI(url).replace('//', 'https://'))
    imageUrl.searchParams.set('w', `${roundedWidth}`)
    imageUrl.searchParams.set('q', `${quality}`)
    return imageUrl.href
  }

  return url
}

export { formatDate, getDirtyFieldValues, convertToYesNo, getImageUrl }
