import { gql } from '@apollo/client'
import { CheckIcon, EyeIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid'
import {
  Button,
  Dropdown,
  FormField,
  FormLabel,
  MultiSelectWithSearch,
  Popover,
  Radio,
  Table
} from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import cn from 'classnames'
import { Direction } from 'common/enums'
import { PaginatedEntity } from 'common/types'
import DebouncedSearchInput from 'components/DebouncedSearchInput'
import FilterWrapper from 'components/FilterWrapper'
import { PageHeader } from 'components/PageHeader'
import { Pagination } from 'components/Pagination'
import dayjs from 'dayjs'
import { CampaignType, MessageCampaignDto, SortDirection, useAllAccountGroupsQuery } from 'generated'
import { useDeleteModal } from 'hooks/useDeleteModal'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { isArray } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import DeleteMessageCampaignModal from './DeleteMessageCampaignModal'

type MessageCampaignsResult<T = MessageCampaignDto> = {
  messageCampaigns: PaginatedEntity<T>
}

const TABLE_SHAPE = [
  { label: 'Name', key: 'name', isSortable: true },
  { label: 'Sent At', key: 'sentAt', isSortable: true },
  { label: 'Next Send At', key: 'nextRunAt', isSortable: true },
  { label: 'Message', key: 'message', isSortable: false },
  { label: 'Type', key: 'type', isSortable: false },
  { label: 'Created At', key: 'createdAt', isSortable: true },
  { label: 'Actions', key: '', isSortable: false }
]

const dropdownItemStyle = 'block w-full text-left px-5 py-2 hover:bg-gray-100 text-sm rounded-sm cursor-pointer'

const sortItems = [
  { key: 'scheduled_asc', label: 'Scheduled Date: Ascending', value: 'scheduledAt', direction: Direction.ASC },
  { key: 'scheduled_desc', label: 'Scheduled Date: Descending', value: 'scheduledAt', direction: Direction.DESC },
  { key: 'sent_asc', label: 'Sent Date: Ascending', value: 'sentAt', direction: Direction.ASC },
  { key: 'sent_desc', label: 'Sent Date: Descending', value: 'sentAt', direction: Direction.DESC }
]

const buildFilter = (filter: any) => {
  const f: any = {}

  if (filter.type) {
    f.type = { eq: filter.type }
  }
  if (filter.name) {
    f.name = { iLike: `%${filter.name}%` }
  }
  if (filter.message) {
    f.message = { or: [{ name: { iLike: `%${filter.message}%` } }, { title: { iLike: `%${filter.message}%` } }] }
  }
  if (filter.startSentDate && filter.endSentDate) {
    f.sentAt = { between: { lower: filter.startSentDate, upper: filter.endSentDate } }
  }
  if (filter.startScheduledDate && filter.endScheduledDate) {
    f.scheduledAt = { between: { lower: filter.startScheduledDate, upper: filter.endScheduledDate } }
  }
  if ('sent' in filter) {
    if (filter.sent) {
      f.sentAt = { isNot: null }
    } else {
      f.sentAt = { is: null }
    }
  }
  if (filter.accountGroups) {
    if (isArray(filter.accountGroups) && filter.accountGroups.length > 0) {
      f.accountGroups = { or: filter.accountGroups.map((accountGroup: string) => ({ id: { eq: accountGroup } })) }
    }
  }

  return f
}

export default function MessageCampaginsTable(props: RouteComponentProps): JSX.Element {
  const { query, pagination, sort, filtering } = usePaginatedQuery<MessageCampaignsResult, MessageCampaignDto>({
    query: MessageCampaginsTable.query,
    accessor: 'messageCampaigns',
    options: {},
    persist: true,
    limit: 20,
    buildFilter,
    defaultSort: { sortField: 'createdAt', direction: SortDirection.Desc }
  })
  const { data: accountGroupsData } = useAllAccountGroupsQuery()

  const accountGroupsOptions = useMemo(() => {
    if (accountGroupsData?.allAccountGroups) {
      return accountGroupsData.allAccountGroups.map(({ id, name }) => ({ key: id, value: id, label: name }))
    }
  }, [accountGroupsData])

  const deleteModalProps = useDeleteModal()
  const { refetch, data } = query
  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback, setSort, removeSort] = sort
  const { filter, setFilter, removeFilter } = filtering

  const filterByTypeRef = useRef<Record<CampaignType, Record<string, any>>>({
    [CampaignType.Once]: { type: CampaignType.Once, sent: false },
    [CampaignType.Recurring]: { type: CampaignType.Recurring }
  })
  const [focusedSentDatesInput, setFocusedSentDatesInput] = useState<FocusedInputShape | null>(null)
  const [focusedScheduledDatesInput, setFocusedScheduledDatesInput] = useState<FocusedInputShape | null>(null)

  const removeMultipleFilter = () => {
    removeFilter('startSentDate')
    removeFilter('endSentDate')
    removeFilter('startScheduledDate')
    removeFilter('endScheduledDate')
    removeFilter('type')
    removeFilter('sent')
    removeFilter('accountGroups')
  }

  const onFilterAccountGroups = (value: string[]) => {
    if (value.length === 0) {
      removeFilter('accountGroups', undefined, { isArray: true })
    } else {
      setFilter('accountGroups', value)
    }
  }

  const customSort = (name: string, direction: Direction) => () => {
    setSort(name, direction)
  }

  const goTo = (path: string) => () => {
    props.navigate?.(path)
  }
  const openDelete = (id: string, name: string) => () => {
    deleteModalProps.openModal(id, name)
  }

  useEffect(() => {
    if (filter.type) {
      filterByTypeRef.current[filter.type as CampaignType] = filter
    }
  }, [filter])
  const isMultipleFilter = Object.keys(filter).length > 0
  return (
    <div>
      <DeleteMessageCampaignModal
        {...deleteModalProps}
        onDelete={() => {
          deleteModalProps.onClose()
          refetch()
        }}
      />
      <PageHeader>
        <h1 className="text-lg">Message Campaigns</h1>
        <Button variant="plain" onClick={goTo('create')}>
          Create <PlusIcon className="w-4 h-4" />
        </Button>
      </PageHeader>
      <div className="bg-white w-full flex py-2 px-5 justify-between">
        <div className="flex space-x-2">
          <DebouncedSearchInput
            onRemoveFilter={() => removeFilter('name')}
            as="input"
            variant="outline"
            width="md"
            id="name"
            name="name"
            onChange={(e) => setFilter('name', e.target.value)}
            value={filter.name ?? ''}
            placeholder="Search by name"
            className="mr-5"
          />
          <DebouncedSearchInput
            onRemoveFilter={() => removeFilter('message')}
            as="input"
            variant="outline"
            width="md"
            id="message"
            name="message"
            onChange={(e) => setFilter('message', e.target.value)}
            value={filter.message ?? ''}
            placeholder="Search by message"
            className="mr-5"
          />
        </div>
        <div className="flex space-x-2">
          <FilterWrapper canRemoveFilter={isMultipleFilter} removeFilterAction={removeMultipleFilter}>
            <Popover>
              <Popover.Button>Filters</Popover.Button>
              <Popover.Panel>
                <div className="py-2 px-4 space-y-5">
                  <div>
                    <p className="font-medium text-sm mb-1">Campaign Type</p>
                    <FormField direction="row" className="mr-2">
                      <Radio
                        id="onceOff"
                        name="campaignType"
                        aria-label="OnceOff"
                        onClick={() => setFilter('type', CampaignType.Once)}
                        checked={filter?.type === CampaignType.Once}
                      />
                      <FormLabel htmlFor="onceOff">Once Off</FormLabel>
                    </FormField>
                    <FormField direction="row">
                      <Radio
                        id="recurring"
                        name="campaignType"
                        aria-label="Recurring"
                        onClick={() => setFilter('type', CampaignType.Recurring)}
                        checked={filter?.type === CampaignType.Recurring}
                      />
                      <FormLabel htmlFor="recurring">Recurring</FormLabel>
                    </FormField>
                  </div>
                  <div>
                    <p className="font-medium text-sm mb-1">Sent / Not Sent</p>
                    <FormField direction="row" className="mr-2">
                      <Radio
                        id="sent"
                        name="sentFilter"
                        aria-label="Sent"
                        onClick={() => setFilter('sent', true)}
                        checked={filter?.sent === true}
                      />
                      <FormLabel htmlFor="sent">Sent</FormLabel>
                    </FormField>
                    <FormField direction="row">
                      <Radio
                        id="notSent"
                        name="sentFilter"
                        aria-label="Sent"
                        onClick={() => setFilter('sent', false)}
                        checked={filter?.sent === false}
                      />
                      <FormLabel htmlFor="notSent">Not Sent</FormLabel>
                    </FormField>
                  </div>
                  <div>
                    <p className="font-medium text-sm mb-1">Sent date range</p>
                    <DateRangePicker
                      startDate={filter.startSentDate}
                      startDateId={'startSentDate'}
                      endDate={filter.endSentDate}
                      endDateId={'endSentDate'}
                      onDatesChange={({ startDate, endDate }) => {
                        setFilter('startSentDate', startDate)
                        setFilter('endSentDate', endDate)
                      }}
                      focusedInput={focusedSentDatesInput}
                      onFocusChange={(focusedSentDatesInput) => setFocusedSentDatesInput(focusedSentDatesInput)}
                      small
                      block
                      isOutsideRange={() => false}
                    />
                  </div>
                  <div>
                    <p className="font-medium text-sm mb-1">Scheduled date range</p>
                    <DateRangePicker
                      startDate={filter.startScheduledDate}
                      startDateId={'startScheduledDate'}
                      endDate={filter.endScheduledDate}
                      endDateId={'endScheduledDate'}
                      onDatesChange={({ startDate, endDate }) => {
                        setFilter('startScheduledDate', startDate)
                        setFilter('endScheduledDate', endDate)
                      }}
                      focusedInput={focusedScheduledDatesInput}
                      onFocusChange={(focusedScheduledDatesInput) =>
                        setFocusedScheduledDatesInput(focusedScheduledDatesInput)
                      }
                      small
                      block
                      isOutsideRange={() => false}
                    />
                  </div>
                  <div>
                    <p className="font-medium text-sm mb-1">Audience Segments</p>
                    {accountGroupsOptions && accountGroupsOptions.length > 0 ? (
                      <MultiSelectWithSearch
                        key={filter?.accountGroups ? 'accountGroups' : 'noAccountGroups'}
                        items={accountGroupsOptions}
                        value={filter?.accountGroups || []}
                        onChange={onFilterAccountGroups}
                      />
                    ) : null}
                  </div>
                </div>
              </Popover.Panel>
            </Popover>
          </FilterWrapper>
          <FilterWrapper canRemoveFilter={!!sortField} removeFilterAction={removeSort}>
            <Dropdown>
              <Dropdown.Button>Sort By</Dropdown.Button>
              <Dropdown.Items className="z-10">
                {sortItems.map((item) => (
                  <Dropdown.Item>
                    <button
                      key={item.key}
                      className={cn(dropdownItemStyle, 'flex justify-between items-center')}
                      onClick={customSort(item.value, item.direction)}
                    >
                      {item.label}
                      {sortField === item.value && direction?.value === item.direction ? (
                        <CheckIcon className="h-4 w-4 text-green-600" />
                      ) : null}
                    </button>
                  </Dropdown.Item>
                ))}
              </Dropdown.Items>
            </Dropdown>
          </FilterWrapper>
        </div>
      </div>
      <Table
        shape={TABLE_SHAPE}
        sortCallback={sortCallback}
        activeSort={sortField}
        sortDirection={direction?.toLowerCase()}
      >
        {data?.messageCampaigns.edges.map(({ node: campaign }) => {
          return (
            <Table.Row key={campaign.id}>
              <Table.Cell>{campaign.name}</Table.Cell>
              <Table.Cell>{campaign.sentAt ? dayjs(campaign.sentAt).format('DD/MM/YYYY HH:mm') : ''}</Table.Cell>
              <Table.Cell>{campaign.nextRunAt ? dayjs(campaign.nextRunAt).format('DD/MM/YYYY HH:mm') : ''}</Table.Cell>
              <Table.Cell>{campaign.message?.name ?? ''}</Table.Cell>
              <Table.Cell>{campaign.type}</Table.Cell>
              <Table.Cell>{dayjs(campaign.createdAt).format('DD/MM/YYYY')}</Table.Cell>

              <Table.Cell className="flex gap-2">
                <EyeIcon className={styles.icon} onClick={goTo(`${campaign.id}/view`)} />
                <PencilIcon className={styles.icon} onClick={goTo(`${campaign.id}/edit`)} />
                <TrashIcon
                  className={cn(styles.icon, 'text-red-500')}
                  onClick={openDelete(campaign.id, campaign.name)}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table>
      <Pagination
        hasPreviousPage={data?.messageCampaigns.pageInfo.hasPreviousPage}
        hasNextPage={data?.messageCampaigns.pageInfo.hasNextPage}
        onNext={nextPage}
        onPrev={prevPage}
        page={page}
      />
    </div>
  )
}

const styles = {
  icon: 'hover:text-gray-400 w-5 h-5 cursor-pointer',
  tab:
    'w-full border-b-2 text-blue-600 bg-white py-2.5 text-sm leading-5 font-medium focus:outline-none hover:border-blue-500',
  tabList: 'flex rounded-xl w-full justify-center',
  tabUnselected: 'border-transparent',
  tabSelected: 'border-blue-500'
}

MessageCampaginsTable.query = gql`
  query MessageCampaigns(
    $paging: CursorPaging
    $filter: MessageCampaignDTOFilter
    $sorting: [MessageCampaignDTOSort!]
  ) {
    messageCampaigns(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          id
          name
          createdAt
          sentAt
          scheduledAt
          nextRunAt
          interval
          type
          message {
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`
