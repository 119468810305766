import { gql } from '@apollo/client'
import { CheckIcon } from '@heroicons/react/solid'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dropdown,
  FormField,
  FormLabel,
  MultiSelectWithSearch,
  Popover,
  Radio,
  SelectWithSearch,
  Table
} from '@plusplusminus/plusplusdash'
import { RouteComponentProps, WindowLocation } from '@reach/router'
import cn from 'classnames'
import { Direction, TagTypes } from 'common/enums'
import { GraphqlOperations } from 'common/types'
import DebouncedSearchInput from 'components/DebouncedSearchInput'
import FilterWrapper from 'components/FilterWrapper'
import { FullPageLoader } from 'components/FullPageLoader'
import { ProductStats } from 'components/ProductStats/ProductStats'
import { useAddTagsToProductsMutation, useRemoveTagsFromProductsMutation } from 'generated'
import { useAllBrandsQuery } from 'hooks/brands'
import { buildProductsFilter, useProductIdsQuery, useProductsQuery, useUpdateProductsMutation } from 'hooks/products'
import { useAllTags } from 'hooks/tags'
import { useBatch } from 'hooks/useBatch'
import { SyntheticEvent, useState } from 'react'
import toast from 'react-hot-toast'
import { getImageUrl } from 'utils'
import { buildTagBadges, buildTagSelectOptions } from 'utils/tags'
import { Pagination } from '../../components/Pagination'

const TABLE_SHAPE = [
  { label: '', key: '', isSortable: false },
  { label: 'Title', key: 'title', isSortable: true },
  { label: 'Brand', key: 'brand', isSortable: false },
  { label: 'Enabled', key: 'enabled', isSortable: true },
  { label: 'Tags', key: 'tags', isSortable: false }
]

const dropdownItemStyle = 'block w-full text-left px-5 py-2 hover:bg-gray-100 text-sm rounded-sm cursor-pointer'

const sortItems = [
  { key: 'created_asc', label: 'Created Date: Ascending', value: 'createdAt', direction: Direction.ASC },
  { key: 'created_desc', label: 'Created Date: Descending', value: 'createdAt', direction: Direction.DESC },
  { key: 'updated_asc', label: 'Updated Date: Ascending', value: 'updatedAt', direction: Direction.ASC },
  { key: 'updated_desc', label: 'Updated Date: Descending', value: 'updatedAt', direction: Direction.DESC }
]

export const Products: React.FC<RouteComponentProps<{ location: WindowLocation<any> }>> & GraphqlOperations = () => {
  const [tagsToAdd, setTagsToAdd] = useState<string[]>([])
  const [tagsToRemove, setTagsToRemove] = useState<string[]>([])
  const [isSelectAll, setIsSelectAll] = useState(false)

  const { query, pagination, sort, filtering } = useProductsQuery({ persist: true })
  const { refetch, loading, error, data } = query
  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback, setSort, removeSort] = sort
  const { filter, setFilter, removeFilter } = filtering

  const [selectedIds, onClickItem, contains, resetSelected, setSelected] = useBatch()

  const [getProductIds] = useProductIdsQuery({
    onCompleted: (data) => {
      if (data) {
        const ids = data.allProducts.map(({ id }) => id)
        setSelected(ids)
        setIsSelectAll(true)
      }
    }
  })
  const { data: brandsData, loading: loadingBrands } = useAllBrandsQuery()
  const brandSelectOptions = brandsData
    ? brandsData.allBrands.map(({ brandName, id }) => ({ label: brandName, key: id, value: id }))
    : []

  const reset = async () => {
    await refetch()
    resetSelected()
    resetTagsToRemove()
    resetTagsToAdd()
    setIsSelectAll(false)
  }

  const { updateProducts, loading: loadingUpdateProducts } = useUpdateProductsMutation({
    onCompleted: () => {
      toast.success('Successfully set updated products')
      reset()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update products')
    }
  })
  const [addTagsToProducts] = useAddTagsToProductsMutation({
    onCompleted: () => {
      reset()
      toast.success('Tags added to selected products')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not add tags')
    }
  })
  const [removeTagsFromProducts] = useRemoveTagsFromProductsMutation({
    onCompleted: () => {
      reset()
      toast.success('Tags removed from selected products')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not remove tags')
    }
  })
  const [tags] = useAllTags(TagTypes.Product)

  const selectedBrand = filter.brand ? brandsData?.allBrands.find((brand) => filter.brand === brand.id) : null

  const tagSelectOptions = selectedBrand
    ? [
        {
          heading: `${selectedBrand.brandName} Tags`,
          items: buildTagSelectOptions(selectedBrand.tags)
        },
        {
          heading: 'Product Tags',
          items: buildTagSelectOptions(tags)
        }
      ]
    : buildTagSelectOptions(tags)

  function onClickCheckbox(productId: string) {
    return (e: SyntheticEvent) => {
      e.stopPropagation()
      onClickItem(productId)
      resetTagsToRemove()
    }
  }

  function onClickRow(productId: string) {
    return () => {
      // navigate(`/products/${productId}`)
      window.open(`${window.origin}/products/${productId}`, '_blank', 'noreferrer')
    }
  }

  const customSort = (name: string, direction: Direction) => () => {
    setSort(name, direction)
  }

  const onFilterMultiSelect = (key: string) => (value: string[]) => {
    if (value.length === 0) {
      removeFilter(key, undefined, { isArray: true })
    } else {
      setFilter(key, value)
    }
  }

  const tagProducts = () => {
    addTagsToProducts({ variables: { input: { productIds: selectedIds, tagIds: tagsToAdd } } })
  }

  const removeProductTags = () => {
    removeTagsFromProducts({ variables: { input: { productIds: selectedIds, tagIds: tagsToRemove } } })
  }

  const resetTagsToRemove = () => {
    setTagsToRemove([])
  }
  const resetTagsToAdd = () => {
    setTagsToAdd([])
  }

  const tableShape = [
    {
      label: 'Select All',
      key: 'select-all',
      isSortable: false,
      render: () => (
        <Checkbox
          id="selectAll"
          name="selectAll"
          checked={isSelectAll}
          onChange={(e) => {
            if ((e.target as HTMLInputElement).checked) {
              const builtFilter = buildProductsFilter(filter)
              getProductIds({ variables: { filter: builtFilter } })
            } else {
              resetSelected()
              setIsSelectAll(false)
            }
          }}
        />
      )
    },
    ...TABLE_SHAPE
  ]

  const removeMultipleFilter = () => {
    removeFilter('tags', undefined, { isArray: true })
    removeFilter('enabled')
    removeFilter('status')
  }

  const isMultipleFilter = filter.status || typeof filter.enabled !== 'undefined' || filter.tags
  return (
    <Box className="flex flex-col min-h-screen">
      <Box className="flex p-5 justify-between border-b border-gray-200 bg-white">
        <h1 className="text-lg">Products</h1>
      </Box>
      {error ? (
        <Alert type="error">
          <Alert.Heading>There was an error fetching products</Alert.Heading>
        </Alert>
      ) : (
        <Box className="flex-grow flex flex-col">
          <div className="px-5 py-2 flex items-center justify-between bg-white">
            <div className="flex gap-5">
              <DebouncedSearchInput
                onRemoveFilter={() => removeFilter('title')}
                as="input"
                variant="outline"
                width="md"
                placeholder="Search by product name"
                onChange={(e) => setFilter('title', e.target.value)}
                value={filter?.title || ''}
              />
              <DebouncedSearchInput
                onRemoveFilter={() => removeFilter('brand.name')}
                as="input"
                variant="outline"
                width="md"
                placeholder="Search by brand name"
                onChange={(e) => setFilter('brand.name', e.target.value)}
                value={filter?.['brand.name'] || ''}
              />
            </div>
            <div className="flex gap-2">
              <FilterWrapper canRemoveFilter={!!filter.brand} removeFilterAction={() => removeFilter('brand')}>
                <Popover>
                  <Popover.Button>
                    {filter.brand ? brandsData?.allBrands.find((b) => b.id === filter.brand)?.brandName : 'Brands'}
                  </Popover.Button>
                  {!loadingBrands && (
                    <Popover.Panel>
                      <div className="py-2 px-4">
                        <p className="text-sm mb-2 font-medium">Select a brand to filter by</p>
                        <SelectWithSearch
                          items={brandSelectOptions}
                          value={filter.brand}
                          onChange={(value) => setFilter('brand', value)}
                        />
                      </div>
                    </Popover.Panel>
                  )}
                </Popover>
              </FilterWrapper>
              <FilterWrapper canRemoveFilter={isMultipleFilter} removeFilterAction={removeMultipleFilter}>
                <Popover>
                  <Popover.Button>Other Filters</Popover.Button>
                  <Popover.Panel>
                    <div className="pb-4 px-4">
                      <div className="mb-2">
                        <p className="font-medium text-sm mb-1">Shopify Status</p>
                        <FormField direction="row" className="mr-2">
                          <FormLabel htmlFor="shopify">
                            <Radio
                              id="shopifyStatus"
                              name="shopify"
                              aria-label="Active"
                              onClick={() => setFilter('status', 'active')}
                              checked={filter?.status === 'active'}
                            />
                            Active
                          </FormLabel>
                        </FormField>
                        <FormField direction="row">
                          <Radio
                            id="shopifyStatus"
                            name="shopify"
                            aria-label="Draft"
                            onClick={() => setFilter('status', 'draft')}
                            checked={filter?.status === 'draft'}
                          />
                          <FormLabel htmlFor="disabled">Draft</FormLabel>
                        </FormField>
                        <FormField direction="row">
                          <Radio
                            id="shopifyStatus"
                            name="shopify"
                            aria-label="Archived"
                            onClick={() => setFilter('status', 'archived')}
                            checked={filter?.status === 'archived'}
                          />
                          <FormLabel htmlFor="disabled">Archived</FormLabel>
                        </FormField>
                        <p className="font-medium text-sm mb-1">Product Status</p>
                        <FormField direction="row" className="mr-2">
                          <Radio
                            id="enabled"
                            name="enabledFilter"
                            aria-label="Enabled"
                            onClick={() => setFilter('enabled', true)}
                            checked={filter?.enabled === true}
                          />
                          <FormLabel htmlFor="enabled">Enabled</FormLabel>
                        </FormField>
                        <FormField direction="row">
                          <Radio
                            id="disabled"
                            name="enabledFilter"
                            aria-label="Enabled"
                            onClick={() => setFilter('enabled', false)}
                            checked={filter?.enabled === false}
                          />
                          <FormLabel htmlFor="disabled">Disabled</FormLabel>
                        </FormField>
                      </div>
                      {tagSelectOptions.length > 0 ? (
                        <div>
                          <p className="font-medium text-sm mb-1">Tags</p>
                          <MultiSelectWithSearch
                            key={filter?.tags ? 'tags' : 'notags'}
                            items={tagSelectOptions}
                            value={filter?.tags || []}
                            onChange={onFilterMultiSelect('tags')}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Popover.Panel>
                </Popover>
              </FilterWrapper>
              <FilterWrapper canRemoveFilter={!!sortField} removeFilterAction={removeSort}>
                <Dropdown>
                  <Dropdown.Button>Sort By</Dropdown.Button>
                  <Dropdown.Items className="z-10">
                    {sortItems.map((item) => (
                      <Dropdown.Item>
                        <button
                          key={item.key}
                          className={cn(dropdownItemStyle, 'flex justify-between items-center')}
                          onClick={customSort(item.value, item.direction)}
                        >
                          {item.label}
                          {sortField === item.value && direction?.value === item.direction ? (
                            <CheckIcon className="h-4 w-4 text-green-600" />
                          ) : null}
                        </button>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Items>
                </Dropdown>
              </FilterWrapper>
            </div>
          </div>
          {selectedIds.length > 0 ? (
            <div className="bg-gray-50 w-full flex gap-2 py-2 px-5 justify-between items-center">
              <p className="text-sm">{selectedIds.length} products selected</p>
              <div className="flex gap-2">
                <Popover>
                  <Popover.Button>Remove Tags</Popover.Button>
                  <Popover.Panel>
                    <div className="px-5 py-2">
                      <p className="text-sm font-medium mb-2">Tags to remove</p>
                      <MultiSelectWithSearch
                        items={tagSelectOptions}
                        value={tagsToRemove}
                        onChange={(value) => {
                          setTagsToRemove(value)
                        }}
                      />
                      {tagsToRemove.length > 0 ? (
                        <Button
                          variant="primary"
                          colorScheme="green"
                          className="mt-2 float-right"
                          onClick={removeProductTags}
                        >
                          Save
                        </Button>
                      ) : null}
                    </div>
                  </Popover.Panel>
                </Popover>
                <Popover>
                  <Popover.Button>Add Tags</Popover.Button>
                  <Popover.Panel>
                    <div className="px-5 py-2">
                      <p className="text-sm font-medium mb-2">Tags to add</p>
                      <MultiSelectWithSearch
                        items={tagSelectOptions}
                        value={tagsToAdd}
                        onChange={(value) => setTagsToAdd(value)}
                      />
                      {tagsToAdd.length > 0 ? (
                        <Button
                          variant="primary"
                          colorScheme="green"
                          className="mt-2 float-right"
                          onClick={tagProducts}
                        >
                          Save
                        </Button>
                      ) : null}
                    </div>
                  </Popover.Panel>
                </Popover>
                <Dropdown>
                  <Dropdown.Button>Enabled</Dropdown.Button>
                  <Dropdown.Items>
                    <Dropdown.Item>
                      <button
                        className={dropdownItemStyle}
                        onClick={() => updateProducts(selectedIds, { enabled: true })}
                        disabled={selectedIds.length === 0 || loadingUpdateProducts}
                      >
                        Enable
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button
                        className={dropdownItemStyle}
                        onClick={() => updateProducts(selectedIds, { enabled: false })}
                        disabled={selectedIds.length === 0 || loadingUpdateProducts}
                      >
                        Disable
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Items>
                </Dropdown>
              </div>
            </div>
          ) : null}
          {!loading && data ? (
            <>
              <Table
                shape={tableShape}
                sortCallback={sortCallback}
                activeSort={sortField}
                sortDirection={direction?.toLowerCase()}
                className="table-fixed"
              >
                {data.products.edges.map(({ node: product }) => {
                  const imageUrl = getImageUrl(product?.image, 160)

                  return (
                    <Table.Row
                      key={product.id}
                      className={cn('cursor-pointer hover:bg-blue-50 border-b border-gray-100', {
                        'bg-blue-100': contains(product.id)
                      })}
                      onClick={onClickRow(product.id)}
                    >
                      <Table.Cell className="w-6" onClick={onClickCheckbox(product.id)}>
                        <Checkbox id={product.id} checked={contains(product.id)} />
                      </Table.Cell>
                      <Table.Cell className="w-32" style={{ paddingRight: 0, paddingLeft: 0 }}>
                        {imageUrl ? <img className="w-32 h-32 object-cover" src={imageUrl} /> : null}
                      </Table.Cell>
                      <Table.Cell className="font-medium text-gray-900 w-96 whitespace-pre-wrap">
                        {product.productTitle}
                      </Table.Cell>
                      <Table.Cell className="font-medium text-gray-900">{product.brand.brandName}</Table.Cell>
                      <Table.Cell>{product.enabled ? 'Yes' : 'No'}</Table.Cell>
                      <Table.Cell className="max-w-64 overflow-hidden">
                        <div className="inline">{buildTagBadges(product.tags?.slice(0, 3))}</div>
                        {product.tags?.length && product.tags.length > 3 ? (
                          <p className="text-xs text-gray-400 ml-2 inline">{product.tags.length - 3} others</p>
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table>
              <Pagination
                hasPreviousPage={data.products.pageInfo.hasPreviousPage}
                hasNextPage={data.products.pageInfo.hasNextPage}
                onNext={nextPage}
                onPrev={prevPage}
                page={page}
              />
            </>
          ) : (
            <FullPageLoader isActive />
          )}
        </Box>
      )}
    </Box>
  )
}

Products.mutations = {
  removeTags: gql`
    mutation RemoveTagsFromProducts($input: RemoveTagsFromProducts!) {
      removeTagsFromProducts(input: $input)
    }
  `,
  addTags: gql`
    mutation AddTagsToProducts($input: AddTagsToProducts!) {
      addTagsToProducts(input: $input)
    }
  `
}
