import { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { FormLabel } from '@plusplusminus/plusplusdash'
import cn from 'classnames'

export const styles = {
  label: `block text-sm font-medium text-gray-700 mb-1`,
  button: `flex flex-wrap bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`,
  menuContainer: `absolute mt-1 w-full rounded-md bg-white shadow-lg z-50`,
  menu: `max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm`,
  menuItem: `text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 cursor-pointer`
}

type SelectOption = {
  key: string
  label: string
  value: string
}
interface SelectProps {
  label?: string
  options: SelectOption[]
  value?: string
  onChange: (value: any) => void
  className?: string
}
const Select = ({ label, options, value, onChange, className }: SelectProps): JSX.Element => {
  const [selected, setSelected] = useState<SelectOption | undefined>(() =>
    value ? options.find(({ value: optionValue }) => optionValue === value) : undefined
  )
  const [query, setQuery] = useState('')
  const filteredItems =
    query === ''
      ? options
      : options.filter((option) =>
          option.label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        )
  return (
    <div className={className}>
      {label && <FormLabel className={styles.label}>{label}</FormLabel>}
      <Combobox
        value={selected}
        onChange={(value) => {
          setSelected(value)
          onChange(value?.value)
        }}
      >
        <div className="relative">
          <div className="relative mb-1">
            <Combobox.Input
              className="p-2 focus:border-indigo-500 block sm:text-sm outline-none rounded border-2 border-gray-200 w-full"
              displayValue={(item: SelectOption) => item?.label ?? ''}
              onChange={(event) => setQuery(event.target.value)}
              style={{ boxShadow: 'none' }}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <svg
                className="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 rounded-md w-full bg-white shadow-lg z-50 py-1 max-h-80 overflow-y-auto">
              {filteredItems.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
              ) : (
                filteredItems.map((option, optionIdx) => (
                  <Combobox.Option
                    key={option.key || optionIdx}
                    className={({ selected }) =>
                      cn(`text-gray-900 select-none relative py-2 pl-3 pr-9 cursor-pointer hover:bg-gray-100`, {
                        'bg-gray-200': selected
                      })
                    }
                    value={option}
                  >
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {option.label}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}

export default Select
