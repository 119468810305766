import { CheckIcon, XIcon } from '@heroicons/react/outline'
import { Modal, Table } from '@plusplusminus/plusplusdash'
import { Pagination } from 'components/Pagination'
import dayjs from 'dayjs'
import { TUsePaginatedQuery } from 'hooks/usePaginatedQuery'
import { useEffect, useState } from 'react'

import DebouncedSearchInput from 'components/DebouncedSearchInput'
import { EmailTemplatesCollection } from 'hooks/useEmailTemplates'
import { MessagesResult } from './MessagesTable'
import Button from 'components/Button'

const TABLE_SHAPE = [
  { label: '', key: 'selected', isSortable: false },
  { label: 'Name', key: 'name', isSortable: true },
  { label: 'Title', key: 'title', isSortable: false },
  { label: 'Create At', key: 'createdAt', isSortable: true },
  { label: 'Updated At', key: 'updatedAt', isSortable: true }
]

export type MessageType = {
  messageId?: string
  messageName?: string
}

interface SearchMessageProps {
  paginatedMessages: TUsePaginatedQuery<MessagesResult>
  emailTemplateCollection: EmailTemplatesCollection
  initialMessage?: MessageType
  onSelectedMessage: (message: MessageType | undefined) => void
  wrapperClassName?: string
  buttonLabel?: string
  showSelectionsTags?: boolean
  onModalOpen?: () => void
  onModalClose?: () => void
}
const SearchMessage = ({
  paginatedMessages,
  emailTemplateCollection,
  wrapperClassName,
  onSelectedMessage,
  initialMessage,
  buttonLabel = 'Search',
  showSelectionsTags = true,
  onModalOpen,
  onModalClose
}: SearchMessageProps): JSX.Element => {
  const [selectedMessage, setSelectedMessage] = useState<MessageType | undefined>(initialMessage)

  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => {
    setIsOpen(false)
    onModalClose?.()
  }
  const onOpen = () => {
    setIsOpen(true)
    onModalOpen?.()
  }

  const { query, pagination, filtering, sort } = paginatedMessages
  const [sortField, direction, sortCallback] = sort
  const { data, loading } = query
  const messageConnection = data?.messages
  const messages = messageConnection?.edges.map(({ node }) => node)
  const { page, nextPage, prevPage } = pagination
  const { filter, setFilter, removeFilter } = filtering

  const onRemoveSelection = () => {
    setSelectedMessage(undefined)
    onSelectedMessage(undefined)
  }
  const onAddToSelection = (id: string, name: string) => {
    setSelectedMessage({ messageId: id, messageName: name })
    onSelectedMessage({ messageId: id, messageName: name })
  }

  const onCancel = () => {
    setSelectedMessage(undefined)
    onClose()
  }

  const onApply = () => {
    setSelectedMessage(selectedMessage)
    onClose()
  }

  useEffect(() => {
    if (!initialMessage?.messageId) {
      setSelectedMessage(undefined)
    } else {
      if (initialMessage.messageId !== selectedMessage?.messageId) {
        setSelectedMessage(initialMessage)
      }
    }
  }, [initialMessage?.messageId])
  return (
    <>
      <div className={wrapperClassName}>
        <Button
          variant="plain"
          type="button"
          size="lg"
          colorScheme="black"
          isDisabled={loading}
          isLoading={loading}
          onClick={onOpen}
          className="w-full flex flex-wrap relative"
        >
          <span className="block truncate">
            {selectedMessage?.messageName ? selectedMessage.messageName : buttonLabel}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </Button>
        {showSelectionsTags && (
          <div className="mt-1">
            {selectedMessage?.messageName && (
              <button
                type="button"
                className="mr-1 mb-1 inline-flex cursor-pointer items-center rounded-sm bg-gray-200 px-1 py-1 text-xs"
                onClick={() => onRemoveSelection()}
              >
                {selectedMessage.messageName}&nbsp; <XIcon className="h-4 w-4" />
              </button>
            )}
          </div>
        )}
      </div>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <Modal.Title>Search Messages</Modal.Title>
        <div className="mt-4">
          <DebouncedSearchInput
            onRemoveFilter={() => removeFilter('search')}
            as="input"
            variant="outline"
            width="md"
            id="search"
            name="search"
            onChange={(e) => setFilter('search', e.target.value)}
            value={filter.search ?? ''}
            placeholder="Search by name / title"
          />
          {!loading && (
            <div className="mt-4">
              <Table
                shape={TABLE_SHAPE}
                sortCallback={sortCallback}
                activeSort={sortField}
                sortDirection={direction?.toLowerCase()}
              >
                {!messages || messages.length === 0 ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
                ) : (
                  messages?.map((message) => {
                    const { id, name, title, contentId, createdAt, updatedAt } = message
                    const selected = selectedMessage?.messageId === id
                    const isContentful = !!contentId
                    let contentfulMessageName
                    if (isContentful) {
                      contentfulMessageName = emailTemplateCollection.items.find((e) => e.sys.id === contentId)?.name
                    }
                    return (
                      <Table.Row
                        key={id}
                        className="relative cursor-pointer select-none py-2 pl-10 pr-4 text-gray-700 hover:bg-gray-100 hover:text-black"
                        onClick={() => {
                          if (selected) {
                            onRemoveSelection()
                          } else {
                            onAddToSelection(id, name)
                          }
                        }}
                      >
                        <Table.Cell>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-teal-600">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </Table.Cell>
                        <Table.Cell>{name}</Table.Cell>
                        <Table.Cell>{isContentful && contentfulMessageName ? contentfulMessageName : title}</Table.Cell>
                        <Table.Cell>{dayjs(createdAt).format('DD/MM/YYYY')}</Table.Cell>
                        <Table.Cell>{dayjs(updatedAt).format('DD/MM/YYYY')}</Table.Cell>
                      </Table.Row>
                    )
                  })
                )}
              </Table>
            </div>
          )}
        </div>
        <div className="mt-3">
          <Pagination
            hasPreviousPage={data?.messages.pageInfo.hasPreviousPage}
            hasNextPage={data?.messages.pageInfo.hasNextPage}
            onNext={nextPage}
            onPrev={prevPage}
            page={page}
          />
        </div>
        <div className="flex justify-center space-x-2 sm:justify-end">
          <Button variant="primary" type="button" size="md" colorScheme="red" className="mt-6" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            type="button"
            size="md"
            colorScheme="green"
            className="mt-6"
            isDisabled={!selectedMessage?.messageId}
            onClick={onApply}
          >
            Apply
          </Button>
        </div>
        <div className="mt-3">
          <p className="mt-4 mb-2 border-b pb-2 font-semibold">Selected Message</p>
          {selectedMessage?.messageName && (
            <button
              type="button"
              className="mr-1 mb-1 inline-flex cursor-pointer items-center rounded-sm bg-gray-200 px-1 py-1 text-xs"
              onClick={() => onRemoveSelection()}
            >
              {selectedMessage.messageName}&nbsp; <XIcon className="h-4 w-4" />
            </button>
          )}
        </div>
      </Modal>
    </>
  )
}

export default SearchMessage
