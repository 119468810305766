import { gql } from '@apollo/client'
import { EyeIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid'
import { Button, Table } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import classNames from 'classnames'
import { PageInfo } from 'common/types'
import DebouncedSearchInput from 'components/DebouncedSearchInput'
import { PageHeader } from 'components/PageHeader'
import { Pagination } from 'components/Pagination'
import dayjs from 'dayjs'
import { Trigger, TriggersQuery } from 'generated'
import { useDeleteModal } from 'hooks/useDeleteModal'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { convertToYesNo } from 'utils'
import DeleteTriggerModal from './DeleteTriggerModal'

type TriggersResult = {
  triggers: Omit<TriggersQuery['triggers'], 'pageInfo'> & { pageInfo: PageInfo }
}

const TABLE_SHAPE = [
  { label: 'Name', key: 'name', isSortable: true },
  { label: 'Enabled', key: 'enabled', isSortable: false },
  { label: 'Event Type', key: 'eventType', isSortable: false },
  { label: 'Created At', key: 'createdAt', isSortable: true },
  { label: 'Updated At', key: 'updatedAt', isSortable: true },
  { label: 'Actions', key: '', isSortable: false }
]

export default function TriggersTable(props: RouteComponentProps): JSX.Element {
  const deleteModalProps = useDeleteModal()

  const { query, pagination, sort, filtering } = usePaginatedQuery<TriggersResult, Partial<Trigger>>({
    query: TriggersTable.query,
    accessor: 'triggers',
    options: {},
    persist: true,
    limit: 20
  })

  const { refetch, data } = query
  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback] = sort
  const { filter, setFilter, removeFilter } = filtering

  const goTo = (path: string) => () => {
    props.navigate?.(path)
  }
  const openDelete = (id: string, name: string) => () => {
    deleteModalProps.openModal(id, name)
  }

  return (
    <div>
      <DeleteTriggerModal
        {...deleteModalProps}
        onDelete={() => {
          deleteModalProps.onClose()
          refetch()
        }}
      />
      <PageHeader>
        <h1 className="text-lg">Triggers</h1>
        <Button variant="plain" onClick={goTo('create')}>
          Create <PlusIcon className="w-4 h-4" />
        </Button>
      </PageHeader>
      <div className="bg-white w-full flex py-2 px-5 justify-between">
        <DebouncedSearchInput
          onRemoveFilter={() => removeFilter('name')}
          as="input"
          variant="outline"
          width="md"
          id="name"
          name="name"
          onChange={(e) => setFilter('name', e.target.value)}
          value={filter.name ?? ''}
          placeholder="Search by name"
        />
      </div>
      <Table
        shape={TABLE_SHAPE}
        sortCallback={sortCallback}
        activeSort={sortField}
        sortDirection={direction?.toLowerCase()}
      >
        {data?.triggers.edges.map(({ node: trigger }) => {
          return (
            <Table.Row key={trigger.id}>
              <Table.Cell>{trigger.name}</Table.Cell>
              <Table.Cell>{convertToYesNo(trigger.enabled)}</Table.Cell>
              <Table.Cell>{trigger.eventType}</Table.Cell>
              <Table.Cell>{dayjs(trigger.createdAt).format('DD-MM-YYYY')}</Table.Cell>
              <Table.Cell>{dayjs(trigger.updatedAt).format('DD-MM-YYYY')}</Table.Cell>
              <Table.Cell className="flex gap-2">
                <EyeIcon className={styles.icon} onClick={goTo(`${trigger.id}/view`)} />
                <PencilIcon className={styles.icon} onClick={goTo(`${trigger.id}/edit`)} />
                <TrashIcon
                  className={classNames(styles.icon, 'text-red-500')}
                  onClick={openDelete(trigger.id, trigger.name)}
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table>
      <Pagination
        hasPreviousPage={data?.triggers.pageInfo.hasPreviousPage}
        hasNextPage={data?.triggers.pageInfo.hasNextPage}
        onNext={nextPage}
        onPrev={prevPage}
        page={page}
      />
    </div>
  )
}

const styles = {
  icon: 'hover:text-gray-400 w-5 h-5 cursor-pointer'
}

TriggersTable.query = gql`
  query Triggers($paging: CursorPaging, $filter: TriggerFilter, $sorting: [TriggerSort!]) {
    triggers(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          id
          name
          enabled
          eventType
          createdAt
          updatedAt
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`
