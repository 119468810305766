import { BookmarkIcon, PencilIcon } from '@heroicons/react/solid'
import { Alert, Box, Button, Loader } from '@plusplusminus/plusplusdash'
import { useIndexProductMutation, useProductQueryQuery } from 'generated'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Page, Permission } from '../../common/types'
import { ProductEdit } from '../../components/Product/ProductEdit'
import { ProductView } from '../../components/Product/ProductView'
import { useUser } from '../../hooks/useUser'

interface ProductRouteProps {
  productId: string
}

export const Product: Page<ProductRouteProps> = (props) => {
  const { productId = '' } = props

  const [isEditing, setIsEditing] = useState(false)

  const { user: loggedInUser } = useUser()
  const { data, loading, refetch, error } = useProductQueryQuery({ variables: { id: productId as string } })
  const [indexProductMutation] = useIndexProductMutation({
    onCompleted: () => toast.success('Successfully indexed product.'),
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not index product')
    }
  })

  const indexProduct = () => indexProductMutation({ variables: { id: productId } })

  if (error?.graphQLErrors) {
    return (
      <Alert type="error">
        <Alert.Heading>Error fetching product</Alert.Heading>
        <Alert.Description>An unexpected error occured when fetching the specified product</Alert.Description>
      </Alert>
    )
  }

  if (!productId) return null

  if (loading) {
    return <Loader isActive />
  }

  if (!data || !data.product) {
    return null
  }

  const { product } = data

  return (
    <Box>
      <Box className="flex justify-between p-5 border-b border-gray-300 items-center bg-white">
        <Box>
          <h3 className="text-lg leading-6 font-medium text-gray-900">Product Information</h3>
        </Box>
        <div className="flex gap-2">
          <Button variant="plain" onClick={indexProduct} className="flex gap-1 items-center">
            <span>Index Product</span>
            <BookmarkIcon className="h-4 w-4" />
          </Button>
          {!isEditing ? (
            <Button key="edit-product-button" variant="primary" colorScheme="blue" onClick={() => setIsEditing(true)}>
              <span className="flex justify-between">
                <span className="mr-2">Edit</span>
                <PencilIcon className="w-4 h-4" />
              </span>
            </Button>
          ) : null}
        </div>
      </Box>
      {isEditing ? (
        <ProductEdit
          productId={productId}
          product={product}
          cancel={() => setIsEditing(false)}
          updateCallback={() => {
            refetch()
            setIsEditing(false)
          }}
        />
      ) : (
        <ProductView productId={productId} product={product} />
      )}
    </Box>
  )
}
