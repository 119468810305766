import { gql } from '@apollo/client'
import { MultiSelectWithSearch } from '@plusplusminus/plusplusdash'
import classNames from 'classnames'
import { Operator, useAllAccountGroupsQuery } from 'generated'
import { forwardRef, PropsWithRef, useMemo } from 'react'

type AccountGroupComboBoxProps = {
  accountGroupIds: string[]
  operator?: Operator
  onChangeAccountGroups: (value: string[]) => void
  onChangeOperator?: (operator: Operator) => void
  hasOperatorSelector?: boolean
}

function AccountGroupComboBox(props: PropsWithRef<AccountGroupComboBoxProps>) {
  const { hasOperatorSelector = true } = props
  const { data } = useAllAccountGroupsQuery()

  const selectItems = useMemo(() => {
    if (data?.allAccountGroups) {
      return data.allAccountGroups.map(({ id, name }) => ({ key: id, value: id, label: name }))
    }
  }, [data])

  const toggleOperator = (operator: Operator) => () => {
    props?.onChangeOperator?.(operator)
  }

  return selectItems && selectItems.length > 0 ? (
    <div className="flex items-start">
      {hasOperatorSelector ? (
        <>
          <span className={styles.buttonGroup}>
            <button
              type="button"
              className={classNames(styles.buttonLeft, { 'bg-blue-200': props.operator === Operator.And })}
              onClick={toggleOperator(Operator.And)}
            >
              AND
            </button>
            <button
              type="button"
              className={classNames(styles.buttonRight, { 'bg-blue-200': props.operator === Operator.Or })}
              onClick={toggleOperator(Operator.Or)}
            >
              OR
            </button>
          </span>
        </>
      ) : null}
      <div className={classNames({ 'ml-4': hasOperatorSelector }, { '-ml-1': !hasOperatorSelector }, 'inline-block')}>
        <MultiSelectWithSearch
          value={props.accountGroupIds}
          items={selectItems}
          onChange={props.onChangeAccountGroups}
        />
      </div>
    </div>
  ) : null
}

const styles = {
  buttonGroup: 'relative z-0 inline-flex shadow-sm rounded-md mb-2',
  buttonLeft:
    'relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none',
  buttonRight:
    '-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none'
}

export default forwardRef(AccountGroupComboBox)

AccountGroupComboBox.query = gql`
  query AllAccountGroups {
    allAccountGroups {
      id
      name
    }
  }
`
