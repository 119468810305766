import { gql, useMutation } from '@apollo/client'
import { RefreshIcon } from '@heroicons/react/solid'
import Button from 'components/Button'
import toast from 'react-hot-toast'

interface ReprocessInvoiceButtonProps {
  onRefetch?: () => void
  orderLineId: string
}
const ReprocessInvoiceButton = (props: ReprocessInvoiceButtonProps) => {
  const { onRefetch } = props
  const [reprocessInvoice, { loading }] = useMutation(REPROCESS_INVOICE, {
    variables: {
      id: props.orderLineId
    },
    onCompleted: () => {
      toast.success('Successfully reprocessed invoice')
      if (onRefetch) {
        onRefetch()
      }
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error reprocessing the invoice')
    }
  })

  return (
    <Button
      isDisabled={loading}
      key="edit-brand-button"
      variant="plain"
      colorScheme="blue"
      onClick={() =>
        reprocessInvoice({
          variables: { id: props.orderLineId }
        })
      }
      isLoading={loading}
    >
      <span className="w-36 flex items-center justify-center">
        <span className="mr-2">{loading ? <RefreshIcon className="animate-spin h-4 w-4" /> : 'Reprocess Invoice'}</span>
      </span>
    </Button>
  )
}

const REPROCESS_INVOICE = gql`
  mutation ReprocessInvoice($id: String!) {
    reprocessInvoice(id: $id)
  }
`

export default ReprocessInvoiceButton
