import { gql, MutationHookOptions, QueryHookOptions, QueryResult, useMutation, useQuery } from '@apollo/client'
import { UserStatus } from 'common/enums'
import { Account, PaginatedEntity } from 'common/types'
import { SortDirection } from 'generated'
import { ACCOUNT_FIELDS, PAGING_FIELDS } from 'graphql/fragments'
import { useCallback } from 'react'
import { TUsePaginatedQuery, usePaginatedQuery } from './usePaginatedQuery'

interface AccountResult {
  accounts: PaginatedEntity<Account>
}

type UserUpdate = {
  firstName?: string
  lastName?: string
  enabled?: boolean
  status?: UserStatus
  roles?: string[]
}

type CreateUserInput = {
  email: string
  firstName: string
  lastName: string
  password: string
}

const USER_QUERY = gql`
  ${ACCOUNT_FIELDS}
  query User($id: ID!, $firstTransactions: Int!, $after: String) {
    account(id: $id) {
      ...AccountFields
      customer {
        availableCredit
        transactions(first: $firstTransactions, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              id
              type
              amount
              createdAt
              order {
                id
                orderNumber
              }
              voucher {
                id
                redemptionDate
                updatedAt
                voucherNumber
              }
            }
          }
        }
      }
    }
  }
`

const USERS_QUERY = gql`
  ${ACCOUNT_FIELDS}
  ${PAGING_FIELDS}
  query UsersQuery($paging: CursorPaging, $filter: AccountFilter, $sorting: [AccountSort!]) {
    accounts(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...AccountFields
        }
      }
      pageInfo {
        ...PagingFields
      }
    }
  }
`

const CREATE_USER = gql`
  ${ACCOUNT_FIELDS}
  mutation CreateUser($input: CreateOneAccountInput!) {
    createOneAccount(input: $input) {
      ...AccountFields
    }
  }
`

const UPDATE_USER = gql`
  ${ACCOUNT_FIELDS}
  mutation UpdateUser($id: ID!, $update: AccountUpdateInput!) {
    updateOneAccount(input: { id: $id, update: $update }) {
      ...AccountFields
    }
  }
`

const UPDATE_USERS = gql`
  mutation UpdateUsers($ids: [String!]!, $update: AccountUpdateInput!) {
    updateManyAccounts(input: { filter: { id: { in: $ids } }, update: $update }) {
      updatedCount
    }
  }
`

const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteOneAccount(input: { id: $id }) {
      id
    }
  }
`

const DELETE_USERS = gql`
  mutation DeleteUsers($ids: [String!]!) {
    deleteManyAccounts(input: { filter: { id: { in: $ids } } }) {
      deletedCount
    }
  }
`
function useUserQuery(id: string): QueryResult<{ account: Account }> {
  const query = useQuery<{ account: Account }>(USER_QUERY, { variables: { id } })
  return query
}

function useUsers(args: { options?: QueryHookOptions; persist: boolean }): TUsePaginatedQuery<AccountResult> {
  const { options, persist = false } = args

  return usePaginatedQuery<AccountResult, Account>({
    query: USERS_QUERY,
    accessor: 'accounts',
    options,
    persist,
    defaultSort: { sortField: 'createdAt', direction: SortDirection.Desc }
  })
}

function useDeleteUsers(options?: MutationHookOptions): { deleteUsers: (ids: string[]) => void; loading: boolean } {
  const [deleteUsersMutation, { loading }] = useMutation(DELETE_USERS, options)

  const deleteUsers = useCallback(
    async (ids: string[]) => {
      try {
        await deleteUsersMutation({ variables: { ids } })
      } catch (e) {
        console.error(e)
      }
    },
    [deleteUsersMutation]
  )

  return { deleteUsers, loading }
}

type CreateAccountMutationInput = { input: { account: CreateUserInput } }

function useCreateUser(
  options?: MutationHookOptions<any, CreateAccountMutationInput>
): { createUser: (input: CreateUserInput) => void; loading: boolean } {
  const [createUserMutation, { loading }] = useMutation<any, CreateAccountMutationInput>(CREATE_USER, options)

  const createUser = useCallback(
    async (input: CreateUserInput) => {
      try {
        await createUserMutation({ variables: { input: { account: input } } })
      } catch (e) {
        console.error(e)
      }
    },
    [createUserMutation]
  )

  return { createUser, loading }
}

function useUpdateUsers(
  options?: MutationHookOptions
): {
  enableUsers: (ids: string[], enabled: boolean) => void
  updateUsersStatus: (ids: string[], status: UserStatus) => void
  loading: boolean
} {
  const [updateUsersMutation, { loading }] = useMutation(UPDATE_USERS, options)

  const enableUsers = useCallback(
    async (ids: string[], enabled: boolean) => {
      try {
        await updateUsersMutation({ variables: { ids, update: { enabled } } })
      } catch (e) {
        console.error(e)
      }
    },
    [updateUsersMutation]
  )

  const updateUsersStatus = useCallback(
    async (ids: string[], status: UserStatus) => {
      try {
        await updateUsersMutation({ variables: { ids, update: { status } } })
      } catch (e) {
        console.error(e)
      }
    },
    [updateUsersMutation]
  )

  return { enableUsers, updateUsersStatus, loading }
}

function useDeleteUser(): { deleteUser: (id: string) => void; loading: boolean } {
  const [deleteUserMutation, { loading }] = useMutation(DELETE_USER)

  const deleteUser = useCallback(
    async (id: string) => {
      try {
        await deleteUserMutation({ variables: { id } })
      } catch (e) {
        console.error(e)
      }
    },
    [deleteUserMutation]
  )

  return { deleteUser, loading }
}

function useUpdateUser(
  options?: MutationHookOptions
): { updateUser: (id: string, update: UserUpdate) => void; loading: boolean } {
  const [updateUserMutation, { loading }] = useMutation(UPDATE_USER, options)

  const updateUser = useCallback(
    async (id: string, update: UserUpdate) => {
      try {
        await updateUserMutation({ variables: { id, update } })
      } catch (e) {
        console.error(e)
      }
    },
    [updateUserMutation]
  )

  return { updateUser, loading }
}

export { useUserQuery, useUsers, useDeleteUsers, useUpdateUsers, useDeleteUser, useUpdateUser, useCreateUser }
export type { UserUpdate, CreateUserInput }
