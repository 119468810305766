import { Input, Modal } from '@plusplusminus/plusplusdash'
import Repeater from 'components/Repeater/Repeater'
import Button from 'components/Button'
import { BrandQuery, useUpdateBrandMutation } from 'generated'
import { isEqual } from 'lodash'
import { FocusEvent, useState } from 'react'
import toast from 'react-hot-toast'

interface EditEmailsProps {
  isOpen: boolean
  onClose: () => void
  brand: NonNullable<BrandQuery['brand']>
}

const EditEmails: React.FC<EditEmailsProps> = (props) => {
  const { isOpen, onClose, brand } = props
  const [emails, setEmails] = useState(brand.adminEmails)
  const [updateBrand, { loading }] = useUpdateBrandMutation({
    onCompleted: () => {
      toast.success('Successfully updated brand order emails')
      onClose()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update brand order emails')
    }
  })

  const onChange = (index: number) => (event: FocusEvent<HTMLInputElement>) => {
    setEmails([...emails.slice(0, index), event.target.value, ...emails.slice(index + 1)])
  }

  const renderItem = (item: string, index: number) => {
    return <Input variant="standard" defaultValue={emails[index]} onBlur={onChange(index)} />
  }
  const addEmail = () => {
    setEmails([...emails, ''])
  }
  const removeEmail = (index: number) => {
    setEmails([...emails.slice(0, index), ...emails.slice(index + 1)])
  }
  const onSubmit = () => {
    if (!isEqual(brand.adminEmails, emails)) {
      updateBrand({ variables: { id: brand.id, update: { adminEmails: emails } } })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <Modal.Title>Order Emails</Modal.Title>
      <Modal.Description className="my-4">
        <div className="mb-8">
          <Repeater data={emails} renderItem={renderItem} onAdd={addEmail} onRemove={removeEmail} />
        </div>
        <div className="flex justify-end gap-2">
          <Button variant="plain" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary" colorScheme="green" onClick={onSubmit} isDisabled={loading} isLoading={loading}>
            Save
          </Button>
        </div>
      </Modal.Description>
    </Modal>
  )
}

export default EditEmails
