import { gql } from '@apollo/client'
import Select from 'components/Select'

import { useAccountEventOptionsQuery } from 'generated'

type AccountEventSelectProps = {
  onChange: (value: string) => void
  value: string
}

export default function AccountEventSelect(props: AccountEventSelectProps): JSX.Element | null {
  const { data } = useAccountEventOptionsQuery()
  return data?.accountEventOptions?.length ? <Select options={data?.accountEventOptions} {...props} /> : null
}

AccountEventSelect.query = gql`
  query AccountEventOptions {
    accountEventOptions
  }
`
