import React from 'react'
import { Loader } from '@plusplusminus/plusplusdash'

interface FullPageLoaderProps {
  isActive: boolean
}

const FullPageLoader: React.FC<FullPageLoaderProps> = ({ isActive = true }) => (
  <div className="w-full h-full flex justify-center items-center flex-grow">
    <Loader isActive={isActive} />
  </div>
)

export { FullPageLoader }
