import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { offsetLimitPagination, relayStylePagination } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { useAuthToken } from '../hooks/useAuthToken'

const httpLink: any = createUploadLink({
  uri: process.env.REACT_APP_TLE_API,
  credentials: 'include'
})

const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      // You can log errors, send them to an error tracking service, etc.
    })
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    // Handle network errors here, such as retrying the request or alerting the user.
  }

  // If there's data along with errors, keep the data in the response
  if (response && response.errors && response.data) {
    response.errors = undefined // This ensures that the operation doesn't treat the response as an error.
  }
})

const authMiddleware = (jwtToken: string) =>
  new ApolloLink((operation, forward) => {
    if (jwtToken) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${jwtToken}`
        }
      })
    }
    return forward(operation)
  })

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        vouchers: {
          keyArgs: ['id'],
          merge: false
        }
      }
    },
    Customer: {
      fields: {
        transactions: relayStylePagination()
      }
    },
    Statistics: {
      fields: {
        brandSales: offsetLimitPagination()
      }
    }
  }
})

const CONTENTFUL_API =
  process.env.REACT_APP_CONTENTFUL_API ??
  'https://graphql.contentful.com/content/v1/spaces/y37odsz4p1zv/environments/{{CONTENTFUL_ENVIRONMENT}}?access_token={{CONTENTFUL_ACCESS_TOKEN}}'
const CONTENTFUL_ENVIRONMENT = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT ?? 'staging'
const CONTENTFUL_ACCESS_TOKEN = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN ?? ''

const contentfulLink = createHttpLink({
  uri: CONTENTFUL_API.replace(/{{CONTENTFUL_ENVIRONMENT}}/, CONTENTFUL_ENVIRONMENT).replace(
    /{{CONTENTFUL_ACCESS_TOKEN}}/,
    CONTENTFUL_ACCESS_TOKEN
  )
})

export const useAppApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const [authToken] = useAuthToken()
  return new ApolloClient({
    cache,
    link: ApolloLink.from([
      errorLink,
      authMiddleware(authToken),
      ApolloLink.split((operation) => operation.getContext().clientName === 'contentful', contentfulLink, httpLink)
    ])
  })
}
