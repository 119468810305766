import { gql } from '@apollo/client'
import { PlusIcon } from '@heroicons/react/solid'
import { Alert, Box, Button, Checkbox, Dropdown, Loader, Popover, Table } from '@plusplusminus/plusplusdash'
import { Link, RouteComponentProps, WindowLocation } from '@reach/router'
import { Pagination } from 'components/Pagination'
import Select from 'components/Select'
import { CreateUser } from 'components/User/CreateUser'
import UserExport from 'components/UserExport'
import { UsersFilter } from 'components/Users'
import dayjs from 'dayjs'
import { AccountsAction, useGetAccountGroupsQuery, useUpdateAccountGroupUsersMutation } from 'generated'
import { useUpdateUsers, useUsers } from 'hooks/users'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { UserStatus } from '../../common/enums'
import { DeleteUser } from '../../components/User'
import { useBatch } from '../../hooks/useBatch'

const TABLE_SHAPE = [
  { label: '', key: '', isSortable: false },
  { label: 'Name', key: 'firstName', isSortable: true },
  { label: 'Email', key: 'email', isSortable: true },
  { label: 'Role', key: 'roles', isSortable: false },
  { label: 'Status', key: 'status', isSortable: true },
  { label: 'Enabled', key: 'enabled', isSortable: true },
  { label: 'Created Date', key: 'createdAt', isSortable: true },
  { label: '', key: '', isSortable: false }
]

export const Users: React.FC<RouteComponentProps<{ location: WindowLocation<any> }>> = (props) => {
  const [isCreatingUser, setIsCreatingUser] = useState(false)

  const [selectedIds, onClickItem, contains, resetSelected] = useBatch()
  const { query, pagination, sort, filtering } = useUsers({ persist: true })

  const { refetch, loading: loadingUsers, error, data, previousData } = query
  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback] = sort

  const reset = useCallback(() => {
    refetch()
    resetSelected()
  }, [resetSelected, refetch])

  const closeCreateModal = () => setIsCreatingUser(false)
  const openCreateModal = () => setIsCreatingUser(true)

  const { enableUsers, updateUsersStatus, loading } = useUpdateUsers({
    onCompleted: () => {
      toast.success('Successfully updated user.')
      refetch()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Server error. could not update user.')
    }
  })

  const tableData = data || previousData

  useEffect(() => {
    if (props?.location?.state?.refetch) {
      refetch()
    }
  }, [props.location])

  return (
    <Box className="flex flex-col">
      <CreateUser isOpen={isCreatingUser} onClose={closeCreateModal} refetch={refetch} />
      <Box className="p-5 bg-white border-b border-gray-200 flex justify-between">
        <h1 className="text-lg">Users</h1>
        <div className="flex gap-2">
          <UserExport />
          <Button variant="primary" colorScheme="blue" size="sm" className="text-white" onClick={openCreateModal}>
            Add User <PlusIcon className="h-4 w-4" />
          </Button>
        </div>
      </Box>
      {error ? (
        <Alert type="error">
          <Alert.Heading>There was an error fetching users</Alert.Heading>
        </Alert>
      ) : !previousData && loadingUsers ? (
        <div className="flex flex-auto justify-center items-center">
          <Loader isActive={true} />
        </div>
      ) : tableData ? (
        <Box>
          <UsersFilter filtering={filtering} />
          {selectedIds.length > 0 ? (
            <div className="bg-gray-50 w-full flex py-2 px-5 justify-between">
              <DeleteUser ids={selectedIds} reset={reset} />
              <div className="flex gap-2">
                <AccountGroupsSelect selectedIds={selectedIds} />
                <Dropdown>
                  <Dropdown.Button>Enabled</Dropdown.Button>
                  <Dropdown.Items>
                    <Dropdown.Item>
                      <button
                        className="block w-full text-left px-5 py-1 hover:bg-gray-100 text-sm rounded-sm cursor-pointer"
                        onClick={() => enableUsers(selectedIds, true)}
                        disabled={loading}
                      >
                        Enable
                      </button>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <button
                        className="block w-full text-left px-5 py-1 hover:bg-gray-100 text-sm rounded-sm cursor-pointer"
                        onClick={() => enableUsers(selectedIds, false)}
                        disabled={loading}
                      >
                        Disable
                      </button>
                    </Dropdown.Item>
                  </Dropdown.Items>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Button>Status</Dropdown.Button>
                  <Dropdown.Items>
                    {[UserStatus.ACTIVE, UserStatus.BLOCKED, UserStatus.DISABLED, UserStatus.PENDING].map((status) => (
                      <Dropdown.Item>
                        <button
                          className="block w-full text-left px-5 py-1 hover:bg-gray-100 text-sm rounded-sm cursor-pointer"
                          onClick={() => updateUsersStatus(selectedIds, status)}
                          disabled={loading}
                        >
                          {status.charAt(0).toUpperCase()}
                          {status.slice(1)}
                        </button>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Items>
                </Dropdown>
              </div>
            </div>
          ) : null}
          <Table
            shape={TABLE_SHAPE}
            sortCallback={sortCallback}
            activeSort={sortField}
            sortDirection={direction?.toLowerCase()}
          >
            {tableData.accounts.edges.map(({ node: account }) => {
              return (
                <Table.Row key={account.email}>
                  <Table.Cell className="w-6">
                    <Checkbox id={account.id} checked={contains(account.id)} onClick={() => onClickItem(account.id)} />
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900">
                    {account.firstName} {account.lastName}
                  </Table.Cell>
                  <Table.Cell>{account.email}</Table.Cell>
                  <Table.Cell>{account.roles.join(', ')}</Table.Cell>
                  <Table.Cell>{account.status}</Table.Cell>
                  <Table.Cell>{account.enabled ? 'Yes' : 'No'}</Table.Cell>
                  <Table.Cell>{account.createdAt ? dayjs(account.createdAt).format('DD-MM-YYYY') : ''}</Table.Cell>
                  <Table.Cell>
                    <Link className="underline" to={`/users/${account.id}`}>
                      View
                    </Link>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table>
          <Pagination
            hasPreviousPage={tableData.accounts.pageInfo.hasPreviousPage}
            hasNextPage={tableData.accounts.pageInfo.hasNextPage}
            onNext={nextPage}
            onPrev={prevPage}
            page={page}
          />
        </Box>
      ) : null}
    </Box>
  )
}

type AccountGroupsSelectProps = { selectedIds: string[] }
const AccountGroupsSelect = (props: AccountGroupsSelectProps) => {
  const [selectedAccountGroup, setSelectedAccountGroup] = useState<string>()
  const [exclusionAccountGroup, setExclusionAccountGroup] = useState<string>()

  const { data, loading: loadingAccounts } = useGetAccountGroupsQuery({ variables: { paging: { first: 50 } } })
  const [updateAccountsForGroup, { loading: loadingUpdateAccounts }] = useUpdateAccountGroupUsersMutation({
    onCompleted: () => {
      toast.success('Successfully updated accounts')
      setSelectedAccountGroup(undefined)
      setExclusionAccountGroup(undefined)
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update accounts')
    }
  })

  const selectItems = useMemo(() => {
    if (data && data.accountGroups) {
      return data.accountGroups.edges.map(({ node }) => ({ key: node.id, value: node.id, label: node.name }))
    }
  }, [data])

  const submitUpdate = (close: () => void, action: AccountsAction, accountGroupId?: string) => () => {
    if (accountGroupId) {
      updateAccountsForGroup({ variables: { ids: props.selectedIds, accountGroupId, action } })
    }
    close()
  }

  if (loadingAccounts) {
    return <Loader isActive />
  }

  return selectItems && selectItems.length > 0 ? (
    <>
      <Popover>
        <Popover.Button>Include in Audience Segment</Popover.Button>
        <Popover.Panel>
          {({ close }: { close: any }) => (
            <div className="py-2 px-4">
              <Select
                options={selectItems}
                value={selectedAccountGroup}
                onChange={(value) => setSelectedAccountGroup(value)}
              />
              <div className="mt-2 flex justify-end">
                <Button
                  variant="primary"
                  colorScheme="green"
                  size="sm"
                  onClick={submitUpdate(close, AccountsAction.Include, selectedAccountGroup)}
                  disabled={!selectedAccountGroup || loadingUpdateAccounts}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Popover>
      <Popover>
        <Popover.Button>Exclude from Audience Segment</Popover.Button>
        <Popover.Panel>
          {({ close }: { close: any }) => (
            <div className="py-2 px-4">
              <Select
                options={selectItems}
                value={exclusionAccountGroup}
                onChange={(value) => setExclusionAccountGroup(value)}
              />
              <div className="mt-2 flex justify-end">
                <Button
                  variant="primary"
                  colorScheme="green"
                  size="sm"
                  onClick={submitUpdate(close, AccountsAction.Exclude, exclusionAccountGroup)}
                  disabled={!exclusionAccountGroup || loadingUpdateAccounts}
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Popover.Panel>
      </Popover>
    </>
  ) : null
}

AccountGroupsSelect.mutation = gql`
  mutation UpdateAccountGroupUsers($ids: [String!]!, $accountGroupId: String!, $action: AccountsAction!) {
    updateAccountGroupUsers(ids: $ids, accountGroupId: $accountGroupId, action: $action)
  }
`
