import { Disclosure } from '@headlessui/react'
import {
  CalculatorIcon,
  ChartPieIcon,
  ChevronUpIcon,
  CogIcon,
  CollectionIcon,
  CubeTransparentIcon,
  KeyIcon,
  MailIcon,
  ShoppingBagIcon,
  ShoppingCartIcon,
  SpeakerphoneIcon,
  TagIcon,
  TicketIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  CurrencyDollarIcon
} from '@heroicons/react/solid'
import { Box, NavItem, SideNav } from '@plusplusminus/plusplusdash'
import { Link } from '@reach/router'
import classNames from 'classnames'
import { Permission } from 'common/types'
import { arraysIntersect } from 'common/utils'
import React from 'react'
import { Toaster } from 'react-hot-toast'
import { useLogin } from '../../hooks/useLogin'
import { useUser } from '../../hooks/useUser'
import logo from '../../images/tle-logo-stacked-black.png'

const ADMIN_LINKS = [
  {
    to: '/dashboard',
    label: 'Dashboard',
    icon: ChartPieIcon,
    permissions: [Permission.Admin, Permission.FinancialAdmin]
  },
  { to: '/orders', label: 'Orders', icon: CalculatorIcon, permissions: [Permission.Admin, Permission.OrderAdmin] },
  {
    to: '/products',
    label: 'Products',
    icon: ShoppingCartIcon,
    permissions: [Permission.Admin, Permission.ProductAdmin]
  },
  { to: '/brands', label: 'Brands', icon: ShoppingBagIcon, permissions: [Permission.Admin, Permission.BrandAdmin] },
  { to: '/users', label: 'Users', icon: UsersIcon, permissions: [Permission.Admin] },
  {
    to: '/vouchers',
    label: 'Vouchers & Wallets',
    icon: TicketIcon,
    permissions: [Permission.Admin, Permission.OrderAdmin]
  },
  { to: '/tags', label: 'Tags', icon: TagIcon, permissions: [Permission.Admin] },
  {
    to: '/bulk-tag',
    label: 'Bulk Tag',
    icon: CollectionIcon,
    permissions: [Permission.Admin, Permission.ProductAdmin]
  },
  {
    to: '/refunds',
    label: 'Refunds',
    icon: CurrencyDollarIcon,
    permissions: [Permission.Admin, Permission.FinancialAdmin]
  },
  { to: '/audience-segments', label: 'Audience Segments', icon: UserGroupIcon, permissions: [Permission.Admin] },
  {
    to: '/notifications',
    label: 'Notifications',
    icon: SpeakerphoneIcon,
    sublinks: [
      { to: '/messages', label: 'Messages', icon: MailIcon },
      { to: '/message-campaigns', label: 'Message Campaigns', icon: SpeakerphoneIcon }
    ],
    permissions: [Permission.Admin]
  },
  {
    to: '/notifications',
    label: 'Automation',
    icon: CubeTransparentIcon,
    sublinks: [{ to: '/triggers', label: 'Triggers', icon: KeyIcon }],
    permissions: [Permission.Admin]
  },
  { to: '/app-config', label: 'App Config', icon: CogIcon, permissions: [Permission.SuperAdmin] }
]

const styles = {
  icon: 'w-4 h-4 text-gray-700 mr-3'
}

type NavLinkProps = {
  to: string
  label: string
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element
  sublinks?: Array<NavLinkProps>
}
const NavLink = (props: NavLinkProps) => {
  const { to, icon: Icon, label, sublinks } = props
  return sublinks ? (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="w-full">
            <NavItem className="flex justify-between">
              <div className="flex items-center">
                <Icon className={styles.icon} />
                {label}
              </div>
              <ChevronUpIcon className={classNames(styles.icon, 'ml-8', { 'transform rotate-180': !open })} />
            </NavItem>
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className="pl-4">
              {sublinks.map((sublink) => (
                <Link key={sublink.to} to={sublink.to}>
                  <NavItem>
                    <sublink.icon className={styles.icon} />
                    {sublink.label}
                  </NavItem>
                </Link>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  ) : (
    <Link key={to} to={to}>
      <NavItem>
        <Icon className={styles.icon} />
        {label}
      </NavItem>
    </Link>
  )
}

export const Layout: React.FC = (props) => {
  const { user, isAuth } = useUser()
  const { logout } = useLogin()
  return (
    <Box className="flex w-full h-screen">
      <div className="flex flex-grow-1 flex-shrink-0 flex-col w-64 bg-gray-100">
        <SideNav
          renderLogo={() => (
            <div className="w-full flex justify-center">
              <img src={logo} className="h-24 w-24 object-contain" />
            </div>
          )}
          renderFooter={
            isAuth && user
              ? () => (
                  <Box className="flex items-center">
                    <UserIcon className="h-12 w-12 text-gray-600 mr-2" />
                    <div>
                      <div className="text-sm font-medium text-gray-500">
                        {user.firstName} {user.lastName}
                      </div>
                      <a className="cursor-pointer underline text-sm text-gray-500" onClick={() => logout()}>
                        Logout
                      </a>
                    </div>
                  </Box>
                )
              : undefined
          }
        >
          {ADMIN_LINKS.filter((link) => {
            return arraysIntersect(user?.roles ?? [], link.permissions)
          }).map(NavLink)}
        </SideNav>
      </div>
      <Box className="flex-auto bg-gray-100 overflow-y-auto h-full relative">
        <Toaster />
        {props.children}
      </Box>
    </Box>
  )
}
