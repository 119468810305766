import { gql, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import Button from 'components/Button'

type AccountGroupExportProps = {
  accountGroupId: string
}

export default function AccountGroupExport(props: AccountGroupExportProps): JSX.Element {
  const [exportAccountGroups, { loading }] = useMutation(AccountGroupExport.mutation, {
    variables: {
      id: props.accountGroupId
    },
    onCompleted: () => {
      toast.success('Sent audience segment csv to email. PLease note this may take a few minutes')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. Could not send audience segment csv.')
    }
  })

  const submitDownload = async () => {
    await exportAccountGroups()
  }

  return (
    <Button colorScheme="blue" variant="primary" onClick={submitDownload} disabled={loading}>
      {loading ? 'Loading...' : 'Export Audience Segment'}
    </Button>
  )
}

AccountGroupExport.mutation = gql`
  mutation ExportAccountGroup($id: String!) {
    exportAccountGroupAccounts(accountGroupId: $id)
  }
`
