import { PencilIcon, TrashIcon } from '@heroicons/react/solid'
import { Table } from '@plusplusminus/plusplusdash'
import classNames from 'classnames'
import { ActionEventType } from 'generated'
import { EmailTemplatesCollection } from 'hooks/useEmailTemplates'
import { TUsePaginatedQuery } from 'hooks/usePaginatedQuery'
import { MessagesResult } from 'pages/Message/MessagesTable'
import ActionModal from './ActionModal'
import { ExtendedUpdateActionInput } from './Actions'

function getDefaultValues(data: ExtendedUpdateActionInput) {
  return {
    id: data?.id,
    name: data?.name,
    messageId: data?.messageId,
    messageName: data?.messageName,
    type: data?.type,
    channels: data?.channels,
    enabled: data?.enabled,
    voucherMessage: data?.voucherMessage,
    voucherValue: data?.voucherValue,
    expiryDate: data?.expiryDate ?? { value: 0, unit: 'day' },
    delay: data?.delay ?? { value: 0, unit: 'minutes' }
  }
}

const styles = {
  icon: 'hover:text-gray-400 w-5 h-5 cursor-pointer'
}

interface ActionsTableProps {
  actions: ExtendedUpdateActionInput[]
  onAddAction?: (type?: ActionEventType, index?: number) => (action: ExtendedUpdateActionInput) => void
  onDeleteAction?: (id: string) => void
  reloadMessages?: () => void
  paginatedMessages?: TUsePaginatedQuery<MessagesResult>
  emailTemplateCollection?: EmailTemplatesCollection
}
const ActionsTable = ({
  actions,
  onAddAction,
  onDeleteAction,
  paginatedMessages,
  emailTemplateCollection,
  reloadMessages
}: ActionsTableProps): JSX.Element => {
  const ISSUE_VOUCHER_TABLE_SHAPE = [
    { label: 'Name', key: 'name', isSortable: false },
    { label: 'Voucher Value', key: 'vaucherValue', isSortable: false },
    { label: 'Expiration', key: 'expiration', isSortable: false },
    { label: 'Message', key: 'message', isSortable: false },
    { label: 'Delay', key: 'delay', isSortable: false }
  ]
  const SEND_MESSAGE_TABLE_SHAPE = [
    { label: 'Name', key: 'name', isSortable: false },
    { label: 'Message', key: 'message', isSortable: false },
    { label: 'Channels', key: 'channels', isSortable: false },
    { label: 'Delay', key: 'delay', isSortable: false }
  ]
  if (onAddAction) {
    ISSUE_VOUCHER_TABLE_SHAPE.push({ label: 'Actions', key: '', isSortable: false })
    SEND_MESSAGE_TABLE_SHAPE.push({ label: 'Actions', key: '', isSortable: false })
  }
  const voucherActions = actions.filter(({ type }) => type === ActionEventType.IssueVoucher)
  const messageActions = actions.filter(({ type }) => type === ActionEventType.SendMessage)

  return (
    <div>
      {voucherActions.length === 0 && messageActions.length === 0 && <p className="mb-0 mt-4">No actions added</p>}
      {voucherActions.length > 0 && (
        <div className="mt-5">
          <p className="font-bold">Issue Voucher Actions</p>
          <Table shape={ISSUE_VOUCHER_TABLE_SHAPE}>
            {voucherActions.map((action, index) => {
              const { id, name, voucherValue, expiryDate, messageName, delay } = action
              const expirationString =
                expiryDate && expiryDate.value > 0 ? `${expiryDate.value} ${expiryDate.unit}s` : 'never'
              let delayString = ''
              if (delay && delay.value > 0) {
                delayString = `${delay.value} ${delay.unit}`
              } else {
                delayString = 'none'
              }
              return (
                <Table.Row key={id ?? index}>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{`R ${voucherValue}`}</Table.Cell>
                  <Table.Cell>{expirationString}</Table.Cell>
                  <Table.Cell>{messageName ?? ''}</Table.Cell>
                  <Table.Cell>{delayString}</Table.Cell>
                  {onAddAction && reloadMessages && paginatedMessages && (
                    <Table.Cell className="flex gap-2">
                      <ActionModal
                        isLoading={false}
                        defaultValues={getDefaultValues(action)}
                        modalTitle="Update Action"
                        renderIcon={() => <PencilIcon className={styles.icon} />}
                        onAddAction={onAddAction(ActionEventType.IssueVoucher, index)}
                        {...{
                          paginatedMessages,
                          emailTemplateCollection: emailTemplateCollection as EmailTemplatesCollection,
                          reloadMessages
                        }}
                      />
                      <TrashIcon
                        className={classNames(styles.icon, 'text-red-500')}
                        onClick={() => onDeleteAction?.(id as string)}
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              )
            })}
          </Table>
        </div>
      )}
      {messageActions.length > 0 && (
        <div className="mt-5">
          <p className="font-bold">Send Message Actions</p>
          <Table shape={SEND_MESSAGE_TABLE_SHAPE}>
            {messageActions.map((action, index) => {
              const { id, name, messageName, channels, delay } = action
              const channelsString = channels?.reduce((acc, cur) => {
                if (!acc) {
                  return cur
                }
                return (acc += `, ${cur}`)
              }, '')
              let delayString = ''
              if (delay && delay.value > 0) {
                delayString = `${delay.value} ${delay.unit}`
              } else {
                delayString = 'none'
              }
              return (
                <Table.Row key={id ?? index}>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{messageName}</Table.Cell>
                  <Table.Cell>{channelsString ?? ''}</Table.Cell>
                  <Table.Cell>{delayString}</Table.Cell>
                  {onAddAction && reloadMessages && paginatedMessages && (
                    <Table.Cell className="flex gap-2">
                      <ActionModal
                        isLoading={false}
                        defaultValues={getDefaultValues(action)}
                        modalTitle="Edit Action"
                        renderIcon={() => <PencilIcon className={styles.icon} />}
                        onAddAction={onAddAction(ActionEventType.SendMessage, index)}
                        {...{
                          paginatedMessages,
                          emailTemplateCollection: emailTemplateCollection as EmailTemplatesCollection,
                          reloadMessages
                        }}
                      />
                      <TrashIcon
                        className={classNames(styles.icon, 'text-red-500')}
                        onClick={() => onDeleteAction?.(id as string)}
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              )
            })}
          </Table>
        </div>
      )}
    </div>
  )
}

export default ActionsTable
