import { DocumentNode, gql } from '@apollo/client'
import { Tab } from '@headlessui/react'
import { PlusIcon } from '@heroicons/react/solid'
import {
  Dropdown,
  Form,
  FormError,
  FormLabel,
  Input,
  Loader,
  Modal,
  Popover,
  Radio,
  Table
} from '@plusplusminus/plusplusdash'
import cn from 'classnames'
import { Card } from 'components/Card'
import DebouncedSearchInput from 'components/DebouncedSearchInput'
import { PageHeader } from 'components/PageHeader'
import dayjs from 'dayjs'
import {
  CreateVouchersInput,
  CursorPaging,
  SortDirection,
  useCreateVouchersMutation,
  useResendVoucherMutation,
  useVoucherDashboardQuery,
  useVouchersQuery,
  VoucherFilter,
  VoucherSort,
  VoucherSortFields,
  VouchersQuery,
  VoucherStatus
} from 'generated'
import { PAGING_FIELDS } from 'graphql/fragments'
import { set, unset } from 'lodash'
import moment from 'moment'
import React, { MutableRefObject, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { formatPrice } from 'utils'
import Button from 'components/Button'
import FilterWrapper from 'components/FilterWrapper'
import VouchersExport from 'components/VouchersExport'

const TABLE_SHAPE = [
  { label: 'Number', key: 'voucherNumber', isSortable: true },
  { label: 'Recipient', key: 'recipientEmail', isSortable: true },
  { label: 'Value', key: 'value', isSortable: true },
  { label: 'Created At', key: 'createdAt', isSortable: true },
  { label: 'Expiry', key: 'expiryDate', isSortable: true },
  { label: 'Status', key: 'status', isSortable: false },
  { label: 'Reason', key: 'reason', isSortable: false },
  { label: 'Created By', key: 'createdBy', isSortable: false },
  { label: 'Code', key: 'code', isSortable: false },
  { label: 'Actions', key: '', isSortable: false }
]

type State = {
  paging: CursorPaging
  sorting: Array<VoucherSort>
  filter: VoucherFilter
}
type Action =
  | {
      type: 'UPDATE_FILTER'
      payload: Record<string, any>
    }
  | { type: 'UPDATE_SORT'; payload: VoucherSortFields }
  | { type: 'REMOVE_FILTER'; payload: keyof VoucherFilter }
type TableSort = React.ComponentProps<typeof Table>['sortDirection']

const voucherReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'UPDATE_FILTER': {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload
        }
      }
    }
    case 'UPDATE_SORT': {
      const field = action.payload
      const sorting = [] as Array<VoucherSort>

      const existing = state.sorting.find((s: any) => s.field === field)

      if (!existing) {
        sorting.push({ field, direction: SortDirection.Desc })
      } else if (existing && existing.direction === SortDirection.Desc) {
        sorting.push({ field, direction: SortDirection.Asc })
      }

      return {
        ...state,
        sorting
      }
    }
    case 'REMOVE_FILTER': {
      const newFilter = { ...state.filter }
      unset(newFilter, action.payload)
      return { ...state, filter: newFilter }
    }
    default:
      return state
  }
}

const styles = {
  tab:
    'w-full border-b-2 text-blue-600 bg-white py-2.5 text-sm leading-5 font-medium focus:outline-none hover:border-blue-500',
  tabList: 'flex rounded-xl w-full justify-center',
  tabUnselected: 'border-transparent',
  tabSelected: 'border-blue-500',
  dropdownItem: 'block w-full text-left px-5 py-2 hover:underline cursor-pointer text-sm'
}

const LIMIT = 20

const VouchersRoute: React.FC = () => {
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const vouchersCb = useRef<any>(null)

  const toggleModal = () => setIsCreateOpen((isOpen) => !isOpen)
  const onCreate = () => {
    toggleModal()
    vouchersCb.current?.()
  }

  return (
    <div>
      <CreateVoucherModal isOpen={isCreateOpen} onClose={toggleModal} onCreate={onCreate} />
      <PageHeader>
        <h3>Vouchers & Wallets</h3>
        <div className="flex gap-2">
          <VouchersExport />
          <Button variant="plain" onClick={toggleModal}>
            Create Vouchers
            <PlusIcon className="w-4 h-4" />
          </Button>
        </div>
      </PageHeader>
      <Tab.Group>
        <Tab.List className={styles.tabList}>
          <Tab as={React.Fragment}>
            {({ selected }) => (
              <button className={cn(styles.tab, { [styles.tabSelected]: selected, [styles.tabUnselected]: !selected })}>
                Vouchers Table
              </button>
            )}
          </Tab>
          <Tab as={React.Fragment}>
            {({ selected }) => (
              <button className={cn(styles.tab, { [styles.tabSelected]: selected, [styles.tabUnselected]: !selected })}>
                Dashboard
              </button>
            )}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            <Vouchers refetch={vouchersCb} />
          </Tab.Panel>
          <Tab.Panel>
            <Dashboard />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

function convertCode(code: string) {
  return code.replace(/(.{3})(.{0,3})?(.{0,3})?/g, function (curr, first?: string, second?: string, third?: string) {
    if (first && second && third) {
      return `${first}-${second}-${third}`
    } else if (first && second) {
      return `${first}-${second}`
    } else if (first) {
      return `${first}`
    } else {
      return curr
    }
  })
}

type VoucherGQL = {
  query: DocumentNode
  mutation: { resendVoucher: DocumentNode; createVouchers: DocumentNode }
}
const Vouchers: React.FC<{ refetch: MutableRefObject<any> }> & VoucherGQL = (props) => {
  const [state, dispatch] = useReducer(voucherReducer, {
    paging: { first: LIMIT },
    filter: { status: { eq: VoucherStatus.Active } },
    sorting: [{ field: 'createdAt', direction: SortDirection.Desc }] as Array<VoucherSort>
  })
  const { data, loading, fetchMore, refetch } = useVouchersQuery({
    variables: state
  })
  const [resendVoucherMutation, { loading: loadingResend }] = useResendVoucherMutation({
    onCompleted: (data) => {
      if (data) {
        toast.success(`Voucher has been resent.`)
      }
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not resend voucher.')
    }
  })

  /* useQuery randomly stopped refetching on state change */
  useEffect(() => {
    refetch(state)
  }, [state])

  useEffect(() => {
    props.refetch.current = refetch
  }, [refetch])

  const { sorting } = state

  const updateFilter = (key: string[], value: any, operator = 'eq') => {
    dispatch({ type: 'UPDATE_FILTER', payload: set({}, [...key, operator], value) })
  }
  const removeFilter = (key: keyof VoucherFilter) => {
    dispatch({ type: 'REMOVE_FILTER', payload: key })
  }
  const resendVoucher = (id: string) => () => {
    resendVoucherMutation({ variables: { id } })
  }
  const copyVoucherCode = (voucher: VouchersQuery['vouchers']['edges'][number]['node']) => () => {
    if (voucher?.code) {
      const friendlyCode = convertCode(voucher.code)
      navigator.clipboard.writeText(friendlyCode).then(() => {
        toast.success('Voucher code copied to clipboard')
      })
    }
  }

  return (
    <div>
      <div className="bg-white w-full flex py-2 px-5 justify-between">
        <VouchersFilter {...state} updateFilter={updateFilter} removeFilter={removeFilter} />
      </div>
      <Table
        shape={TABLE_SHAPE}
        sortCallback={(name: string) => dispatch({ type: 'UPDATE_SORT', payload: name as VoucherSortFields })}
        activeSort={sorting.length > 0 ? sorting[0].field : ''}
        sortDirection={sorting.length > 0 ? (sorting[0].direction.toLowerCase() as TableSort) : undefined}
      >
        {loading ? (
          <Loader isActive />
        ) : (
          data?.vouchers.edges.map(({ node }) => {
            return (
              <Table.Row key={node.id}>
                <Table.Cell>{node.voucherNumber}</Table.Cell>
                <Table.Cell>{node.recipientEmail}</Table.Cell>
                <Table.Cell>{formatPrice(node.value)}</Table.Cell>
                <Table.Cell>{dayjs(node.createdAt).format('DD-MM-YYYY')}</Table.Cell>
                <Table.Cell>{dayjs(node.expiryDate).format('DD-MM-YYYY')}</Table.Cell>
                <Table.Cell>{node.status}</Table.Cell>
                <Table.Cell>{node.reason}</Table.Cell>
                <Table.Cell>{node.createdBy ? node.createdBy.email : null}</Table.Cell>
                <Table.Cell>{convertCode(node.code)}</Table.Cell>
                <Table.Cell>
                  <Dropdown>
                    <Dropdown.Button>Actions</Dropdown.Button>
                    <Dropdown.Items className="z-10">
                      {node.recipientEmail ? (
                        <Dropdown.Item>
                          <button
                            className={styles.dropdownItem}
                            onClick={resendVoucher(node.id)}
                            disabled={loadingResend}
                          >
                            Resend
                          </button>
                        </Dropdown.Item>
                      ) : null}
                      <Dropdown.Item>
                        <button className={styles.dropdownItem} onClick={copyVoucherCode(node)}>
                          Copy Voucher Code
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Items>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            )
          })
        )}
      </Table>
      <div className="flex justify-end pt-2 pb-4 px-8 gap-x-4">
        {data?.vouchers.pageInfo.hasPreviousPage ? (
          <Button
            variant="plain"
            onClick={() =>
              fetchMore({
                variables: { ...state, paging: { last: LIMIT, before: data.vouchers.pageInfo.startCursor } }
              })
            }
          >
            Prev
          </Button>
        ) : null}
        {data?.vouchers.pageInfo.hasNextPage ? (
          <Button
            variant="plain"
            onClick={() =>
              fetchMore({ variables: { ...state, paging: { first: LIMIT, after: data.vouchers.pageInfo.endCursor } } })
            }
          >
            Next
          </Button>
        ) : null}
      </div>
    </div>
  )
}

const VouchersFilter = (props: any) => {
  const { filter, updateFilter, removeFilter } = props

  const search = (key: string, operator = 'eq') => (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    if (value) {
      if (key === 'code') {
        value = value.trim().replace(/-/g, '')
      }
      if (operator === 'iLike') {
        value = `%${value}%`
      }
      updateFilter(key.split('.'), value, operator)
    } else {
      removeFilter(key.split('.'))
    }
  }
  const filterStatus = (status: string) => () => {
    updateFilter(['status'], status)
  }
  const isMultipleFilter = filter.code || filter.createdBy || filter.reason
  const removeMultipleFilter = () => {
    removeFilter('code')
    removeFilter('createdBy.email')
    removeFilter('reason')
  }
  return (
    <div className="flex justify-between w-full">
      <div className="flex gap-2">
        <DebouncedSearchInput
          onRemoveFilter={() => removeFilter('recipientEmail')}
          as="input"
          variant="outline"
          width="md"
          id="recipientEmail"
          name="recipientEmail"
          onChange={search('recipientEmail', 'iLike')}
          value={filter.recipientEmail ?? ''}
          placeholder="Recipient Email"
        />
      </div>
      <div className="flex gap-2">
        <FilterWrapper canRemoveFilter={isMultipleFilter} removeFilterAction={removeMultipleFilter}>
          <Popover>
            <Popover.Button>Other Filters</Popover.Button>
            <Popover.Panel>
              <div className="pb-4 px-4 space-y-5">
                <DebouncedSearchInput
                  onRemoveFilter={() => removeFilter('code')}
                  as="input"
                  variant="outline"
                  width="md"
                  id="code"
                  name="code"
                  onChange={search('code', 'iLike')}
                  value={filter.code ? filter.code.iLike.replaceAll('%', '') : ''}
                  placeholder="Voucher Code"
                />
                <DebouncedSearchInput
                  onRemoveFilter={() => removeFilter('createdBy.email')}
                  as="input"
                  variant="outline"
                  width="md"
                  id="createdBy.email"
                  name="createdBy.email"
                  onChange={search('createdBy.email', 'iLike')}
                  value={filter.createdBy?.email ? filter.createdBy?.email.iLike.replaceAll('%', '') : ''}
                  placeholder="Created By"
                />
                <DebouncedSearchInput
                  onRemoveFilter={() => removeFilter('reason')}
                  as="input"
                  variant="outline"
                  width="md"
                  id="reason"
                  name="reason"
                  onChange={search('reason', 'iLike')}
                  value={filter.reason ? filter.reason.iLike.replaceAll('%', '') : ''}
                  placeholder="Reason"
                />
              </div>
            </Popover.Panel>
          </Popover>
        </FilterWrapper>
        <Popover>
          <Popover.Button>Status</Popover.Button>
          <Popover.Panel>
            <div className="py-2 px-4">
              <FormLabel className="font-medium mb-2">Select Status to filter by</FormLabel>
              <div className="max-h-64 overflow-y-auto px-2">
                {Object.values(VoucherStatus).map((status) => {
                  return (
                    <FormLabel key={status} htmlFor="status" className="flex gap-2 items-center my-1">
                      <Radio
                        id={status}
                        name="status"
                        checked={filter.status && filter.status.eq === status}
                        onChange={filterStatus(status)}
                      />
                      <span>{status}</span>
                    </FormLabel>
                  )
                })}
              </div>
            </div>
          </Popover.Panel>
        </Popover>
      </div>
    </div>
  )
}

const Dashboard: React.FC & { query: DocumentNode } = () => {
  const { data, loading } = useVoucherDashboardQuery()

  const chartData = useMemo(() => {
    if (data) {
      return data.voucherStats.dailySales.map(({ day, total }) => ({
        name: dayjs(day).format('DD-MM-YYYY'),
        total
      }))
    } else {
      return []
    }
  }, [data?.voucherStats.dailySales])

  if (loading || !data) {
    return <Loader isActive />
  }

  const tooltipFormatter = (value: string) => formatPrice(value)

  return (
    <div className="p-5">
      <div className="grid grid-cols-4 gap-5">
        <Card className="col-span-4">
          <Card.Header>Voucher Sales per Day</Card.Header>
          <Card.Content>
            <ResponsiveContainer width="100%" height={350}>
              <LineChart data={chartData} margin={{ top: 15, right: 30, left: 30, bottom: 30 }}>
                <XAxis dataKey="name" label={{ value: 'Date', position: 'bottom', offset: 15 }} />
                <YAxis label={{ value: 'ZAR', angle: -90, position: 'left', offset: 20 }} />
                <Tooltip formatter={tooltipFormatter} />
                <Line dataKey="total" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Card.Content>
        </Card>
        <Card>
          <Card.Header>
            <div>
              Unredeemed Vouchers
              <p className="block text-xs text-gray-600">Vouchers that are still active, but have not been redeemed</p>
            </div>
          </Card.Header>
          <Card.Content>
            <div className="grid grid-cols-2">
              <strong>Purchased</strong>
              <p>{formatPrice(data.voucherStats.unredeemedAmounts.purchased)}</p>
              <strong>Internal</strong>
              <p>{formatPrice(data.voucherStats.unredeemedAmounts.internal)}</p>
            </div>
          </Card.Content>
        </Card>
        <Card>
          <Card.Header>
            <div>
              Total Vouchers Issued
              <p className="block text-xs text-gray-600">Internally created vouchers which have not expired</p>
            </div>
          </Card.Header>
          <Card.Content>{formatPrice(data.voucherStats.totalIssued)}</Card.Content>
        </Card>
        <Card>
          <Card.Header>
            <div>
              Total Voucher Sales
              <p className="block text-xs text-gray-600">Total revenue from the sale of vouchers</p>
            </div>
          </Card.Header>
          <Card.Content>{formatPrice(data.voucherStats.totalSales)}</Card.Content>
        </Card>
        <Card>
          <Card.Header>
            <div>
              Average Sales Per Day
              <p className="block text-xs text-gray-600">Average per day over the last 30 days</p>
            </div>
          </Card.Header>
          <Card.Content>{formatPrice(data.voucherStats.averageSalesPerDay)}</Card.Content>
        </Card>
        <Card>
          <Card.Header>
            <div>
              Total Wallet Credit
              <p className="block text-xs text-gray-600">Available funds across all user's wallets</p>
            </div>
          </Card.Header>
          <Card.Content>{formatPrice(data.voucherStats.totalWalletBalance)}</Card.Content>
        </Card>
        <Card className="col-start-1 col-span-4">
          <Card.Header>
            <div>
              Internal Vouchers
              <p className="block text-xs text-gray-600">Stats relating to marketing and internally created vouchers</p>
            </div>
          </Card.Header>
          <Card.Content>
            <div className="grid grid-cols-3 grid-rows-2 gap-y-4 gap-x-4 grid-flow-col">
              <div>
                <strong>Created Vouchers</strong>
                <p className="block text-xs text-gray-600">
                  Value of created vouchers - does not consider usage or expiry
                </p>
              </div>
              <div className="grid grid-cols-2">
                <strong>This Month</strong>
                <p>{formatPrice(data.voucherStats.internal.created.month)}</p>
                <strong>Total</strong>
                <p>{formatPrice(data.voucherStats.internal.created.total)}</p>
              </div>
              <div>
                <strong>Remaining Value</strong>
                <p className="block text-xs text-gray-600">
                  Remaining value of internally created vouchers. This will essentially be the sum of the created value
                  less expiries and usage on orders.
                </p>
              </div>
              <div className="grid grid-cols-2">
                <strong>This Month</strong>
                <p>{formatPrice(data.voucherStats.internal.available.month)}</p>
                <strong>Total</strong>
                <p>{formatPrice(data.voucherStats.internal.available.total)}</p>
              </div>
              <div>
                <strong>Used Value</strong>
                <p className="block text-xs text-gray-600">
                  The amount of internal voucher "credit" used in order checkout.
                </p>
              </div>
              <div className="grid grid-cols-2">
                <strong>This Month</strong>
                <p>{formatPrice(data.voucherStats.internal.used.month)}</p>
                <strong>Total</strong>
                <p>{formatPrice(data.voucherStats.internal.used.total)}</p>
              </div>
            </div>
          </Card.Content>
        </Card>
      </div>
    </div>
  )
}

type CreateVoucherModalProps = {
  isOpen: boolean
  onClose: () => void
  onCreate: () => void
}
const CreateVoucherModal = (props: CreateVoucherModalProps) => {
  const { register, errors, control, handleSubmit } = useForm<CreateVouchersInput>({
    defaultValues: { quantity: 1, value: 250 }
  })
  const [isDateFocused, setIsDateFocused] = useState(false)

  const [createVouchersMutation, { loading: loadingCreate }] = useCreateVouchersMutation({
    onCompleted: (data) => {
      if (data.createVouchers) {
        toast.success('Vouchers created successfully')
        props.onCreate()
      }
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not create vouchers')
    }
  })

  const onSubmit = useCallback(
    async (input: CreateVouchersInput) => {
      const maxIterations = Math.ceil(input.quantity / 500)

      for (let i = 0; i < maxIterations; i++) {
        const quantity = Math.min(500, input.quantity - i * 500)
        await createVouchersMutation({ variables: { input: { ...input, quantity } } })
      }
    },
    [createVouchersMutation]
  )

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="md">
      <Modal.Title>Create Vouchers</Modal.Title>
      <Modal.Description>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-y-4 py-4">
            <FormLabel>
              Reason{' '}
              <Input width="full" variant="standard" name="reason" ref={register({ required: 'Reason is required' })} />
              {errors.reason ? <FormError>{errors.reason.message}</FormError> : null}
            </FormLabel>
            <FormLabel>
              Value
              <Input
                width="full"
                type="number"
                step={50}
                variant="standard"
                name="value"
                ref={register({ valueAsNumber: true, required: 'Value is required' })}
              />
              {errors.value ? <FormError>{errors.value.message}</FormError> : null}
            </FormLabel>
            <FormLabel>
              Quantity
              <Input
                width="full"
                type="number"
                step={1}
                variant="standard"
                name="quantity"
                ref={register({ valueAsNumber: true, required: 'Quantity is required' })}
              />
              {errors.quantity ? <FormError>{errors.quantity.message}</FormError> : null}
            </FormLabel>
            <FormLabel>
              Expiry Date
              <Controller
                name="expiryDate"
                control={control}
                rules={{ required: 'Expiry date is required' }}
                render={({ onChange, value }) => (
                  <SingleDatePicker
                    id="expiry_date"
                    block
                    regular
                    date={moment(value)}
                    onDateChange={(date) => onChange(date?.toDate())}
                    focused={isDateFocused}
                    onFocusChange={({ focused }) => setIsDateFocused(focused)}
                  />
                )}
              />
              {errors.expiryDate ? <FormError>{errors.expiryDate.message}</FormError> : null}
            </FormLabel>
            <div className="justify-self-center w-56">
              <Button
                variant="primary"
                colorScheme="green"
                className="w-full justify-center"
                isDisabled={loadingCreate}
                isLoading={loadingCreate}
              >
                Create
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Description>
    </Modal>
  )
}

Vouchers.query = gql`
  query Vouchers($paging: CursorPaging, $filter: VoucherFilter, $sorting: [VoucherSort!]) {
    vouchers(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        ...PagingFields
      }
      edges {
        node {
          id
          voucherNumber
          code
          value
          recipientEmail
          createdAt
          status
          expiryDate
          reason
          createdBy {
            id
            email
          }
        }
      }
    }
  }
  ${PAGING_FIELDS}
`

Vouchers.mutation = {
  resendVoucher: gql`
    mutation ResendVoucher($id: String!) {
      resendVoucher(id: $id)
    }
  `,
  createVouchers: gql`
    mutation CreateVouchers($input: CreateVouchersInput!) {
      createVouchers(input: $input)
    }
  `
}

Dashboard.query = gql`
  query VoucherDashboard {
    voucherStats {
      totalSales
      totalIssued
      unredeemedAmounts {
        internal
        purchased
      }
      dailySales {
        day
        total
      }
      averageSalesPerDay
      totalWalletBalance
      internal {
        created {
          total
          month
        }
        available {
          total
          month
        }
        used {
          total
          month
        }
      }
    }
  }
`

export default VouchersRoute
