import { Form, FormLabel, Input, Modal } from '@plusplusminus/plusplusdash'
import { banks } from 'common/constants'
import Select from 'components/Select'
import Button from 'components/Button'
import { BankDetailsInput, BrandQuery, Maybe, PaymentTerms, useUpdateBrandMutation } from 'generated'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'

type EditPayoutProps = {
  isOpen: boolean
  onClose: () => void
  brand: NonNullable<BrandQuery['brand']>
}

type FormValues = {
  commission: number
  paymentTerms?: Maybe<PaymentTerms>
  bankDetails: Maybe<BankDetailsInput>
}

const banksSelectItems = banks.map((b) => ({ value: b.name, key: b.name, label: b.name }))
const unitSelectItems = [
  { value: 'days', key: 'days', label: 'days' },
  { value: 'months', key: 'months', label: 'months' },
  { value: 'weeks', key: 'weeks', label: 'weeks' }
]

const EditPayout: React.FC<EditPayoutProps> = (props) => {
  const { isOpen, onClose, brand } = props

  const { register, handleSubmit, reset, control, watch, setValue } = useForm<FormValues>({
    defaultValues: { commission: brand.commission, paymentTerms: brand.paymentTerms, bankDetails: brand.bankDetails }
  })

  const bankName = watch('bankDetails.bankName')
  useEffect(() => {
    reset({ commission: brand.commission, paymentTerms: brand.paymentTerms, bankDetails: brand.bankDetails })
  }, [brand])

  useEffect(() => {
    if (bankName) {
      const bank = banks.find((b) => b.name === bankName)
      if (bank) {
        setValue('bankDetails.branchCode', bank.branchCode)
      }
    }
  }, [bankName])

  const [updateBrand, { loading }] = useUpdateBrandMutation({
    onCompleted: () => {
      onClose()
      toast.success('Successfully updated payout info.')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update payout info.')
    }
  })
  const onSubmit: SubmitHandler<FormValues> = (update) => {
    updateBrand({ variables: { id: brand.id, update } })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <Modal.Title>Links</Modal.Title>
      <Modal.Description className="my-4">
        <Form className="mb-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <FormLabel htmlFor="commission">
              <span className="font-bold text-sm">Commission</span>
              <Input
                id="commission"
                name="commission"
                type="number"
                ref={register({ valueAsNumber: true, min: 0, max: 100 })}
                variant="standard"
                max={100}
                min={0}
              />
            </FormLabel>
          </div>
          <h3 className="font-medium text-lg">Payment Terms</h3>
          <div className="grid grid-cols-3 gap-4 mb-8">
            <FormLabel htmlFor="paymentTerms.unit">
              <span className="text-sm font-bold">Unit</span>
              <Input
                width="full"
                id="paymentTerms.unit"
                name="paymentTerms.unit"
                ref={register({ valueAsNumber: true })}
                variant="standard"
              />
            </FormLabel>
            <FormLabel htmlFor="bankDetails.accountNumber">
              <span className="text-sm font-bold">Period</span>
              <Controller
                control={control}
                name="paymentTerms.amount"
                render={({ onChange, value }) => <Select options={unitSelectItems} value={value} onChange={onChange} />}
              />
            </FormLabel>
          </div>
          <h3 className="font-medium text-lg">Bank Details</h3>
          <div className="grid grid-cols-3 gap-4 mb-8">
            <FormLabel htmlFor="bankDetails.accountNumber">
              <span className="text-sm font-bold">Account Number</span>
              <Input
                width="full"
                id="bankDetails.accountNumber"
                name="bankDetails.accountNumber"
                ref={register}
                variant="standard"
              />
            </FormLabel>
            <FormLabel htmlFor="bankDetails.bankName">
              <span className="text-sm font-bold">Bank Name</span>
              <Controller
                control={control}
                name="bankDetails.bankName"
                render={({ onChange, value }) => (
                  <Select options={banksSelectItems} value={value} onChange={onChange} />
                )}
              />
            </FormLabel>
            <FormLabel htmlFor="bankDetails.branchCode">
              <span className="text-sm font-bold">Branch Code</span>
              <Input
                width="full"
                id="bankDetails.branchCode"
                name="bankDetails.branchCode"
                ref={register}
                variant="standard"
              />
            </FormLabel>
          </div>
          <div className="flex justify-end gap-2">
            <Button variant="plain" onClick={onClose} type="button">
              Close
            </Button>
            <Button variant="primary" colorScheme="green" type="submit" isDisabled={loading} isLoading={loading}>
              Save
            </Button>
          </div>
        </Form>
      </Modal.Description>
    </Modal>
  )
}

export { EditPayout }
