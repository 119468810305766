import { Notes as TNotes } from 'generated'

export type Notes = Omit<TNotes, '__typename'>
export const noteTypes: Array<{ key: keyof Notes; title: string; description: string }> = [
  { key: 'product', title: 'Product', description: 'Displayed in product information' },
  { key: 'cart', title: 'Cart', description: 'Displayed above "Add to Cart" button"' },
  { key: 'shipping', title: 'Shipping', description: 'Displayed on shipping information for order line' },
  { key: 'alert', title: 'Alert', description: 'Displayed as a banner on all relevant brand & product screens' },
  { key: 'checkout', title: 'Checkout', description: 'Displayed on cart screen for relevant order line' },
  {
    key: 'freeShippingThreshold',
    title: 'Free Shipping Threshold',
    description: 'Displayed above "Add to Cart" button" and on brand page / section'
  }
]
