import { Loader } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import { CreateMessageCampaignInput } from 'generated'
import { EmailTemplatesCollection, useEmailTemplates } from 'hooks/useEmailTemplates'
import { Moment } from 'moment'
import CreateMessageCampaignForm from './CreateMessageCampaignForm'

type LocationType = { location: { state: { messageId?: string; messageName?: string } } }

type CreateMessageCampaignForm = Omit<CreateMessageCampaignInput, 'scheduledAt' | 'nextRunAt' | 'timeToRun'> & {
  scheduledAt: Moment
  accountGroupIds: string
  nextRunAt?: Moment
  timeToRun?: Moment
  messageName?: string
}

export default function CreateMessageCampaign(props: RouteComponentProps & LocationType): JSX.Element {
  const { data: emailTemplatesData, loading: loadingEmailTemplates } = useEmailTemplates()
  return (
    <div>
      <PageHeader>Create Message Campaign</PageHeader>
      <div className="p-5">
        <Card>
          {loadingEmailTemplates ? (
            <Loader isActive />
          ) : (
            <Card.Content>
              <CreateMessageCampaignForm
                messageId={props.location.state.messageId}
                messageName={props.location.state.messageName}
                onCompleted={(data) =>
                  data && props.navigate?.(`/message-campaigns/${data.createMessageCampaign.id}/view`)
                }
                emailTemplateCollection={emailTemplatesData?.emailTemplatesCollection as EmailTemplatesCollection}
              />
            </Card.Content>
          )}
        </Card>
      </div>
    </div>
  )
}

CreateMessageCampaign
