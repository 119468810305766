import {
  gql,
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client'
import { PaginatedEntity, Variant } from 'common/types'
import { IMAGE_FIELDS, PAGING_FIELDS, PRODUCT_FIELDS, TAG_FIELDS, VARIANT_FIELDS } from 'graphql/fragments'
import { isArray } from 'lodash'
import { useCallback, useMemo } from 'react'
import { Image, Product } from '../common/types'
import { TUsePaginatedQuery, usePaginatedQuery } from './usePaginatedQuery'

type ProductsResult = {
  products: PaginatedEntity<Product>
}
type FilterFields = {
  title?: string
  enabled?: boolean
  'brand.name'?: string
  tags?: string[]
  brand?: string
  status?: string
}
type ProductUpdate = { title?: string; description?: string; enabled?: boolean; image?: Image }
type ProductVariantUpdate = { title?: string }

const PRODUCT_TABLE_FIELDS = gql`
  ${TAG_FIELDS}
  ${IMAGE_FIELDS}
  fragment ProductTableFields on Product {
    id
    productTitle
    image {
      ...ImageFields
    }
    brand {
      brandName
    }
    enabled
    tags {
      ...TagFields
    }
  }
`

const PRODUCTS_QUERY = gql`
  ${PRODUCT_TABLE_FIELDS}
  ${PAGING_FIELDS}
  query ProductsQuery($paging: CursorPaging, $filter: ProductFilter, $sorting: [ProductSort!]) {
    products(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...ProductTableFields
        }
      }
      pageInfo {
        ...PagingFields
      }
    }
  }
`

const PRODUCT_QUERY = gql`
  ${PRODUCT_FIELDS}
  query ProductQuery($id: ID!) {
    product(id: $id) {
      ...ProductFields
    }
  }
`

const PRODUCT_IDS_QUERY = gql`
  query AllProducts($paging: CursorPaging, $filter: ProductFilter, $sorting: [ProductSort!]) {
    allProducts(paging: $paging, filter: $filter, sorting: $sorting) {
      id
    }
  }
`

const UPDATE_PRODUCT = gql`
  ${PRODUCT_FIELDS}
  mutation UpdateProduct($id: ID!, $update: ProductsInput!) {
    updateOneProduct(input: { id: $id, update: $update }) {
      ...ProductFields
    }
  }
`

const UPDATE_PRODUCTS = gql`
  mutation UpdateProducts($ids: [ID!]!, $update: ProductsInput!) {
    updateManyProducts(input: { filter: { id: { in: $ids } }, update: $update }) {
      updatedCount
    }
  }
`

const SET_TAGS_ON_PRODUCT = gql`
  ${TAG_FIELDS}
  mutation SetTagsOnProduct($id: ID!, $tagIds: [ID!]!) {
    setTagsOnProduct(input: { id: $id, relationIds: $tagIds }) {
      id
      tags {
        ...TagFields
      }
    }
  }
`

const SET_IMAGE_ON_PRODUCT = gql`
  ${IMAGE_FIELDS}
  mutation SetImageOnProduct($id: ID!, $imageId: ID!) {
    setImageOnProduct(input: { id: $id, relationId: $imageId }) {
      id
      image {
        ...ImageFields
      }
    }
  }
`

const SET_IMAGES_ON_PRODUCT = gql`
  ${IMAGE_FIELDS}
  mutation SetImagesOnProduct($id: ID!, $imageIds: [ID!]!) {
    setImagesOnProduct(input: { id: $id, relationIds: $imageIds }) {
      id
      images {
        ...ImageFields
      }
    }
  }
`

const UPDATE_PRODUCT_VARIANT = gql`
  ${VARIANT_FIELDS}
  mutation UpdateProductVariant($id: ID!, $update: ProductVariantsInput!) {
    updateOneProductVariant(input: { id: $id, update: $update }) {
      ...VariantFields
    }
  }
`

gql`
  mutation IndexProduct($id: String!) {
    indexProduct(id: $id)
  }
`

const buildProductsFilter = (filter?: FilterFields): { [x: string]: any } => {
  const f = { and: [], or: [] } as { [x: string]: any }
  if (filter) {
    if (filter.title) {
      f.and.push({ or: [{ title: { iLike: `%${filter.title}%` } }, { providerTitle: { iLike: `%${filter.title}%` } }] })
    }
    if ('enabled' in filter) {
      f.enabled = { is: filter.enabled }
    }

    if ('status' in filter) {
      f.status = { eq: filter.status }
    } else {
      f.status = { eq: 'active' }
    }

    if (filter['brand.name']) {
      const brandFilter = {
        or: [
          { brand: { name: { iLike: `%${filter['brand.name']}%` } } },
          { brand: { providerName: { iLike: `%${filter['brand.name']}%` } } }
        ]
      }
      f.and.push(brandFilter)
    }
    if (filter.tags) {
      if (isArray(filter.tags) && filter.tags.length > 0) {
        f.tags = { or: filter.tags.map((tag) => ({ id: { eq: tag } })) }
      }
    }
    if (filter.brand) {
      f.and.push({ brand: { id: { eq: filter.brand } } })
    }
  }
  return f
}

function useProductsQuery(args: { options?: QueryHookOptions; persist: boolean }): TUsePaginatedQuery<ProductsResult> {
  const { options, persist = false } = args

  const filter = useMemo(() => {
    return buildProductsFilter
  }, [buildProductsFilter])
  return usePaginatedQuery<ProductsResult, Product>({
    query: PRODUCTS_QUERY,
    accessor: 'products',
    options,
    buildFilter: filter,
    persist,
    limit: 50
  })
}

function useProductQuery(id: string): QueryResult<{ product: Product }> {
  return useQuery<{ product: Product }>(PRODUCT_QUERY, { variables: { id } })
}

interface ProductIdsRes {
  allProducts: {
    id: string
  }[]
}
function useProductIdsQuery(options?: QueryHookOptions<ProductIdsRes>): QueryTuple<ProductIdsRes, OperationVariables> {
  return useLazyQuery<ProductIdsRes>(PRODUCT_IDS_QUERY, {
    fetchPolicy: 'network-only',
    ...options
  })
}

function useUpdateProductMutation(
  options?: MutationHookOptions
): { updateProduct: (id: string, update: ProductUpdate) => void; loading: boolean } {
  const [updateProductMutation, { loading }] = useMutation(UPDATE_PRODUCT, options)

  const updateProduct = useCallback(
    async (id: string, update: ProductUpdate) => {
      try {
        await updateProductMutation({ variables: { id, update } })
      } catch (e) {
        console.error(e)
      }
    },
    [updateProductMutation]
  )

  return { updateProduct, loading }
}

function useUpdateProductsMutation(
  options?: MutationHookOptions
): { updateProducts: (ids: string[], update: ProductUpdate) => void; loading: boolean } {
  const [updateProductsMutation, { loading }] = useMutation(UPDATE_PRODUCTS, options)

  const updateProducts = useCallback(
    async (ids: string[], update: ProductUpdate) => {
      try {
        await updateProductsMutation({ variables: { ids, update } })
      } catch (e) {
        console.error(e)
      }
    },
    [updateProductsMutation]
  )

  return { updateProducts, loading }
}

function useSetTagsOnProductMutation(
  options?: MutationHookOptions
): { setTagsOnProduct: (id: string, tagIds: string[]) => void; loading: boolean } {
  const [setTagsOnProductMutation, { loading }] = useMutation(SET_TAGS_ON_PRODUCT, options)

  const setTagsOnProduct = useCallback(
    async (id: string, tagIds: string[]) => {
      try {
        await setTagsOnProductMutation({ variables: { id, tagIds } })
      } catch (e) {
        console.error(e)
      }
    },
    [setTagsOnProductMutation]
  )

  return { setTagsOnProduct, loading }
}

type ImageUpdateRes = { id: string; image: Image }
type ImageUpdateReq = { id: string; imageId: string }

function useSetImageOnProductMutation(
  options?: MutationHookOptions<ImageUpdateRes, ImageUpdateReq>
): { setImageOnProduct: (id: string, imageId: string) => void; loading: boolean } {
  const [setImageOnProductMutation, { loading }] = useMutation<ImageUpdateRes, ImageUpdateReq>(
    SET_IMAGE_ON_PRODUCT,
    options
  )

  const setImageOnProduct = useCallback(
    async (id: string, imageId: string) => {
      try {
        await setImageOnProductMutation({ variables: { id, imageId } })
      } catch (e) {
        console.error(e)
      }
    },
    [setImageOnProductMutation]
  )

  return { setImageOnProduct, loading }
}

type ImagesUpdateReq = { id: string; imageIds: string[] }

function useSetImagesOnProductMutation(
  options?: MutationHookOptions<ImageUpdateRes[], ImagesUpdateReq>
): { setImagesOnProduct: (id: string, imageIds: string[]) => void; loading: boolean } {
  const [setImagesOnProductMutation, { loading }] = useMutation<ImageUpdateRes[], ImagesUpdateReq>(
    SET_IMAGES_ON_PRODUCT,
    options
  )

  const setImagesOnProduct = useCallback(
    async (id: string, imageIds: string[]) => {
      try {
        await setImagesOnProductMutation({ variables: { id, imageIds } })
      } catch (e) {
        console.error(e)
      }
    },
    [setImagesOnProductMutation]
  )

  return { setImagesOnProduct, loading }
}

type ProductVariantInput = { id: string; update: ProductVariantUpdate }

function useUpdateProductVariantMutation(
  options?: MutationHookOptions<Variant, ProductVariantInput>
): { updateProductVariant: (id: string, update: ProductVariantUpdate) => void; loading: boolean } {
  const [updateProductVariantMutation, { loading }] = useMutation<Variant, ProductVariantInput>(
    UPDATE_PRODUCT_VARIANT,
    options
  )

  const updateProductVariant = useCallback(
    async (id: string, update: ProductVariantUpdate) => {
      try {
        await updateProductVariantMutation({ variables: { id, update } })
      } catch (e) {
        console.error(e)
      }
    },
    [updateProductVariantMutation]
  )
  return { updateProductVariant, loading }
}

export {
  buildProductsFilter,
  useProductsQuery,
  useProductQuery,
  useProductIdsQuery,
  useUpdateProductMutation,
  useUpdateProductsMutation,
  useSetTagsOnProductMutation,
  useSetImageOnProductMutation,
  useSetImagesOnProductMutation,
  useUpdateProductVariantMutation
}
export type { ProductUpdate }
