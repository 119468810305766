import { RouteComponentProps } from '@reach/router'
import { useLocation } from '@reach/router'
import { useMemo } from 'react'
import logo from '../../images/tle-logo-stacked-black.png'

export const BrandVerify: React.FC<RouteComponentProps> = () => {
  const location = useLocation()
  const hasError = useMemo(() => location.search === '?error=true', [])

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="TLE Logo" />
        {hasError ? (
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            An error has occurred trying to verify your brand. Please contact admin.
          </h2>
        ) : (
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Thank you for verifying your brand.
          </h2>
        )}
      </div>
    </div>
  )
}
