import React, { FC, useState } from 'react'
import { PencilIcon } from '@heroicons/react/solid'
import { Modal } from '@plusplusminus/plusplusdash'
import { useUpdaterOrderLinePaymentDateMutation, OrderLinePaymentDateInput } from 'generated'
import toast from 'react-hot-toast'
import { SubmitHandler } from 'react-hook-form'
import RefundPayoutDateForm from './RefundPayoutDateForm'

interface RefundPayoutDateEditProps {
  orderLineId: string
  currentPaymentDate: Date
  onRefetch: () => void
}
const RefundPayoutDateEdit: FC<RefundPayoutDateEditProps> = ({ orderLineId, onRefetch, currentPaymentDate }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  const [updateOrderLinePaymentDate, { loading }] = useUpdaterOrderLinePaymentDateMutation({
    onCompleted: () => {
      onClose()
      onRefetch()
      toast.success('Successfully updated order line payment date')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update order line payment date')
    }
  })

  const onSubmit: SubmitHandler<OrderLinePaymentDateInput> = (input) => {
    updateOrderLinePaymentDate({ variables: { input } })
  }
  return (
    <>
      <button className="mb-1" type="button" onClick={onOpen}>
        <PencilIcon className="h-5 w-5" />
      </button>
      <Modal isOpen={isOpen} onClose={onClose} size="sm">
        <Modal.Title>Edit Payment Date</Modal.Title>
        <Modal.Description className="my-4">
          <RefundPayoutDateForm
            defaultValues={{ id: orderLineId, paymentDate: currentPaymentDate }}
            onSubmit={onSubmit}
            onClose={onClose}
            isLoading={loading}
          />
        </Modal.Description>
      </Modal>
    </>
  )
}

export default RefundPayoutDateEdit
