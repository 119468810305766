import { gql } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Card } from 'components/Card'
import CreateMessageComponent from 'components/CreateMessageComponent'
import { PageHeader } from 'components/PageHeader'

export default function CreateMessage(props: RouteComponentProps): JSX.Element {
  const onCompleted = () => {
    props.navigate?.('/messages')
  }

  return (
    <div>
      <PageHeader>Create Message</PageHeader>
      <div className="p-5">
        <Card>
          <Card.Content>
            <CreateMessageComponent onCompleted={onCompleted} />
          </Card.Content>
        </Card>
      </div>
    </div>
  )
}

CreateMessage.mutation = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      name
    }
  }
`
