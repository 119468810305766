import { PlusCircleIcon, TrashIcon } from '@heroicons/react/solid'
import { ReactElement } from 'react'
import cn from 'classnames'

interface RepeaterProps<T> {
  data: T[]
  renderItem: (item: T, index: number) => JSX.Element
  onAdd: (item: T) => void
  onRemove: (index: number) => void
}

const itemStyle = 'flex border border-gray-200 gap-x-4 items-center w-full rounded-md py-2 px-4 rounded-md'

const Repeater = <T,>(props: RepeaterProps<T>): ReactElement => {
  const { data, renderItem, onAdd, onRemove } = props

  const onClickAdd = () => {
    onAdd({} as T)
  }
  const onClickRemove = (index: number) => () => {
    onRemove(index)
  }

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col gap-y-4">
        {data.map((item, index) => {
          return (
            <div key={index} className={itemStyle}>
              {renderItem(item, index)}
              <TrashIcon className="w-4 h-4 text-red-500" onClick={onClickRemove(index)} />
            </div>
          )
        })}
      </div>
      <div className={cn(itemStyle, 'cursor-pointer')} onClick={onClickAdd}>
        <PlusCircleIcon className="h-5 w-5 mr-2" />
        <span>Add</span>
      </div>
    </div>
  )
}

export default Repeater
