import {
  ApolloError,
  useQuery,
  gql,
  useMutation,
  MutationHookOptions,
  QueryResult,
  QueryHookOptions
} from '@apollo/client'
import { TagTypes } from 'common/enums'
import { IMAGE_FIELDS, PAGING_FIELDS, TAG_FIELDS } from 'graphql/fragments'
import { useCallback } from 'react'
import { Image, PaginatedEntity, Tag, TagType } from '../common/types'
import { TUsePaginatedQuery, usePaginatedQuery } from './usePaginatedQuery'

interface TagsResult {
  tags: PaginatedEntity<Tag>
}
type AllTagsResult = { allTags: Tag[] }
type TagTypesResult = {
  tagTypes: {
    edges: {
      node: TagType
    }[]
  }
}
type TagForm = {
  name: string
  description?: string
  types?: string[]
  category?: string
}
type FilterFields = { name?: string; brand?: boolean; product?: boolean; story?: boolean; category?: string[] }
type TagInput = { name?: string; description?: string; image?: Image; types?: TagType[]; category?: string | null }

const TAG_QUERY = gql`
  ${TAG_FIELDS}
  query TagQuery($id: ID!) {
    tag(id: $id) {
      ...TagFields
    }
  }
`

const TAG_TYPES_QUERY = gql`
  query TagTypes {
    tagTypes {
      edges {
        node {
          name
        }
      }
    }
  }
`

gql`
  query TagCategories {
    tagCategories {
      name
      title
    }
  }
`

const ALL_TAGS_QUERY = gql`
  query AllTags($type: TagTypeEnum) {
    allTags(type: $type) {
      ...TagFields
    }
  }
  ${TAG_FIELDS}
`

const TAGS_QUERY = gql`
  ${TAG_FIELDS}
  ${PAGING_FIELDS}
  query TagsQuery($paging: CursorPaging, $filter: TagFilter, $sorting: [TagSort!]) {
    tags(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...TagFields
        }
      }
      pageInfo {
        ...PagingFields
      }
    }
  }
`

const DELETE_TAG = gql`
  mutation DeleteTag($id: ID!) {
    deleteOneTag(input: { id: $id }) {
      id
    }
  }
`

const DELETE_TAGS = gql`
  mutation DeleteTags($ids: [String!]!) {
    deleteManyTags(input: { filter: { id: { in: $ids } } }) {
      deletedCount
    }
  }
`

const UPDATE_TAG = gql`
  ${TAG_FIELDS}
  mutation UpdateTag($id: ID!, $update: UpdateTag!) {
    updateOneTag(input: { id: $id, update: $update }) {
      ...TagFields
    }
  }
`

const CREATE_TAG = gql`
  ${TAG_FIELDS}
  mutation CreateTag($tag: CreateTag!) {
    createOneTag(input: { tag: $tag }) {
      ...TagFields
    }
  }
`

const SET_IMAGE_ON_TAG = gql`
  ${IMAGE_FIELDS}
  mutation SetImageOnTag($id: ID!, $imageId: ID!) {
    setImageOnTag(input: { id: $id, relationId: $imageId }) {
      id
      image {
        ...ImageFields
      }
    }
  }
`

const PUBLISH_TAG_FEED_MUTATION = gql`
  mutation PublishTagFeed($tagId: String!) {
    publishTagFeed(tagId: $tagId)
  }
`

function useTagQuery(id: string): QueryResult<{ tag: Tag }> {
  return useQuery<{ tag: Tag }>(TAG_QUERY, { variables: { id } })
}

function useAllTags(type?: TagTypes): [Tag[] | undefined, ApolloError | undefined] {
  const variables = type ? { variables: { type } } : {}
  const res = useQuery<AllTagsResult, { type: string }>(ALL_TAGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...variables
  })
  return [res.data?.allTags, res.error]
}

const buildTagFilter = (filter?: FilterFields) => {
  const f = {} as { [x: string]: any }
  if (filter) {
    f.or = []
    if (filter.name) {
      f.name = { iLike: `%${filter.name}%` }
    }
    if (filter.brand) {
      f.or.push({ types: { name: { eq: 'Brand' } } })
    }
    if (filter.story) {
      f.or.push({ types: { name: { eq: 'Story' } } })
    }
    if (filter.product) {
      f.or.push({ types: { name: { eq: 'Product' } } })
    }
    if (filter.category) {
      f.category = { in: filter.category }
    }
  }
  return f
}

function usePaginatedTagsQuery(args: { options?: QueryHookOptions; persist: boolean }): TUsePaginatedQuery<TagsResult> {
  const { options, persist = false } = args
  return usePaginatedQuery<TagsResult, Tag>({
    query: TAGS_QUERY,
    accessor: 'tags',
    options,
    persist,
    buildFilter: buildTagFilter
  })
}

function useTagTypesQuery(): { tagTypes: TagType[] | undefined; loading: boolean; error: ApolloError | undefined } {
  const { data, loading, error } = useQuery<TagTypesResult>(TAG_TYPES_QUERY)
  const tagTypes = data?.tagTypes.edges.map(({ node }) => node)
  return { tagTypes, loading, error }
}

function useDeleteTagsMutation(options?: MutationHookOptions): (ids: string[]) => void {
  const [deleteTagsMutation] = useMutation(DELETE_TAGS, options)

  const deleteTags = useCallback(
    async (ids: string[]) => {
      try {
        await deleteTagsMutation({ variables: { ids } })
      } catch (e) {
        console.error(e)
      }
    },
    [deleteTagsMutation]
  )

  return deleteTags
}

function useDeleteTagMutation(options?: MutationHookOptions): { deleteTag: (id: string) => void; loading: boolean } {
  const [deleteTagMutation, { loading }] = useMutation(DELETE_TAG, options)

  const deleteTag = useCallback(
    async (id: string) => {
      try {
        await deleteTagMutation({ variables: { id } })
      } catch (e) {
        console.error(e)
      }
    },
    [deleteTagMutation]
  )

  return { deleteTag, loading }
}

function useUpdateTagMutation(
  options?: MutationHookOptions
): { updateTag: (id: string, update: TagInput) => void; loading: boolean } {
  const [updateTagMutation, { loading }] = useMutation(UPDATE_TAG, options)

  const updateTag = useCallback(
    async (id: string, update: TagInput) => {
      try {
        if (!update.types) {
          update.types = [{ name: 'Brand' }, { name: 'Story' }, { name: 'Product' }]
        }
        await updateTagMutation({ variables: { id, update } })
      } catch (e) {
        console.error(e)
      }
    },
    [updateTagMutation]
  )

  return { updateTag, loading }
}

function useCreateTagMutation(options?: MutationHookOptions): { createTag: (tag: TagInput) => void; loading: boolean } {
  const [createTagMutation, { loading }] = useMutation(CREATE_TAG, options)

  const createTag = useCallback(
    async (tag: TagInput) => {
      // remove __typename that cause invalid Image type
      const { image, ...t }: any = tag

      if (image) {
        const i: any = image
        delete i.__typename
        t.image = image
      }

      // Default to all tags
      if (!t.types) {
        t.types = [{ name: 'Brand' }, { name: 'Story' }, { name: 'Product' }]
      }

      try {
        await createTagMutation({
          variables: {
            tag: t
          }
        })
      } catch (e) {
        console.error(e)
      }
    },
    [createTagMutation]
  )

  return { createTag, loading }
}

type ImageUpdateRes = { id: string; image: Image }
type ImageUpdateReq = { id: string; imageId: string }

function useSetImageOnTagMutation(
  options?: MutationHookOptions<ImageUpdateRes, ImageUpdateReq>
): { setImageOnTag: (id: string, imageId: string) => void; loading: boolean } {
  const [setImageOnTagMutation, { loading }] = useMutation<ImageUpdateRes, ImageUpdateReq>(SET_IMAGE_ON_TAG, options)

  const setImageOnTag = useCallback(
    async (id: string, imageId: string) => {
      try {
        await setImageOnTagMutation({ variables: { id, imageId } })
      } catch (e) {
        console.error(e)
      }
    },
    [setImageOnTagMutation]
  )

  return { setImageOnTag, loading }
}

type PublishTagVars = { tagId: string }

function usePublishTagMutation(
  options?: MutationHookOptions<boolean, PublishTagVars>
): { publishTag: (tagId: string) => void; loading: boolean } {
  const [publishTagMutation, { loading }] = useMutation<boolean, PublishTagVars>(PUBLISH_TAG_FEED_MUTATION, options)
  const publishTag = useCallback(
    async (tagId: string) => {
      try {
        await publishTagMutation({ variables: { tagId } })
      } catch (e) {
        console.error(e)
      }
    },
    [publishTagMutation]
  )
  return { publishTag, loading }
}

export {
  useTagQuery,
  useAllTags,
  usePaginatedTagsQuery,
  useTagTypesQuery,
  useDeleteTagMutation,
  useDeleteTagsMutation,
  useUpdateTagMutation,
  useCreateTagMutation,
  useSetImageOnTagMutation,
  usePublishTagMutation
}
export type { TagInput, TagForm }
