import React, { forwardRef } from 'react'
import cn from 'classnames'
import { styles } from './styles'

interface GridItemProps {
  colStart?: number
  colEnd?: number
  colSpan?: number
  rowStart?: number
  rowEnd?: number
  rowSpan?: number
  children?: React.ReactNode
  className?: string
}

export const GridItem = forwardRef<HTMLDivElement, GridItemProps>((props, ref) => {
  const {
    colStart,
    colEnd,
    colSpan,
    rowStart,
    rowEnd,
    rowSpan,
    children,
    className: overrideClassname,
    ...rest
  } = props

  const className = cn(
    styles.getItemDimensions({
      start: colStart,
      end: colEnd,
      span: colSpan,
      type: 'column'
    }),
    styles.getItemDimensions({
      start: rowStart,
      end: rowEnd,
      span: rowSpan,
      type: 'row'
    }),
    overrideClassname
  )

  return (
    <div ref={ref} className={className} {...rest}>
      {children}
    </div>
  )
})
