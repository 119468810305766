import { PlusCircleIcon as PlusCircleIconOutline } from '@heroicons/react/outline'
import { Modal } from '@plusplusminus/plusplusdash'
import cn from 'classnames'
import CreateMessageComponent from 'components/CreateMessageComponent'
import React, { useState } from 'react'

interface CreateMessageModal {
  onCompleted?: (id: string, name: string) => void
  className?: string
}

export const CreateMessageModal: React.FC<CreateMessageModal> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const onCompleted = (id: string, name: string) => {
    setIsOpen(false)
    props.onCompleted?.(id, name)
  }
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <PlusCircleIconOutline
        className={cn('h-4 w-4 cursor-pointer', props.className)}
        onClick={() => setIsOpen(true)}
      />
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="lg">
        <Modal.Title>Create Message</Modal.Title>
        <CreateMessageComponent onCompleted={onCompleted} />
      </Modal>
    </div>
  )
}
