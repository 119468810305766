import React, { useMemo } from 'react'
import { gql } from '@apollo/client'
import { useVoucherReasonsQuery } from 'generated'
import { Select } from '@plusplusminus/plusplusdash'

interface VoucherReasonsSelectProps {
  onChange: (value: string) => void
  value: string
}

const VoucherReasonsSelect = ({ onChange, value }: VoucherReasonsSelectProps) => {
  const { data, loading } = useVoucherReasonsQuery()
  const voucherReasonOptions = useMemo(() => {
    if (data) {
      return data.voucherReasons.map((reason) => ({
        key: reason.replace(/\s/g, ''),
        label: reason,
        value: reason
      }))
    }
    return []
  }, [data])
  if (loading || !voucherReasonOptions.length) return null
  return <Select value={`${value}`} items={voucherReasonOptions} onChange={onChange} />
}

export default VoucherReasonsSelect

VoucherReasonsSelect.query = gql`
  query voucherReasons {
    voucherReasons
  }
`
