import { useContext } from 'react'
import { ILoginContext, LoginContext } from '../context/LoginContext'

export const useUser = (): ILoginContext => {
  const context = useContext(LoginContext)

  if (!context) {
    throw new Error('useUser must be used withing a LoginProvider')
  }

  return context
}
