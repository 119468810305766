import { gql } from '@apollo/client'
import { Form, FormError, FormField, FormLabel, Popover } from '@plusplusminus/plusplusdash'
import Button from 'components/Button'
import { VouchersExportMutationVariables, useVouchersExportMutation } from 'generated'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import VoucherReasonsSelect from './VoucherReasonsSelect'

export default function VouchersExport(): JSX.Element {
  const { control, errors, handleSubmit } = useForm()

  const [exportCsv, { loading }] = useVouchersExportMutation({
    onCompleted: () => toast.success('Sent vouchers csv to admin email'),
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not set vouchers csv')
    }
  })

  const onSubmit = (close: () => void) => async ({ reason }: { reason: string | undefined }) => {
    const variables = { reason } as VouchersExportMutationVariables
    await exportCsv({ variables })
    close()
  }

  return (
    <Popover>
      <Popover.Button>Export</Popover.Button>
      <Popover.Panel className="w-96">
        {({ close }: { close: any }) => (
          <Form className="p-8" onSubmit={handleSubmit(onSubmit(close))}>
            <div className="mb-4">
              <FormField>
                <FormLabel htmlFor="codeId">Select a Reason</FormLabel>
                <Controller
                  control={control}
                  id="reason"
                  name="reason"
                  rules={{
                    required: 'Please select a reason'
                  }}
                  render={({ onChange, value }) => (
                    <div className="flex-1">
                      <VoucherReasonsSelect onChange={onChange} value={value} />
                    </div>
                  )}
                />
                {errors['reason']?.message ? <FormError>{errors['reason'].message}</FormError> : null}
              </FormField>
            </div>
            <div className="flex justify-end gap-2">
              <Button isDisabled={loading} variant="plain" onClick={close} type="button">
                Close
              </Button>
              <Button isDisabled={loading} isLoading={loading} variant="primary" colorScheme="green" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Popover.Panel>
    </Popover>
  )
}

VouchersExport.mutation = gql`
  mutation VouchersExport($reason: String) {
    sendVouchersCsv(reason: $reason)
  }
`
