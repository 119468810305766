import { Modal } from '@plusplusminus/plusplusdash'
import { CreateMessageCampaignInput, CreateMessageCampaignMutation } from 'generated'
import { EmailTemplatesCollection } from 'hooks/useEmailTemplates'
import { Moment } from 'moment'
import CreateMessageCampaignForm from './CreateMessageCampaignForm'

type CreateMessageCampaignForm = Omit<CreateMessageCampaignInput, 'scheduledAt' | 'nextRunAt' | 'timeToRun'> & {
  scheduledAt: Moment
  accountGroupIds: string
  nextRunAt?: Moment
  timeToRun?: Moment
  messageName?: string
}

interface CreateMessageCampaignModalProps {
  emailTemplateCollection: EmailTemplatesCollection
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  messageId?: string
  messageName?: string
  onCompleted?: (data?: CreateMessageCampaignMutation) => void
}

export default function CreateMessageCampaignModal({
  isOpen,
  onClose,
  onOpen,
  ...props
}: CreateMessageCampaignModalProps): JSX.Element {
  return (
    <>
      <button type="button" style={{ color: '#1890ff' }} onClick={onOpen}>
        Send campaign
      </button>
      <Modal isOpen={isOpen} size="lg" onClose={onClose}>
        <Modal.Title>Send a message campaign for this message</Modal.Title>
        <Modal.Description>
          <div className="mt-5">
            <CreateMessageCampaignForm {...props} />
          </div>
        </Modal.Description>
      </Modal>
    </>
  )
}
