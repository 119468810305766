import { Input } from '@plusplusminus/plusplusdash'
import { InputProps } from 'common/types'
import React, { useCallback, useMemo } from 'react'
import FilterWrapper from './FilterWrapper'

// Define a function to be executed after a delay
function debounce(func: any, delay: any) {
  let timeoutId: any
  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func(...args)
      timeoutId = null
    }, delay)
  }
}

type DebouncedSearchInputProps = InputProps &
  React.RefAttributes<HTMLInputElement | HTMLTextAreaElement> & {
    onRemoveFilter: () => void
    closeClassName?: string
  }

const DebouncedSearchInput: React.FC<DebouncedSearchInputProps> = ({
  onRemoveFilter,
  closeClassName,
  value: initialValue,
  onChange,
  ...props
}) => {
  const [value, setValue] = React.useState(initialValue)
  const handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (event) => {
      onChange?.(event)
    },
    [onChange]
  )

  const debounceOnChange = useMemo(() => debounce(handleChange, 1000), [handleChange])

  const onRemoveFilterAction = () => {
    setValue('')
    onRemoveFilter()
  }
  return (
    <FilterWrapper canRemoveFilter={!!value} removeFilterAction={onRemoveFilterAction} closeClassName={closeClassName}>
      <Input
        {...props}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          debounceOnChange(e)
        }}
      />
    </FilterWrapper>
  )
}

export default DebouncedSearchInput
