import { Redirect, RouteComponentProps } from '@reach/router'
import { React } from '@ungap/global-this'
import { FC } from 'react'
import { Permission } from '../../common/types'
import { useUser } from '../../hooks/useUser'

interface PrivateRouteProps extends RouteComponentProps {
  Component: React.ComponentType<RouteComponentProps & any>
  roles?: Permission[]
}

export const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const { Component, roles, children, ...rest } = props
  const { isAuth, user } = useUser()

  const currentRoles = user?.roles ?? []

  const hasRole = roles && roles.length > 0 ? currentRoles.find((role) => roles.includes(role)) : false
  const isAllowed = isAuth && hasRole

  return isAllowed ? (
    <Component {...rest}>{children}</Component>
  ) : !isAuth ? (
    <Redirect to="/login" noThrow />
  ) : !hasRole ? (
    <Redirect to="/" noThrow />
  ) : null
}
