import { gql } from '@apollo/client'
import { Checkbox, Form, FormError, FormLabel, Input, Loader, Radio } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import Select from 'components/Select'
import { MessageQuery, UpdateMessageInput, useMessageQuery, useUpdateMessageMutation } from 'generated'
import { useDeleteModal } from 'hooks/useDeleteModal'
import { useEmailTemplates } from 'hooks/useEmailTemplates'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import DeleteMessageModal from './DeleteMessageModal'
import ViewMessage from './ViewMessage'
import Button from 'components/Button'

interface EditMessageProps extends RouteComponentProps {
  id: string
}

type FormData = Omit<UpdateMessageInput, 'meta'> & { deeplink: string }

function getFormValues(data: MessageQuery['message']) {
  return {
    name: data?.name,
    title: data?.title,
    body: data?.body,
    notificationTitle: data?.notificationTitle,
    notificationBody: data?.notificationBody,
    contentId: data?.contentId,
    deeplink: data?.meta?.deeplink,
    enabled: data?.enabled
  }
}

export default function EditMessage(props: EditMessageProps): JSX.Element {
  const [contentfulTemplates, setContentfulTemplates] = useState(false)
  const deleteModalProps = useDeleteModal()

  const { data, loading } = useMessageQuery({ variables: { id: props.id } })
  const { data: emailTemplatesData, loading: loadingEmailTemplates } = useEmailTemplates()
  const [updateMessage, { loading: loadingUpdateMessage }] = useUpdateMessageMutation({
    onCompleted: () => {
      toast.success('Successfully updated message.')
      props.navigate?.(`/messages/${props.id}/view`)
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update message')
    }
  })

  const { register, reset, handleSubmit, errors, control } = useForm<FormData>({
    defaultValues: getFormValues(data?.message)
  })

  useEffect(() => {
    if (data) {
      reset(getFormValues(data.message))
      if (data.message?.contentId) {
        setContentfulTemplates(true)
      }
    }
  }, [data])

  const onSubmit = ({ deeplink, ...data }: FormData) => {
    const input = { ...data } as UpdateMessageInput
    if (deeplink) {
      input.meta = { deeplink }
    }
    updateMessage({ variables: { id: props.id, input } })
  }

  const openDelete = () => deleteModalProps.openModal(props.id, data?.message?.name)

  return (
    <div>
      <DeleteMessageModal
        {...deleteModalProps}
        onDelete={() => {
          props.navigate?.(`/messages`)
        }}
      />
      <PageHeader>
        <h1 className="text-lg">Edit Message</h1>
        <Button variant="primary" colorScheme="red" onClick={openDelete}>
          Delete
        </Button>
      </PageHeader>
      <div className="p-5">
        <Card>
          {loading ? (
            <Loader isActive />
          ) : (
            <>
              <Card.Content>
                {!loadingEmailTemplates && emailTemplatesData && (
                  <div className="mb-2">
                    <p className="mb-1 text-sm font-bold">Choose a template</p>
                    <div className="flex space-x-4 items-center mb-2">
                      <FormLabel htmlFor="basic" className="flex items-center">
                        <Radio
                          id="basic"
                          name="basic"
                          checked={!contentfulTemplates}
                          onClick={() => setContentfulTemplates(false)}
                        />
                        Basic
                      </FormLabel>
                      <FormLabel htmlFor="contentful" className="flex items-center">
                        <Radio
                          id="contentful"
                          name="contentful"
                          checked={contentfulTemplates}
                          onClick={() => setContentfulTemplates(true)}
                        />
                        Contentful
                      </FormLabel>
                    </div>
                  </div>
                )}
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-4 items-start">
                    <FormLabel>
                      <strong>Name*</strong>
                      <Input
                        width="full"
                        variant="standard"
                        name="name"
                        ref={register({ required: 'Name is required' })}
                      />
                      {errors.name && <FormError>{errors.name.message}</FormError>}
                    </FormLabel>
                    <FormLabel>
                      <strong>Enabled</strong>
                      <Controller
                        name="enabled"
                        control={control}
                        render={({ value, onChange }) => (
                          <Checkbox
                            id="enabled"
                            className="block ml-1"
                            checked={value}
                            onChange={() => onChange(!value)}
                          />
                        )}
                      />
                      {errors.name && <FormError>{errors.name.message}</FormError>}
                    </FormLabel>
                    <div>
                      {contentfulTemplates && emailTemplatesData ? (
                        <FormLabel>
                          <strong>Contentful Email Template</strong>
                          <Controller
                            control={control}
                            name="contentId"
                            defaultValue={data?.message?.contentId}
                            render={(props) => (
                              <Select
                                options={emailTemplatesData.emailTemplatesCollection.items.map((email) => ({
                                  label: email.name,
                                  key: email.sys.id,
                                  value: email.sys.id
                                }))}
                                {...props}
                              />
                            )}
                          />
                        </FormLabel>
                      ) : (
                        <>
                          <FormLabel className="block mb-4">
                            <strong>Subject</strong>
                            <Input width="full" variant="standard" name="title" ref={register} />
                            {errors.title && <FormError>{errors.title.message}</FormError>}
                          </FormLabel>
                          <FormLabel>
                            <strong>Message Body</strong>
                            <Input width="full" as="textarea" variant="standard" name="body" ref={register} />
                          </FormLabel>
                        </>
                      )}
                    </div>
                    <div>
                      <FormLabel className="block mb-4">
                        <strong>Notification Title</strong>
                        <Input width="full" variant="standard" name="notificationTitle" ref={register} />
                      </FormLabel>
                      <FormLabel>
                        <strong>Notification Body</strong>
                        <Input width="full" as="textarea" variant="standard" name="notificationBody" ref={register} />
                      </FormLabel>
                    </div>
                    <FormLabel>
                      <strong>Deeplink</strong>
                      <Input width="full" variant="standard" name="deeplink" ref={register} />
                    </FormLabel>
                  </div>
                  <Button
                    colorScheme="green"
                    type="submit"
                    variant="primary"
                    isDisabled={loadingUpdateMessage}
                    isLoading={loadingUpdateMessage}
                  >
                    Save
                  </Button>
                </Form>
              </Card.Content>
            </>
          )}
        </Card>
      </div>
    </div>
  )
}

EditMessage.mutation = gql`
  mutation UpdateMessage($id: String!, $input: UpdateMessageInput!) {
    updateMessage(id: $id, input: $input) {
      ...MessageFields
    }
  }
  ${ViewMessage.fields}
`
