import { CheckIcon } from '@heroicons/react/solid'
import { Checkbox, Dropdown, FormLabel, Popover } from '@plusplusminus/plusplusdash'
import cn from 'classnames'
import { Direction } from 'common/enums'
import DebouncedSearchInput from 'components/DebouncedSearchInput'
import FilterWrapper from 'components/FilterWrapper'
import { useAllBrandsQuery } from 'hooks/brands'
import { TUseFilter } from 'hooks/useFilter'
import { SortTuple } from 'hooks/useSort'
import React, { useState } from 'react'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

const dropdownItemStyle = 'block w-full text-left px-5 py-2 hover:bg-gray-100 text-sm rounded-sm cursor-pointer'
const sortItems = [
  { key: 'created_asc', label: 'Created Date: Ascending', value: 'createdAt', direction: Direction.ASC },
  { key: 'created_desc', label: 'Created Date: Descending', value: 'createdAt', direction: Direction.DESC },
  { key: 'updated_asc', label: 'Updated Date: Ascending', value: 'updatedAt', direction: Direction.ASC },
  { key: 'updated_desc', label: 'Updated Date: Descending', value: 'updatedAt', direction: Direction.DESC }
]

type OrdersFilterProps = {
  filtering: TUseFilter
  sort: SortTuple
  isBrand?: boolean
}

const OrdersFilter: React.FC<OrdersFilterProps> = (props) => {
  const {
    filtering: { filter, setFilter, pushFilter, removeFilter },
    sort,
    isBrand = false
  } = props

  const [sortField, direction, , setSort, removeSort] = sort

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>('startDate')

  const { data, loading: loadingBrands } = useAllBrandsQuery({ skip: isBrand })

  const removeDatesFilter = () => {
    removeFilter('startDate')
    removeFilter('endDate')
  }

  const customSort = (name: string, direction: Direction) => () => {
    setSort(name, direction)
  }

  const isDatesFilter = Boolean(filter.startDate) || Boolean(filter.endDate)

  return (
    <>
      <div className="flex gap-2">
        <DebouncedSearchInput
          onRemoveFilter={() => removeFilter('account')}
          as="input"
          variant="outline"
          id="account"
          name="account"
          onChange={(e) => setFilter('account', e.target.value)}
          value={filter.account ?? ''}
          placeholder="Search by user"
        />
        <DebouncedSearchInput
          onRemoveFilter={() => removeFilter('orderNumber')}
          as="input"
          variant="outline"
          id="orderNumber"
          name="orderNumber"
          onChange={(e) => setFilter('orderNumber', e.target.value)}
          value={filter.orderNumber ?? ''}
          placeholder="Search by order number"
        />
      </div>
      <div className="flex gap-2">
        <FilterWrapper canRemoveFilter={isDatesFilter} removeFilterAction={removeDatesFilter}>
          <Popover>
            <Popover.Button>Dates</Popover.Button>
            <Popover.Panel className="w-60">
              <div className="py-2 px-4">
                <FormLabel className="font-medium mb-2">Select date range</FormLabel>
                <DateRangePicker
                  startDate={filter.startDate}
                  startDateId={'startDate'}
                  endDate={filter.endDate}
                  endDateId={'endDate'}
                  onDatesChange={({ startDate, endDate }) => {
                    setFilter('startDate', startDate)
                    setFilter('endDate', endDate)
                  }}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  small
                  block
                  isOutsideRange={() => false}
                />
              </div>
            </Popover.Panel>
          </Popover>
        </FilterWrapper>
        {!loadingBrands && !isBrand ? (
          <FilterWrapper
            canRemoveFilter={!!filter.brands}
            removeFilterAction={() => removeFilter('brands', undefined, { isArray: true })}
          >
            <Popover>
              <Popover.Button>Brands</Popover.Button>
              <Popover.Panel>
                <div className="py-2 px-4">
                  <FormLabel className="font-medium mb-2">Select Brands to filter by</FormLabel>
                  <div className="max-h-64 overflow-y-auto px-2">
                    {data?.allBrands.map((brand) => (
                      <FormLabel key={brand.id} htmlFor={brand.id} className="flex gap-2 items-center my-1">
                        <Checkbox
                          id={brand.id}
                          name="brand"
                          checked={filter.brands?.includes(brand.id)}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.checked) {
                              pushFilter('brands', brand.id)
                            } else {
                              removeFilter('brands', brand.id)
                            }
                          }}
                        />
                        <span>{brand.brandName}</span>
                      </FormLabel>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Popover>
          </FilterWrapper>
        ) : null}
        <FilterWrapper canRemoveFilter={!!sortField} removeFilterAction={removeSort}>
          <Dropdown>
            <Dropdown.Button>Sort By</Dropdown.Button>
            <Dropdown.Items className="z-10">
              {sortItems.map((item) => (
                <Dropdown.Item>
                  <button
                    key={item.key}
                    className={cn(dropdownItemStyle, 'flex justify-between items-center')}
                    onClick={customSort(item.value, item.direction)}
                  >
                    {item.label}
                    {sortField === item.value && direction?.value === item.direction ? (
                      <CheckIcon className="h-4 w-4 text-green-600" />
                    ) : null}
                  </button>
                </Dropdown.Item>
              ))}
            </Dropdown.Items>
          </Dropdown>
        </FilterWrapper>
      </div>
    </>
  )
}

export { OrdersFilter }
