import { gql } from '@apollo/client'
import { Badge, Button, Loader } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import { useMessageQuery } from 'generated'
import { useEmailTemplates } from 'hooks/useEmailTemplates'

interface ViewMessageProps extends RouteComponentProps {
  id: string
}

export default function ViewMessage(props: ViewMessageProps): JSX.Element {
  const { data, loading } = useMessageQuery({ variables: { id: props.id } })
  const { data: emailTemplatesData, loading: loadingEmailTemplates } = useEmailTemplates()

  const toggleEdit = () => {
    props.navigate?.(`/messages/${props.id}/edit`)
  }

  return (
    <div>
      <PageHeader>
        <h1 className="text-lg">View Message</h1>
        <Button variant="primary" colorScheme="blue" onClick={toggleEdit}>
          Edit
        </Button>
      </PageHeader>
      <div className="p-5">
        <Card>
          {loading ? (
            <Loader isActive />
          ) : (
            <>
              <Card.Header>
                <div>{data?.message?.name}</div>
                <Badge color={data?.message?.enabled ? 'green' : 'red'}>
                  {data?.message?.enabled ? 'Enabled' : 'Disabled'}
                </Badge>
              </Card.Header>
              <Card.Content>
                <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                  <div>
                    <strong>Subject</strong>
                    <p>{data?.message?.title}</p>
                  </div>
                  <div>
                    <strong>Notification Title</strong>
                    <p>{data?.message?.notificationTitle}</p>
                  </div>
                  <div>
                    <strong>Body</strong>
                    <p>{data?.message?.body}</p>
                  </div>
                  <div>
                    <strong>Notification Body</strong>
                    <p>{data?.message?.notificationBody}</p>
                  </div>
                  {data?.message?.contentId && !loadingEmailTemplates && emailTemplatesData ? (
                    <div>
                      <strong>Contentful Email</strong>
                      <a
                        className="block underline text-blue-400"
                        href={`https://app.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}/entries/${data.message.contentId}`}
                        target="__blank"
                        rel="noopener noreferrer"
                      >
                        {
                          emailTemplatesData.emailTemplatesCollection.items.find(
                            (e) => e.sys.id === data.message?.contentId
                          )?.name
                        }
                      </a>
                    </div>
                  ) : null}
                  <div>
                    <strong>Deeplink</strong>
                    <p>{data?.message?.meta?.deeplink}</p>
                  </div>
                </div>
              </Card.Content>
            </>
          )}
        </Card>
      </div>
    </div>
  )
}

ViewMessage.fields = gql`
  fragment MessageFields on Message {
    id
    name
    title
    body
    contentId
    notificationTitle
    notificationBody
    enabled
    meta {
      deeplink
    }
  }
`

ViewMessage.query = gql`
  query Message($id: ID!) {
    message(id: $id) {
      ...MessageFields
    }
  }
  ${ViewMessage.fields}
`
