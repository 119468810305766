import { XIcon } from '@heroicons/react/solid'
import React from 'react'

interface FilterWrapperProps {
  canRemoveFilter: boolean
  removeFilterAction: () => void
  closeClassName?: string
}
const FilterWrapper: React.FC<FilterWrapperProps> = ({
  children,
  canRemoveFilter,
  removeFilterAction,
  closeClassName
}) => {
  return (
    <div className="flex items-center gap-2">
      {children}

      {canRemoveFilter && (
        <button type="button" className={closeClassName} onClick={removeFilterAction}>
          <XIcon className="w-5 h-5 text-gray-500" />
        </button>
      )}
    </div>
  )
}

export default FilterWrapper
