import {
  Alert,
  Badge,
  Box,
  Button,
  Checkbox,
  FormField,
  FormLabel,
  Loader,
  MultiSelectWithSearch,
  Popover,
  Table
} from '@plusplusminus/plusplusdash'
import { Link, RouteComponentProps, WindowLocation } from '@reach/router'
import DebouncedSearchInput from 'components/DebouncedSearchInput'
import FilterWrapper from 'components/FilterWrapper'
import { useDeleteTagsMutation, usePaginatedTagsQuery } from 'hooks/tags'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { getImageUrl } from 'utils'
import { DeleteModal } from '../../components/Modals/Delete'
import { Pagination } from '../../components/Pagination'
import { useTagCategoriesQuery } from 'generated'
import { useBatch } from '../../hooks/useBatch'
import dayjs from 'dayjs'

const TABLE_SHAPE = [
  { label: '', key: '', isSortable: false },
  { label: '', key: '', isSortable: false },
  { label: 'Name', key: 'name', isSortable: true },
  { label: 'Group', key: 'category', isSortable: false },
  { label: 'Type', key: 'types', isSortable: false },
  { label: 'Created At', key: 'createdAt', isSortable: true },
  { label: 'Updated At', key: 'updatedAt', isSortable: true },
  { label: '', key: '', isSortable: false }
]

export const Tags: React.FC<RouteComponentProps<{ location: WindowLocation<any> }>> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [selectedIds, onClickItem, contains, resetSelected] = useBatch()
  const { query, pagination, sort, filtering } = usePaginatedTagsQuery({ persist: true })
  const { refetch, loading, error, data, previousData } = query
  const { page, nextPage, prevPage } = pagination
  const [sortField, direction, sortCallback] = sort
  const { filter, setFilter, removeFilter } = filtering

  const { data: dataTagCategories, loading: loadingTagCategories } = useTagCategoriesQuery()
  const tagCategoriesOptions =
    dataTagCategories?.tagCategories.map(({ name, title }) => ({ value: name, label: title, key: name })) ?? []

  const reset = () => {
    setIsModalOpen(false)
    resetSelected()
    refetch()
  }

  const deleteTags = useDeleteTagsMutation({
    onCompleted: () => {
      toast.success('Successfully deleted tags ')
      reset()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not delete tags ')
    }
  })

  const tableData = data || previousData

  useEffect(() => {
    if (props.location?.state?.refetch) {
      refetch()
    }
  }, [props.location])

  const removeMultipleFilter = () => {
    removeFilter('brand')
    removeFilter('story')
    removeFilter('tags')
  }

  const onFilterCategory = (value: string[]) => {
    if (value.length === 0) {
      removeFilter('category', undefined, { isArray: true })
    } else {
      setFilter('category', value)
    }
  }

  const isMultipleFilter = filter.brand || filter.story || filter.product

  return (
    <Box className="flex flex-col">
      <Box className="flex p-5 justify-between items-center bg-white border-b border-gray-200">
        <h1 className="text-lg">Tags</h1>
        <Link to="/tags/create">
          <Button type="button" variant="primary" size="sm" colorScheme="blue">
            Create Tag
          </Button>
        </Link>
      </Box>
      {error ? (
        <Alert type="error">
          <Alert.Heading>There was an error fetching tags</Alert.Heading>
        </Alert>
      ) : !previousData && loading ? (
        <div className="flex flex-auto justify-center items-center">
          <Loader isActive={true} />
        </div>
      ) : tableData ? (
        <Box>
          <div className="bg-white w-full flex justify-between py-2 px-5">
            <DebouncedSearchInput
              onRemoveFilter={() => removeFilter('name')}
              as="input"
              variant="outline"
              width="md"
              id="name"
              name="name"
              onChange={(e) => setFilter('name', e.target.value)}
              value={filter.name ?? ''}
              placeholder="Type to search for tags"
            />
            <FilterWrapper canRemoveFilter={isMultipleFilter} removeFilterAction={removeMultipleFilter}>
              <Popover>
                <Popover.Button>Filters</Popover.Button>
                <Popover.Panel className="z-10 py-2 px-4">
                  <span className="text-sm font-medium">Type</span>
                  <div>
                    <FormField direction="row">
                      <Checkbox
                        id="brand"
                        name="brand"
                        checked={filter?.brand}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilter('brand', e.target.checked)}
                      />
                      <FormLabel>Brand</FormLabel>
                    </FormField>
                    <FormField direction="row">
                      <Checkbox
                        id="story"
                        name="story"
                        checked={filter?.story}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilter('story', e.target.checked)}
                      />
                      <FormLabel>Story</FormLabel>
                    </FormField>
                    <FormField direction="row">
                      <Checkbox
                        id="product"
                        name="product"
                        checked={filter?.product}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilter('product', e.target.checked)}
                      />
                      <FormLabel>Products</FormLabel>
                    </FormField>
                    {!loadingTagCategories ? (
                      <div>
                        <p className="font-medium text-sm mb-1">Groups</p>
                        <MultiSelectWithSearch
                          key={filter?.category ? 'categories' : 'noCategories'}
                          items={tagCategoriesOptions}
                          value={filter?.category || []}
                          onChange={onFilterCategory}
                        />
                      </div>
                    ) : null}
                  </div>
                </Popover.Panel>
              </Popover>
            </FilterWrapper>
          </div>
          {selectedIds.length > 0 ? (
            <div className="bg-gray-50 w-full flex py-2 px-5 justify-between">
              <Button colorScheme="red" variant="plain" className="text-red-500" onClick={() => setIsModalOpen(true)}>
                Delete
              </Button>
            </div>
          ) : null}
          <DeleteModal
            headline="Delete Tags"
            description="Are you sure you want to delete the selected tags?"
            isModalOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onDelete={() => {
              deleteTags(selectedIds)
            }}
          />
          <Table
            shape={TABLE_SHAPE}
            sortCallback={sortCallback}
            activeSort={sortField}
            sortDirection={direction?.toLowerCase()}
          >
            {tableData.tags.edges.map(({ node: tag }) => {
              const imageUrl = getImageUrl(tag?.image, 160)
              return (
                <Table.Row key={tag.id} className={contains(tag.id) ? 'bg-blue-100' : ''}>
                  <Table.Cell className="w-6">
                    <Checkbox id={tag.id} checked={contains(tag.id)} onClick={() => onClickItem(tag.id)} />
                  </Table.Cell>
                  <Table.Cell className="w-32">
                    {imageUrl ? <img className="w-full object-cover" src={imageUrl} /> : null}
                  </Table.Cell>
                  <Table.Cell className="font-medium text-gray-900">{tag.name}</Table.Cell>
                  <Table.Cell className="font-medium text-gray-900">{tag.categoryLabel ?? ''}</Table.Cell>
                  <Table.Cell className="font-medium text-gray-900">
                    {tag.types?.map(({ name }) => (
                      <span className="mr-2">
                        <Badge color="gray">{name}</Badge>
                      </span>
                    ))}
                  </Table.Cell>
                  <Table.Cell>{dayjs(tag.createdAt).format('DD-MM-YYYY')}</Table.Cell>
                  <Table.Cell>{dayjs(tag.updatedAt).format('DD-MM-YYYY')}</Table.Cell>
                  <Table.Cell className="text-right">
                    <Link className="underline" to={`/tags/${tag.id}`}>
                      Edit
                    </Link>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table>
          <Pagination
            hasPreviousPage={tableData.tags.pageInfo.hasPreviousPage}
            hasNextPage={tableData.tags.pageInfo.hasNextPage}
            onNext={nextPage}
            onPrev={prevPage}
            page={page}
          />
        </Box>
      ) : null}
    </Box>
  )
}
