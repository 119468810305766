import { gql } from '@apollo/client'
import { CogIcon, FingerPrintIcon, PencilIcon, UserGroupIcon, UserIcon, XIcon } from '@heroicons/react/solid'
import {
  Alert,
  Box,
  Dropdown,
  FormField,
  FormLabel,
  Input,
  Loader,
  MultiSelect,
  Toggle
} from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import cn from 'classnames'
import { UserStatus } from 'common/enums'
import { Permission } from 'common/types'
import Button from 'components/Button'
import { Card } from 'components/Card'
import { InfoLabel } from 'components/Info/InfoLabel'
import { InfoValue } from 'components/Info/InfoValue'
import Select from 'components/Select'
import dayjs from 'dayjs'
import {
  AccountUpdateInput,
  Statuses,
  useDeleteUserMutation,
  usePermissionsQuery,
  UserQuery,
  useUpdateUserMutation,
  useUserQuery,
  VoucherLedgerType
} from 'generated'
import { useUser } from 'hooks/useUser'
import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { formatPrice, getDirtyFieldValues } from 'utils'
import { DeleteModal } from '../../components/Modals/Delete'
import { useForgotPassword } from '../../hooks/useForgotPassword'
import { ROLES } from 'common/constants'

interface UserRouteProps {
  userId: string
}

const StatusIndicator = ({ status }: { status: string }) => {
  const colorMap: Record<string, string> = {
    [UserStatus.ACTIVE]: 'bg-green-100 text-green-800',
    [UserStatus.PENDING]: 'bg-yellow-100 text-yellow-800',
    [UserStatus.DISABLED]: 'bg-gray-100 text-gray-800',
    [UserStatus.BLOCKED]: 'bg-red-100 text-red-800'
  }

  return (
    <div>
      <span className={cn('inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium', colorMap[status])}>
        {status.charAt(0).toUpperCase()}
        {status.slice(1)}
      </span>
    </div>
  )
}

const styles = {
  label: 'text-sm font-medium text-gray-500',
  value: 'mt-1 text-sm text-gray-900 sm:mt-0 py-2'
}

const STATUSES = [
  { key: UserStatus.ACTIVE, value: UserStatus.ACTIVE, label: 'Active' },
  { key: UserStatus.PENDING, value: UserStatus.PENDING, label: 'Pending' },
  { key: UserStatus.DISABLED, value: UserStatus.DISABLED, label: 'Disabled' },
  { key: UserStatus.BLOCKED, value: UserStatus.BLOCKED, label: 'Blocked' }
]

const TRANSACTION_TYPE_MAP: Partial<Record<VoucherLedgerType, string>> = {
  [VoucherLedgerType.Transaction]: 'Credit used on Order',
  [VoucherLedgerType.Purchase]: 'Redeemed Voucher',
  [VoucherLedgerType.Internal]: 'Redeemed Voucher',
  [VoucherLedgerType.Expiry]: 'Voucher Expired'
}

function getDefaultValues(
  user?: UserQuery['account'] | undefined
): {
  firstName?: string
  lastName?: string
  roles?: string[]
  enabled?: boolean
  status?: Statuses
} {
  if (!user) {
    return {}
  } else {
    return {
      firstName: user.firstName ?? '',
      lastName: user.lastName,
      roles: user.roles,
      enabled: user.enabled,
      status: user.status as Statuses
    }
  }
}

export const User: React.FC<RouteComponentProps<UserRouteProps>> = (props) => {
  const { userId } = props
  const { data, loading, error, fetchMore } = useUserQuery({
    variables: { id: userId as string, firstTransactions: 20 }
  })
  const [isEditing, setIsEditing] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [deleteAccount, { loading: loadingDeleteAccount }] = useDeleteUserMutation({
    onCompleted: () => {
      window.history.back()
      toast.success('User deleted')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not delete user')
    }
  })
  const { sendForgotPassword, loading: loadingForgotPassword } = useForgotPassword()

  const { user: currentUser } = useUser()

  const { account: user } = data || {}
  const {
    control,
    reset,
    register,
    // errors: validationErrors,
    getValues,
    formState: { dirtyFields, isDirty }
  } = useForm<AccountUpdateInput>({ defaultValues: getDefaultValues(user) })

  const [updateAccount, { loading: loadingUpdateAccount }] = useUpdateUserMutation({
    onCompleted: () => {
      toast.success('Successfully updated user')
      reset(getDefaultValues(user))
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update action')
    }
  })

  const { data: permissions } = usePermissionsQuery()

  const resetToDefault = useCallback(() => {
    reset(getDefaultValues(user))
  }, [user])

  if (error?.graphQLErrors) {
    return (
      <Alert type="error">
        <Alert.Heading>Error fetching account</Alert.Heading>
        <Alert.Description>An unexpected error occured when fetching the specified account</Alert.Description>
      </Alert>
    )
  }

  if (!user || loading) {
    return <Loader isActive />
  }

  const submitData = () => {
    const data = getValues()
    const update = getDirtyFieldValues(data, dirtyFields)
    updateAccount({ variables: { id: user.id, update } })
  }

  const { firstName, lastName, email, enabled, roles, status } = user
  // const isDirty = Object.keys(dirtyFields).length > 0

  return (
    <Box>
      <DeleteModal
        isLoading={loadingDeleteAccount}
        headline="Delete Account"
        description="Are you sure you want to delete this account?"
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDelete={() => {
          deleteAccount({ variables: { id: user.id } })
        }}
      />
      <Box className="flex justify-between p-5 border-b border-gray-300 items-center bg-white">
        <Box>
          <h3 className="text-lg leading-6 font-medium text-gray-900">User Information</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">User details for viewing and editing</p>
        </Box>
        {isEditing ? (
          <div className="flex gap-2">
            <Button
              variant="plain"
              type="button"
              size="sm"
              onClick={() => {
                setIsEditing(false)
                resetToDefault()
              }}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              size="sm"
              colorScheme="green"
              onClick={submitData}
              isDisabled={loadingUpdateAccount || !isDirty}
              isLoading={loadingUpdateAccount}
            >
              Save
            </Button>
          </div>
        ) : (
          <Dropdown>
            <Dropdown.Button>Actions</Dropdown.Button>
            <Dropdown.Items>
              <Dropdown.Item>
                <div
                  className={cn(
                    'hover:bg-gray-100 text-gray-500 text-sm p-2 cursor-pointer flex justify-between items-center',
                    { 'opacity-30': loadingForgotPassword }
                  )}
                  onClick={() => sendForgotPassword(user.email)}
                >
                  Send new password request
                  <FingerPrintIcon className="h-4 w-4 text-gray-500" />
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div
                  className="hover:bg-blue-100 text-blue-500 text-sm p-2 cursor-pointer flex justify-between items-center"
                  onClick={() => setIsEditing(true)}
                >
                  Edit
                  <PencilIcon className="h-4 w-4 text-blue-500" />
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div
                  className="hover:bg-red-100 text-red-500 text-sm p-2 cursor-pointer flex justify-between items-center"
                  onClick={() => setIsModalOpen(true)}
                >
                  Delete User
                  <XIcon className="h-4 w-4 text-red-500" />
                </div>
              </Dropdown.Item>
            </Dropdown.Items>
          </Dropdown>
        )}
      </Box>
      <div className="p-5">
        <div className="bg-white shadow sm:rounded-lg p-5">
          <div className="flex flex-row">
            <div className="flex flex-initial w-36 flex-col justify-between">
              <UserIcon className="w-32 h-32 text-gray-600" />
            </div>
            <div className="flex-1 px-5">
              {!isEditing ? (
                <div className="text-lg mb-2">
                  {firstName} {lastName}
                </div>
              ) : (
                <div className="flex gap-4 mb-2">
                  <FormField>
                    <FormLabel htmlFor="firstName" className={styles.label}>
                      First Name
                    </FormLabel>
                    <Input
                      id="firstName"
                      name="firstName"
                      variant="outline"
                      defaultValue={firstName ?? ''}
                      ref={register}
                    />
                  </FormField>
                  <FormField>
                    <FormLabel htmlFor="lastName" className={styles.label}>
                      Last Name
                    </FormLabel>
                    <Input id="lastName" name="lastName" variant="outline" defaultValue={lastName} ref={register} />
                  </FormField>
                </div>
              )}
              <div className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <InfoLabel>Email Address</InfoLabel>
                  <InfoValue>{email}</InfoValue>
                </div>
                {currentUser && currentUser.roles.includes(Permission.Admin) ? (
                  <div className="sm:col-span-1">
                    <InfoLabel className="flex gap-2">
                      Roles <UserGroupIcon className="h-4 w-4 text-gray-500" />
                    </InfoLabel>
                    <InfoValue>
                      {isEditing ? (
                        <Controller
                          defaultValue={user.roles}
                          control={control}
                          name="roles"
                          render={({ onChange, value }) => (
                            <MultiSelect
                              items={Object.entries(ROLES).map(([key, value]) => {
                                return {
                                  value: key,
                                  key: key,
                                  label: value
                                }
                              })}
                              value={value}
                              onChange={onChange}
                            />
                          )}
                        />
                      ) : (
                        roles.map((role) => (
                          <span className="inline-flex mr-1 items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                            {ROLES[role as Permission]}
                          </span>
                        ))
                      )}
                    </InfoValue>
                  </div>
                ) : null}

                {isEditing ? (
                  <>
                    <FormField direction="column">
                      <FormLabel htmlFor="enabled" className={styles.label}>
                        Enabled
                      </FormLabel>
                      <Controller
                        defaultValue={user.enabled}
                        control={control}
                        name="enabled"
                        render={({ onChange, value }) => (
                          <Toggle aria-label="enabled" on={value} onClick={() => onChange(!value)} />
                        )}
                      />
                    </FormField>
                    <FormField direction="column">
                      <FormLabel htmlFor="status" className={styles.label}>
                        Status
                      </FormLabel>
                      <Controller
                        defaultValue={user.status}
                        control={control}
                        name="status"
                        render={({ onChange, value }) => (
                          <Select options={STATUSES} value={value} onChange={onChange} />
                        )}
                      />
                    </FormField>
                  </>
                ) : null}
              </div>
            </div>
            <div className="flex-initial w-64 flex flex-col items-end">
              <div className="mb-1">
                <StatusIndicator status={status} />
              </div>
              <span
                className={cn(
                  'inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium mb-4',
                  { 'bg-green-100 text-green-800': enabled },
                  { 'bg-red-100 text-red-800': !enabled }
                )}
              >
                {enabled ? 'Enabled' : 'Disabled'}
              </span>
            </div>
          </div>
        </div>
        <Card className="mt-5 w-1/2">
          <Card.Header>Wallet Info</Card.Header>
          <Card.Content>
            <div className="flex justify-between border-b border-gray-100 py-4">
              <strong>Balance</strong>
              {typeof user.customer?.availableCredit === 'number' ? (
                <p>{formatPrice(user.customer.availableCredit)}</p>
              ) : null}
            </div>
            <div className="py-4">
              <strong>Transactions</strong>
              {user.customer?.transactions.edges.map(({ node }) => {
                return (
                  <div key={node.id} className="flex justify-between items-center border-b border-gray-100 py-4">
                    <div>
                      <p className="text-xs text-gray-500">
                        {[VoucherLedgerType.Purchase, VoucherLedgerType.Internal].includes(node.type)
                          ? dayjs(node.voucher.redemptionDate ?? node.voucher.updatedAt).format('DD-MM-YYYY')
                          : dayjs(node.createdAt).format('DD-MM-YYYY')}
                      </p>
                      <p>
                        {TRANSACTION_TYPE_MAP[node.type]}{' '}
                        {node.order?.orderNumber && node.type === VoucherLedgerType.Transaction ? (
                          <span> #{node.order.orderNumber}</span>
                        ) : null}
                      </p>
                      <p className="text-xs text-gray-500">Voucher #{node.voucher.voucherNumber}</p>
                    </div>
                    <p>{formatPrice(node.amount)}</p>
                  </div>
                )
              })}
              {user.customer?.transactions.pageInfo.hasNextPage ? (
                <div className="py-4 flex justify-center items-center">
                  <Button
                    variant="plain"
                    onClick={() =>
                      fetchMore({
                        variables: { firstTransactions: 10, after: user.customer?.transactions.pageInfo.endCursor }
                      })
                    }
                  >
                    Load More
                  </Button>
                </div>
              ) : null}
            </div>
          </Card.Content>
        </Card>
      </div>
    </Box>
  )
}

gql`
  query Permissions {
    permissions {
      label
      value
    }
  }
`
