import { gql } from '@apollo/client'
import { CheckIcon, TrashIcon, XIcon } from '@heroicons/react/solid'
import { Button, Loader, Table } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import classNames from 'classnames'
import AccountGroupQueryBuilder from 'components/AccountGroupQueryBuilder'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import { Pagination } from 'components/Pagination'
import dayjs from 'dayjs'
import {
  AccountsAction,
  useGetAccountGroupQuery,
  useGetAccountsInAccountGroupQuery,
  useUpdateAccountGroupUsersMutation
} from 'generated'
import { usePagination } from 'hooks/usePagination'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { formatPrice } from 'utils'
import AccountGroupExport from './ExportAccountGroup'

enum QueryView {
  BUILDER,
  JSON
}

interface ViewAccountGroupProps extends RouteComponentProps {
  id: string
}

const TABLE_SHAPE = [
  { label: '', key: '', isSortable: false },
  { label: 'Name', key: 'name', isSortable: false },
  { label: 'Email', key: 'email', isSortable: false },
  { label: 'Recency Group', key: 'customerRecencyGroup', isSortable: false },
  { label: 'Last Purchase Date', key: 'lastPurchaseAt', isSortable: false },
  { label: 'Last Login', key: 'lastLogin', isSortable: false },
  { label: 'Has Active Cart', key: 'hasActiveCart', isSortable: false },
  { label: 'Current Cart Value', key: 'currentCartValue', isSortable: false },
  { label: 'Cart Updated', key: 'cartUpdatedAt', isSortable: false },
  { label: 'Number of Orders', key: 'numberOfOrders', isSortable: false },
  { label: 'Value of Orders', key: 'valueOfOrders', isSortable: false },
  { label: 'Registered Date', key: 'registeredDate', isSortable: false },
  { label: 'App OS', key: 'appOS', isSortable: false },
  { label: 'Birthday', key: 'birthday', isSortable: false }
]

export default function ViewAccountGroup(props: ViewAccountGroupProps): JSX.Element {
  const [queryView, toggleQueryView] = useState<QueryView>(QueryView.BUILDER)
  const { data, loading: loadingAccountGroups } = useGetAccountGroupQuery({ variables: { id: props.id } })

  const { page, nextPage, prevPage, setCursor, paging } = usePagination({ limit: 20 })
  const { data: accounts, refetch } = useGetAccountsInAccountGroupQuery({
    fetchPolicy: 'cache-and-network',
    variables: { ids: [props.id], ...paging },
    onCompleted: (data) => {
      if (data.getAccountGroupAccounts.pageInfo) {
        const { startCursor, endCursor } = data.getAccountGroupAccounts.pageInfo
        if (startCursor && endCursor) {
          setCursor({ startCursor, endCursor })
        }
      }
    }
  })
  const [updateAccountGroupUsers, { loading: loadingRemoveUser }] = useUpdateAccountGroupUsersMutation({
    onCompleted: () => {
      toast.success('Account removed from audience segment')
      refetch()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not remove account from audience segment')
    }
  })

  if (loadingAccountGroups) return <Loader isActive />

  const toggleViewMode = () => {
    toggleQueryView((queryView) => (queryView === QueryView.BUILDER ? QueryView.JSON : QueryView.BUILDER))
  }
  const toggleEdit = () => {
    props.navigate?.(`/audience-segments/${props.id}/edit`)
  }
  const removeUser = (userId: string) => () => {
    updateAccountGroupUsers({ variables: { accountGroupId: props.id, ids: [userId], action: AccountsAction.Exclude } })
  }

  return (
    <div>
      <PageHeader>
        <h1 className="text-lg">Audience Segment</h1>
        <div className="flex gap-2">
          <AccountGroupExport accountGroupId={props.id} />
          <Button variant="primary" colorScheme="blue" onClick={toggleEdit}>
            Edit
          </Button>
        </div>
      </PageHeader>

      <div className="p-5">
        <Card className="mb-8">
          <Card.Content>
            <div className="flex flex-col gap-4">
              <div>
                <strong>Name</strong>
                <div>{data?.accountGroup?.name}</div>
              </div>
              <div>
                <strong>Description</strong>
                <div>{data?.accountGroup?.description}</div>
              </div>
              <div>
                <div className="flex justify-between mb-2">
                  <strong>Definition</strong>
                  <Button size="sm" variant="plain" onClick={toggleViewMode}>
                    View {queryView === QueryView.BUILDER ? 'JSON' : 'Query Builder'}
                  </Button>
                </div>
                {queryView === QueryView.BUILDER ? (
                  <AccountGroupQueryBuilder
                    tree={data?.accountGroup?.definition}
                    settings={{
                      immutableGroupsMode: true,
                      immutableFieldsMode: true,
                      immutableOpsMode: true,
                      immutableValuesMode: true,
                      canReorder: false,
                      canRegroup: false
                    }}
                  />
                ) : (
                  <pre className="block">{JSON.stringify(data?.accountGroup?.definition, null, 2)}</pre>
                )}
              </div>
            </div>
          </Card.Content>
        </Card>
        <Card>
          <Card.Header>
            <h2 className="text-md font-bold">
              Users <span className="text-gray-400">({accounts?.getAccountGroupAccounts.count})</span>
            </h2>
          </Card.Header>
          <Card.Content>
            <Table shape={TABLE_SHAPE}>
              {accounts?.getAccountGroupAccounts.edges.map(({ node: acc }) => {
                return (
                  <Table.Row key={acc.id}>
                    <Table.Cell>
                      <TrashIcon
                        className={classNames(styles.icon, 'cursor-pointer text-red-500')}
                        onClick={!loadingRemoveUser ? removeUser(acc.id) : undefined}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {acc.firstName} {acc.lastName}
                    </Table.Cell>
                    <Table.Cell>{acc.email}</Table.Cell>
                    <Table.Cell>{acc.customerRecencyGroup}</Table.Cell>
                    <Table.Cell>
                      {acc.lastPurchaseAt ? dayjs(acc.lastPurchaseAt).format('DD/MM/YYYY') : null}
                    </Table.Cell>
                    <Table.Cell>{dayjs(acc.lastLogin).format('DD/MM/YYYY')}</Table.Cell>
                    <Table.Cell className="text-center">
                      {acc.hasActiveCart ? (
                        <CheckIcon className={classNames(styles.icon, 'text-green-600')} />
                      ) : (
                        <XIcon className={classNames(styles.icon, 'text-red-600')} />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {typeof acc.currentCartValue === 'number' ? formatPrice(acc.currentCartValue) : null}
                    </Table.Cell>
                    <Table.Cell>{acc.cartUpdatedAt ? dayjs(acc.cartUpdatedAt).format('DD/MM/YYYY') : null}</Table.Cell>
                    <Table.Cell>{acc.numberOfOrders}</Table.Cell>
                    <Table.Cell>
                      {typeof acc.valueOfOrders === 'number' ? formatPrice(acc.valueOfOrders) : null}
                    </Table.Cell>
                    <Table.Cell>{dayjs(acc.registeredDate).format('DD/MM/YYYY')}</Table.Cell>
                    <Table.Cell>{acc.appOS}</Table.Cell>
                    <Table.Cell>{acc.birthday ? dayjs(acc.birthday).format('DD/MM/YYYY') : null}</Table.Cell>
                  </Table.Row>
                )
              })}
            </Table>
            <Pagination
              hasPreviousPage={accounts?.getAccountGroupAccounts.pageInfo.hasPreviousPage}
              hasNextPage={accounts?.getAccountGroupAccounts.pageInfo.hasNextPage}
              onNext={nextPage}
              onPrev={prevPage}
              page={page}
            />
          </Card.Content>
        </Card>
      </div>
    </div>
  )
}

const styles = {
  icon: 'h-4 w-4'
}

ViewAccountGroup.fields = gql`
  fragment AccountGroupFields on AccountGroup {
    id
    name
    description
    definition
  }
`

ViewAccountGroup.query = {
  accountGroup: gql`
    query GetAccountGroup($id: ID!) {
      accountGroup(id: $id) {
        ...AccountGroupFields
      }
    }
    ${ViewAccountGroup.fields}
  `,
  accounts: gql`
    query GetAccountsInAccountGroup($ids: [String!]!, $first: Int, $after: String, $last: Int, $before: String) {
      getAccountGroupAccounts(accountGroupIds: $ids, first: $first, after: $after, last: $last, before: $before) {
        count
        edges {
          node {
            id: accountId
            email
            customerRecencyGroup
            lastPurchaseAt
            lastLogin
            hasActiveCart
            currentCartValue
            cartUpdatedAt
            numberOfOrders
            valueOfOrders
            registeredDate
            appOS
            firstName
            lastName
            birthday
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
      }
    }
  `
}
