import { navigate, RouteComponentProps } from '@reach/router'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Form,
  FormField,
  FormLabel,
  FormError,
  Grid,
  Input,
  MultiSelect,
  Select
} from '@plusplusminus/plusplusdash'
import { TagForm, TagInput, useCreateTagMutation, useTagTypesQuery } from 'hooks/tags'
import { useEffect, useState } from 'react'
import { Image } from 'common/types'
import { useUploadImage } from 'hooks/images'
import { Upload } from 'components/Upload'
import { getImageUrl } from 'utils'
import toast from 'react-hot-toast'
import Button from 'components/Button'
import { useTagCategoriesQuery } from 'generated'

export const CreateTag: React.FC<RouteComponentProps> = () => {
  const { register, handleSubmit, errors, control } = useForm<TagForm>()

  const { tagTypes } = useTagTypesQuery()
  const tagTypeOptions = tagTypes ? tagTypes.map(({ name }) => ({ value: name, label: name, key: name })) : []
  const { data, loading: loadingTagCategories } = useTagCategoriesQuery()
  const tagCategoriesOptions =
    data?.tagCategories.map(({ name, title }) => ({ value: name, label: title, key: name })) ?? []

  const { createTag, loading } = useCreateTagMutation({
    onCompleted: () => {
      toast.success('Successfully created tag')
      navigate('/tags', { state: { refetch: true } })
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not create tag')
    }
  })

  const onSubmit = (data: TagForm) => {
    const { types, ...rest } = data

    let input: Partial<TagInput> = { ...rest }

    if (image) {
      input = { ...input, image }
    } else if (types && types.length > 0) {
      input = { ...input, types: types.map((t) => ({ name: t })) }
    }
    createTag(input)
  }
  const [image, setImage] = useState<Image>()
  const { uploadOneImage, uploadedImage, uploading } = useUploadImage({})

  const onUpload = (event: any) => {
    uploadOneImage(event?.target?.files[0])
  }

  useEffect(() => {
    if (uploadedImage) {
      setImage(uploadedImage)
    }
  }, [uploadedImage])

  return (
    <Box>
      <Box className="flex justify-between p-5 border-b border-gray-300 items-center">
        <Box>
          <h3 className="text-lg leading-6 font-medium text-gray-900">New Tag</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">Enter tag details to create a new tag</p>
        </Box>
      </Box>
      <Box className="p-5">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid className="sm:w-full lg:w-2/3 mb-4" columns={1} rowGap={4} columnGap={4}>
            <FormField direction="column">
              <FormLabel htmlFor="name">Tag Name *</FormLabel>
              <Input
                id="name"
                name="name"
                as="input"
                variant="standard"
                width="full"
                ref={register({ required: { value: true, message: 'Required' } })}
              />
              {errors.name && <FormError>{errors.name.message}</FormError>}
            </FormField>
            <FormField direction="column">
              <FormLabel htmlFor="name">Tag Description *</FormLabel>
              <Input id="description" name="description" as="textarea" variant="standard" width="full" ref={register} />
            </FormField>
            <FormField>
              <FormLabel>Group</FormLabel>
              {!loadingTagCategories && (
                <Controller
                  name="category"
                  control={control}
                  render={({ onChange, value }) => (
                    <Select items={tagCategoriesOptions} value={value} onChange={onChange} />
                  )}
                />
              )}
            </FormField>
            {tagTypeOptions && tagTypeOptions.length > 0 && (
              <FormField>
                <FormLabel>Type</FormLabel>
                <Controller
                  name="types"
                  control={control}
                  render={({ value, onChange }) => (
                    <MultiSelect items={tagTypeOptions} value={value} onChange={onChange} />
                  )}
                />
              </FormField>
            )}
            <FormField direction="column">
              <FormLabel htmlFor="name">Tag Image</FormLabel>
              <div className="row flex">
                {image ? (
                  <div className="flex relative">
                    <img
                      src={getImageUrl(image, 200)}
                      className="w-24 object-contain p-1 cursor-pointer border-2 border-transparent"
                    />
                  </div>
                ) : null}
                <div className="h-24 flex items-center justify-center">
                  <Upload uploading={uploading} onUpload={onUpload} multiple={false} />
                </div>
              </div>
            </FormField>
          </Grid>
          <Button
            variant="primary"
            colorScheme="green"
            type="submit"
            className="px-8"
            disabled={loading}
            isLoading={loading}
          >
            Save
          </Button>
        </Form>
      </Box>
    </Box>
  )
}
