import { Page } from 'common/types'
import { PageHeader } from 'components/PageHeader'
import { RefundsPaginated } from 'components/Refunds/RefundsPaginated'
import { RefundsFilter } from 'components/Refunds'
import { useRefundsQuery } from 'hooks/refunds'
import { Alert, Loader } from '@plusplusminus/plusplusdash'
import { gql, DocumentNode } from '@apollo/client'

const Refunds: Page & { query: DocumentNode } = () => {
  const { query, pagination, sort, filtering } = useRefundsQuery({ persist: true, isActive: true })
  const { data, previousData, loading, error } = query
  const tableData = data || previousData

  return (
    <div>
      <PageHeader>Refunds</PageHeader>
      {error ? (
        <Alert type="error">
          <Alert.Heading>There was an error fetching orders.</Alert.Heading>
        </Alert>
      ) : !previousData && loading ? (
        <Loader isActive />
      ) : tableData ? (
        <>
          <div className="bg-white w-full flex py-2 px-5 justify-between">
            <RefundsFilter filtering={filtering} sort={sort} />
          </div>

          <RefundsPaginated query={query} sort={sort} pagination={pagination} />
        </>
      ) : null}
    </div>
  )
}

Refunds.query = gql`
  mutation UpdaterOrderLinePaymentDate($input: OrderLinePaymentDateInput!) {
    updateOrderLinePaymentDate(input: $input) {
      ...OrderLineFields
    }
  }
`

export { Refunds }
