import { gql } from '@apollo/client'

export const EMAIL_QUERY = gql`
  query EmailTemplates {
    emailTemplatesCollection {
      items {
        sys {
          id
        }
        name
      }
    }
  }
`
