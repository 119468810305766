import React, { FC, useState } from 'react'
import { useCombobox, useMultipleSelection } from 'downshift'
import { ISelectItem, ISelectSection } from '../../common/types'
import { selectStyles, multiSelectStyles } from './styles'
import { FormLabel } from '../Form'
import { isSection } from './helpers'
import { SelectItem } from './SelectItem'
import { Input } from '../Input'
import cn from 'classnames'

interface MultiSelectProps {
  items: ISelectItem[] | ISelectSection[]
  value?: string[]
  label?: string
  onChange?: (value: string[]) => void
}

const MultiSelectWithSearch: FC<MultiSelectProps> = (props) => {
  const { items: selectItems, value, label, onChange } = props

  const [inputValue, setInputValue] = useState('')

  const onSelectedItemsChange = (changes: any) => {
    onChange && onChange(changes.selectedItems)
  }

  const items = isSection(selectItems)
    ? selectItems.reduce((acc, { items }) => {
        acc.push(...items)
        return acc
      }, [] as ISelectItem[])
    : selectItems

  const getFilteredItems = (items: ISelectItem[]) =>
    items.filter(
      (item) => selectedItems.indexOf(item.value) < 0 && item.label.toLowerCase().startsWith(inputValue.toLowerCase())
    )

  const {
    getSelectedItemProps,
    addSelectedItem,
    removeSelectedItem,
    selectedItems,
    getDropdownProps
  } = useMultipleSelection({
    initialSelectedItems:
      value && value.length >= 0 ? value?.filter((val) => items.find((item) => item.value === val)) : [],
    onSelectedItemsChange
  })

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    selectItem
  } = useCombobox<ISelectItem | null>({
    inputValue,
    items: getFilteredItems(items),
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue as string)
          break
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('')
            addSelectedItem(selectedItem.value)
            selectItem(null)
          }

          break
        default:
          break
      }
    }
  })

  let sectionIndex = 0

  return (
    <div className="relative">
      {label && (
        <FormLabel className={selectStyles.label} {...getLabelProps()}>
          {label}
        </FormLabel>
      )}
      <div className="relative inline-block ml-1 mb-1" {...getComboboxProps()}>
        <Input {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))} width="full" />
        <button type="button" className="absolute right-2 top-0 h-full w-5" {...getToggleButtonProps()} />
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <svg
            className="h-5 w-5 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </span>
        <div className={selectStyles.menuContainer}>
          <ul tabIndex="-1" className={isOpen ? selectStyles.menu : ''} {...getMenuProps()}>
            {isOpen &&
              (isSection(selectItems)
                ? selectItems.map((item) => {
                    return (
                      <div className="border-b border-gray-300">
                        <p className="text-base font-medium py-2 pl-3">{item.heading}</p>
                        {getFilteredItems(item.items).map((item) => {
                          const index = sectionIndex++
                          return (
                            <SelectItem
                              key={`${item.key}${index}`}
                              highlightedIndex={highlightedIndex}
                              selectedItems={selectedItems}
                              index={index}
                              item={item}
                              getItemProps={getItemProps}
                            />
                          )
                        })}
                      </div>
                    )
                  })
                : getFilteredItems(selectItems).map((item, index) => {
                    return (
                      <SelectItem
                        key={`${item.key}${index}`}
                        highlightedIndex={highlightedIndex}
                        selectedItems={selectedItems}
                        index={index}
                        item={item}
                        getItemProps={getItemProps}
                      />
                    )
                  }))}
          </ul>
        </div>
      </div>
      <div className="flex flex-wrap gap-1">
        {selectedItems.map((selectedItem, index) => (
          <span
            className={cn(multiSelectStyles.selectedItem.replace('mr-2', ''), 'text-xs')}
            key={`selected-item-${index}`}
            {...getSelectedItemProps({ selectedItem, index })}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <span className="mr-2">{items.find((item) => item.value === selectedItem)?.label}</span>
            <span className="cursor-pointer" onClick={() => removeSelectedItem(selectedItem)}>
              &#10005;
            </span>
          </span>
        ))}
      </div>
    </div>
  )
}

export { MultiSelectWithSearch }
