import { RouteComponentProps, Router } from '@reach/router'
import { Brand, Brands, CreateBrand } from 'pages/Brands'
import { Home } from 'pages/Home'
import { Tag, Tags, CreateTag } from 'pages/Tags'
import { User, Users } from 'pages/Users'
import { PrivateRoute } from '../PrivateRoute'
import { Products } from 'pages/Products'
import { Product } from 'pages/Products'
import { Options } from 'pages/Options/Options'
import { Order, Orders } from 'pages/Orders'
import BrandOrders from 'pages/BrandOrders'
import Dashboard from 'pages/Dashboard'
import Vouchers from 'pages/Vouchers/Vouchers'
import CreateAccountGroup from 'pages/AccountGroups/CreateAccountGroup'
import AccountGroupsTable from 'pages/AccountGroups/AccountGroupsTable'
import ViewAccountGroup from 'pages/AccountGroups/ViewAccountGroup'
import EditAccountGroup from 'pages/AccountGroups/EditAccountGroup'
import CreateMessage from 'pages/Message/CreateMessage'
import MessagesTable from 'pages/Message/MessagesTable'
import ViewMessage from 'pages/Message/ViewMessage'
import EditMessage from 'pages/Message/EditMessage'
import MessageCampaginsTable from 'pages/MessageCampaigns/MessageCampaignsTable'
import CreateMessageCampaign from 'pages/MessageCampaigns/CreateMessageCampaign'
import ViewMessageCampaign from 'pages/MessageCampaigns/ViewMessageCampaign'
import EditMessageCampaign from 'pages/MessageCampaigns/EditMessageCampaign'
import { AppConfig } from 'pages/AppConfig'
import ViewAction from 'pages/Actions/ViewAction'
import TriggersTable from 'pages/Triggers/TriggersTable'
import ViewTrigger from 'pages/Triggers/ViewTrigger'
import CreateTrigger from 'pages/Triggers/CreateTrigger'
import EditTrigger from 'pages/Triggers/EditTrigger'
import { BulkTag } from 'pages/Products/BulkTag'
import { Permission } from 'common/types'
import { Refund, Refunds } from 'pages/Refunds'

export const AuthenticatedApp: React.FC<RouteComponentProps> = () => {
  return (
    <Router className="h-full">
      <PrivateRoute path="/" Component={Home} />
      <PrivateRoute path="/users" roles={[Permission.Admin, Permission.SuperAdmin]} Component={Users} />
      <PrivateRoute path="/users/:userId" roles={[Permission.Admin, Permission.SuperAdmin]} Component={User} />
      <PrivateRoute path="/brands" roles={[Permission.Admin, Permission.SuperAdmin]} Component={Brands} />
      <PrivateRoute path="/brands/create" roles={[Permission.Admin, Permission.SuperAdmin]} Component={CreateBrand} />
      <PrivateRoute path="/brands/:brandId" roles={[Permission.Admin, Permission.SuperAdmin]} Component={Brand} />
      <PrivateRoute
        path="/brands/:brandId/orders"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={BrandOrders}
      />
      <PrivateRoute path="/tags" roles={[Permission.Admin, Permission.SuperAdmin]} Component={Tags} />
      <PrivateRoute path="/bulk-tag" roles={[Permission.Admin, Permission.SuperAdmin]} Component={BulkTag} />
      <PrivateRoute path="/tags/create" roles={[Permission.Admin, Permission.SuperAdmin]} Component={CreateTag} />
      <PrivateRoute path="/tags/:tagId" roles={[Permission.Admin, Permission.SuperAdmin]} Component={Tag} />
      <PrivateRoute
        path="/products"
        roles={[Permission.Admin, Permission.SuperAdmin, Permission.ProductAdmin]}
        Component={Products}
      />
      <PrivateRoute
        path="/products/:productId"
        roles={[Permission.Admin, Permission.SuperAdmin, Permission.ProductAdmin]}
        Component={Product}
      />
      <PrivateRoute path="/options" roles={[Permission.Admin, Permission.SuperAdmin]} Component={Options} />
      <PrivateRoute
        path="/orders"
        roles={[Permission.Admin, Permission.SuperAdmin, Permission.OrderAdmin]}
        Component={Orders}
      />
      <PrivateRoute
        path="/orders/:orderId"
        roles={[Permission.Admin, Permission.SuperAdmin, Permission.OrderAdmin]}
        Component={Order}
      />
      <PrivateRoute
        path="/refunds"
        roles={[Permission.Admin, Permission.SuperAdmin, Permission.OrderAdmin]}
        Component={Refunds}
      />
      <PrivateRoute
        path="/refunds/:refundId"
        roles={[Permission.Admin, Permission.SuperAdmin, Permission.OrderAdmin]}
        Component={Refund}
      />
      <PrivateRoute path="/dashboard" roles={[Permission.Admin]} Component={Dashboard} />
      <PrivateRoute
        path="/vouchers"
        roles={[Permission.Admin, Permission.SuperAdmin, Permission.OrderAdmin]}
        Component={Vouchers}
      />
      <PrivateRoute
        path="/audience-segments"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={AccountGroupsTable}
      />
      <PrivateRoute
        path="/audience-segments/create"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={CreateAccountGroup}
      />
      <PrivateRoute
        path="/audience-segments/:id/view"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={ViewAccountGroup}
      />
      <PrivateRoute
        path="/audience-segments/:id/edit"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={EditAccountGroup}
      />
      <PrivateRoute path="/messages" roles={[Permission.Admin, Permission.SuperAdmin]} Component={MessagesTable} />
      <PrivateRoute
        path="/messages/create"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={CreateMessage}
      />
      <PrivateRoute
        path="/messages/:id/view"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={ViewMessage}
      />
      <PrivateRoute
        path="/messages/:id/edit"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={EditMessage}
      />
      <PrivateRoute
        path="/message-campaigns"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={MessageCampaginsTable}
      />
      <PrivateRoute
        path="/message-campaigns/create"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={CreateMessageCampaign}
      />
      <PrivateRoute
        path="/message-campaigns/:id/view"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={ViewMessageCampaign}
      />
      <PrivateRoute
        path="/message-campaigns/:id/edit"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={EditMessageCampaign}
      />
      <PrivateRoute path="/app-config" roles={[Permission.Admin, Permission.SuperAdmin]} Component={AppConfig} />
      <PrivateRoute path="/actions/:id/view" roles={[Permission.Admin, Permission.SuperAdmin]} Component={ViewAction} />
      <PrivateRoute path="/triggers" roles={[Permission.Admin, Permission.SuperAdmin]} Component={TriggersTable} />
      <PrivateRoute
        path="/triggers/:id/view"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={ViewTrigger}
      />
      <PrivateRoute
        path="/triggers/:id/edit"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={EditTrigger}
      />
      <PrivateRoute
        path="/triggers/create"
        roles={[Permission.Admin, Permission.SuperAdmin]}
        Component={CreateTrigger}
      />
    </Router>
  )
}
