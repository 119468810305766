import { gql, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import Button from 'components/Button'

type OrderExportProps = {
  query: any
}

export default function OrdersExport(props: OrderExportProps): JSX.Element {
  const { query } = props

  const [downloadOrders, { loading }] = useMutation(OrdersExport.mutation, {
    variables: query,
    onCompleted: () => {
      toast.success('Sent order csv to email. PLease note this may take a few minutes')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. Could not send order csv.')
    }
  })

  const submitDownload = async () => {
    await downloadOrders()
  }

  return (
    <Button colorScheme="blue" variant="primary" onClick={submitDownload} disabled={loading}>
      {loading ? 'Loading...' : 'Export Orders'}
    </Button>
  )
}

OrdersExport.mutation = gql`
  mutation ExportOrders($paging: CursorPaging, $filter: OrderFilter, $sorting: [OrderSort!]) {
    exportOrders(paging: $paging, filter: $filter, sorting: $sorting)
  }
`
