import { gql, QueryHookOptions } from '@apollo/client'
import { PaginatedEntity } from 'common/types'
import { Refund, RefundFilter } from 'generated'
import { IMAGE_FIELDS, PAGING_FIELDS } from '../graphql/fragments'
import { ORDER_ADDRESS_FIELDS, ORDER_LINE_FIELDS } from './orders'
import { TUsePaginatedQuery, usePaginatedQuery } from './usePaginatedQuery'

/** Types */

type RefundsResult = {
  refunds: PaginatedEntity<Refund>
}

type FilterFields = {
  state?: string
  brands?: string[]
}

/** Queries */

const REFUNDS_QUERY = gql`
  ${IMAGE_FIELDS}
  ${ORDER_LINE_FIELDS}
  ${PAGING_FIELDS}
  ${ORDER_ADDRESS_FIELDS}
  fragment RefundFields on Refund {
    id
    createdAt
    updatedAt
    state
    basketAmount
    adjustmentAmount
    note
    orderLine {
      ...OrderLineFields
      order {
        id
        orderNumber
        orderDate
        status
        createdAt
        updatedAt
        creditUsed
        subTotal
        totalPaid
        customer {
          id
          firstName
          lastName
          accountId
          email
          mobile
        }
        shippingAddress {
          ...OrderAddressFields
        }
        billingAddress {
          ...OrderAddressFields
        }
        payments {
          id
          amount
          createdAt
          method
          state
        }
        lines {
          id
        }
        productCount
      }
    }
  }

  query Refunds($paging: CursorPaging, $filter: RefundFilter, $sorting: [RefundSort!]) {
    refunds(paging: $paging, filter: $filter, sorting: $sorting) {
      edges {
        node {
          ...RefundFields
        }
      }
      pageInfo {
        ...PagingFields
      }
    }
  }
`

/** Mutations */

/** Hooks */

function useRefundsQuery(args: {
  options?: QueryHookOptions
  persist: boolean
  isActive: boolean
  defaultFilter?: RefundFilter
}): TUsePaginatedQuery<RefundsResult> {
  const { options, persist = false, isActive = false, defaultFilter = {} as RefundFilter } = args

  return usePaginatedQuery<RefundsResult, Refund>({
    query: REFUNDS_QUERY,
    accessor: 'refunds',
    options,
    persist,
    buildFilter: buildRefundsFilter(isActive, defaultFilter)
  })
}

/** Utils */

const buildRefundsFilter = (isActive: boolean, defaultFilter: RefundFilter) => (filter: FilterFields) => {
  const f = defaultFilter

  if (filter.brands && filter.brands.length > 0) {
    f.orderLine = { or: filter.brands.map((id) => ({ brandId: { eq: id } })) }
  }
  if (filter.state) {
    f.state = { eq: filter.state }
  }
  return f
}

export { useRefundsQuery }
export type { FilterFields, RefundsResult }
