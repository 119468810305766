import { DocumentNode, gql } from '@apollo/client'
import { Alert, Badge, Loader } from '@plusplusminus/plusplusdash'
import { useParams } from '@reach/router'
import { Page } from 'common/types'
import { Card } from 'components/Card'
import OrderDetails from 'components/Orders/OrderDetails'
import { PageHeader } from 'components/PageHeader'
import { useAccountingCodesQuery, useOrderQuery } from 'generated'
import { ACCOUNTING_CODE_FIELDS, ORDER_FIELDS } from 'hooks/orders'
import React from 'react'
import { formatDate } from 'utils'
import LineItem from '../../components/LineItem'

const paymentBadgeColor: Record<string, React.ComponentProps<typeof Badge>['color']> = {
  Created: 'gray',
  Authorized: 'yellow',
  Settled: 'green',
  Declined: 'red',
  Error: 'red',
  Cancelled: 'red'
}

const Order: Page<any> & { query: DocumentNode } = () => {
  const { orderId } = useParams()

  const { data, loading, error, refetch } = useOrderQuery({ variables: { id: orderId } })
  const { data: dataAccountingCodes, loading: loadingAccountingCodes } = useAccountingCodesQuery()
  const onRefetch = () => refetch()
  if (error?.graphQLErrors) {
    return (
      <Alert type="error">
        <Alert.Heading>Error fetching order</Alert.Heading>
        <Alert.Description>An unexpected error occurred when fetching the specified order.</Alert.Description>
      </Alert>
    )
  }

  if (loading || loadingAccountingCodes) {
    return <Loader isActive />
  }

  if (!data || !data.order || !dataAccountingCodes?.accountingCodes) {
    return null
  }

  const { order } = data
  const isCompleted = order.status === 'Completed'
  return (
    <div>
      <PageHeader>
        <div className="flex justify-between items-center w-full">
          Order Detail{' '}
          {!!order?.metadata?.checkout?.platform && <Badge color="green">{order?.metadata?.checkout?.platform}</Badge>}
        </div>
      </PageHeader>
      <div className="p-5">
        <OrderDetails order={order} />
        <Card className="mb-5">
          <Card.Header>
            <div className="flex justify-between">
              <span>Line Items</span>
            </div>
          </Card.Header>
          <Card.Content>
            {order.lines.map((li) => (
              <LineItem
                orderId={order.id}
                isCompleted={isCompleted}
                accountingCodes={dataAccountingCodes.accountingCodes}
                onRefetch={onRefetch}
                lineItem={li}
              />
            ))}
          </Card.Content>
        </Card>
        <Card>
          <Card.Header>
            <div className="flex justify-between">
              <span>Payments</span>
            </div>
          </Card.Header>
          <Card.Content>
            {order.payments.map((payment) => {
              return (
                <div className="flex space-x-5 items-center py-2 border-b border-gray-100">
                  <span>{formatDate(payment.createdAt)}</span>
                  <span>R {payment.amount}</span>
                  <span className="text-sm text-gray-600">{payment.method}</span>
                  <Badge color={paymentBadgeColor[payment.state]}>{payment.state}</Badge>
                </div>
              )
            })}
          </Card.Content>
        </Card>
      </div>
    </div>
  )
}

Order.query = gql`
  query Order($id: ID!) {
    order(id: $id) {
      ...OrderFields
    }
  }
  ${ORDER_FIELDS}
  ${ACCOUNTING_CODE_FIELDS}
  query AccountingCodes {
    accountingCodes {
      ...AccountingCodeFields
    }
  }

  mutation CreateLedgerItem($orderLineId: String!, $input: OrderLineLedgerInput!) {
    createLedgerItem(orderLineId: $orderLineId, input: $input) {
      ...OrderLedgerFields
    }
  }
  mutation UpdateLedgerItem($id: String!, $input: OrderLineLedgerInput!) {
    updateLedgerEntry(id: $id, input: $input) {
      ...OrderLedgerFields
    }
  }
  mutation DeleteLedgerItem($id: String!) {
    deleteLedgerEntry(id: $id)
  }
`

export { Order }
