import { useLocation } from '@reach/router'
import { useEffect, useReducer } from 'react'
import queryString from 'query-string'

type Action =
  | {
      type: 'set' | 'push' | 'remove'
      payload: { key: string; value: any }
    }
  | { type: 'reset' }
type AddFunction = (key: string, value: any) => void
type TUseFilter = {
  filter: any
  setFilter: AddFunction
  pushFilter: AddFunction
  removeFilter: (key: string, value?: string, options?: { isArray: boolean }) => void
  resetFilter: () => void
}
type UseFilterOptions = {
  persist?: boolean
  onFilterChange?: () => void
}

function reducer(prevState: any, action: Action) {
  switch (action.type) {
    case 'set': {
      const { key, value } = action.payload
      if (key in prevState && prevState[key] === value) {
        return prevState
      }
      return { ...prevState, [key]: value }
    }
    case 'push': {
      const { key, value } = action.payload
      const existing = prevState[key] || []
      const newState = [...existing, value]
      return { ...prevState, [key]: newState }
    }
    case 'remove': {
      const { key, value } = action.payload

      if (value && Array.isArray(prevState[key])) {
        const arr = prevState[key].filter((item: string) => item !== value)
        return { ...prevState, [key]: arr }
      }

      const prev = { ...prevState }
      delete prev[key]
      return prev
    }
    case 'reset':
      return {}
    default:
      return prevState
  }
}
const ignoreParams = ['after', 'first', 'before', 'last', 'sortField', 'direction', 'page']

function useFilter(options: UseFilterOptions): TUseFilter {
  const { persist = false, onFilterChange } = options
  const [state, dispatch] = useReducer(reducer, {})
  const location = useLocation()

  const { search } = location || {}

  useEffect(() => {
    if (search) {
      const params = queryString.parse(search, {
        arrayFormat: 'bracket',
        parseBooleans: true
      })

      Object.entries(params).forEach(([key, value]) => {
        if (!ignoreParams.includes(key)) {
          dispatch({ type: 'set', payload: { key, value } })
        }
      })
    }
  }, [])

  const setFilter = (key: string, value: any) => {
    dispatch({ type: 'set', payload: { key, value } })
    if (persist) {
      const url = new URL(window.location.toString())
      if (typeof value === 'boolean' || value) {
        if (Array.isArray(value)) {
          url.searchParams.delete(`${key}[]`)
          value.forEach((val) => {
            url.searchParams.append(`${key}[]`, val)
          })
        } else {
          url.searchParams.set(key, value)
        }
      } else {
        url.searchParams.delete(key)
      }
      history.pushState({}, '', url.toString())
    }
    onFilterChange && onFilterChange()
  }
  const pushFilter = (key: string, value: any) => {
    dispatch({ type: 'push', payload: { key, value } })
    if (persist) {
      const url = new URL(window.location.toString())
      url.searchParams.append(`${key}[]`, value)
      history.pushState({}, '', url.toString())
    }
    onFilterChange && onFilterChange()
  }
  const removeFilter = (key: string, value?: string, options?: { isArray: boolean }) => {
    dispatch({ type: 'remove', payload: { key, value } })
    if (persist) {
      const url = new URL(window.location.toString())
      if (options?.isArray) {
        url.searchParams.delete(`${key}[]`)
      } else {
        url.searchParams.delete(key)
      }
      history.pushState({}, '', url.toString())
    }
    onFilterChange && onFilterChange()
  }
  const resetFilter = () => {
    dispatch({ type: 'reset' })
    if (persist) {
      const url = new URL(window.location.toString().replace(window.location.search, ''))
      history.pushState({}, '', url.toString())
    }
    onFilterChange && onFilterChange()
  }

  return { filter: state, setFilter, pushFilter, removeFilter, resetFilter }
}

export { useFilter }
export type { TUseFilter }
