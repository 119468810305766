import { Badge } from '@plusplusminus/plusplusdash'
import { Link } from '@reach/router'
import { ExtendedUpdateActionInput } from 'components/Actions/Actions'
import ActionsTable from 'components/Actions/ActionsTable'
import { Card } from 'components/Card'
import { AccountEventType, TriggerFieldsFragment } from 'generated'
import { useMemo } from 'react'
import { convertToYesNo } from 'utils'

type TriggerProps = {
  trigger: TriggerFieldsFragment
}

export function Trigger(props: TriggerProps): JSX.Element {
  const actions: ExtendedUpdateActionInput[] = useMemo(() => {
    if (props.trigger?.actions) {
      return props.trigger.actions.map((action) => ({ ...action, messageName: action.message?.name ?? '' }))
    }
    return []
  }, [props.trigger?.actions])
  return (
    <Card>
      {(() => {
        const { trigger } = props
        return (
          <>
            <Card.Header>
              <h2>{trigger?.name}</h2>
              <Badge color={trigger?.enabled ? 'green' : 'gray'}>{trigger?.enabled ? 'Enabled' : 'Disabled'}</Badge>
            </Card.Header>
            <Card.Content>
              <div className="grid grid-cols-2 gap-x-8 gap-y-4">
                <div>
                  <strong>Account Event Type</strong>
                  <p>{trigger?.eventType}</p>
                </div>
                {trigger?.accountGroups && trigger.accountGroups.length > 0 ? (
                  <div>
                    <strong>Audience Segments</strong>
                    {trigger?.accountGroups?.map((group) => (
                      <Link className="block underline text-blue-400" to={`/audience-segments/${group.id}/view`}>
                        {group.name}
                      </Link>
                    ))}
                  </div>
                ) : null}
                <div className="col-start-1 col-span-2">
                  <strong>Criteria</strong>
                  <div className={styles.criteriaItem}>
                    <p>Runs once per account</p>
                    <p>{convertToYesNo(trigger?.criteria.oncePerAccount)}</p>
                  </div>
                  <div className={styles.criteriaItem}>
                    <p>Runs once per time period</p>
                    <p>{convertToYesNo(trigger?.criteria.oncePerTimePeriod)}</p>
                  </div>
                  <div className={styles.criteriaItem}>
                    <p>Max number of runs</p>
                    <p>{trigger?.criteria.maxNumberOfRuns ?? 'Not set'}</p>
                  </div>
                  {[AccountEventType.OrderConfirmation, AccountEventType.CreateCart].includes(trigger?.eventType) ? (
                    <>
                      <div className={styles.criteriaItem}>
                        <p>Order Total</p>
                        <p>{trigger?.criteria.orderTotal ? `R ${trigger.criteria.orderTotal}` : 'Not set'}</p>
                      </div>
                      <div className={styles.criteriaItem}>
                        <p>Number of Orders</p>
                        <p>{trigger?.criteria.numberOfOrders ?? 'Not set'}</p>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="col-span-2">
                  <strong>Actions</strong>
                  <ActionsTable actions={actions} />
                </div>
              </div>
            </Card.Content>
          </>
        )
      })()}
    </Card>
  )
}

const styles = {
  criteriaItem: 'flex justify-between items-center border-b border-gray-200 py-4',
  actionItem: 'grid grid-cols-8 gap-x-12 border-b border-gray-200 py-4'
}
