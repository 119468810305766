import { FC } from 'react'
import { useUser } from '../../hooks/useUser'
import { Layout } from '../Layout'
import { UserLoader } from '../UserLoader'
import { AuthenticatedApp } from './authenticated-app'
import { UnauthenticatedApp } from './unauthenticated-app'

export const App: FC = () => {
  const { user } = useUser()

  return (
    <UserLoader>
      {user ? (
        <Layout>
          <AuthenticatedApp />
        </Layout>
      ) : (
        <UnauthenticatedApp />
      )}
    </UserLoader>
  )
}
