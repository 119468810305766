import { ErrorType, Field } from './enums'

function getFormError(type: ErrorType, field?: Field): string | undefined {
  if (field === Field.EMAIL) {
    if (type === ErrorType.PATTERN) {
      return 'Please enter a valid email address'
    } else if (type === ErrorType.REQUIRED) {
      return 'Email address is required'
    }
  } else if (field === Field.PASSWORD) {
    if (type === ErrorType.REQUIRED) {
      return 'Password is required'
    }
  } else {
    if (type === ErrorType.REQUIRED) {
      return 'Required'
    }
  }
}

function formatPrice(value: number | string): string {
  const val = typeof value === 'string' ? parseFloat(value) : value
  return new Intl.NumberFormat('en-za', {
    style: 'currency',
    currency: 'ZAR'
  }).format(val)
}

export { getFormError, formatPrice }

export function arraysIntersect<T>(arr1: T[], arr2: T[]): boolean {
  return arr1.reduce((intersects, role) => {
    return intersects || arr2.includes(role)
  }, false as boolean)
}
