import { gql } from '@apollo/client'
import { Loader } from '@plusplusminus/plusplusdash'
import QueryBuilder from 'components/QueryBuilder'
import { useAccountGroupConfigQuery } from 'generated'
import React, { useMemo } from 'react'
import { JsonTree, Settings } from 'react-awesome-query-builder'

type AccountGroupQueryBuilderProps = {
  onChange?: (jsonLogic: any) => void
  tree?: JsonTree
  settings?: Partial<Settings>
}

export default function AccountGroupQueryBuilder(props: AccountGroupQueryBuilderProps): JSX.Element {
  const { onChange, tree, settings } = props
  const { data, loading } = useAccountGroupConfigQuery()

  const config = useMemo(() => ({ fields: data?.accountGroupConfig.fields }), [data])

  if (loading || !data) return <Loader isActive />

  return <QueryBuilder tree={tree} config={config} settings={settings} onChange={onChange} />
}

AccountGroupQueryBuilder.query = gql`
  query AccountGroupConfig {
    accountGroupConfig {
      fields
    }
  }
`
