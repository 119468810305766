import { gql } from '@apollo/client'

import { Button, Checkbox, FormError, FormLabel, Input } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import AccountEventSelect from 'components/AccountEventSelect'
import AccountGroupComboBox from 'components/AccountGroupComboBox'

import Actions from 'components/Actions'
import { ExtendedUpdateActionInput } from 'components/Actions/Actions'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import {
  AccountEventType,
  CreateActionInput,
  CreateTriggerInput,
  Message,
  SortDirection,
  useCreateTriggerMutation
} from 'generated'
import { EmailTemplatesCollection, useEmailTemplates } from 'hooks/useEmailTemplates'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import MessagesTable, { MessagesResult } from 'pages/Message/MessagesTable'
import { useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

const limit = 5

const buildFilter = (filter: any) => {
  const f: any = {}
  if (filter.search) {
    f.or = [{ name: { iLike: `%${filter.search}%` } }, { title: { iLike: `%${filter.search}%` } }]
  }
  if ('enabled' in filter) {
    f.enabled = { is: filter.enabled }
  }
  return f
}

type FormData = CreateTriggerInput

export default function CreateTrigger(props: RouteComponentProps): JSX.Element {
  const { register, handleSubmit, errors, control, setValue, watch } = useForm<FormData>({
    defaultValues: { enabled: true }
  })
  const actionsRef = useRef<ExtendedUpdateActionInput[] | null>(null)
  const eventType = watch('eventType')
  const paginatedMessages = usePaginatedQuery<MessagesResult, Partial<Message>>({
    query: MessagesTable.query,
    accessor: 'messages',
    options: {},
    persist: false,
    limit,
    buildFilter,
    defaultSort: { sortField: 'createdAt', direction: SortDirection.Desc }
  })
  const { data: emailTemplatesData, loading: loadingEmailTemplates } = useEmailTemplates()
  const reloadMessages = () => paginatedMessages.query.refetch()
  const [createTrigger, { loading }] = useCreateTriggerMutation({
    onCompleted: (data) => {
      toast.success('Successfully created trigger')
      props.navigate?.(`/triggers/${data.createTrigger.id}/view`)
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not create trigger')
    }
  })

  const onSubmit = ({ ...input }: FormData) => {
    const newActions = JSON.parse(JSON.stringify([...(actionsRef.current ?? [])])) as ExtendedUpdateActionInput[]
    const newCriteria = JSON.parse(JSON.stringify({ ...input.criteria }))
    if (Object.is(newCriteria.maxNumberOfRuns, NaN) || typeof newCriteria.maxNumberOfRuns !== 'number') {
      delete newCriteria.maxNumberOfRuns
    }
    if (newActions && newActions.length > 0) {
      newActions.forEach((action) => {
        delete action.messageName
        if (!action.delay || !action.delay.value || Object.is(action.delay.value, NaN) || action.delay.value <= 0) {
          action.delay = null
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { __typename, ...rest } = action.delay as any
          action.delay = { ...rest }
        }
        if (
          !action.expiryDate ||
          !action.expiryDate.value ||
          Object.is(action.expiryDate.value, NaN) ||
          action.expiryDate.value <= 0
        ) {
          action.expiryDate = null
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { __typename, ...rest } = action.expiryDate as any
          action.expiryDate = { ...rest }
        }
      })
    }
    createTrigger({
      variables: { input: { ...input, criteria: newCriteria, actions: newActions as CreateActionInput[] } }
    })
  }
  return (
    <div>
      <PageHeader>Create Trigger</PageHeader>
      <div className="p-5">
        <Card>
          <Card.Content>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-2 gap-x-8 gap-y-4 mb-4 items-start">
                <FormLabel>
                  <strong>Name*</strong>
                  <Input width="full" variant="standard" name="name" ref={register({ required: 'Name is required' })} />
                  {errors.name && <FormError>{errors.name.message}</FormError>}
                </FormLabel>
                <FormLabel>
                  <strong>Account Event Type</strong>
                  <Controller
                    control={control}
                    name="eventType"
                    rules={{ required: 'Event Type is required' }}
                    render={(props) => <AccountEventSelect {...props} />}
                  />
                  {errors.eventType && <FormError>{errors.eventType.message}</FormError>}
                </FormLabel>
                <FormLabel>
                  <strong className="mr-2">Enabled</strong>
                  <Controller
                    control={control}
                    name="enabled"
                    render={({ onChange, value }) => (
                      <Checkbox id="enabled" className="block" checked={value} onChange={() => onChange(!value)} />
                    )}
                  />
                </FormLabel>
                <FormLabel>
                  <strong>Audience Segments</strong>
                  <Controller
                    control={control}
                    name="accountGroupIds"
                    render={({ onChange: onChangeAccounts, value: accountGroupIds }) => (
                      <AccountGroupComboBox
                        accountGroupIds={accountGroupIds}
                        onChangeAccountGroups={onChangeAccounts}
                        hasOperatorSelector={false}
                      />
                    )}
                  />
                </FormLabel>
                <div className="col-start-1 col-span-2">
                  <strong>Criteria</strong>
                  <FormLabel className={styles.criteriaItem}>
                    <p>Runs once per account</p>
                    <Controller
                      control={control}
                      name="criteria.oncePerAccount"
                      render={({ onChange, value }) => (
                        <Checkbox id="criteria.oncePerAccount" checked={value} onChange={() => onChange(!value)} />
                      )}
                    />
                  </FormLabel>
                  <FormLabel className={styles.criteriaItem}>
                    <p className="self-start">Max number of runs</p>
                    <div className="flex flex-col items-end">
                      <Input
                        id="criteria.maxNumberOfRuns"
                        name="criteria.maxNumberOfRuns"
                        type="number"
                        ref={register({ valueAsNumber: true, min: 0 })}
                        variant="standard"
                        min={0}
                      />
                      <div
                        className="underline cursor-pointer"
                        onClick={() => setValue('criteria.maxNumberOfRuns', null)}
                      >
                        Unset
                      </div>
                    </div>
                  </FormLabel>
                  {[AccountEventType.OrderConfirmation, AccountEventType.CreateCart].includes(eventType) ? (
                    <>
                      <FormLabel className={styles.criteriaItem}>
                        <div className="pr-8">
                          <p className="self-start">Order Total</p>
                          <p className="text-xs text-gray-700">
                            The order value for which this trigger should pass and run. Setting this to zero would mean
                            any successful order will pass validation. Setting this to 1500, for example, would mean
                            that only a successful order with a total value of R1500 will have the actions run.
                          </p>
                        </div>
                        <div className="flex flex-col items-end">
                          <Input
                            id="criteria.orderTotal"
                            name="criteria.orderTotal"
                            ref={register({ valueAsNumber: true, min: 0 })}
                            variant="standard"
                            min={0}
                          />
                          <div
                            className="underline cursor-pointer"
                            onClick={() => setValue('criteria.orderTotal', null)}
                          >
                            Unset
                          </div>
                        </div>
                      </FormLabel>
                      <FormLabel className={styles.criteriaItem}>
                        <div className="pr-8">
                          <p className="self-start">Number of Orders</p>
                          <p className="text-xs text-gray-700">
                            Limit this order confirmation trigger to users who have had X many previous orders. Setting
                            this to zero would mean any successful order will pass validation.
                          </p>
                        </div>
                        <div className="flex flex-col items-end">
                          <Input
                            id="criteria.numberOfOrders"
                            name="criteria.numberOfOrders"
                            ref={register({ valueAsNumber: true, min: 0 })}
                            variant="standard"
                            min={0}
                          />
                          <div
                            className="underline cursor-pointer"
                            onClick={() => setValue('criteria.numberOfOrders', null)}
                          >
                            Unset
                          </div>
                        </div>
                      </FormLabel>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="">
                <strong>Actions</strong>
                <Actions
                  actionsRef={actionsRef}
                  initialActions={[]}
                  {...{
                    paginatedMessages,
                    emailTemplateCollection: emailTemplatesData?.emailTemplatesCollection as EmailTemplatesCollection,
                    reloadMessages
                  }}
                />
              </div>
              <Button disabled={loading || loadingEmailTemplates} colorScheme="green" type="submit" variant="primary">
                Save
              </Button>
            </form>
          </Card.Content>
        </Card>
      </div>
    </div>
  )
}

const styles = {
  criteriaItem: 'flex justify-between items-center border-b border-gray-200 py-4'
}

CreateTrigger.mutation = gql`
  mutation CreateTrigger($input: CreateTriggerInput!) {
    createTrigger(input: $input) {
      id
    }
  }
`
