import { RouteComponentProps } from '@reach/router'
import React from 'react'
import logo from '../../images/tle-logo-stacked-black.png'

const ResetSuccess: React.FC<RouteComponentProps<any>> = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto object-contain" src={logo} alt="TLE Logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Password successfully reset</h2>
      </div>
    </div>
  )
}

export default ResetSuccess
