import { DocumentNode, gql } from '@apollo/client'
import { Form, FormError, FormLabel, Input, Modal } from '@plusplusminus/plusplusdash'
import Button from 'components/Button'
import { useUpdateConfigMutation } from 'generated'
import React from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

interface AppConfigEditProps {
  isOpen: boolean
  onClose: () => void
  onReload: () => void
  currentAppVersionValue: string
  currentAppVersionId: string
}
const AppConfigEdit: React.FC<AppConfigEditProps> & { query: DocumentNode } = ({
  isOpen,
  onClose,
  onReload,
  currentAppVersionId,
  currentAppVersionValue
}) => {
  const { register, handleSubmit, errors, watch, setValue } = useForm()
  const appVersion = watch('minimalAppVersion')
  const [updateConfig, { loading }] = useUpdateConfigMutation({
    onCompleted: () => {
      toast.success('Minimum app version updated successfully')
      onReload()
      onClose()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Minimum app version could not be updated')
    }
  })

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue('minimalAppVersion', e.target.value.trim().replace(' ', ''))
  }

  const onSubmit = (data: { minimalAppVersion: string }) => {
    updateConfig({ variables: { id: currentAppVersionId, input: { value: data.minimalAppVersion } } })
  }
  const hasErrors = Object.keys(errors).length > 0
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <Modal.Title>App Config</Modal.Title>
      <Modal.Description className="my-4">
        <Form className="mb-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <FormLabel htmlFor="minimalAppVersion">
              <span className="font-bold text-sm">Minimum App Version</span>
              <Input
                id="minimalAppVersion"
                name="minimalAppVersion"
                autoComplete="off"
                variant="standard"
                defaultValue={currentAppVersionValue}
                value={appVersion}
                onChange={onChange}
                ref={register({ required: true, pattern: /^(\d+\.)?(\d+\.)?(\*|\d+)$/ })}
              />
              {hasErrors && <FormError>Please enter a valid app version</FormError>}
            </FormLabel>
          </div>
          <div className="flex justify-end gap-2">
            <Button variant="plain" onClick={onClose} type="button">
              Close
            </Button>
            <Button
              isDisabled={currentAppVersionValue === appVersion || loading}
              isLoading={loading}
              variant="primary"
              colorScheme="green"
              type="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal.Description>
    </Modal>
  )
}

AppConfigEdit.query = gql`
  mutation UpdateConfig($id: String!, $input: UpdateConfigInput!) {
    updateConfig(id: $id, input: $input)
  }
`

export default AppConfigEdit
