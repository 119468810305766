import { InformationCircleIcon, PlusCircleIcon } from '@heroicons/react/solid'
import { ActionEventType, UpdateActionInput } from 'generated'
import { EmailTemplatesCollection } from 'hooks/useEmailTemplates'
import { TUsePaginatedQuery } from 'hooks/usePaginatedQuery'
import { MessagesResult } from 'pages/Message/MessagesTable'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import ActionModal from './ActionModal'
import ActionsTable from './ActionsTable'

export type ExtendedUpdateActionInput = UpdateActionInput & {
  messageName?: string
}

interface ActionsProps {
  initialActions?: ExtendedUpdateActionInput[]
  reloadMessages: () => void
  paginatedMessages: TUsePaginatedQuery<MessagesResult>
  emailTemplateCollection: EmailTemplatesCollection
  actionsRef: React.MutableRefObject<UpdateActionInput[] | null>
}
const Actions = ({
  initialActions = [],
  reloadMessages,
  paginatedMessages,
  emailTemplateCollection,
  actionsRef
}: ActionsProps): JSX.Element => {
  const [actions, setActions] = useState<ExtendedUpdateActionInput[]>(initialActions)

  const onDeleteAction = (actionId: string) => {
    toast.success('Action deleted. Click Save to apply changes', {
      icon: <InformationCircleIcon className="w-6 h-6 text-blue-500" />
    })
    const index = actions.findIndex(({ id }) => actionId === id)
    const newActions = [...actions.slice(0, index), ...actions.slice(index + 1)]
    setActions(newActions)
    actionsRef.current = newActions
  }

  const onAddAction = (actionType?: ActionEventType, actionTypeIndex?: number) => (
    action: ExtendedUpdateActionInput
  ) => {
    toast.success(`Action ${action?.id ? 'saved' : 'added'}. Click Save to apply changes`, {
      icon: <InformationCircleIcon className="w-6 h-6 text-blue-500" />
    })
    let newActions = [...actions]
    if (action?.id) {
      const actionsIndex = actions.findIndex((savedAction) => savedAction.id === action.id)
      newActions = [...actions.slice(0, actionsIndex), action, ...actions.slice(actionsIndex + 1)]
    } else {
      if (!actionType) {
        newActions = [...actions, action]
      } else {
        let totalCount = -1
        for (let i = 0; i < actions.length; i++) {
          if (actions[i].type === actionType) {
            totalCount++
            if (totalCount === actionTypeIndex) {
              newActions = [...actions.slice(0, i), action, ...actions.slice(i + 1)]
              break
            }
          }
        }
      }
    }

    setActions(newActions)
    actionsRef.current = newActions
  }

  return (
    <div>
      <ActionsTable
        actions={actions}
        onAddAction={onAddAction}
        onDeleteAction={onDeleteAction}
        reloadMessages={reloadMessages}
        paginatedMessages={paginatedMessages}
        emailTemplateCollection={emailTemplateCollection}
      />
      <ActionModal
        isLoading={false}
        defaultValues={{}}
        modalTitle="Create Action"
        renderIcon={() => (
          <span className="py-4 w-60 text-left  flex items-center space-x-1">
            <PlusCircleIcon className="w-6 h-6" />
            <span>Add Action</span>
          </span>
        )}
        onAddAction={onAddAction()}
        {...{
          paginatedMessages,
          emailTemplateCollection,
          reloadMessages
        }}
      />
    </div>
  )
}

export default Actions
