import { Loader } from '@plusplusminus/plusplusdash'
import { useUser } from '../../hooks/useUser'

export const UserLoader: React.FC = ({ children }) => {
  const { loadingUser } = useUser()

  return loadingUser ? (
    <div className="flex h-full justify-center items-center">
      <Loader isActive />
    </div>
  ) : (
    <>{children}</>
  )
}
