import { Reducer, useCallback, useReducer } from 'react'

type DeleteState = {
  id?: string
  name?: string
  isOpen: boolean
}

type Action = {
  type: 'open' | 'close'
  payload?: {
    id?: string
    name?: string
  }
}

type UseDeleteModal = {
  isOpen: boolean
  id?: string
  name?: string
  onClose: () => void
  openModal: (id: string, name?: string) => void
}

function reducer(prevState: DeleteState, action: Action) {
  switch (action.type) {
    case 'open': {
      return {
        ...prevState,
        isOpen: true,
        ...action.payload
      }
    }
    case 'close': {
      return { isOpen: false }
    }
  }
}

export function useDeleteModal(): UseDeleteModal {
  const [{ isOpen, id, name }, dispatch] = useReducer<Reducer<DeleteState, Action>>(reducer, { isOpen: false })

  const closeModal = useCallback(() => {
    dispatch({ type: 'close' })
  }, [])

  const openModal = useCallback((id: string, name?: string) => {
    dispatch({ type: 'open', payload: { id, name } })
  }, [])

  return { isOpen, id, name, onClose: closeModal, openModal }
}
