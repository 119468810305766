import { RouteComponentProps } from '@reach/router'
import { useForm } from 'react-hook-form'
import { Input, FormLabel, Form, FormError, FormField } from '@plusplusminus/plusplusdash'
import { emailRegex } from '../../common/constants'
import { getFormError } from '../../common/utils'
import { Field } from '../../common/enums'
import { useForgotPassword } from '../../hooks/useForgotPassword'
import logo from '../../images/tle-logo-stacked-black.png'
import toast, { Toaster } from 'react-hot-toast'
import Button from 'components/Button'

export const ForgotPassword: React.FC<RouteComponentProps> = (props) => {
  const { register, handleSubmit, errors } = useForm()
  const { sendForgotPassword, loading } = useForgotPassword({
    onCompleted: () => {
      toast.success('Password reset email sent')
      setTimeout(() => {
        props?.navigate?.(`/`)
      }, 2000)
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not send password reset email')
    }
  })

  const onSubmit = (data: { email: string }) => sendForgotPassword(data.email)

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Toaster />
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="TLE Logo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Request password reset</h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md h-full">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <Form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <FormField direction="column">
              <FormLabel htmlFor="email">Email address</FormLabel>
              <Input
                as="input"
                variant="outline"
                width="full"
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                ref={register({ required: true, pattern: emailRegex })}
              />
              {errors.email ? <FormError>{getFormError(errors.email.type, Field.EMAIL)}</FormError> : null}
            </FormField>
            <div>
              <Button
                variant="primary"
                type="submit"
                colorScheme="indigo"
                className="w-full justify-center"
                isDisabled={loading}
                isLoading={loading}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}
