import { Page } from 'common/types'
import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { Tab } from '@headlessui/react'
import { OrdersPaginated } from 'components/Orders/OrdersPaginated'
import { OrdersFilter } from 'components/Orders'
import { useOrdersQuery } from 'hooks/orders'
import { Alert, Box, Loader } from '@plusplusminus/plusplusdash'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import OrdersExport from './OrdersExport'
import PaymentRequirementReport from './PaymentRequirementReport'
import SalesCommissionReport from './SalesCommissionReport'

const styles = {
  tab:
    'w-full border-b-2 text-blue-600 bg-white py-2.5 text-sm leading-5 font-medium focus:outline-none hover:border-blue-500',
  tabList: 'flex rounded-xl w-full justify-center',
  tabUnselected: 'border-transparent',
  tabSelected: 'border-blue-500'
}

const Orders: Page = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const { query, pagination, sort, filtering, queryFields } = useOrdersQuery({ persist: true, isActive })
  const { setFilter } = filtering
  const { data, previousData, loading, error } = query
  const tableData = data || previousData
  const location = useLocation()

  const { search } = location || {}

  const onTabChange = (index: number) => {
    if (index === 0) {
      setIsActive(false)
      setFilter('isActive', false)
      setFilter('status', 'Completed')
    } else {
      setIsActive(true)
      setFilter('isActive', true)
      setFilter('status', 'Pending')
    }
    setSelectedIndex(index)
  }

  const initialState = (index: number) => {
    if (index === 0) {
      setIsActive(false)
    } else {
      setIsActive(true)
    }
    setSelectedIndex(index)
  }

  const isSelected = (index: number) => {
    return selectedIndex === index
  }

  useEffect(() => {
    if (search) {
      const params = queryString.parse(search, {
        arrayFormat: 'bracket',
        parseBooleans: true
      })
      if ('isActive' in params) {
        if (params.isActive) {
          initialState(1)
        } else {
          initialState(0)
        }
      }
    } else {
      onTabChange(0)
    }
  }, [search])

  return (
    <div>
      {error ? (
        <Alert type="error">
          <Alert.Heading>There was an error fetching orders.</Alert.Heading>
        </Alert>
      ) : !previousData && loading ? (
        <Loader isActive />
      ) : tableData ? (
        <Box className="flex flex-col">
          <Box className="p-5 bg-white border-b border-gray-200 flex justify-between">
            <h1 className="text-lg">Orders</h1>
            <div className="flex gap-2">
              <PaymentRequirementReport />
              <SalesCommissionReport />
              <OrdersExport query={queryFields} />
            </div>
          </Box>
          <div className="bg-white w-full flex py-2 px-5 justify-between">
            <OrdersFilter filtering={filtering} sort={sort} />
          </div>
          <Tab.Group selectedIndex={selectedIndex} onChange={onTabChange}>
            <Tab.List className={styles.tabList}>
              <Tab as={React.Fragment}>
                <button
                  className={cn(styles.tab, {
                    [styles.tabSelected]: isSelected(0),
                    [styles.tabUnselected]: !isSelected(0)
                  })}
                >
                  Complete Orders
                </button>
              </Tab>
              <Tab as={React.Fragment}>
                <button
                  className={cn(styles.tab, {
                    [styles.tabSelected]: isSelected(1),
                    [styles.tabUnselected]: !isSelected(1)
                  })}
                >
                  Incomplete Orders
                </button>
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <OrdersPaginated query={query} sort={sort} pagination={pagination} />
              </Tab.Panel>
              <Tab.Panel>
                <OrdersPaginated query={query} sort={sort} pagination={pagination} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </Box>
      ) : null}
    </div>
  )
}

export { Orders }
