import cn from 'classnames'
import { Maybe, Tag } from 'generated'

function buildTagSelectOptions(
  tags: Omit<Tag, '__typename'>[] | undefined
): { key: string; value: string; label: string }[] {
  return (
    tags?.map((tag) => ({
      key: tag.id,
      value: tag.id,
      label: tagCategory(tag.name.charAt(0) + tag.name.slice(1), tag.categoryLabel)
    })) || []
  )
}

function buildTagsMap(tags: Omit<Tag, '__typename'>[] | undefined): Record<string, string> {
  return (
    tags?.reduce((acc, tag) => {
      const label = tagCategory(tag.name.charAt(0) + tag.name.slice(1), tag.category)
      const key = tag.id
      acc[key] = label
      return acc
    }, {} as Record<string, string>) || {}
  )
}

function buildTagBadges(tags: Omit<Tag, '__typename'>[] | undefined): JSX.Element[] {
  return (
    tags?.map((tag) => {
      return (
        <span
          className={cn(
            'mr-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800'
          )}
        >
          {tagCategory(tag.name, tag.categoryLabel)}
        </span>
      )
    }) || []
  )
}

function tagCategory(tag: string, category?: Maybe<string>): string {
  return `${tag}${category ? ` - ${category}` : ''}`
}

export { buildTagSelectOptions, buildTagBadges, buildTagsMap, tagCategory }
