import React, { useState } from 'react'
import { DeleteModal } from '../Modals/Delete'
import { Button } from '@plusplusminus/plusplusdash'
import { useDeleteUsers } from 'hooks/users'
import toast from 'react-hot-toast'

interface DeleteUserProps {
  reset: () => void
  ids: string[]
}

const DeleteUser: React.FC<DeleteUserProps> = (props) => {
  const { reset, ids } = props
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { deleteUsers, loading } = useDeleteUsers({
    onCompleted: () => {
      toast.success('Successfully deleted users.')
      setIsModalOpen(false)
      reset()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Server error. could not delete users.')
    }
  })

  return (
    <>
      <DeleteModal
        isLoading={loading}
        headline="Delete Users"
        description="Are you sure you want to delete the selected users?"
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onDelete={() => deleteUsers(ids)}
      />
      <Button colorScheme="red" variant="plain" className="text-red-500 mr-1" onClick={() => setIsModalOpen(true)}>
        Delete
      </Button>
    </>
  )
}

export { DeleteUser }
