import { DocumentNode, gql } from '@apollo/client'
import { Alert, Loader } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import { OrdersFilter } from 'components/Orders'
import { OrdersPaginated } from 'components/Orders/OrdersPaginated'
import { BrandQuery } from 'generated'
import { ORDER_LINE_FIELDS, useOrdersQuery } from 'hooks/orders'
import React from 'react'

const BrandOrdersTable: React.FC<RouteComponentProps<{ brand: NonNullable<BrandQuery['brand']> }>> & {
  query: DocumentNode
} = ({ brand }) => {
  const { query, pagination, sort, filtering } = useOrdersQuery({
    persist: true,
    isActive: false,
    defaultFilter: { lines: { brandId: { eq: brand?.id ?? '' } }, status: { eq: 'Completed' } }
  })
  const { data, previousData, loading, error } = query
  const tableData = data || previousData

  return (
    <div>
      {error ? (
        <Alert type="error">
          <Alert.Heading>There was an error fetching orders.</Alert.Heading>
        </Alert>
      ) : !previousData && loading ? (
        <Loader isActive />
      ) : tableData ? (
        <>
          <div className="bg-white w-full flex py-2 px-5 justify-between">
            <OrdersFilter filtering={filtering} sort={sort} isBrand />
          </div>
          <OrdersPaginated query={query} sort={sort} pagination={pagination} />
        </>
      ) : null}
    </div>
  )
}

BrandOrdersTable.query = gql`
  query BrandOrdersQuery($filter: OrderLineFilter, $sorting: [OrderLineSort!], $paging: CursorPaging) {
    orderLines(filter: $filter, sorting: $sorting, paging: $paging) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          ...OrderLineFields
          order {
            id
            orderNumber
            isActive
            status
            account {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
  ${ORDER_LINE_FIELDS}
`

export default BrandOrdersTable
