import React, { forwardRef } from 'react'
import { styles } from './styles'

interface SideNavProps {
  children?: React.ReactNode
  renderFooter?: () => JSX.Element
  renderLogo?: () => JSX.Element
}

export const SideNav = forwardRef<HTMLDivElement, SideNavProps>((props, ref) => {
  const { children, renderFooter, renderLogo } = props
  return (
    <div ref={ref} className={styles.container}>
      <div className={styles.inner}>
        {renderLogo && <div className={styles.logo}>{renderLogo()}</div>}
        <nav className={styles.nav} aria-label="Sidebar">
          {children}
        </nav>
      </div>
      {renderFooter && <div className={styles.footer}>{renderFooter()}</div>}
    </div>
  )
})
