import { gql } from '@apollo/client'
import { Form, FormError, FormLabel, Input } from '@plusplusminus/plusplusdash'
import { RouteComponentProps } from '@reach/router'
import AccountGroupQueryBuilder from 'components/AccountGroupQueryBuilder'
import { Card } from 'components/Card'
import { PageHeader } from 'components/PageHeader'
import { useCreateAccountGroupMutation } from 'generated'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import Button from 'components/Button'

export default function CreateAccountGroup(props: RouteComponentProps): JSX.Element {
  const { control, register, handleSubmit, errors } = useForm()
  const [createAccountGroup, { loading }] = useCreateAccountGroupMutation({
    onCompleted: (data) => {
      toast.success('Audience Segment created successfully')
      props?.navigate?.(`/audience-segments/${data.createOneAccountGroup.id}/view`)
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not create audience segment')
    }
  })

  const onSubmit = async (data: any) => {
    createAccountGroup({ variables: { input: data } })
  }

  return (
    <div>
      <PageHeader>Create Audience Segment</PageHeader>
      <div className="p-5">
        <Card>
          <Card.Content>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-4 items-start">
                <FormLabel>
                  <strong>Name*</strong>
                  <Input variant="standard" name="name" ref={register({ required: 'Name is required' })} />
                  {errors.name && <FormError>{errors.name.message}</FormError>}
                </FormLabel>
                <FormLabel>
                  <strong>Description</strong>
                  <Input variant="standard" width="lg" name="description" ref={register} />
                </FormLabel>
                <FormLabel>
                  <strong>Query Definition</strong>
                </FormLabel>
                <div className="self-stretch">
                  <Controller
                    control={control}
                    name="definition"
                    render={({ onChange }) => <AccountGroupQueryBuilder onChange={(json) => onChange(json)} />}
                  />
                </div>
                <Button colorScheme="green" type="submit" variant="primary" isDisabled={loading} isLoading={loading}>
                  Save
                </Button>
              </div>
            </Form>
          </Card.Content>
        </Card>
      </div>
    </div>
  )
}

CreateAccountGroup.mutation = gql`
  mutation CreateAccountGroup($input: AccountGroupInput!) {
    createOneAccountGroup(input: { accountGroup: $input }) {
      id
      name
    }
  }
`
