import { gql, useMutation } from '@apollo/client'
import toast from 'react-hot-toast'
import Button from 'components/Button'

export default function PaymentRequirementReport(): JSX.Element {
  const [paymentRequirements, { loading }] = useMutation(PaymentRequirementReport.mutation, {
    onCompleted: () => {
      toast.success('Sent report to email. PLease note this may take a few minutes')
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. Could not send report.')
    }
  })

  const onSubmit = async () => {
    await paymentRequirements()
  }

  return (
    <Button colorScheme="black" variant="primary" onClick={onSubmit} disabled={loading}>
      {loading ? 'Sending...' : 'Payment Requirements Report'}
    </Button>
  )
}

PaymentRequirementReport.mutation = gql`
  mutation paymentRequirements {
    paymentRequirements
  }
`
