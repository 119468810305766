import { Popover } from '@plusplusminus/plusplusdash'
import { Link } from '@reach/router'
import { useDeleteModal } from 'hooks/useDeleteModal'
import { EmailTemplatesCollection } from 'hooks/useEmailTemplates'
import CreateMessageCampaignModal from 'pages/MessageCampaigns/CreateMessageCampaignModal'
import { useState } from 'react'
import DeleteMessageModal from './DeleteMessageModal'

interface MessageActionsProps {
  messageId: string
  messageName: string
  onDeleteMessage?: () => void
  isLast?: boolean
  emailTemplateCollection: EmailTemplatesCollection
}
const MessageActions = ({
  messageId,
  messageName,
  onDeleteMessage,
  emailTemplateCollection
}: MessageActionsProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const onOpen = () => setIsOpen(true)
  const deleteModalProps = useDeleteModal()
  const openDelete = (id: string, name: string) => () => {
    deleteModalProps.openModal(id, name)
  }
  return (
    <>
      <DeleteMessageModal
        {...deleteModalProps}
        onDelete={() => {
          deleteModalProps.onClose()
          onDeleteMessage?.()
        }}
      />
      <Popover style={{ position: 'static' }}>
        {({ open }: { open: boolean }) => {
          return (
            <>
              <Popover.Button>Actions</Popover.Button>
              {(open || isOpen) && (
                <Popover.Panel static className="right-5">
                  <div className="flex flex-col mt-2 space-y-2 py-2 px-4">
                    <Link to={`/messages/${messageId}/view`}>
                      <button type="button">View</button>
                    </Link>

                    <Link to={`/messages/${messageId}/edit`}>
                      <button type="button">Edit</button>
                    </Link>

                    <button
                      type="button"
                      onClick={openDelete(messageId, messageName)}
                      className="text-red-500 text-left"
                    >
                      Delete
                    </button>
                    <div>
                      <CreateMessageCampaignModal
                        isOpen={isOpen}
                        onClose={onClose}
                        onOpen={onOpen}
                        onCompleted={onClose}
                        messageId={messageId}
                        messageName={messageName}
                        emailTemplateCollection={emailTemplateCollection}
                      />
                    </div>
                  </div>
                </Popover.Panel>
              )}
            </>
          )
        }}
      </Popover>
    </>
  )
}

export default MessageActions
