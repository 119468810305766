import { navigate, RouteComponentProps } from '@reach/router'
import { Form, FormField, FormLabel, FormError, Input, Button } from '@plusplusminus/plusplusdash'
import { useForm } from 'react-hook-form'
import { Field } from '../../common/enums'
import { getFormError } from '../../common/utils'
import logo from '../../images/tle-logo-stacked-black.png'
import { parse } from 'querystring'
import { useState } from 'react'
import { useResetPasswordMutation } from 'generated'
import { gql, DocumentNode } from '@apollo/client'
import toast from 'react-hot-toast'

export const ResetPassword: React.FC<RouteComponentProps<{ token: string }>> & { mutation: DocumentNode } = (props) => {
  const query = props.location?.search ? parse(props.location?.search.substring(1)) : {}

  const [error, setError] = useState<string>()
  const { register, errors, handleSubmit } = useForm()
  const [resetPassword] = useResetPasswordMutation({
    onCompleted: (data) => {
      if (data.resetOnePassword) {
        try {
          navigate('/success')
        } catch (e) {
          console.error(e)
        }
      }
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not reset password')
    }
  })

  const onSubmit = async (data: { password: string; confirmPassword: string }) => {
    const { password, confirmPassword } = data

    if (password !== confirmPassword) {
      setError('Passwords do not match')
      return
    }

    if (query.token && query.email && typeof query.token === 'string' && typeof query.email === 'string') {
      await resetPassword({ variables: { input: { password, email: query.email, token: query.token } } })
    }
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      {!query.token || !query.email ? (
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={logo} alt="TLE Logo" />
          <h3 className="mt-6 text-xl font-medium text-center">
            Something is missing. Please check your email for any password reset requests that have been made.
          </h3>
        </div>
      ) : (
        <>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto h-12 w-auto" src={logo} alt="TLE Logo" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Reset password</h2>
          </div>
          <div className="mt-8 mx-auto w-4/5 md:w-full max-w-full md:max-w-md">
            <div className="bg-white py-8 px-8 md:px-10 shadow sm:rounded-lg">
              <Form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <FormField direction="column">
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input
                    as="input"
                    variant="outline"
                    width="full"
                    ref={register({ required: true })}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                  />
                  {errors.password ? <FormError>{getFormError(errors.password.type, Field.PASSWORD)}</FormError> : null}
                </FormField>
                <FormField direction="column">
                  <FormLabel htmlFor="confirmPassword">Confirm Password</FormLabel>
                  <Input
                    as="input"
                    variant="outline"
                    width="full"
                    ref={register({ required: true })}
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                  />
                  {errors.password ? <FormError>{getFormError(errors.password.type, Field.PASSWORD)}</FormError> : null}
                </FormField>
                {error ? <FormError>{error}</FormError> : null}

                <Button variant="primary" type="submit" colorScheme="black" className="w-full justify-center">
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

ResetPassword.mutation = gql`
  mutation ResetPassword($input: ResetOnePasswordInput!) {
    resetOnePassword(input: $input)
  }
`
